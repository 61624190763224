
import React, { useEffect, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from 'react-toastify';
import { BASE_URL, FRONTEND_URL, IMAGE_URL } from '../../config/axios';
import Select from 'react-select';
import axios from 'axios';
import { customSortByKey, formatCurrencyIndian, formatDateTime, formatMonthYear, handleAllError, inputMaxLimit } from '../CustomFunctions';
import { formattedDate, formattedDateLong } from '../Date';
import { AddwithBlueCircle, Attachment, CheckGreen, CrossRed, CrosswithGreyCircle, DeleteDustbin, DropdownArrow, DropdownArrowOption, Exit, Eye } from '../AllSvg';
import usePermission from '../../config/permissions';
import numberToWords from 'number-to-words';

const AddSeprationFormat = ({ getSeprationFormats }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setFormData({
      name: "",
      sub_company: sessionStorage.getItem('company_id'),
      sepration_headings: [],
    })
    setErrors({});
    setInputState({});
    setShow(false)
  };
  const handleShow = () => setShow(true);
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();

  const [formData, setFormData] = useState({
    name: "",
    sub_company: sessionStorage.getItem('company_id'),
    sepration_headings: [
      // {
      //   sorting_var: "",
      //   name: "",
      //   department: "",
      //   approving_authority: "",
      // }
    ],
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "name",
      "sub_company",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });


    // Validate documents array
    formData.sepration_headings?.forEach((doc, index) => {
      // if (!doc.sorting_var || doc.sorting_var.trim() === "") {
      //   newErrors[`sorting_var_${index}`] = `Sorting Position is required for document ${index + 1}`;
      // }
      // if (!doc.heading_name || doc.heading_name.trim() === "") {
      //   newErrors[`heading_name${index}`] = `Heading Name is required for document ${index + 1}`;
      // }
      // if (!doc.department || doc.department.trim() === "") {
      //   newErrors[`department_${index}`] = `Department is required for document ${index + 1}`;
      // }

      // Check Heading Name or Department (One is required)
      if ((!doc.heading_name || doc.heading_name.trim() === "") && (!doc.department || doc.department.trim() === "")) {
        newErrors[`heading_or_department_${index}`] = `Either Heading Name or Department is required for document ${index + 1}`;
      }
      if (!doc.approving_authority || doc.approving_authority.trim() === "") {
        newErrors[`approving_authority_${index}`] = `Approving Authority is required for document ${index + 1}`;
      }
    });

    setErrors(newErrors);
    setIsSubmitted(true);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });
    setFormData({
      ...formData,
      [name]: value,
    });
    if (value.trim()) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };

  const handleOtherInputChange = (index, e) => {
    if (!e || !e.target) {
      console.error("Invalid event object: ", e);
      return;
    }
    const { name, value } = e.target;

    if (name === 'heading_name') {
      setFormData((prevFormData) => {
        const newOthers = [...prevFormData.sepration_headings];
        newOthers[index] = {
          ...newOthers[index],
          [name]: value,
          sorting_var: index + 3,
          department: '',
        };
        return {
          ...prevFormData,
          sepration_headings: newOthers
        };
      });
    }
    else if (name === 'department') {
      setFormData((prevFormData) => {
        const newOthers = [...prevFormData.sepration_headings];
        newOthers[index] = {
          ...newOthers[index],
          [name]: value,
          sorting_var: index + 3,
          heading_name: '',
        };
        return {
          ...prevFormData,
          sepration_headings: newOthers
        };
      });
    }
    else {
      setFormData((prevFormData) => {
        const newOthers = [...prevFormData.sepration_headings];
        newOthers[index] = {
          ...newOthers[index],
          [name]: value,
          sorting_var: index + 3,
        };
        return {
          ...prevFormData,
          sepration_headings: newOthers
        };
      });
    }
  };

  const handleAddOthers = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      sepration_headings: [
        ...prevFormData.sepration_headings,
        {
          sorting_var: prevFormData.sepration_headings?.length + 3,
          heading_name: "",
          department: "",
          approving_authority: "",
        },
      ]
    }));
  };

  const handleRemoveOthers = (index) => {
    setFormData((prevFormData) => {
      const newOthers = [...prevFormData.sepration_headings];
      newOthers.splice(index, 1);
      return {
        ...prevFormData,
        sepration_headings: newOthers
      };
    });
  };

  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: name wait..."); //toast Logic
      console.log("formData:", formData)

      try {
        let res = await axios.post(
          `${BASE_URL}/wfm/seprationformatandheadings/`,
          formData
        );
        if (res.status === 200) {
          await getSeprationFormats();
          handleClose()
        } else {
          console.log("res:", res);
        }
      } catch (err) {
        handleAllError(err, loadingToastId)
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  const [buffer1, setBuffering1] = useState(true);
  const [buffer2, setBuffering2] = useState(true);

  const [departmentlist, setDepartmentList] = useState([]);
  const [allemployeedata, setAllemployee] = useState([]);


  const getDepartment = async () => {
    try {
      setBuffering1(true)
      const dep = await axios.get(`${BASE_URL}/wfm/departmentfilterbysubcompanycount/${formData.sub_company || 'null'}/active/`);
      setDepartmentList(dep.data)
    } catch (err) {
    } finally {
      setBuffering1(false)
    }
  };

  const getAllempdata = async () => {
    try {
      setBuffering2(true)
      const res = await axios.get(`${BASE_URL}/wfm/ourcompanyempdetailsactive/${formData.sub_company || 'null'}/`);
      const sortedList = customSortByKey(res.data, "emp_code");
      setAllemployee(sortedList);
    } catch (err) {
      alert(err.message);
    } finally {
      setBuffering2(false)
    }
  };

  useEffect(() => {
    getDepartment();
    getAllempdata();
  }, [formData.sub_company]);


  const options = allemployeedata
    .sort((a, b) => parseInt(a.emp_code) - parseInt(b.emp_code))
    .map((e) => ({
      value: e.emp_code,
      label: `${e.emp_code} - ${e.name}`,
    }));

  const preselectedOption = options.find((option) => option.value === sessionStorage.getItem("emp_code"));

  const handleChangecc = (selected) => {
    const multipleperson = selected?.map((item) => item.value) || [];
    setFormData((prevState) => ({
      ...prevState,
      multipleperson,
    }));
  };


  return (
    <>
      <button title="Add Sepration Format" className="models-button model-add" onClick={handleShow}>
        <AddwithBlueCircle />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        <Modal.Header closeButton>
          <Modal.Title>Add Sepration Format</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <div className="form-flex-wrap ">

                <div className="flex-column">
                  <label
                    htmlFor="name"
                    className="form-labels  font-weight500 announce-date font-weight400  font-size-heading"
                  >
                    Format Name<span className="required">*</span>
                  </label>
                  <input
                    id="name"
                    type="text"
                    name="name"
                    placeholder="Sepration Format Name"
                    onChange={handleInputChange}
                    value={formData.name}
                    className={`form-input ${errors.name
                      ? "error"
                      : inputState.name
                        ? "success"
                        : ""
                      }`}
                  />
                  <div>
                    {errors.name && (
                      <span className="error-message font-size-text ">{errors.name}</span>
                    )}
                  </div>
                </div>

                {PERMISSION_AUTHORITY ?
                  <div className="flex-column form-group-select">
                    <label className="form-labels  font-weight500    font-size-subheading">
                      Sub Company<span className="required">*</span>
                    </label>
                    <br />
                    <select
                      name="sub_company"
                      onChange={handleInputChange}
                      value={formData.sub_company}
                      className={`form-input ${errors.sub_company
                        ? "error"
                        : inputState.sub_company
                          ? "success"
                          : ""
                        }`}
                    >
                      <option value="">Select Sub Company</option>
                      {SUB_COMPANIES.map((company) => (
                        <option value={company.id}>{company.title}</option>
                      ))}

                    </select>
                    {/* <DropdownArrow /> */}
                    <div>
                      {errors.sub_company && (
                        <span className="error-message font-size-text ">{errors.sub_company}</span>
                      )}
                    </div>
                  </div>
                  : null}
              </div>
              <h5
                htmlFor="project_name"
                className="announce-date font-weight500"
              >
                Sepration Flow -
              </h5>

              <ol>
                <li>
                  <div className="flex-column">
                    <label className="form-labels font-weight500 announce-date">
                      Reporiting Head
                    </label>
                  </div>
                </li>

                <li>
                  <div className="flex-column">
                    <label className="form-labels font-weight500 announce-date">
                      Head of Department
                    </label>
                  </div>
                </li>

                {
                  formData.sepration_headings?.length > 0 ?
                    formData.sepration_headings.map((head) =>
                      <li>
                        <div className="flex-column">
                          <label className="form-labels font-weight500 announce-date">
                            {head.heading_name || departmentlist.find(deps => deps.id === head.department)?.title || "pending..."}

                          </label>
                        </div>
                      </li>)
                    : null
                }

                <li>
                  <div className="flex-column">
                    <label className="form-labels font-weight500 announce-date">
                      HR
                    </label>
                  </div>
                </li>

                <li>
                  <div className="flex-column">
                    <label className="form-labels font-weight500 announce-date">
                      Admin
                    </label>
                  </div>
                </li>

                <li>
                  <div className="flex-column">
                    <label className="form-labels font-weight500 announce-date">
                      Accounts
                    </label>
                  </div>
                </li>

                <li>
                  <div className="flex-column">
                    <label className="form-labels font-weight500 announce-date">
                      Final Sepration
                    </label>
                  </div>
                </li>
              </ol>

              <hr className="line" />
              {formData.sepration_headings?.map((heading, index) => (
                <div className="form-flex-wrap content-tabs " key={index}>

                  <div className="flex-column justify-center" >
                    {heading.sorting_var}.
                  </div>

                  {heading.department ? null :
                    < div className="flex-column">
                      <div className="expense-req">
                        <div className="flex-row">
                          <label htmlFor={`heading_name_${index}`} className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                            Heading Name<span className="required">*</span>
                          </label>
                        </div >
                      </div>
                      <input
                        id={`heading_name_${index}`}
                        type="text"
                        name="heading_name"
                        placeholder="Heading Name"
                        onChange={(e) => handleOtherInputChange(index, e)}
                        value={heading.heading_name}
                        className={`form-input-date`}
                      />
                      {errors[`heading_name_${index}`] && (
                        <span className="error-message font-size-text">
                          {errors[`heading_name_${index}`]}
                        </span>
                      )}
                    </div>
                  }
                  {heading.department == "" && heading.heading_name == "" ?
                    <div className="flex-row justify-center" >
                      OR
                    </div>
                    : null}
                  {heading.heading_name ? null :
                    <div className="flex-column">
                      <div className="expense-req">
                        <div className="flex-row">
                          <label htmlFor={`department_${index}`} className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                            Department<span className="required">*</span>
                          </label>
                        </div >
                      </div>
                      {buffer1 ?
                        <div className="form-loader-date">
                          <div className="bar-loader"></div>
                        </div> :
                        <select
                          id={`department_${index}`}
                          name="department"
                          onChange={(e) => handleOtherInputChange(index, e)}
                          value={heading.department}
                          className={`form-input-date`}
                        >
                          <option value="">Select Department</option>
                          {departmentlist.map((dep) => (
                            <option value={dep.id}>{dep.title}</option>
                          ))}
                        </select>}
                      {errors[`department_${index}`] && (
                        <span className="error-message font-size-text">
                          {errors[`department_${index}`]}
                        </span>
                      )}
                    </div>
                  }

                  <div className="flex-column form-group-selectt">
                    <label htmlFor="person" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                      Assigned Authority<span className="required">*</span>
                    </label>
                    {buffer2 ?
                      <div className="form-loader-date">
                        <div className="bar-loader"></div>
                      </div> :
                      <Select
                        id='approving_authority'
                        name='approving_authority'
                        options={options}
                        isClearable
                        onChange={(selectedOption) => {
                          // Ensure a valid option is selected
                          if (selectedOption) {
                            handleOtherInputChange(index, {
                              target: {
                                name: 'approving_authority',
                                value: selectedOption.value,
                              }
                            });
                          } else {
                            // Handle case when no option is selected
                            handleOtherInputChange(index, {
                              target: {
                                name: 'approving_authority',
                                value: '',
                              }
                            });
                          }
                        }}
                        value={heading.approving_authority ? { value: heading.approving_authority, label: heading.approving_authority } : null}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: '20vw',
                            minHeight: '50px',
                            borderRadius: '10px',
                            border: '1px solid #e3e3e3',
                            margin: '10px 0px 20px 0px',
                            color: '#4a4a4a',
                            backgroundColor: '#f7f8fa',
                            padding: '0px 10px',
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: '#4a4a4a',
                          }),
                          menu: (provided) => ({
                            ...provided,
                            borderRadius: '10px',
                            marginTop: '0',
                          }),
                          input: (provided) => ({
                            ...provided,
                            color: '#4a4a4a',
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            color: '#4a4a4a',
                          }),
                        }}
                      />}
                    {errors[`approving_authority_${index}`] && (
                      <span className="error-message font-size-text">
                        {errors[`approving_authority_${index}`]}
                      </span>
                    )}
                  </div>

                  <div className="flex-column justify-center" >
                    <button type='button' title="Remove" className="flex-row action-div" onClick={() => handleRemoveOthers(index)}>
                      <CrosswithGreyCircle />
                    </button>
                  </div>

                </div>
              ))}

              <button type='button' title="Add Document" className="jd-heading-main font-weight600 -right-button" onClick={handleAddOthers}>
                <AddwithBlueCircle />
                Add More Headings
              </button>

              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
            <p className="error-message font-size-text">
              {isSubmitted && Object.keys(errors).length > 0 && (
                Object.keys(errors).length > 5 ? (
                  <h5 className="text-center">Please fill all mandatory fields!</h5>
                ) : (

                  <h6 className="text-center">
                    {Object.keys(errors).map((field) =>
                      field.charAt(0).toUpperCase() + field.slice(1)
                    ).join(', ') + ' are required!'}
                  </h6>
                )
              )}
            </p>
          </div>

        </Modal.Body>
      </Modal >
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

const ViewSeprationFormatFlow = ({ i }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button title="View Sepration Flow" className="" onClick={handleShow}>
        <Eye />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="half-modal ">
        <Modal.Header closeButton>
          <Modal.Title>Sepration Process Flow of "{i.name}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <hr className="line" /> */}
              {/* <h5
                htmlFor="project_name"
                className="announce-date font-weight500"
              >
                Sepration Flow -
              </h5> */}

              <ol>
                <li>
                  <div className="flex-column">
                    <label className="form-labels font-weight500 announce-date">
                      Reporiting Head
                    </label>
                  </div>
                </li>

                <li>
                  <div className="flex-column">
                    <label className="form-labels font-weight500 announce-date">
                      Head of Department
                    </label>
                  </div>
                </li>

                {
                  i.sepration_headings?.length > 0 ?
                    i.sepration_headings.map((head) =>
                      <li>
                        <div className="flex-column">
                          <label className="form-labels font-weight500 announce-date">
                            {head.heading_name || head.department_name || "pending..."}  (Assigned to "{head.approving_authority}-{head.approving_authority_name}")
                          </label>
                        </div>
                      </li>)
                    : null
                }

                <li>
                  <div className="flex-column">
                    <label className="form-labels font-weight500 announce-date">
                      HR
                    </label>
                  </div>
                </li>

                <li>
                  <div className="flex-column">
                    <label className="form-labels font-weight500 announce-date">
                      Admin
                    </label>
                  </div>
                </li>

                <li>
                  <div className="flex-column">
                    <label className="form-labels font-weight500 announce-date">
                      Accounts
                    </label>
                  </div>
                </li>

                <li>
                  <div className="flex-column">
                    <label className="form-labels font-weight500 announce-date">
                      Final Sepration
                    </label>
                  </div>
                </li>
              </ol>
            </div>
          </div>
        </Modal.Body>
      </Modal >
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

const DeleteSeprationFormat = ({ i, getSeprationFormats }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    getDepartment();
    setShow(true)
  };
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    setLoading(true); //loading logic
    const loadingToastId = toast.loading("Loading: name wait..."); //toast Logic

    try {
      let res = await axios.put(
        `${BASE_URL}/wfm/seprationFormat/${i.id}/`);
      if (res.status === 200) {
        await getSeprationFormats();
        handleClose()
      } else {
        console.log("res:", res);
      }
    } catch (err) {
      handleAllError(err, loadingToastId)
    } finally {
      setLoading(false); //loading logic
      toast.dismiss(loadingToastId);
    }

  };


  const [buffer1, setBuffering1] = useState(true);
  const [departmentlist, setDepartmentList] = useState([]);

  const getDepartment = async () => {
    try {
      setBuffering1(true)
      const dep = await axios.get(`${BASE_URL}/wfm/departmentfilterbysubcompanycount/${i.sub_company || 'null'}/active/`);
      setDepartmentList(dep.data)
    } catch (err) {
    } finally {
      setBuffering1(false)
    }
  };

  return (
    <>
      <button title="Delete Sepration Format" className="model-delete-button" onClick={handleShow}>
        <DeleteDustbin />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="half-modal ">
        <Modal.Header closeButton>
          <Modal.Title>Delete Sepration Format</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-column">
                <label
                  htmlFor="title"
                  className="form-labels  font-weight500 announce-date font-weight400  font-size-heading"
                >
                  Format Name : {i.name}
                </label>
              </div>

              {PERMISSION_AUTHORITY ?
                <div className="flex-column form-group-select">
                  <label className="form-labels  font-weight500    font-size-subheading">
                    Sub Company : {i.sub_company_name}
                  </label>
                </div> : null}
              <hr className="line" />
              <h5
                htmlFor="project_name"
                className="announce-date font-weight500"
              >
                Sepration Flow -
              </h5>

              <ol>
                <li>
                  <div className="flex-column">
                    <label className="form-labels font-weight500 announce-date">
                      Reporiting Head
                    </label>
                  </div>
                </li>

                <li>
                  <div className="flex-column">
                    <label className="form-labels font-weight500 announce-date">
                      Head of Department
                    </label>
                  </div>
                </li>

                {
                  i.sepration_headings?.length > 0 ?
                    i.sepration_headings.map((head) =>
                      <li>
                        <div className="flex-column">
                          <label className="form-labels font-weight500 announce-date">
                            {head.heading_name || head.department_name || "pending..."}  (Assigned to "{head.approving_authority}-{head.approving_authority_name}")
                          </label>
                        </div>
                      </li>)
                    : null
                }

                <li>
                  <div className="flex-column">
                    <label className="form-labels font-weight500 announce-date">
                      HR
                    </label>
                  </div>
                </li>

                <li>
                  <div className="flex-column">
                    <label className="form-labels font-weight500 announce-date">
                      Admin
                    </label>
                  </div>
                </li>

                <li>
                  <div className="flex-column">
                    <label className="form-labels font-weight500 announce-date">
                      Accounts
                    </label>
                  </div>
                </li>

                <li>
                  <div className="flex-column">
                    <label className="form-labels font-weight500 announce-date">
                      Final Sepration
                    </label>
                  </div>
                </li>
              </ol>


              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500  model-button-delete  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal >
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

const AddSettlementFormat = ({ getSettlementFormats }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setFormData({
      name: "",
      sub_company: sessionStorage.getItem('company_id'),
      settlement_headings: [],
    })
    setErrors({});
    setInputState({});
    setShow(false)
  };
  const handleShow = () => setShow(true);
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();

  const [formData, setFormData] = useState({
    name: "",
    sub_company: sessionStorage.getItem('company_id'),
    settlement_headings: [
      // {
      //   sorting_var: "",
      //   name: "",
      //   department: "",
      //   approving_role: "",
      // }
    ],
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "name",
      "sub_company",
      // "settlement_headings",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    if (formData.settlement_headings.length === 0) {
      newErrors[`settlement_headings`] = `Atleast 1 Heading is required for document`;
    }
    // Validate documents array
    formData.settlement_headings?.forEach((doc, index) => {
      // if (!doc.sorting_var || doc.sorting_var.trim() === "") {
      //   newErrors[`sorting_var_${index}`] = `Sorting Position is required for document ${index + 1}`;
      // }
      if (!doc.heading_name || doc.heading_name.trim() === "") {
        newErrors[`heading_name_${index}`] = `Heading Name is required for settlement ${index + 1}`;
      }
      if (!doc.approving_role || doc.approving_role.trim() === "") {
        newErrors[`approving_role_${index}`] = `Approving Role is required for settlement ${index + 1}`;
      }
    });

    setErrors(newErrors);
    setIsSubmitted(true);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });
    setFormData({
      ...formData,
      [name]: value,
    });
    if (value.trim()) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };

  const handleOtherInputChange = (index, e) => {
    if (!e || !e.target) {
      console.error("Invalid event object: ", e);
      return;
    }
    const { name, value } = e.target;

    if (name === 'heading_name') {
      setFormData((prevFormData) => {
        const newOthers = [...prevFormData.settlement_headings];
        newOthers[index] = {
          ...newOthers[index],
          [name]: value,
          sorting_var: index + 1,
        };
        return {
          ...prevFormData,
          settlement_headings: newOthers
        };
      });
    }
    else {
      setFormData((prevFormData) => {
        const newOthers = [...prevFormData.settlement_headings];
        newOthers[index] = {
          ...newOthers[index],
          [name]: value,
          sorting_var: index + 1,
        };
        return {
          ...prevFormData,
          settlement_headings: newOthers
        };
      });
    }
  };

  const handleAddOthers = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      settlement_headings: [
        ...prevFormData.settlement_headings,
        {
          sorting_var: prevFormData.settlement_headings?.length + 1,
          heading_name: "",
          settlement_type: "",
          approving_role: "",
        },
      ]
    }));
  };

  const handleRemoveOthers = (index) => {
    setFormData((prevFormData) => {
      const newOthers = [...prevFormData.settlement_headings];
      newOthers.splice(index, 1);
      return {
        ...prevFormData,
        settlement_headings: newOthers
      };
    });
  };

  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: name wait..."); //toast Logic
      console.log("formData:", formData)

      try {
        let res = await axios.post(
          `${BASE_URL}/wfm/settlementformatandheadings/`,
          formData
        );
        if (res.status === 200) {
          await getSettlementFormats();
          handleClose()
        } else {
          console.log("res:", res);
        }
      } catch (err) {
        handleAllError(err, loadingToastId)
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  return (
    <>
      <button title="Add Settlement Format " className="models-button model-add" onClick={handleShow}>
        <AddwithBlueCircle />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        <Modal.Header closeButton>
          <Modal.Title>Add Settlement Format</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <div className="form-flex-wrap ">

                <div className="flex-column">
                  <label
                    htmlFor="name"
                    className="form-labels  font-weight500 announce-date font-weight400  font-size-heading"
                  >
                    Format Name<span className="required">*</span>
                  </label>
                  <input
                    id="name"
                    type="text"
                    name="name"
                    placeholder="Settlement Format Name"
                    onChange={handleInputChange}
                    value={formData.name}
                    className={`form-input ${errors.name
                      ? "error"
                      : inputState.name
                        ? "success"
                        : ""
                      }`}
                  />
                  <div>
                    {errors.name && (
                      <span className="error-message font-size-text ">{errors.name}</span>
                    )}
                  </div>
                </div>

                {PERMISSION_AUTHORITY ?
                  <div className="flex-column form-group-select">
                    <label className="form-labels  font-weight500    font-size-subheading">
                      Sub Company<span className="required">*</span>
                    </label>
                    <br />
                    <select
                      name="sub_company"
                      onChange={handleInputChange}
                      value={formData.sub_company}
                      className={`form-input ${errors.sub_company
                        ? "error"
                        : inputState.sub_company
                          ? "success"
                          : ""
                        }`}
                    >
                      <option value="">Select Sub Company</option>
                      {SUB_COMPANIES.map((company) => (
                        <option value={company.id}>{company.title}</option>
                      ))}

                    </select>
                    {/* <DropdownArrow /> */}
                    <div>
                      {errors.sub_company && (
                        <span className="error-message font-size-text ">{errors.sub_company}</span>
                      )}
                    </div>
                  </div>
                  : null}
              </div>
              <h5
                htmlFor="project_name"
                className="announce-date font-weight500"
              >
                Settlement Heads -
              </h5>

              <ol>
                {
                  formData.settlement_headings?.length > 0 ?
                    formData.settlement_headings
                      ?.sort((a, b) => (a.sorting_var || 0) - (b.sorting_var || 0))
                      .map((head) =>
                        <li>
                          <div className="flex-column">
                            <label className="form-labels font-weight500 announce-date">
                              {head.heading_name ? `${head.heading_name} (${head.approving_role})` : "pending..."}
                            </label>
                          </div>
                        </li>)
                    : null
                }
              </ol>

              <hr className="line" />
              {formData.settlement_headings?.map((heading, index) => (
                <div className="form-flex-wrap content-tabs " key={index}>

                  <div className="flex-column justify-center" >
                    {heading.sorting_var}.
                  </div>

                  <div className="flex-column">
                    <div className="expense-req">
                      <div className="flex-row">
                        <label htmlFor={`heading_name_${index}`} className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                          Heading Name<span className="required">*</span>
                        </label>
                      </div >
                    </div>
                    <input
                      id={`heading_name_${index}`}
                      type="text"
                      name="heading_name"
                      placeholder="Heading Name"
                      onChange={(e) => handleOtherInputChange(index, e)}
                      value={heading.heading_name}
                      className={`form-input-date`}
                    />
                    {errors[`heading_name_${index}`] && (
                      <span className="error-message font-size-text">
                        {errors[`heading_name_${index}`]}
                      </span>
                    )}
                  </div>


                  <div className="flex-column ">
                    <label htmlFor="approving_role" className="form-labels  font-weight500    font-size-subheading">
                      Approving Role<span className="required">*</span>
                    </label>
                    <select
                      id={`approving_role_${index}`}
                      name="approving_role"
                      value={heading.approving_role}
                      onChange={(e) => handleOtherInputChange(index, e)}
                      className={`form-input-div `}
                    >
                      <option value="">Select a Role</option>
                      <option value="HOD">HOD</option>
                      <option value="HR">HR</option>
                      <option value="Account">Accounts</option>
                    </select>

                    {errors[`approving_role_${index}`] && (
                      <span className="error-message font-size-text">
                        {errors[`approving_role_${index}`]}
                      </span>
                    )}
                  </div>

                  <div className="flex-column">
                    <label htmlFor="settlement_type" className="form-labels  font-weight500    font-size-subheading">
                      Settlement Type<span className="required">*</span>
                    </label>
                    <select
                      id={`settlement_type_${index}`}
                      name="settlement_type"
                      value={heading.settlement_type}
                      onChange={(e) => handleOtherInputChange(index, e)}
                      className={`form-input-div `}
                    >
                      <option value="">Select a Type</option>
                      <option value="allowance">Allowance</option>
                      <option value="deduction">Deduction</option>
                    </select>

                    {errors[`settlement_type_${index}`] && (
                      <span className="error-message font-size-text">
                        {errors[`settlement_type_${index}`]}
                      </span>
                    )}
                  </div>

                  <div className="flex-column justify-center" >
                    <button type='button' title="Remove" className="flex-row action-div" onClick={() => handleRemoveOthers(index)}>
                      <CrosswithGreyCircle />
                    </button>
                  </div>
                </div>
              ))}

              <button type='button' title="Add Document" className="jd-heading-main font-weight600 -right-button" onClick={handleAddOthers}>
                <AddwithBlueCircle />
                Add More Headings
              </button>



              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
            <p className="error-message font-size-text">
              {isSubmitted && Object.keys(errors).length > 0 && (
                Object.keys(errors).length > 5 ? (
                  <h5 className="text-center">Please fill all mandatory fields!</h5>
                ) : (

                  <h6 className="text-center">
                    {Object.keys(errors).map((field) =>
                      field.charAt(0).toUpperCase() + field.slice(1)
                    ).join(', ') + ' are required!'}
                  </h6>
                )
              )}
            </p>
          </div>

        </Modal.Body>
      </Modal >
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

const ViewSettlementFormatFlow = ({ i }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button title="View Settlement" className="" onClick={handleShow}>
        <Eye />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="half-modal ">
        <Modal.Header closeButton>
          <Modal.Title>Settlement Headings of "{i.name}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <ol>
                {
                  i.settlement_headings?.length > 0 ?
                    i.settlement_headings
                      ?.sort((a, b) => (a.sorting_var || 0) - (b.sorting_var || 0))
                      .map((head) =>
                        <li>
                          <div className="flex-column">
                            <label className="form-labels font-weight500 announce-date">
                              {head.heading_name ? `${head.heading_name} (${head.approving_role})` : "pending..."}
                            </label>
                          </div>
                        </li>)
                    : null
                }
              </ol>
            </div>
          </div>
        </Modal.Body>
      </Modal >
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

const DeleteSettlementFormat = ({ i, getSettlementFormats }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    getDepartment();
    setShow(true)
  };
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    setLoading(true); //loading logic
    const loadingToastId = toast.loading("Loading: name wait..."); //toast Logic

    try {
      let res = await axios.put(
        `${BASE_URL}/wfm/settlementFormat/${i.id}/`);
      if (res.status === 200) {
        await getSettlementFormats();
        handleClose()
      } else {
        console.log("res:", res);
      }
    } catch (err) {
      handleAllError(err, loadingToastId)
    } finally {
      setLoading(false); //loading logic
      toast.dismiss(loadingToastId);
    }

  };


  const [buffer1, setBuffering1] = useState(true);
  const [departmentlist, setDepartmentList] = useState([]);

  const getDepartment = async () => {
    try {
      setBuffering1(true)
      const dep = await axios.get(`${BASE_URL}/wfm/departmentfilterbysubcompanycount/${i.sub_company || 'null'}/active/`);
      setDepartmentList(dep.data)
    } catch (err) {
    } finally {
      setBuffering1(false)
    }
  };

  return (
    <>
      <button title="Delete Settlement Format" className="model-delete-button" onClick={handleShow}>
        <DeleteDustbin />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="half-modal ">
        <Modal.Header closeButton>
          <Modal.Title>Delete Settlement Format</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-column">
                <label
                  htmlFor="title"
                  className="form-labels  font-weight500 announce-date font-weight400  font-size-heading"
                >
                  Format Name : {i.name}
                </label>
              </div>

              {PERMISSION_AUTHORITY ?
                <div className="flex-column form-group-select">
                  <label className="form-labels  font-weight500    font-size-subheading">
                    Sub Company : {i.sub_company_name}
                  </label>
                </div> : null}
              <hr className="line" />
              <ol>
                {
                  i.settlement_headings?.length > 0 ?
                    i.settlement_headings
                      ?.sort((a, b) => (a.sorting_var || 0) - (b.sorting_var || 0))
                      .map((head) =>
                        <li>
                          <div className="flex-column">
                            <label className="form-labels font-weight500 announce-date">
                              {head.heading_name ? `${head.heading_name} (${head.approving_role})` : "pending..."}
                            </label>
                          </div>
                        </li>)
                    : null
                }
              </ol>


              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500  model-button-delete  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal >
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

const StartEmployeeSeprationProcess = ({ empcode, empData, getEmployeeOrgData, databuffer }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setFormData({
      emp_code: empcode,
      sepration_headings: [],
      settlement_headings: [],
    })
    getEmployeeOrgData(empcode);
    setErrors({});
    setInputState({});
    setShow(false)
  };
  const handleShow = () => {
    getSeprationFormats();
    getSettlementFormats();
    setFormData({
      emp_code: empcode,
      sepration_headings: [],
      settlement_headings: [],
    })
    setShow(true)
  };

  const [formData, setFormData] = useState({
    emp_code: empcode,
    sepration_format: "",
    settlement_format: "",
    settlement_start_date: "",
    settlement_end_date: "",
    sepration_headings: [],
    settlement_headings: [],
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
      "sepration_format",
      "settlement_format",
      "settlement_start_date",
      "settlement_end_date",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);
    setIsSubmitted(true);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const numericValue = Number(value); // Ensure value is numeric

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    if (name === 'sepration_format') {
      setFormData({
        ...formData,
        [name]: value,
        sepration_headings: seprationFormats.find(format => String(format.id) === String(value))?.sepration_headings,
      });
    }
    else if (name === 'settlement_format') {
      setFormData({
        ...formData,
        [name]: value,
        settlement_headings: settlementFormats.find(format => String(format.id) === String(value))?.settlement_headings,
      });
    }
    else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    // console.log("Value from input:", value);
    // console.log("Parsed numeric value:", numericValue);

    // const matchingFormat = SeprationFormats.find(format => format.id === numericValue);
    // console.log("Matching Format:", matchingFormat);

    if (value.trim()) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };


  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: name wait..."); //toast Logic
      console.log("formData:", formData)

      const updatedempData = {
        ...empData,
        sepration_form: true
      };

      try {
        let sepration = await axios.post(
          // `${BASE_URL}/wfm/seprationformandheadings/`,
          `${BASE_URL}/wfm/seprationformandheadingswithsettlement/`,
          formData
        );
        if (sepration.status === 200) {
          let empOrg = await axios.post(
            `${BASE_URL}/wfm/ourcompanyuserdetaildirectadd/`,
            updatedempData
          );
          if (empOrg.status === 200) {
            await getEmployeeOrgData(empcode);
            handleClose()
          }
          else {
            console.log("empOrg:", empOrg);
          }
        } else {
          console.log("sepration:", sepration);
        }
      } catch (err) {
        handleAllError(err, loadingToastId)
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  const [buffer1, setBuffering1] = useState(true);
  const [buffer2, setBuffering2] = useState(true);

  const [seprationFormats, setSeprationFormats] = useState([]);
  const getSeprationFormats = async () => {
    try {
      setBuffering1(true)
      const dep = await axios.get(`${BASE_URL}/wfm/seprationformatbysubcompany/${empData.sub_company || 'null'}/`);
      setSeprationFormats(dep.data)
    } catch (err) {
    } finally {
      setBuffering1(false)
    }
  };


  const [settlementFormats, setSettlementFormats] = useState([]);
  const getSettlementFormats = async () => {
    try {
      setBuffering2(true)
      const dep = await axios.get(`${BASE_URL}/wfm/settlementformatbysubcompany/${empData.sub_company || 'null'}/`);
      setSettlementFormats(dep.data)
    } catch (err) {
    } finally {
      setBuffering2(false)
    }
  };

  return (
    <>
      <div title="Sepration Format" className="flex-column form-group-selectt">
        <div className="flex-row ">

          <div className="checkbox-toggle">
            <input
              type="checkbox"
              id="sepration_form"
              name="sepration_form"
              checked={false}
              // checked={empData.sepration_form}
              // disabled
              readOnly
              onClick={handleShow}
            />
            <label htmlFor="sepration_form"></label>
          </div>
          <hr style={{ marginTop: '10px' }} className="field-cont-hr" />
          <label htmlFor="" className="form-labels  font-weight500    font-size-subheading">
            {empData.sepration_form ? "View" : "Start"} Sepration Process {" "}
          </label>
        </div>

      </div>
      {databuffer ? <div className="spinner-z"></div> : null}

      <Modal show={show} onHide={handleClose} dialogClassName="half-modal">
        <Modal.Header closeButton>
          <Modal.Title>Start Sepration Process of : {empData.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <div className="form-flex-wrap ">
                {/* <div className="flex-row  justify-around"> */}


                <div className="flex-column">
                  <div className="flex-column form-group-selectt">
                    <label htmlFor="sepration_format" className="form-labels font-weight500 font-size-subheading">
                      Sepration Format Type<span className="required">*</span>
                    </label>
                    <br />
                    {buffer1 ?
                      <div className="form-loader-date">
                        <div className="bar-loader"></div>
                      </div> :
                      <>
                        <select
                          id="sepration_format"
                          name="sepration_format"
                          value={formData.sepration_format}
                          onChange={handleInputChange}
                          className={`form-input ${errors.sepration_format ? "error" : inputState.sepration_format ? "success" : ""
                            }`}
                        >
                          <option value="">Select Sepration Format</option>
                          {seprationFormats.map((dep) => (
                            <option value={dep.id}>{dep.name}</option>
                          ))}
                        </select>
                        {/* <DropdownArrow /> */}
                        <div>
                          {errors.sepration_format && (
                            <span className="error-message font-size-text ">{errors.sepration_format}</span>
                          )}
                        </div>
                      </>}
                  </div>

                  <div className='flex-column justify-start'>
                    {formData.sepration_format &&
                      <>
                        <h5
                          htmlFor="project_name"
                          className="announce-date font-weight500"
                        >
                          Sepration Flow -
                        </h5>
                        <ol>
                          <li>
                            <div className="flex-column">
                              <label className="form-labels font-weight500 announce-date">
                                Reporiting Head
                              </label>
                            </div>
                          </li>

                          <li>
                            <div className="flex-column">
                              <label className="form-labels font-weight500 announce-date">
                                Head of Department
                              </label>
                            </div>
                          </li>

                          {
                            formData.sepration_headings?.length > 0 ?
                              formData.sepration_headings.map((head) =>
                                <li>
                                  <div className="flex-column">
                                    <label className="form-labels font-weight500 announce-date">
                                      {/* {head.heading_name || head.department || "pending..."} */}
                                      {head.heading_name || head.department_name || "pending..."} (Assigned to "{head.approving_authority}-{head.approving_authority_name}")
                                    </label>
                                  </div>
                                </li>)
                              : null
                          }

                          <li>
                            <div className="flex-column">
                              <label className="form-labels font-weight500 announce-date">
                                Admin
                              </label>
                            </div>
                          </li>

                          <li>
                            <div className="flex-column">
                              <label className="form-labels font-weight500 announce-date">
                                Accounts
                              </label>
                            </div>
                          </li>

                          <li>
                            <div className="flex-column">
                              <label className="form-labels font-weight500 announce-date">
                                HR
                              </label>
                            </div>
                          </li>
                        </ol>
                      </>
                    }
                  </div>
                </div>

                <div className="flex-column">
                  <div className="flex-column form-group-selectt">
                    <label htmlFor="settlement_format" className="form-labels font-weight500 font-size-subheading">
                      Settlement Format Type<span className="required">*</span>
                    </label>
                    <br />
                    {buffer1 ?
                      <div className="form-loader-date">
                        <div className="bar-loader"></div>
                      </div> :
                      <>
                        <select
                          id="settlement_format"
                          name="settlement_format"
                          value={formData.settlement_format}
                          onChange={handleInputChange}
                          className={`form-input ${errors.settlement_format ? "error" : inputState.settlement_format ? "success" : ""
                            }`}
                        >
                          <option value="">Select Settlement Format</option>
                          {settlementFormats.map((dep) => (
                            <option value={dep.id}>{dep.name}</option>
                          ))}
                        </select>
                        {/* <DropdownArrow /> */}

                        <div>
                          {errors.settlement_format && (
                            <span className="error-message font-size-text ">{errors.settlement_format}</span>
                          )}
                        </div>
                      </>}
                  </div>

                  <div className='flex-column justify-start'>
                    {formData.settlement_format &&
                      <>
                        <h5
                          htmlFor="project_name"
                          className="announce-date font-weight500"
                        >
                          Settlement Heads -
                        </h5>

                        <ol>
                          {
                            formData.settlement_headings?.length > 0 ?
                              formData.settlement_headings
                                ?.sort((a, b) => (a.sorting_var || 0) - (b.sorting_var || 0))
                                .map((head) =>
                                  <li>
                                    <div className="flex-column">
                                      <label className="form-labels font-weight500 announce-date">
                                        {head.heading_name ? `${head.heading_name} (${head.approving_role})` : "pending..."}
                                      </label>
                                    </div>
                                  </li>)
                              : null
                          }
                        </ol>
                      </>
                    }
                  </div>
                </div>
              </div>

              <div title="Settlement Start Date" className="flex-column">
                <label htmlFor="settlement_start_date" className="form-labels  font-weight500    font-size-subheading">
                  Settlement Start Date<span className="required">*</span>
                </label>
                <input
                  type="date"
                  id="settlement_start_date"
                  name="settlement_start_date"
                  onChange={handleInputChange}
                  value={formData.settlement_start_date}
                  className={`form-input ${errors.settlement_start_date
                    ? "error"
                    : inputState.settlement_start_date
                      ? "success"
                      : ""
                    }`}
                />
                {errors.settlement_start_date && (
                  <span className="error-message font-size-text ">
                    {errors.settlement_start_date}
                  </span>
                )}
              </div>

              <div title="Settlement End Date" className="flex-column">
                <label htmlFor="settlement_end_date" className="form-labels  font-weight500    font-size-subheading">
                  Settlement End Date<span className="required">*</span>
                </label>
                <input
                  type="date"
                  id="settlement_end_date"
                  name="settlement_end_date"
                  onChange={handleInputChange}
                  value={formData.settlement_end_date}
                  className={`form-input ${errors.settlement_end_date
                    ? "error"
                    : inputState.settlement_end_date
                      ? "success"
                      : ""
                    }`}
                />
                {errors.settlement_end_date && (
                  <span className="error-message font-size-text ">
                    {errors.settlement_end_date}
                  </span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel font-weight500" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button font-weight500 model-button-leave font-weight500 font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Start
                </button>
              </div>
            </div>
            <p className="error-message font-size-text">
              {isSubmitted && Object.keys(errors).length > 0 && (
                Object.keys(errors).length > 5 ? (
                  <h5 className="text-center">Please fill all mandatory fields!</h5>
                ) : (

                  <h6 className="text-center">
                    {Object.keys(errors).map((field) =>
                      field.charAt(0).toUpperCase() + field.slice(1)
                    ).join(', ') + ' are required!'}
                  </h6>
                )
              )}
            </p>
          </div>

        </Modal.Body>
      </Modal >
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

const ApplySeprationForm = ({ code, getSeprationData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setFormData({
      emp_code: "",
      reason_for_taking_sepration: "",
      date_of_request: formattedDate,
    })
    setErrors({})
    setShow(false)
  };
  const handleShow = () => {
    setFormData({
      emp_code: code || sessionStorage.getItem("emp_code"),
      reason_for_taking_sepration: "",
      date_of_request: formattedDate,
    })
    setShow(true)
  };

  const [formData, setFormData] = useState({
    emp_code: code || sessionStorage.getItem("emp_code"),
    reason_for_taking_sepration: "",
    date_of_request: formattedDate,
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
      "reason_for_taking_sepration",
      "date_of_request",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    if (value.trim()) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };

  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.post(
          `${BASE_URL}/wfm/seprationforms/`,
          formData
        );

        if (res.status === 200) {
          await getSeprationData();
          handleClose()
        } else {
          alert(res);
        }
      } catch (err) {
        handleAllError(err)
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button title="Apply Sepration Form" className="model-view-button model-add" onClick={handleShow}>
        <Exit />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">

        <Modal.Header closeButton>
          <Modal.Title>Apply for Sepration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-column">
                <label htmlFor="reason_for_taking_sepration" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Reason for Taking Sepration<span className="required">*</span>
                </label>
                <textarea
                  id="reason_for_taking_sepration"
                  type="text"
                  name="reason_for_taking_sepration"
                  placeholder="Your Reason"
                  onChange={handleInputChange}
                  // maxLength={200}
                  value={formData.reason_for_taking_sepration}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.reason_for_taking_sepration
                    ? "error"
                    : inputState.reason_for_taking_sepration
                      ? "success"
                      : ""
                    }`}
                />
                {errors.reason_for_taking_sepration && (
                  <span className="error-message font-size-text ">{errors.reason_for_taking_sepration}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};

const SeprationProcessStatusTD = ({ i }) => {
  return (
    <td className="table-body width-5vw ">
      <span
        request-status={i.rh_approval}
        className="request-status"
        title={`${i.rh_approval === "rejected" ? `RH Reason For Rejection : \n${i.rh_log_details.action_description}` : ''}`}
      >
        {i.rh_approval === "pending"
          ? "Pending For RH Approval ⌛"
          : i.rh_approval === "rejected"
            ? <>RH ✖,<br />RH Reject Reason:<br />"{i.rh_log_details.action_description}"</>
            : "Approved By RH ✔,"}
      </span>
      {/* <br /> */}
      <br />
      <span
        request-status={i.hod_approval}
        className="request-status"
        title={`${i.hod_approval === "rejected" ? `HOD Reason For Rejection : \n${i.hod_log_details.action_description}` : ''}`}
      >
        {i.hod_approval === "pending"
          ? "Pending For HOD Approval ⌛"
          : i.hod_approval === "rejected"
            ? <>HOD ✖,<br />HOD Reject Reason:<br />"{i.hod_log_details.action_description}"</>
            : "Approved By HOD ✔,"}
      </span>
      {i.headings.sort((a, b) => a.sorting_var - b.sorting_var).map((heading) => (
        <div key={heading.id}>
          <span
            request-status={heading.approval}
            className="request-status"
            style={{ cursor: 'help' }}
            title={`Assigned to:"${heading.approving_authority}-${heading.approving_authority_name}"
                                  \n${heading.approval === "rejected" ? `Rejection Reason: \n${heading.reject_reason}` : ''}`}
          >
            {heading.approval === "pending"
              ? `Pending for ${heading.heading_name || heading.department_name} Approval ⌛`
              : heading.approval === "rejected"
                ? <>{heading.heading_name || heading.department_name} ✖,<br />Reject Reason:<br />"{heading.reject_reason}"</>
                : `${heading.heading_name || heading.department_name}  Approved ✔,`}
          </span>
          <br />
        </div>
      ))}
      <span
        request-status={i.hr_approval}
        className="request-status"
        title={`${i.hr_approval === "rejected" ? `HR Reason: ${i.hr_log_details.action_description}` : ''}`}
      >
        {i.hr_approval === "rejected"
          ? <>HR ✖,<br />HR Reject Reason:<br />"{i.hr_log_details.action_description}"</>
          : i.hr_approval === "pending"
            ? "Pending For HR Approval ⌛"
            :
            i.hr_approval === "approved"
              ? "Approved By HR ✔,"
              : "HR Status : " + i.hr_approval}
      </span>
      {/* <br /> */}
      <br />
      <span
        request-status={i.admin_approval}
        className="request-status"
        title={`${i.admin_approval === "rejected" ? `Admin Reason: ${i.admin_log_details.action_description}` : ''}`}
      >
        {i.admin_approval === "rejected"
          ? <>Admin ✖,<br />Admin Reject Reason: "{i.admin_log_details.action_description}"</>
          :
          i.admin_approval === "pending"
            ? "Pending For Admin Approval ⌛,"
            :
            i.admin_approval === "approved"
              ? "Approved By Admin ✔,"
              : "Admin Status: " + i.admin_approval}
      </span>
      {/* <br /> */}
      <br />
      <span
        request-status={i.account_approval}
        className="request-status"
        title={`${i.account_approval === "rejected" ? `Accounts Reason: ${i.acc_log_details.action_description}` : ''}`}
      >
        {i.account_approval === "rejected"
          ? <>Accounts ✖,<br />Accounts Reject Reason:<br />"{i.acc_log_details.action_description}"</>
          : i.account_approval === "pending"
            ? "Pending For Accounts Approval ⌛"
            :
            i.account_approval === "approved"
              ? "Accounts Processed ✔,"
              : "Accounts Status : " + i.account_approval}
      </span>
      {/* <br /> */}
      <br />
      <span
        request-status={(i.final_log ? "approved" : "pending")}
        className="request-status"
      >
        {(i.final_log ? "approved" : "pending") === "pending"
          ? "Pending For Final Sepration ⌛"
          :
          (i.final_log ? "approved" : "pending") === "approved"
            ? "Final Sepration Processed ✔,"
            : "Accounts Status : " + (i.final_log ? "approved" : "pending")}
      </span>
    </td>
  );
};

const ViewSeprationDetails = ({ i }) => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(true);
  }

  const handleClose = () => {
    setShow(false);
  }

  return (
    <>
      <button title="View Sepration Process" className="" onClick={handleShow}>
        <Eye />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        <Modal.Header closeButton className="justify-center task-Tab-heading font-weight500 font-size-heading">
          <Modal.Title>Sepration Process of : "{i.emp_code}-{i.emp_name}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={3000} hideProgressBar />

          <form className="register-form">
            <div className="flex-row justify-center">
              <table className="nested-table-lightblue width-40vw">
                <thead>
                  <tr>
                    <th className="align-center">S. No.</th>
                    <th className="align-center">Name of Department</th>
                    <th className="align-center">Concerned Person</th>
                    <th className="align-center">Signature TimeStamp</th>
                    <th className="align-center">Status</th>
                    <th className="align-center">Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  <React.Fragment>
                    {[
                      { label: "Reporting Head", name: i.rh_log_details?.emp_code ? `${i.rh_log_details?.emp_code} - ${i.rh_log_details?.emp_name}` : null, timestamp: i.rh_log_details?.action_datetime, status: i.rh_approval, remarks: i.rh_log_details?.action_description },
                      { label: "Head Of Department", name: i.hod_log_details?.emp_code ? `${i.hod_log_details?.emp_code} - ${i.hod_log_details?.emp_name}` : null, timestamp: i.hod_log_details?.action_datetime, status: i.hod_approval, remarks: i.hod_log_details?.action_description },
                      ...(i.headings || []).sort((a, b) => (a.sorting_var || 0) - (b.sorting_var || 0)).map((head) => ({
                        label: head.heading_name || head.department_name,
                        name: head.approving_authority ? `${head.approving_authority} - ${head.approving_authority_name}` : null,
                        timestamp: head.approval_log_details?.action_datetime,
                        status: head.approval,
                        remarks: head.approval_log_details?.action_description,
                      })),
                      { label: "Human Resource", name: i.hr_log_details?.emp_code ? `${i.hr_log_details?.emp_code} - ${i.hr_log_details?.emp_name}` : null, timestamp: i.hr_log_details?.action_datetime, status: i.hr_approval, remarks: i.hr_log_details?.action_description },
                      { label: "Admin", name: i.admin_log_details?.emp_code ? `${i.admin_log_details?.emp_code} - ${i.admin_log_details?.emp_name}` : null, timestamp: i.admin_log_details?.action_datetime, status: i.admin_approval, remarks: i.admin_log_details?.action_description },
                      { label: "Accounts", name: i.acc_log_details?.emp_code ? `${i.acc_log_details?.emp_code} - ${i.acc_log_details?.emp_name}` : null, timestamp: i.acc_log_details?.action_datetime, status: i.account_approval, remarks: i.acc_log_details?.action_description },
                      { label: "Final Sepration", name: i.final_log_details?.emp_code ? `${i.final_log_details?.emp_code} - ${i.final_log_details?.emp_name}` : null, timestamp: i.final_log_details?.action_datetime, status: i.final_log ? "approved" : "pending", remarks: i.final_log_details?.action_description },
                    ].map(({ label, name, timestamp, status, remarks }, index) => (
                      <tr key={`${label}-${index}`}>
                        <td className="text-center">{index + 1}</td>
                        <td className="text-center">{label}</td>
                        <td className="text-center">{name}</td>
                        <td className="text-center">{timestamp ? formatDateTime(timestamp).full : ""}</td>
                        <td className="text-center request-status form-text-trasformation-uppercase" request-status={status}>{status}</td>
                        <td className="text-center">{remarks}</td>
                      </tr>
                    ))}
                  </React.Fragment>
                </tbody>
              </table>

            </div>

            <div className="flex-row justify-center">
              <table className="nested-table-lightblue width-40vw">
                <thead>
                  <tr>
                    <th className="align-center">S. No.</th>
                    <th className="align-center">Asset Name</th>
                    <th className="align-center">Concerned Heading</th>
                    <th className="align-center">Person Taking Handover</th>
                    <th className="align-center">Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {i.handover_items && i.handover_items.length > 0 ? (
                    i.handover_items
                      ?.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
                      .map((item, index) => (
                        <tr key={`handover-${index}`}>
                          <td className="text-center">{index + 1}</td>
                          <td className="text-center">{item.asset_name}</td>
                          <td className="text-center">{item.concerned_headings}</td>
                          <td className="text-center">{item.person_taking_handover} - {item.person_taking_handover_name}</td>
                          <td className="text-center">{item.remarks}</td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td className="text-center" colSpan="7">No handover items available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className="button-models">
              <button type="button" className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                Close
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

const ViewSeprationAndSettlementDetails = ({ i }) => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    getSettlementPayData();
    setShow(true);
  }

  const handleClose = () => {
    setShow(false);
  }

  const [settlementPayData, setSettlementPayData] = useState({});
  const [buffer, setBuffering] = useState(false);

  const getSettlementPayData = async () => {
    try {
      setBuffering(true)
      const res = await axios.get(
        `${BASE_URL}/wfm/calattendanceandpay/${i.emp_code}/${i.settlement_start_date}/${i.settlement_end_date}/`
      );
      setSettlementPayData(res.data);
    } catch (err) {
      //toast Logic
      handleAllError(err);
    } finally {
      setBuffering(false)
    }
  };
  return (
    <>
      <button title="View Sepration And Settlement Process" className="" onClick={handleShow}>
        <Eye />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        <Modal.Header closeButton className="justify-center task-Tab-heading font-weight500 font-size-heading">
          <Modal.Title>Sepration Process of : "{i.emp_code}-{i.emp_name}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={3000} hideProgressBar />

          <form className="register-form">

            <div className="flex-row justify-center">
              <table className="nested-table-lightblue width-40vw">
                <tbody>
                  <tr >
                    <th className="text-center">NAME</th>
                    <td className="text-center">{i.emp_name ?? "-"}</td>
                    <th className="text-center">CODE</th>
                    <td className="text-center">{i.emp_code ?? "-"}</td>
                  </tr>
                  <tr >
                    <th className="text-center">FATHER / HUSBAND</th>
                    <td className="text-center">{i.salary_data?.user_details?.father_name ?? "-"}</td>
                    <th className="text-center">BANK</th>
                    <td className="text-center">{i.salary_data?.bank_details?.bank_name ?? "-"}</td>
                  </tr>
                  <tr >
                    <th className="text-center">DATE OF JOINING</th>
                    <td className="text-center">{i.user_details?.joining_date ? formattedDateLong(i.salary_data?.user_details?.joining_date) : "-"}</td>
                    <th className="text-center">ACCOUNT NO.</th>
                    <td className="text-center">{i.salary_data?.bank_details?.account_number ?? "-"}</td>
                  </tr>
                  <tr >
                    <th className="text-center">DEPARTMENT</th>
                    <td className="text-center">{i.user_details?.department_name ?? "-"}</td>
                    <th className="text-center">IFSC NO.</th>
                    <td className="text-center">{i.salary_data?.bank_details?.ifsc_code ?? "-"}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <br />
            <h5 className="announce-date font-weight500" >
              Sepration Remarks -
            </h5>
            <div className="flex-row justify-center">
              <table className="nested-table-lightblue width-40vw">
                <thead>
                  <tr>
                    <th className="align-center">S. No.</th>
                    <th className="align-center">Name of Department</th>
                    <th className="align-center">Concerned Person</th>
                    <th className="align-center">Signature TimeStamp</th>
                    <th className="align-center">Status</th>
                    <th className="align-center">Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  <React.Fragment>
                    {[
                      { label: "Reporting Head", name: i.rh_log_details?.emp_code ? `${i.rh_log_details?.emp_code} - ${i.rh_log_details?.emp_name}` : null, timestamp: i.rh_log_details?.action_datetime, status: i.rh_approval, remarks: i.rh_log_details?.action_description },
                      { label: "Head Of Department", name: i.hod_log_details?.emp_code ? `${i.hod_log_details?.emp_code} - ${i.hod_log_details?.emp_name}` : null, timestamp: i.hod_log_details?.action_datetime, status: i.hod_approval, remarks: i.hod_log_details?.action_description },
                      ...(i.headings || []).sort((a, b) => (a.sorting_var || 0) - (b.sorting_var || 0)).map((head) => ({
                        label: head.heading_name || head.department_name,
                        name: head.approving_authority ? `${head.approving_authority} - ${head.approving_authority_name}` : null,
                        timestamp: head.approval_log_details?.action_datetime,
                        status: head.approval,
                        remarks: head.approval_log_details?.action_description,
                      })),
                      { label: "Human Resource", name: i.hr_log_details?.emp_code ? `${i.hr_log_details?.emp_code} - ${i.hr_log_details?.emp_name}` : null, timestamp: i.hr_log_details?.action_datetime, status: i.hr_approval, remarks: i.hr_log_details?.action_description },
                      { label: "Admin", name: i.admin_log_details?.emp_code ? `${i.admin_log_details?.emp_code} - ${i.admin_log_details?.emp_name}` : null, timestamp: i.admin_log_details?.action_datetime, status: i.admin_approval, remarks: i.admin_log_details?.action_description },
                      { label: "Accounts", name: i.acc_log_details?.emp_code ? `${i.acc_log_details?.emp_code} - ${i.acc_log_details?.emp_name}` : null, timestamp: i.acc_log_details?.action_datetime, status: i.account_approval, remarks: i.acc_log_details?.action_description },
                      { label: "Final Sepration", name: i.final_log_details?.emp_code ? `${i.final_log_details?.emp_code} - ${i.final_log_details?.emp_name}` : null, timestamp: i.final_log_details?.action_datetime, status: i.final_log ? "approved" : "pending", remarks: i.final_log_details?.action_description },
                    ].map(({ label, name, timestamp, status, remarks }, index) => (
                      <tr key={`${label}-${index}`}>
                        <td className="text-center">{index + 1}</td>
                        <td className="text-center">{label}</td>
                        <td className="text-center">{name}</td>
                        <td className="text-center">{timestamp ? formatDateTime(timestamp).full : ""}</td>
                        <td className="text-center request-status form-text-trasformation-uppercase" request-status={status}>{status}</td>
                        <td className="text-center">{remarks}</td>
                      </tr>
                    ))}
                  </React.Fragment>
                </tbody>
              </table>
            </div>

            <br />
            <h5 className="announce-date font-weight500" >
              List of Items Handed Over -
            </h5>
            <div className="flex-row justify-center">
              <table className="nested-table-lightblue width-40vw">
                <thead>
                  <tr>
                    <th className="align-center">S. No.</th>
                    <th className="align-center">Asset Name</th>
                    <th className="align-center">Concerned Heading</th>
                    <th className="align-center">Person Taking Handover</th>
                    <th className="align-center">Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {i.handover_items && i.handover_items.length > 0 ? (
                    i.handover_items
                      ?.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
                      .map((item, index) => (
                        <tr key={`handover-${index}`}>
                          <td className="text-center">{index + 1}</td>
                          <td className="text-center">{item.asset_name}</td>
                          <td className="text-center">{item.concerned_headings}</td>
                          <td className="text-center">{item.person_taking_handover} - {item.person_taking_handover_name}</td>
                          <td className="text-center">{item.remarks}</td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td className="text-center" colSpan="7">No handover items available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <hr className="line" />
            <br />

            <h5 className="announce-date font-weight500 align-center" >
              Settlement Period {i.settlement_start_date} to {i.settlement_end_date}
            </h5>
            <div className="flex-row justify-center">
              <table className="nested-table-lightblue width-40vw">
                <tbody>
                  <tr >
                    <td className="text-center salaryslip-heading-blue">TOTAL DAYS</td>
                    <td className="text-center salaryslip-heading-blue">{i.salary_data?.total_days ?? "-"}</td>
                    <td className="text-center salaryslip-heading-blue">WEEKLY OFF</td>
                    <td className="text-center salaryslip-heading-blue">{i.salary_data?.attendance_summary?.weeklyoff ?? "-"}</td>
                  </tr>
                  <tr >
                    <td className="text-center salaryslip-heading-blue">TOTAL LEAVES</td>
                    <td className="text-center salaryslip-heading-blue">{i.salary_data?.attendance_summary?.leave ?? "-"}</td>
                    <td className="text-center salaryslip-heading-blue">LEAVE WITHOUT PAY</td>
                    <td className="text-center salaryslip-heading-blue">{i.salary_data?.attendance_summary?.unpaidleave ?? "-"}</td>
                  </tr>
                  <tr >
                    <td className="text-center salaryslip-heading-blue">TOTAL ABSENT</td>
                    <td className="text-center salaryslip-heading-blue">{i.salary_data?.attendance_summary?.absent ?? "-"}</td>
                    <td className="text-center salaryslip-heading-blue">HOLIDAY</td>
                    <td className="text-center salaryslip-heading-blue">{i.salary_data?.attendance_summary?.holiday ?? "-"}</td>
                  </tr>
                  <tr >
                    <td className="text-center salaryslip-heading-blue">WORKING DAYS</td>
                    <td className="text-center salaryslip-heading-blue">{i.salary_data?.total_paid_days ?? "-"}</td>
                    <td className="text-center salaryslip-heading-blue">NET PAID DAYS</td>
                    <td className="text-center salaryslip-heading-blue">{i.salary_data?.total_paid_days ?? "-"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="flex-row justify-center">
              <div className='salaryslip-body'>
                <table className='nested-table-modern'>
                  <thead>
                    <tr className='slaryslip-head'>
                      {/* <th >S. No.</th> */}
                      <th className="align-center" style={{ width: "260px", padding: "0px 5px" }}>Salary Component</th>
                      <th className="align-center" >STANDARD</th>
                      <th className="align-center" >AMOUNT</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      i.salary_data?.deduction_details
                        ?.filter((j) => !isNaN(j.standard_amount) && !isNaN(j.actual_amount) && j.salaryslip !== false)
                        .sort((a, b) => a.sorting_var - b.sorting_var)
                        .map((j, index) => {
                          const isSpecialRow = j.salary_type === "total" || j.salary_type === "difference";
                          return (
                            <tr key={index} className={isSpecialRow ? "special-row" : ""}>
                              <td>{j.name}</td>
                              <td className="align-right">{formatCurrencyIndian(j.standard_amount)}</td>
                              <td className="align-right">{formatCurrencyIndian(j.actual_amount)}</td>
                            </tr>
                          );
                        })
                    }
                  </tbody>
                </table>
                <table className='nested-table-modern'>
                  <tbody>
                    <tr className="salary-total-row">
                      <td>SALARY PAY</td>
                      <td>
                        {
                          formatCurrencyIndian(
                            i.salary_data?.deduction_details.find((j) => j.final_salary === true)?.actual_amount || 0
                          )
                        }
                      </td>
                    </tr>
                    <tr className="salary-total-row in-words-row">
                      <td>IN WORDS</td>
                      <td>
                        {
                          numberToWords.toWords(
                            i.salary_data?.deduction_details.find((j) => j.final_salary === true)?.actual_amount || 0
                          ).toUpperCase()
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <br />
            <h5 className="announce-date font-weight500" >
              Total Amount Payable for Settlement Period -
            </h5>
            <div className="flex-row justify-center">
              <table className="nested-table-lightblue width-40vw">
                <thead>
                  <tr>
                    <th className="align-center">Payable Headings</th>
                    <th className="align-center">Concerned Person</th>
                    <th className="align-center">Amount</th>
                    <th className="align-center">Remarks</th>
                    <th className="align-center">TimeStamp</th>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th colSpan={6} className="align-start">Deductions</th>
                  </tr>
                </thead>
                <tbody>
                  <React.Fragment>
                    {[
                      { label: "Imprest withheld with Employee", name: i.hr_log_details?.emp_code ? `${i.hr_log_details?.emp_code} - ${i.hr_log_details?.emp_name}` : null, timestamp: i.hr_log_details?.action_datetime, amount: i.loan_amount, remarks: i.imprest_remarks },
                      { label: "Advance Salary", name: i.hr_log_details?.emp_code ? `${i.hr_log_details?.emp_code} - ${i.hr_log_details?.emp_name}` : null, timestamp: i.hr_log_details?.action_datetime, amount: i.loan_amount, remarks: i.advance_remarks },
                      { label: "Loan Taken from Company", name: i.hr_log_details?.emp_code ? `${i.hr_log_details?.emp_code} - ${i.hr_log_details?.emp_name}` : null, timestamp: i.hr_log_details?.action_datetime, amount: i.loan_amount, remarks: i.loan_remarks },
                      ...(i.settlement_headings || [])
                        .filter((head) => head.settlement_type === "deduction")
                        .sort((a, b) => (a.sorting_var || 0) - (b.sorting_var || 0))
                        .map((head) => ({
                          label: head.heading_name,
                          name: head.approving_log_details ? `${head.approving_log_details.emp_code} - ${head.approving_log_details.emp_name}` : null,
                          timestamp: head.approving_log_details?.action_datetime,
                          amount: head.amount,
                          remarks: head.remarks,
                        })),
                    ].map(({ label, name, timestamp, amount, remarks }, index) => (
                      <tr key={`${label}-${index}`}>
                        <td className="text-start">{label}</td>
                        <td className="text-center">{name}</td>
                        <td className="text-center">{amount ? formatCurrencyIndian(amount) : ""}</td>
                        <td className="text-center">{remarks}</td>
                        <td className="text-center">{timestamp ? formatDateTime(timestamp).full : ""}</td>
                      </tr>
                    ))}
                  </React.Fragment>
                </tbody>
                <thead>
                  <tr>
                    <th colSpan={6} className="align-start">Additions</th>
                  </tr>
                </thead>
                <tbody>
                  <React.Fragment>
                    {[
                      ...(i.settlement_headings || [])
                        .filter((head) => head.settlement_type === "allowance")
                        .sort((a, b) => (a.sorting_var || 0) - (b.sorting_var || 0))
                        .map((head) => ({
                          label: head.heading_name,
                          name: head.approving_log_details ? `${head.approving_log_details.emp_code} - ${head.approving_log_details.emp_name}` : null,
                          timestamp: head.approving_log_details?.action_datetime,
                          amount: head.amount,
                          remarks: head.remarks,
                        })),
                    ].map(({ label, name, timestamp, amount, remarks }, index) => (
                      <tr key={`${label}-${index}`}>
                        <td className="text-start">{label}</td>
                        <td className="text-center">{name}</td>
                        <td className="text-center">{amount ? formatCurrencyIndian(amount) : ""}</td>
                        <td className="text-center">{remarks}</td>
                        <td className="text-center">{timestamp ? formatDateTime(timestamp).full : ""}</td>
                      </tr>
                    ))}
                  </React.Fragment>
                </tbody>

              </table>
            </div>

            <div className="button-models">
              <button type="button" className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                Close
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

const ViewSeprationProcessStatus = ({ i }) => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    getEmployeeSeprationData(i.emp_code);
    setShow(true);
  }

  const handleClose = () => {
    setShow(false);
  }

  const [seprationData, setSeprationData] = useState({});
  const [buffer, setBuffering] = useState(false);

  const getEmployeeSeprationData = async (emp_code) => {
    try {
      setBuffering(true)
      const res = await axios.get(
        `${BASE_URL}/wfm/seprationformbyemp/${emp_code}/`
      );
      setSeprationData(res.data);
    } catch (err) {
      //toast Logic
      handleAllError(err);
    } finally {
      setBuffering(false)
    }
  };
  return (
    <>
      {/* <button title="View Sepration Perocess" className="" onClick={handleShow}>
        <Eye />
      </button> */}
      <div title="Sepration Format" className="flex-column form-group-selectt">
        <div className="flex-row ">

          <div className="checkbox-toggle">
            <input
              type="checkbox"
              id="sepration_form"
              name="sepration_form"
              // checked={i.sepration_form}
              checked={true}
              // disabled
              readOnly
              onClick={handleShow}
            />
            <label htmlFor="sepration_form"></label>
          </div>
          <hr style={{ marginTop: '10px' }} className="field-cont-hr" />
          <label htmlFor="" className="form-labels  font-weight500    font-size-subheading">
            View Sepration Process{" "}
          </label>
        </div>

      </div>

      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        <Modal.Header closeButton className="justify-center task-Tab-heading font-weight500 font-size-heading">
          <Modal.Title>Sepration Process of : "{seprationData.emp_code}-{seprationData.emp_name}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={3000} hideProgressBar />

          <form className="register-form">
            <div className="flex-row justify-center">
              <table className="nested-table-lightblue width-40vw">
                <thead>
                  <tr>
                    <th className="align-center">S. No.</th>
                    <th className="align-center">Name of Department</th>
                    <th className="align-center">Concerned Person</th>
                    <th className="align-center">Signature TimeStamp</th>
                    <th className="align-center">Status</th>
                    <th className="align-center">Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  <React.Fragment>
                    {[
                      { label: "Reporting Head", name: seprationData.rh_log_details?.emp_code ? `${seprationData.rh_log_details?.emp_code} - ${seprationData.rh_log_details?.emp_name}` : null, timestamp: seprationData.rh_log_details?.action_datetime, status: seprationData.rh_approval, remarks: seprationData.rh_log_details?.action_description },
                      { label: "Head Of Department", name: seprationData.hod_log_details?.emp_code ? `${seprationData.hod_log_details?.emp_code} - ${seprationData.hod_log_details?.emp_name}` : null, timestamp: seprationData.hod_log_details?.action_datetime, status: seprationData.hod_approval, remarks: seprationData.hod_log_details?.action_description },
                      ...(seprationData.headings || []).sort((a, b) => (a.sorting_var || 0) - (b.sorting_var || 0)).map((head) => ({
                        label: head.heading_name || head.department_name,
                        name: head.approving_authority ? `${head.approving_authority} - ${head.approving_authority_name}` : null,
                        timestamp: head.approval_log_details?.action_datetime,
                        status: head.approval,
                        remarks: head.approval_log_details?.action_description,
                      })),
                      { label: "Human Resource", name: seprationData.hr_log_details?.emp_code ? `${seprationData.hr_log_details?.emp_code} - ${seprationData.hr_log_details?.emp_name}` : null, timestamp: seprationData.hr_log_details?.action_datetime, status: seprationData.hr_approval, remarks: seprationData.hr_log_details?.action_description },
                      { label: "Admin", name: seprationData.admin_log_details?.emp_code ? `${seprationData.admin_log_details?.emp_code} - ${seprationData.admin_log_details?.emp_name}` : null, timestamp: seprationData.admin_log_details?.action_datetime, status: seprationData.admin_approval, remarks: seprationData.admin_log_details?.action_description },
                      { label: "Accounts", name: seprationData.acc_log_details?.emp_code ? `${seprationData.acc_log_details?.emp_code} - ${seprationData.acc_log_details?.emp_name}` : null, timestamp: seprationData.acc_log_details?.action_datetime, status: seprationData.account_approval, remarks: seprationData.acc_log_details?.action_description },
                    ].map(({ label, name, timestamp, status, remarks }, index) => (
                      <tr key={`${label}-${index}`}>
                        <td className="text-center">{index + 1}</td>
                        <td className="text-center">{label}</td>
                        <td className="text-center">{name}</td>
                        <td className="text-center">{timestamp ? formatDateTime(timestamp).full : ""}</td>
                        <td className="text-center request-status form-text-trasformation-uppercase" request-status={status}>{status}</td>
                        <td className="text-center">{remarks}</td>
                      </tr>
                    ))}
                  </React.Fragment>
                </tbody>
              </table>

            </div>

            <div className="flex-row justify-center">
              <table className="nested-table-lightblue width-40vw">
                <thead>
                  <tr>
                    <th className="align-center">S. No.</th>
                    <th className="align-center">Asset Name</th>
                    <th className="align-center">Concerned Heading</th>
                    <th className="align-center">Person Taking Handover</th>
                    <th className="align-center">Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {seprationData.handover_items && seprationData.handover_items.length > 0 ? (
                    seprationData.handover_items
                      ?.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
                      .map((item, index) => (
                        <tr key={`handover-${index}`}>
                          <td className="text-center">{index + 1}</td>
                          <td className="text-center">{item.asset_name}</td>
                          <td className="text-center">{item.concerned_headings}</td>
                          <td className="text-center">{item.person_taking_handover} - {item.person_taking_handover_name}</td>
                          <td className="text-center">{item.remarks}</td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td className="text-center" colSpan="7">No handover items available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className="button-models">
              <button type="button" className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                Close
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

const SeprationFormRHApproval = ({ i, getSeprationData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
      handover_items: [],
    })
    setErrors({})
    setShow(false)
  };
  const handleShow = () => {
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
      handover_items: [],
    })
    setShow(true)
  };

  const [formData, setFormData] = useState({
    emp_code: sessionStorage.getItem("emp_code"),
    action_description: "",
    action_datetime: new Date().toISOString(),
    handover_items: [],
  });


  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
      "action_description",
      "action_datetime",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });


    formData.handover_items?.forEach((items, index) => {

      if ((!items.asset_name || items.asset_name.trim() === "")) {
        newErrors[`asset_name_${index}`] = `Asset Name is required for item ${index + 1}`;
      }
      if (!items.remarks || items.remarks.trim() === "") {
        newErrors[`remarks_${index}`] = `Approving Remarks is required for item ${index + 1}`;
      }
    });

    setErrors(newErrors);
    setIsSubmitted(true);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    if (value.trim()) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };


  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const updatedFormData = {
      rh_approval: "approved",
      rh_log: {
        emp_code: formData.emp_code || sessionStorage.getItem("emp_code"),
        action_description: formData.action_description || "",
        action_datetime: formData.action_datetime || new Date().toISOString(),
      },
      handover_items: formData.handover_items?.map((item) => ({
        asset_name: item.asset_name,
        concerned_headings: item.concerned_headings,
        person_taking_handover: item.person_taking_handover,
        remarks: item.remarks,
      })) || [],
    };

    if (validateForm()) {
      setLoading(true);
      const loadingToastId = toast.loading("Loading: Please wait...");

      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/updatelogsforseprationwithhandovertemsAPIView/${i.id}/`,
          updatedFormData
        );

        if (res.status === 200) {
          await getSeprationData();
          handleClose();
        } else {
          alert(res);
        }
      } catch (err) {
        handleAllError(err);
      } finally {
        setLoading(false);
        toast.dismiss(loadingToastId);
      }
    }
  };



  const handleOtherInputChange = (index, e) => {
    if (!e || !e.target) {
      console.error("Invalid event object: ", e);
      return;
    }
    const { name, value } = e.target;
    setFormData((prevFormData) => {
      const newOthers = [...prevFormData.handover_items];
      newOthers[index] = {
        ...newOthers[index],
        [name]: value,
      };
      return {
        ...prevFormData,
        handover_items: newOthers
      };
    });
  };

  const handleAddOthers = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      handover_items: [
        ...(prevFormData.handover_items || []),  // Ensure array exists
        {
          asset_name: "",
          concerned_headings: "Reporting Head",
          person_taking_handover: sessionStorage.getItem("emp_code"),
          remarks: "",
        },
      ],
    }));
  };


  const handleRemoveOthers = (index) => {
    setFormData((prevFormData) => {
      if (!prevFormData.handover_items) return prevFormData; // Prevent errors
      const newOthers = [...prevFormData.handover_items];
      newOthers.splice(index, 1);
      return {
        ...prevFormData,
        handover_items: newOthers,
      };
    });
  };



  return (
    <>
      <button title="RH Sepration Approval" className="model-edit-button" onClick={handleShow}>
        <CheckGreen />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="half-modal">

        <Modal.Header closeButton>
          <Modal.Title>RH Sepration Approval of "{i.emp_code}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-row ">
                <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Employee:
                </label>
                <div className="flex-row align-center ">
                  {/* <td className="table-body" style={{ textAlign: "center" }}> */}
                  <img
                    className="profile-img"
                    src={
                      `${i.profilepic}`
                        ? `${IMAGE_URL}${i.profilepic}`
                        : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                    alt="profilepic"
                  />
                  <p>{i.emp_name}</p>
                  {/* </td> */}
                  <div className="form-labels announce-date font-size-heading">
                  </div>
                </div>
              </div>

              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Request Date:
                </label>
                <div className="form-labels announce-date font-size-heading align-center">
                  {i.application_date ? formattedDateLong(i.application_date) : '-'}
                </div>
              </div>

              <div className="flex-column">
                <label htmlFor="action_description" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Remarks:<span className="required">*</span>
                </label>
                <textarea
                  id="action_description"
                  type="text"
                  name="action_description"
                  placeholder="Your Remarks"
                  onChange={handleInputChange}
                  // maxLength={200}
                  value={formData.action_description}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.action_description
                    ? "error"
                    : inputState.action_description
                      ? "success"
                      : ""
                    }`}
                />
                {errors.action_description && (
                  <span className="error-message font-size-text ">{errors.action_description}</span>
                )}
              </div>

              <div className="flex-row justify-center">
                <table className="nested-table-lightblue width-40vw">
                  <thead>
                    <tr>
                      <th className="align-center">S. No.</th>
                      <th className="align-center">Asset Name</th>
                      {/* <th className="align-center">Concerned Heading</th> */}
                      {/* <th className="align-center">Person Taking Handover</th> */}
                      <th className="align-center">Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData.handover_items && formData.handover_items.length > 0 ? (
                      formData.handover_items
                        ?.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
                        .map((item, index) => (
                          <tr key={`handover-${index}`}>
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center">{item.asset_name}</td>
                            {/* <td className="text-center">{item.concerned_headings}</td> */}
                            {/* <td className="text-center">{item.person_taking_handover}</td> */}
                            <td className="text-center">{item.remarks}</td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td className="text-center" colSpan="7">No handover items available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <hr className="line" />
              {formData.handover_items?.map((heading, index) => (
                <div className="form-flex-wrap content-tabs " key={index}>
                  < div className="flex-column">
                    <div className="expense-req">
                      <div className="flex-row">
                        <label htmlFor={`asset_name_${index}`} className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                          Asset Name<span className="required">*</span>
                        </label>
                      </div >
                    </div>
                    <input
                      id={`asset_name_${index}`}
                      type="text"
                      name="asset_name"
                      placeholder="Asset Name"
                      onChange={(e) => handleOtherInputChange(index, e)}
                      value={heading.asset_name}
                      className={`form-input-date`}
                    />
                    {errors[`asset_name_${index}`] && (
                      <span className="error-message font-size-text">
                        {errors[`asset_name_${index}`]}
                      </span>
                    )}
                  </div>

                  < div className="flex-column">
                    <div className="expense-req">
                      <div className="flex-row">
                        <label htmlFor={`remarks_${index}`} className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                          Remarks<span className="required">*</span>
                        </label>
                      </div >
                    </div>
                    <input
                      id={`remarks_${index}`}
                      type="text"
                      name="remarks"
                      placeholder="Remarks"
                      onChange={(e) => handleOtherInputChange(index, e)}
                      value={heading.remarks}
                      className={`form-input-date`}
                    />
                    {errors[`remarks_${index}`] && (
                      <span className="error-message font-size-text">
                        {errors[`remarks_${index}`]}
                      </span>
                    )}
                  </div>


                  <div className="flex-column justify-center" >
                    <button type='button' title="Remove" className="flex-row action-div" onClick={() => handleRemoveOthers(index)}>
                      <CrosswithGreyCircle />
                    </button>
                  </div>

                </div>
              ))}

              <button type='button' title="Add Document" className="jd-heading-main font-weight600 -right-button" onClick={handleAddOthers}>
                <AddwithBlueCircle />
                Add More Headings
              </button>



              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Approve
                </button>
              </div>
            </div>
            <p className="error-message font-size-text">
              {isSubmitted && Object.keys(errors).length > 0 && (
                Object.keys(errors).length > 5 ? (
                  <h5 className="text-center">Please fill all mandatory fields!</h5>
                ) : (

                  <h6 className="text-center">
                    {Object.keys(errors).map((field) =>
                      field.charAt(0).toUpperCase() + field.slice(1)
                    ).join(', ') + ' are required!'}
                  </h6>
                )
              )}
            </p>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};

const SeprationFormRHRejection = ({ i, getSeprationData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
    })
    setErrors({})
    setShow(false)
  };
  const handleShow = () => {
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
    })
    setShow(true)
  };

  const [formData, setFormData] = useState({
    emp_code: sessionStorage.getItem("emp_code"),
    action_description: "",
    action_datetime: new Date().toISOString(),
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
      "action_description",
      "action_datetime",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    // console.log("newErrors")
    // console.log(newErrors)
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    if (value.trim()) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };


  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const updatedFormData = {
      rh_approval: "rejected",
      rh_log: formData,
    };
    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/updatelogsforseprationwithhandovertemsAPIView/${i.id}/`,
          updatedFormData
        );

        if (res.status === 200) {
          await getSeprationData();
          handleClose()
        } else {
          alert(res);
        }
      } catch (err) {
        handleAllError(err)
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  return (
    <>
      <button title="RH Sepration Rejection" className="model-delete-button" onClick={handleShow}>
        <CrossRed />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">

        <Modal.Header closeButton>
          <Modal.Title>RH Sepration Rejection of "{i.emp_code}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-row ">
                <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Employee:
                </label>
                <div className="flex-row align-center ">
                  {/* <td className="table-body" style={{ textAlign: "center" }}> */}
                  <img
                    className="profile-img"
                    src={
                      `${i.profilepic}`
                        ? `${IMAGE_URL}${i.profilepic}`
                        : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                    alt="profilepic"
                  />
                  <p>{i.emp_name}</p>
                  {/* </td> */}
                  <div className="form-labels announce-date font-size-heading">
                  </div>
                </div>
              </div>

              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Request Date:
                </label>
                <div className="form-labels announce-date font-size-heading align-center">
                  {i.application_date ? formattedDateLong(i.application_date) : '-'}
                </div>
              </div>

              <div className="flex-column">
                <label htmlFor="action_description" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Reason for Rejection:<span className="required">*</span>
                </label>
                <textarea
                  id="action_description"
                  type="text"
                  name="action_description"
                  placeholder="Your Reason"
                  onChange={handleInputChange}
                  // maxLength={200}
                  value={formData.action_description}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.action_description
                    ? "error"
                    : inputState.action_description
                      ? "success"
                      : ""
                    }`}
                />
                {errors.action_description && (
                  <span className="error-message font-size-text ">{errors.action_description}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-delete  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};


const SeprationFormHODApproval = ({ i, getSeprationData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
      handover_items: [],
      assign_task: {}
    })
    setErrors({})
    setShow(false)
  };
  const handleShow = () => {
    const sortedHeadings = [...i.headings].sort((a, b) => a.sorting_var - b.sorting_var);
    const firstHeading = sortedHeadings[0];
    const concernPerson = firstHeading?.approving_authority || ""; // Fallback if no approving authority

    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
      handover_items: [],
      assign_task: {
        task: link,
        assignedby: sessionStorage.getItem("emp_code"),
        assigndate: formattedDate,
        concern_person: concernPerson, // Updated line
        status: "pending",
        priority: "2",
        due_date: formattedDate,
        assignedtocc: [sessionStorage.getItem("emp_code")],
      }
    })
    setShow(true)
  };


  const sortedHeadings = [...i.headings].sort((a, b) => a.sorting_var - b.sorting_var);
  const firstHeading = sortedHeadings[0];
  const concernPerson = firstHeading?.approving_authority || ""; // Fallback if no approving authority

  const link = `${FRONTEND_URL}/seprationAuthorityRemarks/${i.id}/${concernPerson}/3/ <br/> Please Fill The Sepration Process Details for your respective part`;

  const [formData, setFormData] = useState({
    emp_code: sessionStorage.getItem("emp_code"),
    action_description: "",
    action_datetime: new Date().toISOString(),
    handover_items: [],
    assign_task: {
      task: link,
      assignedby: sessionStorage.getItem("emp_code"),
      assigndate: formattedDate,
      concern_person: concernPerson, // Updated line
      status: "pending",
      priority: "2",
      due_date: formattedDate,
      assignedtocc: [sessionStorage.getItem("emp_code")],
    }
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
      "action_description",
      "action_datetime",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });


    formData.handover_items?.forEach((items, index) => {

      if ((!items.asset_name || items.asset_name.trim() === "")) {
        newErrors[`asset_name_${index}`] = `Asset Name is required for item ${index + 1}`;
      }
      if (!items.remarks || items.remarks.trim() === "") {
        newErrors[`remarks_${index}`] = `Approving Remarks is required for item ${index + 1}`;
      }
    });

    setErrors(newErrors);
    setIsSubmitted(true);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    if (value.trim()) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };


  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const updatedFormData = {
      hod_approval: "approved",
      hod_log: {
        emp_code: formData.emp_code || sessionStorage.getItem("emp_code"),
        action_description: formData.action_description || "",
        action_datetime: formData.action_datetime || new Date().toISOString(),
      },
      handover_items: formData.handover_items?.map((item) => ({
        asset_name: item.asset_name,
        concerned_headings: item.concerned_headings,
        person_taking_handover: item.person_taking_handover,
        remarks: item.remarks,
      })) || [],
      task: formData.assign_task,
    };

    if (validateForm()) {
      setLoading(true);
      const loadingToastId = toast.loading("Loading: Please wait...");

      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/updatelogsforseprationwithhandovertemsAPIView/${i.id}/`,
          updatedFormData
        );

        if (res.status === 200) {
          await getSeprationData();
          handleClose();
        } else {
          alert(res);
        }
      } catch (err) {
        handleAllError(err);
      } finally {
        setLoading(false);
        toast.dismiss(loadingToastId);
      }
    }
  };



  const handleOtherInputChange = (index, e) => {
    if (!e || !e.target) {
      console.error("Invalid event object: ", e);
      return;
    }
    const { name, value } = e.target;
    setFormData((prevFormData) => {
      const newOthers = [...prevFormData.handover_items];
      newOthers[index] = {
        ...newOthers[index],
        [name]: value,
      };
      return {
        ...prevFormData,
        handover_items: newOthers
      };
    });
  };

  const handleAddOthers = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      handover_items: [
        ...(prevFormData.handover_items || []),  // Ensure array exists
        {
          asset_name: "",
          concerned_headings: "Head Of Department",
          person_taking_handover: sessionStorage.getItem("emp_code"),
          remarks: "",
        },
      ],
    }));
  };


  const handleRemoveOthers = (index) => {
    setFormData((prevFormData) => {
      if (!prevFormData.handover_items) return prevFormData; // Prevent errors
      const newOthers = [...prevFormData.handover_items];
      newOthers.splice(index, 1);
      return {
        ...prevFormData,
        handover_items: newOthers,
      };
    });
  };

  return (
    <>
      <button title="HOD Sepration Approval" className="model-edit-button" onClick={handleShow}>
        <CheckGreen />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="half-modal">

        <Modal.Header closeButton>
          <Modal.Title>HOD Sepration Approval of "{i.emp_code}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-row ">
                <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Employee:
                </label>
                <div className="flex-row align-center ">
                  {/* <td className="table-body" style={{ textAlign: "center" }}> */}
                  <img
                    className="profile-img"
                    src={
                      `${i.profilepic}`
                        ? `${IMAGE_URL}${i.profilepic}`
                        : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                    alt="profilepic"
                  />
                  <p>{i.emp_name}</p>
                  {/* </td> */}
                  <div className="form-labels announce-date font-size-heading">
                  </div>
                </div>
              </div>

              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Request Date:
                </label>
                <div className="form-labels announce-date font-size-heading align-center">
                  {i.application_date ? formattedDateLong(i.application_date) : '-'}
                </div>
              </div>

              <div className="flex-column">
                <label htmlFor="action_description" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Remarks:<span className="required">*</span>
                </label>
                <textarea
                  id="action_description"
                  type="text"
                  name="action_description"
                  placeholder="Your Remarks"
                  onChange={handleInputChange}
                  // maxLength={200}
                  value={formData.action_description}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.action_description
                    ? "error"
                    : inputState.action_description
                      ? "success"
                      : ""
                    }`}
                />
                {errors.action_description && (
                  <span className="error-message font-size-text ">{errors.action_description}</span>
                )}
              </div>

              <div className="flex-row justify-center">
                <table className="nested-table-lightblue width-40vw">
                  <thead>
                    <tr>
                      <th className="align-center">S. No.</th>
                      <th className="align-center">Asset Name</th>
                      {/* <th className="align-center">Concerned Heading</th> */}
                      {/* <th className="align-center">Person Taking Handover</th> */}
                      <th className="align-center">Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData.handover_items && formData.handover_items.length > 0 ? (
                      formData.handover_items
                        ?.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
                        .map((item, index) => (
                          <tr key={`handover-${index}`}>
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center">{item.asset_name}</td>
                            {/* <td className="text-center">{item.concerned_headings}</td> */}
                            {/* <td className="text-center">{item.person_taking_handover}</td> */}
                            <td className="text-center">{item.remarks}</td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td className="text-center" colSpan="7">No handover items available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <hr className="line" />
              {formData.handover_items?.map((heading, index) => (
                <div className="form-flex-wrap content-tabs " key={index}>
                  < div className="flex-column">
                    <div className="expense-req">
                      <div className="flex-row">
                        <label htmlFor={`asset_name_${index}`} className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                          Asset Name<span className="required">*</span>
                        </label>
                      </div >
                    </div>
                    <input
                      id={`asset_name_${index}`}
                      type="text"
                      name="asset_name"
                      placeholder="Asset Name"
                      onChange={(e) => handleOtherInputChange(index, e)}
                      value={heading.asset_name}
                      className={`form-input-date`}
                    />
                    {errors[`asset_name_${index}`] && (
                      <span className="error-message font-size-text">
                        {errors[`asset_name_${index}`]}
                      </span>
                    )}
                  </div>

                  < div className="flex-column">
                    <div className="expense-req">
                      <div className="flex-row">
                        <label htmlFor={`remarks_${index}`} className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                          Remarks<span className="required">*</span>
                        </label>
                      </div >
                    </div>
                    <input
                      id={`remarks_${index}`}
                      type="text"
                      name="remarks"
                      placeholder="Remarks"
                      onChange={(e) => handleOtherInputChange(index, e)}
                      value={heading.remarks}
                      className={`form-input-date`}
                    />
                    {errors[`remarks_${index}`] && (
                      <span className="error-message font-size-text">
                        {errors[`remarks_${index}`]}
                      </span>
                    )}
                  </div>


                  <div className="flex-column justify-center" >
                    <button type='button' title="Remove" className="flex-row action-div" onClick={() => handleRemoveOthers(index)}>
                      <CrosswithGreyCircle />
                    </button>
                  </div>

                </div>
              ))}

              <button type='button' title="Add Document" className="jd-heading-main font-weight600 -right-button" onClick={handleAddOthers}>
                <AddwithBlueCircle />
                Add More Headings
              </button>



              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Approve
                </button>
              </div>
            </div>
            <p className="error-message font-size-text">
              {isSubmitted && Object.keys(errors).length > 0 && (
                Object.keys(errors).length > 5 ? (
                  <h5 className="text-center">Please fill all mandatory fields!</h5>
                ) : (

                  <h6 className="text-center">
                    {Object.keys(errors).map((field) =>
                      field.charAt(0).toUpperCase() + field.slice(1)
                    ).join(', ') + ' are required!'}
                  </h6>
                )
              )}
            </p>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};

const SeprationFormHODRejection = ({ i, getSeprationData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
    })
    setErrors({})
    setShow(false)
  };
  const handleShow = () => {
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
    })
    setShow(true)
  };

  const [formData, setFormData] = useState({
    emp_code: sessionStorage.getItem("emp_code"),
    action_description: "",
    action_datetime: new Date().toISOString(),
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
      "action_description",
      "action_datetime",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    // console.log("newErrors")
    // console.log(newErrors)
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    if (value.trim()) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };


  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const updatedFormData = {
      hod_approval: "rejected",
      hod_log: formData,
    };
    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/updatelogsforseprationwithhandovertemsAPIView/${i.id}/`,
          updatedFormData
        );

        if (res.status === 200) {
          await getSeprationData();
          handleClose()
        } else {
          alert(res);
        }
      } catch (err) {
        handleAllError(err)
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  return (
    <>
      <button title="HOD Sepration Rejection" className="model-delete-button" onClick={handleShow}>
        <CrossRed />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">

        <Modal.Header closeButton>
          <Modal.Title>HOD Sepration Rejection of "{i.emp_code}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-row ">
                <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Employee:
                </label>
                <div className="flex-row align-center ">
                  {/* <td className="table-body" style={{ textAlign: "center" }}> */}
                  <img
                    className="profile-img"
                    src={
                      `${i.profilepic}`
                        ? `${IMAGE_URL}${i.profilepic}`
                        : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                    alt="profilepic"
                  />
                  <p>{i.emp_name}</p>
                  {/* </td> */}
                  <div className="form-labels announce-date font-size-heading">
                  </div>
                </div>
              </div>

              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Request Date:
                </label>
                <div className="form-labels announce-date font-size-heading align-center">
                  {i.application_date ? formattedDateLong(i.application_date) : '-'}
                </div>
              </div>

              <div className="flex-column">
                <label htmlFor="action_description" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Reason for Rejection:<span className="required">*</span>
                </label>
                <textarea
                  id="action_description"
                  type="text"
                  name="action_description"
                  placeholder="Your Reason"
                  onChange={handleInputChange}
                  // maxLength={200}
                  value={formData.action_description}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.action_description
                    ? "error"
                    : inputState.action_description
                      ? "success"
                      : ""
                    }`}
                />
                {errors.action_description && (
                  <span className="error-message font-size-text ">{errors.action_description}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-delete  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};

const SeprationFormHeadingsApproval = ({ i, emp_code, position, getSeprationData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setFormData({
      emp_code: emp_code,
      action_description: "",
      action_datetime: new Date().toISOString(),
      handover_items: [],
      assign_task: {}
    })
    setErrors({})
    setShow(false)
  };

  const handleShow = (e) => {
    e.preventDefault()
    const sortedHeadings = Array.isArray(i?.headings)
      ? [...i.headings].sort((a, b) => (a.sorting_var || 0) - (b.sorting_var || 0))
      : [];
    const currentIndex = sortedHeadings.findIndex(
      (h) => Number(h?.sorting_var) === Number(position)
    );
    const nextPerson = sortedHeadings[currentIndex + 1] || null;
    const concernPerson = nextPerson ? nextPerson.approving_authority : "";
    const currentHeading = sortedHeadings[currentIndex];
    const concernedHeadings = currentHeading ? currentHeading.heading_name || currentHeading.department_name : "Pending...";  // Default to "Head Of Department" if not found


    console.log("currentHeading")
    console.log(currentHeading.approval === "pending")

    // Define base formData
    let newFormData = {
      emp_code: emp_code,
      action_description: "",
      action_datetime: new Date().toISOString(),
      handover_items: [],
    };

    // Add assign_task only if nextPerson exists
    if (nextPerson) {
      newFormData.assign_task = {
        task: link,
        assignedby: emp_code,
        assigndate: formattedDate,
        concern_person: concernPerson,
        status: "pending",
        priority: "2",
        due_date: formattedDate,
        assignedtocc: [emp_code],
      };
    }

    setFormData(newFormData);
    setShow(true);
  };



  const sortedHeadings = Array.isArray(i?.headings)
    ? [...i.headings].sort((a, b) => (a.sorting_var || 0) - (b.sorting_var || 0))
    : [];
  const currentIndex = sortedHeadings.findIndex(
    (h) => Number(h?.sorting_var) === Number(position)
  );
  const nextPerson = sortedHeadings[currentIndex + 1] || null;
  const concernPerson = nextPerson ? nextPerson.approving_authority : "";
  const currentHeading = sortedHeadings[currentIndex];
  const concernedHeadings = currentHeading ? currentHeading.heading_name || currentHeading.department_name : "Pending...";  // Default to "Head Of Department" if not found

  const link = `${FRONTEND_URL}/seprationAuthorityRemarks/${i.id}/${concernPerson}/${(Number(position) + 1)}/ <br/> Please Fill The Sepration Process Details for your respective part`;

  // Define initial state based on the existence of nextPerson
  const initialState = {
    emp_code: emp_code,
    action_description: "",
    action_datetime: new Date().toISOString(),
    handover_items: [],
  };

  // Conditionally add assign_task if nextPerson exists
  if (nextPerson) {
    initialState.assign_task = {
      task: link,
      assignedby: emp_code,
      assigndate: formattedDate,
      concern_person: concernPerson,
      status: "pending",
      priority: "2",
      due_date: formattedDate,
      assignedtocc: [emp_code],
    };
  }

  // Initialize state
  const [formData, setFormData] = useState(initialState);

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
      "action_description",
      "action_datetime",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });


    formData.handover_items?.forEach((items, index) => {

      if ((!items.asset_name || items.asset_name.trim() === "")) {
        newErrors[`asset_name_${index}`] = `Asset Name is required for item ${index + 1}`;
      }
      if (!items.remarks || items.remarks.trim() === "") {
        newErrors[`remarks_${index}`] = `Approving Remarks is required for item ${index + 1}`;
      }
    });

    setErrors(newErrors);
    setIsSubmitted(true);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    if (value.trim()) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };


  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const updatedFormData = {
      approval: "approved",
      approval_log: {
        emp_code: formData.emp_code || emp_code,
        action_description: formData.action_description || "",
        action_datetime: formData.action_datetime || new Date().toISOString(),
      },
      handover_items: formData.handover_items?.map((item) => ({
        asset_name: item.asset_name,
        concerned_headings: item.concerned_headings,
        person_taking_handover: item.person_taking_handover,
        remarks: item.remarks,
      })) || [],
      task: formData.assign_task,
    };

    if (nextPerson) {
      updatedFormData.task = {
        task: link,
        assignedby: emp_code,
        assigndate: formattedDate,
        concern_person: concernPerson,
        status: "pending",
        priority: "2",
        due_date: formattedDate,
        assignedtocc: [emp_code],
      };
    }


    if (validateForm()) {
      setLoading(true);
      const loadingToastId = toast.loading("Loading: Please wait...");

      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/updateheadinglogswithitemsAPIView/${currentHeading.id}/`,
          updatedFormData
        );

        if (res.status === 200) {
          await getSeprationData(i.id);
          handleClose();
        } else {
          alert(res);
        }
      } catch (err) {
        handleAllError(err);
      } finally {
        setLoading(false);
        toast.dismiss(loadingToastId);
      }
    }
  };



  const handleOtherInputChange = (index, e) => {
    if (!e || !e.target) {
      console.error("Invalid event object: ", e);
      return;
    }
    const { name, value } = e.target;
    setFormData((prevFormData) => {
      const newOthers = [...prevFormData.handover_items];
      newOthers[index] = {
        ...newOthers[index],
        [name]: value,
      };
      return {
        ...prevFormData,
        handover_items: newOthers
      };
    });
  };

  const handleAddOthers = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      handover_items: [
        ...(prevFormData.handover_items || []),  // Ensure array exists
        {
          asset_name: "",
          concerned_headings: concernedHeadings,  // Dynamically set concerned_headings
          person_taking_handover: emp_code,
          remarks: "",
        },
      ],
    }));
  };



  const handleRemoveOthers = (index) => {
    setFormData((prevFormData) => {
      if (!prevFormData.handover_items) return prevFormData; // Prevent errors
      const newOthers = [...prevFormData.handover_items];
      newOthers.splice(index, 1);
      return {
        ...prevFormData,
        handover_items: newOthers,
      };
    });
  };

  return (
    <>
      {/* <button title={`${concernedHeadings} Sepration Approval`} className="model-edit-button" onClick={handleShow}>
        <CheckGreen />
      </button> */}
      <button className="model-button  font-weight500 model-button-submit" title={`${concernedHeadings} Sepration Approval`} onClick={handleShow}>
        Approve
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="half-modal">

        <Modal.Header closeButton>
          <Modal.Title>{concernedHeadings} Sepration Approval of "{i.emp_code}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-row ">
                <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Employee:
                </label>
                <div className="flex-row align-center ">
                  {/* <td className="table-body" style={{ textAlign: "center" }}> */}
                  <img
                    className="profile-img"
                    src={
                      `${i.profilepic}`
                        ? `${IMAGE_URL}${i.profilepic}`
                        : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                    alt="profilepic"
                  />
                  <p>{i.emp_name}</p>
                  {/* </td> */}
                  <div className="form-labels announce-date font-size-heading">
                  </div>
                </div>
              </div>

              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Request Date:
                </label>
                <div className="form-labels announce-date font-size-heading align-center">
                  {i.application_date ? formattedDateLong(i.application_date) : '-'}
                </div>
              </div>

              <div className="flex-column">
                <label htmlFor="action_description" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Remarks:<span className="required">*</span>
                </label>
                <textarea
                  id="action_description"
                  type="text"
                  name="action_description"
                  placeholder="Your Remarks"
                  onChange={handleInputChange}
                  // maxLength={200}
                  value={formData.action_description}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.action_description
                    ? "error"
                    : inputState.action_description
                      ? "success"
                      : ""
                    }`}
                />
                {errors.action_description && (
                  <span className="error-message font-size-text ">{errors.action_description}</span>
                )}
              </div>

              <div className="flex-row justify-center">
                <table className="nested-table-lightblue width-40vw">
                  <thead>
                    <tr>
                      <th className="align-center">S. No.</th>
                      <th className="align-center">Asset Name</th>
                      {/* <th className="align-center">Concerned Heading</th> */}
                      {/* <th className="align-center">Person Taking Handover</th> */}
                      <th className="align-center">Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData.handover_items && formData.handover_items.length > 0 ? (
                      formData.handover_items
                        ?.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
                        .map((item, index) => (
                          <tr key={`handover-${index}`}>
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center">{item.asset_name}</td>
                            {/* <td className="text-center">{item.concerned_headings}</td> */}
                            {/* <td className="text-center">{item.person_taking_handover}</td> */}
                            <td className="text-center">{item.remarks}</td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td className="text-center" colSpan="7">No handover items available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <hr className="line" />
              {formData.handover_items?.map((heading, index) => (
                <div className="form-flex-wrap content-tabs " key={index}>
                  < div className="flex-column">
                    <div className="expense-req">
                      <div className="flex-row">
                        <label htmlFor={`asset_name_${index}`} className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                          Asset Name<span className="required">*</span>
                        </label>
                      </div >
                    </div>
                    <input
                      id={`asset_name_${index}`}
                      type="text"
                      name="asset_name"
                      placeholder="Asset Name"
                      onChange={(e) => handleOtherInputChange(index, e)}
                      value={heading.asset_name}
                      className={`form-input-date`}
                    />
                    {errors[`asset_name_${index}`] && (
                      <span className="error-message font-size-text">
                        {errors[`asset_name_${index}`]}
                      </span>
                    )}
                  </div>

                  < div className="flex-column">
                    <div className="expense-req">
                      <div className="flex-row">
                        <label htmlFor={`remarks_${index}`} className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                          Remarks<span className="required">*</span>
                        </label>
                      </div >
                    </div>
                    <input
                      id={`remarks_${index}`}
                      type="text"
                      name="remarks"
                      placeholder="Remarks"
                      onChange={(e) => handleOtherInputChange(index, e)}
                      value={heading.remarks}
                      className={`form-input-date`}
                    />
                    {errors[`remarks_${index}`] && (
                      <span className="error-message font-size-text">
                        {errors[`remarks_${index}`]}
                      </span>
                    )}
                  </div>


                  <div className="flex-column justify-center" >
                    <button type='button' title="Remove" className="flex-row action-div" onClick={() => handleRemoveOthers(index)}>
                      <CrosswithGreyCircle />
                    </button>
                  </div>

                </div>
              ))}

              <button type='button' title="Add Document" className="jd-heading-main font-weight600 -right-button" onClick={handleAddOthers}>
                <AddwithBlueCircle />
                Add More Headings
              </button>



              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Approve
                </button>
              </div>
            </div>
            <p className="error-message font-size-text">
              {isSubmitted && Object.keys(errors).length > 0 && (
                Object.keys(errors).length > 5 ? (
                  <h5 className="text-center">Please fill all mandatory fields!</h5>
                ) : (

                  <h6 className="text-center">
                    {Object.keys(errors).map((field) =>
                      field.charAt(0).toUpperCase() + field.slice(1)
                    ).join(', ') + ' are required!'}
                  </h6>
                )
              )}
            </p>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};

const SeprationFormHeadingsRejection = ({ i, emp_code, position, getSeprationData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setFormData({
      emp_code: emp_code,
      action_description: "",
      action_datetime: new Date().toISOString(),
    })
    setErrors({})
    setShow(false)
  };
  const handleShow = (e) => {
    e.preventDefault()
    setFormData({
      emp_code: emp_code,
      action_description: "",
      action_datetime: new Date().toISOString(),
    })
    setShow(true)
  };

  const [formData, setFormData] = useState({
    emp_code: emp_code,
    action_description: "",
    action_datetime: new Date().toISOString(),
  });


  const sortedHeadings = Array.isArray(i?.headings)
    ? [...i.headings].sort((a, b) => (a.sorting_var || 0) - (b.sorting_var || 0))
    : [];
  const currentIndex = sortedHeadings.findIndex(
    (h) => Number(h?.sorting_var) === Number(position)
  );
  const nextPerson = sortedHeadings[currentIndex + 1] || null;
  const concernPerson = nextPerson ? nextPerson.approving_authority : "";
  const currentHeading = sortedHeadings[currentIndex];
  const concernedHeadings = currentHeading ? currentHeading.heading_name || currentHeading.department_name : "Pending...";  // Default to "Head Of Department" if not found

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
      "action_description",
      "action_datetime",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    // console.log("newErrors")
    // console.log(newErrors)
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    if (value.trim()) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };


  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const updatedFormData = {
      approval: "rejected",
      approval_log: formData,
    };
    if (validateForm()) {

      console.log("updatedFormData")
      console.log(updatedFormData)
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/updateheadinglogswithitemsAPIView/${currentHeading.id}/`,
          updatedFormData
        );

        if (res.status === 200) {
          await getSeprationData(i.id)
          handleClose()
        } else {
          alert(res);
        }
      } catch (err) {
        handleAllError(err)
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };



  return (
    <>
      {/* <button title={`${concernedHeadings} Sepration Rejection`} className="model-delete-button" onClick={handleShow}>
        <CrossRed />
      </button> */}
      <button className="model-button  font-weight500 model-button-delete" title={`${concernedHeadings} Sepration Rejection`} onClick={handleShow}>
        Reject
      </button>


      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">

        <Modal.Header closeButton>
          <Modal.Title>{concernedHeadings} Sepration Rejection of "{i.emp_code}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-row ">
                <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Employee:
                </label>
                <div className="flex-row align-center ">
                  {/* <td className="table-body" style={{ textAlign: "center" }}> */}
                  <img
                    className="profile-img"
                    src={
                      `${i.profilepic}`
                        ? `${IMAGE_URL}${i.profilepic}`
                        : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                    alt="profilepic"
                  />
                  <p>{i.emp_name}</p>
                  {/* </td> */}
                  <div className="form-labels announce-date font-size-heading">
                  </div>
                </div >
              </div >

              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Request Date:
                </label>
                <div className="form-labels announce-date font-size-heading align-center">
                  {i.application_date ? formattedDateLong(i.application_date) : '-'}
                </div>
              </div>

              <div className="flex-column">
                <label htmlFor="action_description" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Reason for Rejection:<span className="required">*</span>
                </label>
                <textarea
                  id="action_description"
                  type="text"
                  name="action_description"
                  placeholder="Your Reason"
                  onChange={handleInputChange}
                  // maxLength={200}
                  value={formData.action_description}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.action_description
                    ? "error"
                    : inputState.action_description
                      ? "success"
                      : ""
                    }`}
                />
                {errors.action_description && (
                  <span className="error-message font-size-text ">{errors.action_description}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-delete  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Reject
                </button>
              </div>
            </div >
          </div >
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body >
      </Modal >
    </>
  );
};

const SeprationFormHRApproval = ({ i, getSeprationData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
      handover_items: [],
    })
    setErrors({})
    setShow(false)
  };
  const handleShow = () => {
    getEmployeeOrgData(i.emp_code);
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
      handover_items: [],
    })
    setShow(true)
  };

  const [formData, setFormData] = useState({
    emp_code: sessionStorage.getItem("emp_code"),
    action_description: "",
    action_datetime: new Date().toISOString(),
    handover_items: [],
  });
  const [empData, setEmpData] = useState({})


  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
      "action_description",
      "action_datetime",
      // "employee_type",
      // "last_working_date",

    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });


    formData.handover_items?.forEach((items, index) => {

      if ((!items.asset_name || items.asset_name.trim() === "")) {
        newErrors[`asset_name_${index}`] = `Asset Name is required for item ${index + 1}`;
      }
      if (!items.remarks || items.remarks.trim() === "") {
        newErrors[`remarks_${index}`] = `Approving Remarks is required for item ${index + 1}`;
      }
    });

    setErrors(newErrors);
    setIsSubmitted(true);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    if (value.trim()) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };


  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const updatedFormData = {
      hr_approval: "approved",
      hr_log: {
        emp_code: formData.emp_code || sessionStorage.getItem("emp_code"),
        action_description: formData.action_description || "",
        action_datetime: formData.action_datetime || new Date().toISOString(),
      },
      handover_items: formData.handover_items?.map((item) => ({
        asset_name: item.asset_name,
        concerned_headings: item.concerned_headings,
        person_taking_handover: item.person_taking_handover,
        remarks: item.remarks,
      })) || [],
    };


    const updatedempData = {
      ...empData,
      employee_status: "inactive",
      employee_type: formData.employee_type,
      last_working_date: formData.last_working_date
    };

    if (validateForm()) {
      setLoading(true);
      const loadingToastId = toast.loading("Loading: Please wait...");

      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/updatelogsforseprationwithhandovertemsAPIView/${i.id}/`,
          updatedFormData
        );
        if (res.status === 200) {
          let empOrg = await axios.post(
            `${BASE_URL}/wfm/ourcompanyuserdetaildirectadd/`,
            updatedempData
          );
          if (empOrg.status === 200) {
            await getSeprationData();
            handleClose()
          }
          else {
            console.log("empOrg:", empOrg);
          }
        } else {
          console.log("sepration:", res);
        }
      } catch (err) {
        handleAllError(err);
      } finally {
        setLoading(false);
        toast.dismiss(loadingToastId);
      }
    }
  };



  const handleOtherInputChange = (index, e) => {
    if (!e || !e.target) {
      console.error("Invalid event object: ", e);
      return;
    }
    const { name, value } = e.target;
    setFormData((prevFormData) => {
      const newOthers = [...prevFormData.handover_items];
      newOthers[index] = {
        ...newOthers[index],
        [name]: value,
      };
      return {
        ...prevFormData,
        handover_items: newOthers
      };
    });
  };

  const handleAddOthers = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      handover_items: [
        ...(prevFormData.handover_items || []),  // Ensure array exists
        {
          asset_name: "",
          concerned_headings: "Human Resource",
          person_taking_handover: sessionStorage.getItem("emp_code"),
          remarks: "",
        },
      ],
    }));
  };


  const handleRemoveOthers = (index) => {
    setFormData((prevFormData) => {
      if (!prevFormData.handover_items) return prevFormData; // Prevent errors
      const newOthers = [...prevFormData.handover_items];
      newOthers.splice(index, 1);
      return {
        ...prevFormData,
        handover_items: newOthers,
      };
    });
  };

  const [buffer, setBuffering] = useState(false);

  const getEmployeeOrgData = async (emp_code) => {
    try {
      setBuffering(true)
      const emp = await axios.get(
        `${BASE_URL}/wfm/ourcompanydetailsbyemp/${emp_code}/`
      );
      // setEmployeeOrgDetails(emp.data);
      setEmpData(emp.data);
    } catch (err) {
      //toast Logic
      handleAllError(err);
    } finally {
      setBuffering(false)
    }
  };



  return (
    <>
      <button title="HR Sepration Approval" className="model-edit-button" onClick={handleShow}>
        <CheckGreen />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="half-modal">

        <Modal.Header closeButton>
          <Modal.Title>HR Sepration Approval of "{i.emp_code}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-row ">
                <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Employee:
                </label>
                <div className="flex-row align-center ">
                  {/* <td className="table-body" style={{ textAlign: "center" }}> */}
                  <img
                    className="profile-img"
                    src={
                      `${i.profilepic}`
                        ? `${IMAGE_URL}${i.profilepic}`
                        : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                    alt="profilepic"
                  />
                  <p>{i.emp_name}</p>
                  {/* </td> */}
                  <div className="form-labels announce-date font-size-heading">
                  </div>
                </div>
              </div>

              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Request Date:
                </label>
                <div className="form-labels announce-date font-size-heading align-center">
                  {i.application_date ? formattedDateLong(i.application_date) : '-'}
                </div>
              </div>

              <div className="flex-column">
                <label htmlFor="action_description" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Remarks:<span className="required">*</span>
                </label>
                <textarea
                  id="action_description"
                  type="text"
                  name="action_description"
                  placeholder="Your Remarks"
                  onChange={handleInputChange}
                  // maxLength={200}
                  value={formData.action_description}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.action_description
                    ? "error"
                    : inputState.action_description
                      ? "success"
                      : ""
                    }`}
                />
                {errors.action_description && (
                  <span className="error-message font-size-text ">{errors.action_description}</span>
                )}
              </div>

              <div className="flex-row justify-center">
                <table className="nested-table-lightblue width-40vw">
                  <thead>
                    <tr>
                      <th className="align-center">S. No.</th>
                      <th className="align-center">Asset Name</th>
                      {/* <th className="align-center">Concerned Heading</th> */}
                      {/* <th className="align-center">Person Taking Handover</th> */}
                      <th className="align-center">Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData.handover_items && formData.handover_items.length > 0 ? (
                      formData.handover_items
                        ?.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
                        .map((item, index) => (
                          <tr key={`handover-${index}`}>
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center">{item.asset_name}</td>
                            {/* <td className="text-center">{item.concerned_headings}</td> */}
                            {/* <td className="text-center">{item.person_taking_handover}</td> */}
                            <td className="text-center">{item.remarks}</td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td className="text-center" colSpan="7">No handover items available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <hr className="line" />
              {formData.handover_items?.map((heading, index) => (
                <div className="form-flex-wrap content-tabs " key={index}>
                  < div className="flex-column">
                    <div className="expense-req">
                      <div className="flex-row">
                        <label htmlFor={`asset_name_${index}`} className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                          Asset Name<span className="required">*</span>
                        </label>
                      </div >
                    </div>
                    <input
                      id={`asset_name_${index}`}
                      type="text"
                      name="asset_name"
                      placeholder="Asset Name"
                      onChange={(e) => handleOtherInputChange(index, e)}
                      value={heading.asset_name}
                      className={`form-input-date`}
                    />
                    {errors[`asset_name_${index}`] && (
                      <span className="error-message font-size-text">
                        {errors[`asset_name_${index}`]}
                      </span>
                    )}
                  </div>

                  < div className="flex-column">
                    <div className="expense-req">
                      <div className="flex-row">
                        <label htmlFor={`remarks_${index}`} className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                          Remarks<span className="required">*</span>
                        </label>
                      </div >
                    </div>
                    <input
                      id={`remarks_${index}`}
                      type="text"
                      name="remarks"
                      placeholder="Remarks"
                      onChange={(e) => handleOtherInputChange(index, e)}
                      value={heading.remarks}
                      className={`form-input-date`}
                    />
                    {errors[`remarks_${index}`] && (
                      <span className="error-message font-size-text">
                        {errors[`remarks_${index}`]}
                      </span>
                    )}
                  </div>


                  <div className="flex-column justify-center" >
                    <button type='button' title="Remove" className="flex-row action-div" onClick={() => handleRemoveOthers(index)}>
                      <CrosswithGreyCircle />
                    </button>
                  </div>

                </div>
              ))}

              <button type='button' title="Add Document" className="jd-heading-main font-weight600 -right-button" onClick={handleAddOthers}>
                <AddwithBlueCircle />
                Add More Headings
              </button>

              <hr className="line" />

              {/* <table className="table-css width-10vw">
                <thead>
                  <tr className="custom-table-head-tr width-10vw">
                    <th className="align-left width-10vw">S no.</th>
                    <th className="align-center width-10vw">Office Rent</th>
                    <th className="align-center width-10vw">Actual Month</th>
                  </tr>
                </thead>
                <tbody>
                  {i.settlement_headings?.map((entry, index) => (
                    <tr className="custom-table-head-td" key={index}>
                      <td className="align-left width-5vw">{index + 1}</td>
                      <td>
                        <div title="Office Rent" className="flex-column form-group-selectt">
                          <input
                            id={`officerent_${index}`}
                            name="officerent"
                            // value={officeRent.find(t => t.id === entry.officerent)?.num_of_months || "Loading..."}
                            className={`form-input-date width-10vw ${errors[`${index}_officerent`] ? "error" : ""}`}
                            disabled
                          />
                        </div>
                      </td>
                      <td>
                        <div title="Actual Month" className="flex-column">
                          <input
                            type="number"
                            id={`actual_month_${index}`}
                            name="actual_month"
                            placeholder="Actual Month"
                            value={entry.actual_month}
                            onChange={(e) => handleInputChange(e, index)}
                            className={`form-input-date width-5vw ${errors[`${index}_actual_month`] ? "error" : ""}`}
                          />
                        </div>
                      </td>

                    </tr>
                  ))}
                </tbody>
              </table> */}

              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Complete Process
                </button>
              </div>
            </div>
            <p className="error-message font-size-text">
              {isSubmitted && Object.keys(errors).length > 0 && (
                Object.keys(errors).length > 5 ? (
                  <h5 className="text-center">Please fill all mandatory fields!</h5>
                ) : (

                  <h6 className="text-center">
                    {Object.keys(errors).map((field) =>
                      field.charAt(0).toUpperCase() + field.slice(1)
                    ).join(', ') + ' are required!'}
                  </h6>
                )
              )}
            </p>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};

const SeprationFormHRRejection = ({ i, getSeprationData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
    })
    setErrors({})
    setShow(false)
  };
  const handleShow = () => {
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
    })
    setShow(true)
  };

  const [formData, setFormData] = useState({
    emp_code: sessionStorage.getItem("emp_code"),
    action_description: "",
    action_datetime: new Date().toISOString(),
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
      "action_description",
      "action_datetime",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    // console.log("newErrors")
    // console.log(newErrors)
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    if (value.trim()) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };


  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const updatedFormData = {
      hr_approval: "rejected",
      hr_log: formData,
    };
    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        alert("Work in Progress");
        // let res = await axios.put(
        //   `${BASE_URL}/wfm/updatelogsforseprationwithhandovertemsAPIView/${i.id}/`,
        //   updatedFormData
        // );

        // if (res.status === 200) {
        //   await getSeprationData();
        //   handleClose()
        // } else {
        //   alert(res);
        // }

      } catch (err) {
        handleAllError(err)
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  return (
    <>
      <button title="HR Sepration Cancelation" className="model-delete-button" onClick={handleShow}>
        <CrossRed />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">

        <Modal.Header closeButton>
          <Modal.Title>HR Sepration Cancelation of "{i.emp_code}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-row ">
                <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Employee:
                </label>
                <div className="flex-row align-center ">
                  {/* <td className="table-body" style={{ textAlign: "center" }}> */}
                  <img
                    className="profile-img"
                    src={
                      `${i.profilepic}`
                        ? `${IMAGE_URL}${i.profilepic}`
                        : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                    alt="profilepic"
                  />
                  <p>{i.emp_name}</p>
                  {/* </td> */}
                  <div className="form-labels announce-date font-size-heading">
                  </div>
                </div>
              </div>

              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Request Date:
                </label>
                <div className="form-labels announce-date font-size-heading align-center">
                  {i.application_date ? formattedDateLong(i.application_date) : '-'}
                </div>
              </div>

              <div className="flex-column">
                <label htmlFor="action_description" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Reason for Cancelation of Process:<span className="required">*</span>
                </label>
                <textarea
                  id="action_description"
                  type="text"
                  name="action_description"
                  placeholder="Reason"
                  onChange={handleInputChange}
                  // maxLength={200}
                  value={formData.action_description}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.action_description
                    ? "error"
                    : inputState.action_description
                      ? "success"
                      : ""
                    }`}
                />
                {errors.action_description && (
                  <span className="error-message font-size-text ">{errors.action_description}</span>
                )}
              </div>

              <div className="button-models">
                {/* <button className="model-button model-button-cancel   font-weight500   " onClick={handleClose}>
                  Cancel
                </button> */}
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-delete  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Cancel Process
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};

const SeprationFormAdminApproval = ({ i, getSeprationData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
      handover_items: [],
    })
    setErrors({})
    setShow(false)
  };
  const handleShow = () => {
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
      handover_items: [],
    })
    setShow(true)
  };

  const [formData, setFormData] = useState({
    emp_code: sessionStorage.getItem("emp_code"),
    action_description: "",
    action_datetime: new Date().toISOString(),
    handover_items: [],
  });


  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
      "action_description",
      "action_datetime",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });


    formData.handover_items?.forEach((items, index) => {

      if ((!items.asset_name || items.asset_name.trim() === "")) {
        newErrors[`asset_name_${index}`] = `Asset Name is required for item ${index + 1}`;
      }
      if (!items.remarks || items.remarks.trim() === "") {
        newErrors[`remarks_${index}`] = `Approving Remarks is required for item ${index + 1}`;
      }
    });

    setErrors(newErrors);
    setIsSubmitted(true);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    if (value.trim()) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };


  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const updatedFormData = {
      admin_approval: "approved",
      admin_log: {
        emp_code: formData.emp_code || sessionStorage.getItem("emp_code"),
        action_description: formData.action_description || "",
        action_datetime: formData.action_datetime || new Date().toISOString(),
      },
      handover_items: formData.handover_items?.map((item) => ({
        asset_name: item.asset_name,
        concerned_headings: item.concerned_headings,
        person_taking_handover: item.person_taking_handover,
        remarks: item.remarks,
      })) || [],
    };

    if (validateForm()) {
      setLoading(true);
      const loadingToastId = toast.loading("Loading: Please wait...");

      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/updatelogsforseprationwithhandovertemsAPIView/${i.id}/`,
          updatedFormData
        );

        if (res.status === 200) {
          await getSeprationData();
          handleClose();
        } else {
          alert(res);
        }
      } catch (err) {
        handleAllError(err);
      } finally {
        setLoading(false);
        toast.dismiss(loadingToastId);
      }
    }
  };



  const handleOtherInputChange = (index, e) => {
    if (!e || !e.target) {
      console.error("Invalid event object: ", e);
      return;
    }
    const { name, value } = e.target;
    setFormData((prevFormData) => {
      const newOthers = [...prevFormData.handover_items];
      newOthers[index] = {
        ...newOthers[index],
        [name]: value,
      };
      return {
        ...prevFormData,
        handover_items: newOthers
      };
    });
  };

  const handleAddOthers = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      handover_items: [
        ...(prevFormData.handover_items || []),  // Ensure array exists
        {
          asset_name: "",
          concerned_headings: "Admin",
          person_taking_handover: sessionStorage.getItem("emp_code"),
          remarks: "",
        },
      ],
    }));
  };


  const handleRemoveOthers = (index) => {
    setFormData((prevFormData) => {
      if (!prevFormData.handover_items) return prevFormData; // Prevent errors
      const newOthers = [...prevFormData.handover_items];
      newOthers.splice(index, 1);
      return {
        ...prevFormData,
        handover_items: newOthers,
      };
    });
  };



  return (
    <>
      <button title="Admin Sepration Approval" className="model-edit-button" onClick={handleShow}>
        <CheckGreen />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="half-modal">

        <Modal.Header closeButton>
          <Modal.Title>Admin Sepration Approval of "{i.emp_code}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-row ">
                <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Employee:
                </label>
                <div className="flex-row align-center ">
                  {/* <td className="table-body" style={{ textAlign: "center" }}> */}
                  <img
                    className="profile-img"
                    src={
                      `${i.profilepic}`
                        ? `${IMAGE_URL}${i.profilepic}`
                        : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                    alt="profilepic"
                  />
                  <p>{i.emp_name}</p>
                  {/* </td> */}
                  <div className="form-labels announce-date font-size-heading">
                  </div>
                </div>
              </div>

              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Request Date:
                </label>
                <div className="form-labels announce-date font-size-heading align-center">
                  {i.application_date ? formattedDateLong(i.application_date) : '-'}
                </div>
              </div>

              <div className="flex-column">
                <label htmlFor="action_description" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Remarks:<span className="required">*</span>
                </label>
                <textarea
                  id="action_description"
                  type="text"
                  name="action_description"
                  placeholder="Your Remarks"
                  onChange={handleInputChange}
                  // maxLength={200}
                  value={formData.action_description}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.action_description
                    ? "error"
                    : inputState.action_description
                      ? "success"
                      : ""
                    }`}
                />
                {errors.action_description && (
                  <span className="error-message font-size-text ">{errors.action_description}</span>
                )}
              </div>

              <div className="flex-row justify-center">
                <table className="nested-table-lightblue width-40vw">
                  <thead>
                    <tr>
                      <th className="align-center">S. No.</th>
                      <th className="align-center">Asset Name</th>
                      {/* <th className="align-center">Concerned Heading</th> */}
                      {/* <th className="align-center">Person Taking Handover</th> */}
                      <th className="align-center">Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData.handover_items && formData.handover_items.length > 0 ? (
                      formData.handover_items
                        ?.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
                        .map((item, index) => (
                          <tr key={`handover-${index}`}>
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center">{item.asset_name}</td>
                            {/* <td className="text-center">{item.concerned_headings}</td> */}
                            {/* <td className="text-center">{item.person_taking_handover}</td> */}
                            <td className="text-center">{item.remarks}</td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td className="text-center" colSpan="7">No handover items available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <hr className="line" />
              {formData.handover_items?.map((heading, index) => (
                <div className="form-flex-wrap content-tabs " key={index}>
                  < div className="flex-column">
                    <div className="expense-req">
                      <div className="flex-row">
                        <label htmlFor={`asset_name_${index}`} className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                          Asset Name<span className="required">*</span>
                        </label>
                      </div >
                    </div>
                    <input
                      id={`asset_name_${index}`}
                      type="text"
                      name="asset_name"
                      placeholder="Asset Name"
                      onChange={(e) => handleOtherInputChange(index, e)}
                      value={heading.asset_name}
                      className={`form-input-date`}
                    />
                    {errors[`asset_name_${index}`] && (
                      <span className="error-message font-size-text">
                        {errors[`asset_name_${index}`]}
                      </span>
                    )}
                  </div>

                  < div className="flex-column">
                    <div className="expense-req">
                      <div className="flex-row">
                        <label htmlFor={`remarks_${index}`} className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                          Remarks<span className="required">*</span>
                        </label>
                      </div >
                    </div>
                    <input
                      id={`remarks_${index}`}
                      type="text"
                      name="remarks"
                      placeholder="Remarks"
                      onChange={(e) => handleOtherInputChange(index, e)}
                      value={heading.remarks}
                      className={`form-input-date`}
                    />
                    {errors[`remarks_${index}`] && (
                      <span className="error-message font-size-text">
                        {errors[`remarks_${index}`]}
                      </span>
                    )}
                  </div>


                  <div className="flex-column justify-center" >
                    <button type='button' title="Remove" className="flex-row action-div" onClick={() => handleRemoveOthers(index)}>
                      <CrosswithGreyCircle />
                    </button>
                  </div>

                </div>
              ))}

              <button type='button' title="Add Document" className="jd-heading-main font-weight600 -right-button" onClick={handleAddOthers}>
                <AddwithBlueCircle />
                Add More Headings
              </button>



              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Approve
                </button>
              </div>
            </div>
            <p className="error-message font-size-text">
              {isSubmitted && Object.keys(errors).length > 0 && (
                Object.keys(errors).length > 5 ? (
                  <h5 className="text-center">Please fill all mandatory fields!</h5>
                ) : (

                  <h6 className="text-center">
                    {Object.keys(errors).map((field) =>
                      field.charAt(0).toUpperCase() + field.slice(1)
                    ).join(', ') + ' are required!'}
                  </h6>
                )
              )}
            </p>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};

const SeprationFormAdminRejection = ({ i, getSeprationData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
    })
    setErrors({})
    setShow(false)
  };
  const handleShow = () => {
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
    })
    setShow(true)
  };

  const [formData, setFormData] = useState({
    emp_code: sessionStorage.getItem("emp_code"),
    action_description: "",
    action_datetime: new Date().toISOString(),
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
      "action_description",
      "action_datetime",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    // console.log("newErrors")
    // console.log(newErrors)
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    if (value.trim()) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };


  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const updatedFormData = {
      admin_approval: "rejected",
      admin_log: formData,
    };
    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/updatelogsforseprationwithhandovertemsAPIView/${i.id}/`,
          updatedFormData
        );

        if (res.status === 200) {
          await getSeprationData();
          handleClose()
        } else {
          alert(res);
        }
      } catch (err) {
        handleAllError(err)
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  return (
    <>
      <button title="Admin Sepration Rejection" className="model-delete-button" onClick={handleShow}>
        <CrossRed />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">

        <Modal.Header closeButton>
          <Modal.Title>Admin Sepration Rejection of "{i.emp_code}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-row ">
                <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Employee:
                </label>
                <div className="flex-row align-center ">
                  {/* <td className="table-body" style={{ textAlign: "center" }}> */}
                  <img
                    className="profile-img"
                    src={
                      `${i.profilepic}`
                        ? `${IMAGE_URL}${i.profilepic}`
                        : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                    alt="profilepic"
                  />
                  <p>{i.emp_name}</p>
                  {/* </td> */}
                  <div className="form-labels announce-date font-size-heading">
                  </div>
                </div>
              </div>

              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Request Date:
                </label>
                <div className="form-labels announce-date font-size-heading align-center">
                  {i.application_date ? formattedDateLong(i.application_date) : '-'}
                </div>
              </div>

              <div className="flex-column">
                <label htmlFor="action_description" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Reason for Rejection:<span className="required">*</span>
                </label>
                <textarea
                  id="action_description"
                  type="text"
                  name="action_description"
                  placeholder="Your Reason"
                  onChange={handleInputChange}
                  // maxLength={200}
                  value={formData.action_description}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.action_description
                    ? "error"
                    : inputState.action_description
                      ? "success"
                      : ""
                    }`}
                />
                {errors.action_description && (
                  <span className="error-message font-size-text ">{errors.action_description}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-delete  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};


const SeprationFormAccountApproval = ({ i, getSeprationData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
      handover_items: [],
    })
    setErrors({})
    setShow(false)
  };
  const handleShow = () => {
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
      handover_items: [],
    })
    setShow(true)
  };

  const [formData, setFormData] = useState({
    emp_code: sessionStorage.getItem("emp_code"),
    action_description: "",
    action_datetime: new Date().toISOString(),
    handover_items: [],
  });


  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
      "action_description",
      "action_datetime",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });


    formData.handover_items?.forEach((items, index) => {

      if ((!items.asset_name || items.asset_name.trim() === "")) {
        newErrors[`asset_name_${index}`] = `Asset Name is required for item ${index + 1}`;
      }
      if (!items.remarks || items.remarks.trim() === "") {
        newErrors[`remarks_${index}`] = `Approving Remarks is required for item ${index + 1}`;
      }
    });

    setErrors(newErrors);
    setIsSubmitted(true);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    if (value.trim()) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };


  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const updatedFormData = {
      account_approval: "approved",
      acc_log: {
        emp_code: formData.emp_code || sessionStorage.getItem("emp_code"),
        action_description: formData.action_description || "",
        action_datetime: formData.action_datetime || new Date().toISOString(),
      },
      handover_items: formData.handover_items?.map((item) => ({
        asset_name: item.asset_name,
        concerned_headings: item.concerned_headings,
        person_taking_handover: item.person_taking_handover,
        remarks: item.remarks,
      })) || [],
    };

    if (validateForm()) {
      setLoading(true);
      const loadingToastId = toast.loading("Loading: Please wait...");

      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/updatelogsforseprationwithhandovertemsAPIView/${i.id}/`,
          updatedFormData
        );

        if (res.status === 200) {
          await getSeprationData();
          handleClose();
        } else {
          alert(res);
        }
      } catch (err) {
        handleAllError(err);
      } finally {
        setLoading(false);
        toast.dismiss(loadingToastId);
      }
    }
  };



  const handleOtherInputChange = (index, e) => {
    if (!e || !e.target) {
      console.error("Invalid event object: ", e);
      return;
    }
    const { name, value } = e.target;
    setFormData((prevFormData) => {
      const newOthers = [...prevFormData.handover_items];
      newOthers[index] = {
        ...newOthers[index],
        [name]: value,
      };
      return {
        ...prevFormData,
        handover_items: newOthers
      };
    });
  };

  const handleAddOthers = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      handover_items: [
        ...(prevFormData.handover_items || []),  // Ensure array exists
        {
          asset_name: "",
          concerned_headings: "Accounts",
          person_taking_handover: sessionStorage.getItem("emp_code"),
          remarks: "",
        },
      ],
    }));
  };


  const handleRemoveOthers = (index) => {
    setFormData((prevFormData) => {
      if (!prevFormData.handover_items) return prevFormData; // Prevent errors
      const newOthers = [...prevFormData.handover_items];
      newOthers.splice(index, 1);
      return {
        ...prevFormData,
        handover_items: newOthers,
      };
    });
  };



  return (
    <>
      <button title="Account Sepration Approval" className="model-edit-button" onClick={handleShow}>
        <CheckGreen />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="half-modal">

        <Modal.Header closeButton>
          <Modal.Title>Account Sepration Approval of "{i.emp_code}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-row ">
                <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Employee:
                </label>
                <div className="flex-row align-center ">
                  {/* <td className="table-body" style={{ textAlign: "center" }}> */}
                  <img
                    className="profile-img"
                    src={
                      `${i.profilepic}`
                        ? `${IMAGE_URL}${i.profilepic}`
                        : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                    alt="profilepic"
                  />
                  <p>{i.emp_name}</p>
                  {/* </td> */}
                  <div className="form-labels announce-date font-size-heading">
                  </div>
                </div>
              </div>

              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Request Date:
                </label>
                <div className="form-labels announce-date font-size-heading align-center">
                  {i.application_date ? formattedDateLong(i.application_date) : '-'}
                </div>
              </div>

              <div className="flex-column">
                <label htmlFor="action_description" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Remarks:<span className="required">*</span>
                </label>
                <textarea
                  id="action_description"
                  type="text"
                  name="action_description"
                  placeholder="Your Remarks"
                  onChange={handleInputChange}
                  // maxLength={200}
                  value={formData.action_description}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.action_description
                    ? "error"
                    : inputState.action_description
                      ? "success"
                      : ""
                    }`}
                />
                {errors.action_description && (
                  <span className="error-message font-size-text ">{errors.action_description}</span>
                )}
              </div>

              <div className="flex-row justify-center">
                <table className="nested-table-lightblue width-40vw">
                  <thead>
                    <tr>
                      <th className="align-center">S. No.</th>
                      <th className="align-center">Asset Name</th>
                      {/* <th className="align-center">Concerned Heading</th> */}
                      {/* <th className="align-center">Person Taking Handover</th> */}
                      <th className="align-center">Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData.handover_items && formData.handover_items.length > 0 ? (
                      formData.handover_items
                        ?.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
                        .map((item, index) => (
                          <tr key={`handover-${index}`}>
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center">{item.asset_name}</td>
                            {/* <td className="text-center">{item.concerned_headings}</td> */}
                            {/* <td className="text-center">{item.person_taking_handover}</td> */}
                            <td className="text-center">{item.remarks}</td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td className="text-center" colSpan="7">No handover items available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <hr className="line" />
              {formData.handover_items?.map((heading, index) => (
                <div className="form-flex-wrap content-tabs " key={index}>
                  < div className="flex-column">
                    <div className="expense-req">
                      <div className="flex-row">
                        <label htmlFor={`asset_name_${index}`} className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                          Asset Name<span className="required">*</span>
                        </label>
                      </div >
                    </div>
                    <input
                      id={`asset_name_${index}`}
                      type="text"
                      name="asset_name"
                      placeholder="Asset Name"
                      onChange={(e) => handleOtherInputChange(index, e)}
                      value={heading.asset_name}
                      className={`form-input-date`}
                    />
                    {errors[`asset_name_${index}`] && (
                      <span className="error-message font-size-text">
                        {errors[`asset_name_${index}`]}
                      </span>
                    )}
                  </div>

                  < div className="flex-column">
                    <div className="expense-req">
                      <div className="flex-row">
                        <label htmlFor={`remarks_${index}`} className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                          Remarks<span className="required">*</span>
                        </label>
                      </div >
                    </div>
                    <input
                      id={`remarks_${index}`}
                      type="text"
                      name="remarks"
                      placeholder="Remarks"
                      onChange={(e) => handleOtherInputChange(index, e)}
                      value={heading.remarks}
                      className={`form-input-date`}
                    />
                    {errors[`remarks_${index}`] && (
                      <span className="error-message font-size-text">
                        {errors[`remarks_${index}`]}
                      </span>
                    )}
                  </div>


                  <div className="flex-column justify-center" >
                    <button type='button' title="Remove" className="flex-row action-div" onClick={() => handleRemoveOthers(index)}>
                      <CrosswithGreyCircle />
                    </button>
                  </div>

                </div>
              ))}

              <button type='button' title="Add Document" className="jd-heading-main font-weight600 -right-button" onClick={handleAddOthers}>
                <AddwithBlueCircle />
                Add More Headings
              </button>



              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Approve
                </button>
              </div>
            </div>
            <p className="error-message font-size-text">
              {isSubmitted && Object.keys(errors).length > 0 && (
                Object.keys(errors).length > 5 ? (
                  <h5 className="text-center">Please fill all mandatory fields!</h5>
                ) : (

                  <h6 className="text-center">
                    {Object.keys(errors).map((field) =>
                      field.charAt(0).toUpperCase() + field.slice(1)
                    ).join(', ') + ' are required!'}
                  </h6>
                )
              )}
            </p>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};

const SeprationFormAccountRejection = ({ i, getSeprationData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
    })
    setErrors({})
    setShow(false)
  };
  const handleShow = () => {
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
    })
    setShow(true)
  };

  const [formData, setFormData] = useState({
    emp_code: sessionStorage.getItem("emp_code"),
    action_description: "",
    action_datetime: new Date().toISOString(),
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
      "action_description",
      "action_datetime",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    // console.log("newErrors")
    // console.log(newErrors)
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    if (value.trim()) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };


  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const updatedFormData = {
      account_approval: "rejected",
      acc_log: formData,
    };
    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/updatelogsforseprationwithhandovertemsAPIView/${i.id}/`,
          updatedFormData
        );

        if (res.status === 200) {
          await getSeprationData();
          handleClose()
        } else {
          alert(res);
        }
      } catch (err) {
        handleAllError(err)
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  return (
    <>
      <button title="Account Sepration Rejection" className="model-delete-button" onClick={handleShow}>
        <CrossRed />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">

        <Modal.Header closeButton>
          <Modal.Title>Account Sepration Rejection of "{i.emp_code}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-row ">
                <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Employee:
                </label>
                <div className="flex-row align-center ">
                  {/* <td className="table-body" style={{ textAlign: "center" }}> */}
                  <img
                    className="profile-img"
                    src={
                      `${i.profilepic}`
                        ? `${IMAGE_URL}${i.profilepic}`
                        : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                    alt="profilepic"
                  />
                  <p>{i.emp_name}</p>
                  {/* </td> */}
                  <div className="form-labels announce-date font-size-heading">
                  </div>
                </div>
              </div>

              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Request Date:
                </label>
                <div className="form-labels announce-date font-size-heading align-center">
                  {i.application_date ? formattedDateLong(i.application_date) : '-'}
                </div>
              </div>

              <div className="flex-column">
                <label htmlFor="action_description" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Reason for Rejection:<span className="required">*</span>
                </label>
                <textarea
                  id="action_description"
                  type="text"
                  name="action_description"
                  placeholder="Your Reason"
                  onChange={handleInputChange}
                  // maxLength={200}
                  value={formData.action_description}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.action_description
                    ? "error"
                    : inputState.action_description
                      ? "success"
                      : ""
                    }`}
                />
                {errors.action_description && (
                  <span className="error-message font-size-text ">{errors.action_description}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-delete  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};


const SeprationFormHRFinalApproval = ({ i, getSeprationData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
    })
    setErrors({})
    setShow(false)
  };
  const handleShow = () => {
    getEmployeeOrgData(i.emp_code);
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toI
    })
    setShow(true)
  };

  const [formData, setFormData] = useState({
    emp_code: sessionStorage.getItem("emp_code"),
    action_description: "",
    action_datetime: new Date().toISOString(),
  });
  const [empData, setEmpData] = useState({})


  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
      "action_description",
      "action_datetime",
      "employee_type",
      "last_working_date",

    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });


    formData.handover_items?.forEach((items, index) => {

      if ((!items.asset_name || items.asset_name.trim() === "")) {
        newErrors[`asset_name_${index}`] = `Asset Name is required for item ${index + 1}`;
      }
      if (!items.remarks || items.remarks.trim() === "") {
        newErrors[`remarks_${index}`] = `Approving Remarks is required for item ${index + 1}`;
      }
    });

    setErrors(newErrors);
    setIsSubmitted(true);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    if (value.trim()) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };


  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const updatedFormData = {
      // hr_approval: "approved",
      final_log: {
        emp_code: formData.emp_code || sessionStorage.getItem("emp_code"),
        action_description: formData.action_description || "",
        action_datetime: formData.action_datetime || new Date().toISOString(),
      },
    };


    const updatedempData = {
      ...empData,
      employee_status: "inactive",
      employee_type: formData.employee_type,
      last_working_date: formData.last_working_date
    };

    if (validateForm()) {
      setLoading(true);
      const loadingToastId = toast.loading("Loading: Please wait...");

      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/updatelogsforseprationwithhandovertemsAPIView/${i.id}/`,
          updatedFormData
        );
        if (res.status === 200) {
          let empOrg = await axios.post(
            `${BASE_URL}/wfm/ourcompanyuserdetaildirectadd/`,
            updatedempData
          );
          if (empOrg.status === 200) {
            await getSeprationData();
            handleClose()
          }
          else {
            console.log("empOrg:", empOrg);
          }
        } else {
          console.log("sepration:", res);
        }
      } catch (err) {
        handleAllError(err);
      } finally {
        setLoading(false);
        toast.dismiss(loadingToastId);
      }
    }
  };



  const handleOtherInputChange = (index, e) => {
    if (!e || !e.target) {
      console.error("Invalid event object: ", e);
      return;
    }
    const { name, value } = e.target;
    setFormData((prevFormData) => {
      const newOthers = [...prevFormData.handover_items];
      newOthers[index] = {
        ...newOthers[index],
        [name]: value,
      };
      return {
        ...prevFormData,
        handover_items: newOthers
      };
    });
  };

  const handleAddOthers = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      handover_items: [
        ...(prevFormData.handover_items || []),  // Ensure array exists
        {
          asset_name: "",
          concerned_headings: "Human Resource",
          person_taking_handover: sessionStorage.getItem("emp_code"),
          remarks: "",
        },
      ],
    }));
  };


  const handleRemoveOthers = (index) => {
    setFormData((prevFormData) => {
      if (!prevFormData.handover_items) return prevFormData; // Prevent errors
      const newOthers = [...prevFormData.handover_items];
      newOthers.splice(index, 1);
      return {
        ...prevFormData,
        handover_items: newOthers,
      };
    });
  };

  const [buffer, setBuffering] = useState(false);

  const getEmployeeOrgData = async (emp_code) => {
    try {
      setBuffering(true)
      const emp = await axios.get(
        `${BASE_URL}/wfm/ourcompanydetailsbyemp/${emp_code}/`
      );
      // setEmployeeOrgDetails(emp.data);
      setEmpData(emp.data);
    } catch (err) {
      //toast Logic
      handleAllError(err);
    } finally {
      setBuffering(false)
    }
  };



  return (
    <>
      <button title="HR Sepration Approval" className="model-edit-button" onClick={handleShow}>
        <CheckGreen />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="half-modal">

        <Modal.Header closeButton>
          <Modal.Title>HR Sepration Approval of "{i.emp_code}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-row ">
                <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Employee:
                </label>
                <div className="flex-row align-center ">
                  {/* <td className="table-body" style={{ textAlign: "center" }}> */}
                  <img
                    className="profile-img"
                    src={
                      `${i.profilepic}`
                        ? `${IMAGE_URL}${i.profilepic}`
                        : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                    alt="profilepic"
                  />
                  <p>{i.emp_name}</p>
                  {/* </td> */}
                  <div className="form-labels announce-date font-size-heading">
                  </div>
                </div>
              </div>

              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Request Date:
                </label>
                <div className="form-labels announce-date font-size-heading align-center">
                  {i.application_date ? formattedDateLong(i.application_date) : '-'}
                </div>
              </div>

              <div className="flex-column">
                <label htmlFor="action_description" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Remarks:<span className="required">*</span>
                </label>
                <textarea
                  id="action_description"
                  type="text"
                  name="action_description"
                  placeholder="Your Remarks"
                  onChange={handleInputChange}
                  // maxLength={200}
                  value={formData.action_description}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.action_description
                    ? "error"
                    : inputState.action_description
                      ? "success"
                      : ""
                    }`}
                />
                {errors.action_description && (
                  <span className="error-message font-size-text ">{errors.action_description}</span>
                )}
              </div>

              <hr className="line" />

              <div className="flex-row">
                <label htmlFor="joining_date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Joining Date:
                </label>
                <div className="form-labels announce-date font-size-heading align-center">
                  {empData.joining_date ? formattedDateLong(empData.joining_date) : '-'}
                </div>
              </div>

              <div style={{ cursor: 'help' }} title={`Current Employee Type : ${empData.employee_type}`} className="flex-column form-group-selectt">
                <label htmlFor="employee_type" className="form-labels font-weight500 font-size-subheading">
                  Employee Type<span className="required">*</span>
                </label>
                <br />
                <select
                  id="employee_type"
                  name="employee_type"
                  value={formData.employee_type}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.employee_type ? "error" : inputState.employee_type ? "success" : ""
                    }`}
                >
                  <option value="">Select a Employee</option>
                  {/* <option value="permanent">Permanent</option> */}
                  {/* <option value="temporary">Temporary</option> */}
                  {/* <option value="probation">Probation</option> */}
                  <option value="termination">Termination</option>
                  <option value="resigned">Resigned</option>
                  {/* <option value="absconding">Absconding</option> */}
                  {/* <option value="healthissue">Healthissue</option>
                  <option value="intern">Intern</option>
                  <option value="trainee">Trainee</option> */}
                </select>
                <div className="form-group-selection-arrow">
                  <DropdownArrowOption />
                </div>
                <div>
                  {errors.employee_type && (
                    <span className="error-message font-size-text ">{errors.employee_type}</span>
                  )}
                </div>
              </div>

              <div title="Last Working Date" className="flex-column">
                <label htmlFor="last_working_date" className="form-labels  font-weight500    font-size-subheading">
                  Last Working Date
                  {formData.employee_status === "inactive" ? (
                    <span className="required">*</span>
                  ) : (
                    ""
                  )}
                </label>
                <input
                  type="date"
                  id="last_workig_date"
                  name="last_working_date"
                  onChange={handleInputChange}
                  value={formData.last_working_date}
                  className={`form-input ${errors.last_working_date
                    ? "error"
                    : inputState.last_working_date
                      ? "success"
                      : ""
                    }`}
                />
                {errors.last_working_date && (
                  <span className="error-message font-size-text ">
                    {errors.last_working_date}
                  </span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Complete Process
                </button>
              </div>
            </div>
            <p className="error-message font-size-text">
              {isSubmitted && Object.keys(errors).length > 0 && (
                Object.keys(errors).length > 5 ? (
                  <h5 className="text-center">Please fill all mandatory fields!</h5>
                ) : (

                  <h6 className="text-center">
                    {Object.keys(errors).map((field) =>
                      field.charAt(0).toUpperCase() + field.slice(1)
                    ).join(', ') + ' are required!'}
                  </h6>
                )
              )}
            </p>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};

const SeprationFormHRFinalRejection = ({ i, getSeprationData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
    })
    setErrors({})
    setShow(false)
  };
  const handleShow = () => {
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
    })
    setShow(true)
  };

  const [formData, setFormData] = useState({
    emp_code: sessionStorage.getItem("emp_code"),
    action_description: "",
    action_datetime: new Date().toISOString(),
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
      "action_description",
      "action_datetime",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    // console.log("newErrors")
    // console.log(newErrors)
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    if (value.trim()) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };


  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const updatedFormData = {
      hr_approval: "rejected",
      hr_log: formData,
    };
    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        alert("Work in Progress");
        // let res = await axios.put(
        //   `${BASE_URL}/wfm/updatelogsforseprationwithhandovertemsAPIView/${i.id}/`,
        //   updatedFormData
        // );

        // if (res.status === 200) {
        //   await getSeprationData();
        //   handleClose()
        // } else {
        //   alert(res);
        // }

      } catch (err) {
        handleAllError(err)
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  return (
    <>
      <button title="HR Sepration Cancelation" className="model-delete-button" onClick={handleShow}>
        <CrossRed />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">

        <Modal.Header closeButton>
          <Modal.Title>HR Sepration Cancelation of "{i.emp_code}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-row ">
                <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Employee:
                </label>
                <div className="flex-row align-center ">
                  {/* <td className="table-body" style={{ textAlign: "center" }}> */}
                  <img
                    className="profile-img"
                    src={
                      `${i.profilepic}`
                        ? `${IMAGE_URL}${i.profilepic}`
                        : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                    alt="profilepic"
                  />
                  <p>{i.emp_name}</p>
                  {/* </td> */}
                  <div className="form-labels announce-date font-size-heading">
                  </div>
                </div>
              </div>

              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Request Date:
                </label>
                <div className="form-labels announce-date font-size-heading align-center">
                  {i.application_date ? formattedDateLong(i.application_date) : '-'}
                </div>
              </div>

              <div className="flex-column">
                <label htmlFor="action_description" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Reason for Cancelation of Process:<span className="required">*</span>
                </label>
                <textarea
                  id="action_description"
                  type="text"
                  name="action_description"
                  placeholder="Reason"
                  onChange={handleInputChange}
                  // maxLength={200}
                  value={formData.action_description}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.action_description
                    ? "error"
                    : inputState.action_description
                      ? "success"
                      : ""
                    }`}
                />
                {errors.action_description && (
                  <span className="error-message font-size-text ">{errors.action_description}</span>
                )}
              </div>

              <div className="button-models">
                {/* <button className="model-button model-button-cancel   font-weight500   " onClick={handleClose}>
                  Cancel
                </button> */}
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-delete  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Cancel Process
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};


export {

  AddSeprationFormat,
  ViewSeprationFormatFlow,
  DeleteSeprationFormat,

  AddSettlementFormat,
  ViewSettlementFormatFlow,
  DeleteSettlementFormat,

  StartEmployeeSeprationProcess,

  ApplySeprationForm,

  SeprationProcessStatusTD,
  ViewSeprationDetails,
  ViewSeprationAndSettlementDetails,
  ViewSeprationProcessStatus,

  SeprationFormRHApproval,
  SeprationFormRHRejection,

  SeprationFormHODApproval,
  SeprationFormHODRejection,

  SeprationFormHeadingsApproval,
  SeprationFormHeadingsRejection,

  SeprationFormHRApproval,
  SeprationFormHRRejection,

  SeprationFormAdminApproval,
  SeprationFormAdminRejection,

  SeprationFormAccountApproval,
  SeprationFormAccountRejection,

  SeprationFormHRFinalApproval,
  SeprationFormHRFinalRejection,

};
