import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { BASE_URL } from '../../config/axios';
import usePermission from '../../config/permissions';
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { formattedDateLong, formattedDateNoYear } from '../Date';
import Select from 'react-select';
import { customSortByKey, downloadAsExcel, handleAllError, handleErrorToast, ViewFile } from '../CustomFunctions';
import { Active, CircularGrid, DownloadIcon, Office, Search, SearchBig } from '../AllSvg';
import { useNavigate } from 'react-router-dom';
import Dashboardnavbarcopy from '../../layout/Dashboardnavbarcopy';


const EmployeeDataExcelEducation = () => {
    const tableRef = useRef(null);
    const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
    const [toggleCompany, setToggleCompany] = useState(
        sessionStorage.getItem("company_id")
    );
    const [selectedName, setName] = useState('');
    const [employees, setEmployees] = useState([]);
    const [filteredEmployee, setFilteredEmployee] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedDep, setDep] = useState('');
    const [selectedDesi, setDesi] = useState('');
    // const [selectedYears, setYears] = useState('');
    // const [selectedMonths, setMonths] = useState('');
    const [selectedMinYears, setMinYears] = useState('');
    const [selectedMaxYears, setMaxYears] = useState('');
    const [selectedDegree, setDegree] = useState('');
    const [selectedStream, setStream] = useState('');
    const [departmentname, setDepartmentname] = useState([]);

    const [streamlist, setStreamList] = useState([]);




    const [buffer, setBuffering] = useState(true); //buffering logic
    const getDepartment = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(`${BASE_URL}/wfm/department/`);
            setDepartmentname(res.data);
        } catch (err) {
            //toast Logic
            if (err.response) {
                const errorData = err.response.data;

                if (typeof errorData.error === 'string') {
                    // Single error message
                    toast.error(`Error: ${errorData.error}`);
                } else if (typeof errorData === 'object' && errorData !== null) {
                    // Multiple error messages
                    Object.entries(errorData).forEach(([field, messages]) => {
                        messages.forEach(message => toast.error(`"${field}": ${message}`));
                    });
                } else {
                    toast.error('Error:- Failed to Process!');
                }
            } else {
                toast.error('Error processing your request.');
            }
        } finally {
            setBuffering(false);// End Buffering
        }
    };


    useEffect(() => {
        const fetchEmployees = async () => {
            setBuffering(true); //buffering logic // Start Buffering
            try {
                // const res = await axios.get(
                //     PERMISSION_AUTHORITY ?
                //         `${BASE_URL}/userdata/ActiveEmployeesalleducationdata/null/`
                //         :
                //         `${BASE_URL}/userdata/ActiveEmployeesalleducationdata/${toggleCompany}/`
                // );
                const response = await axios.get(`${BASE_URL}/userdata/ActiveEmployeesalleducationdata/`);

                const sortedData = customSortByKey(response.data, "company_details.emp_code");
                setEmployees(sortedData);


                // Get unique set of stream types
                const uniqueStreamTypes = new Set();

                sortedData.forEach(entry => {
                    entry.education_details.forEach(education => {
                        uniqueStreamTypes.add(education.stream);
                    });
                });

                // setStreamList(Array.from(uniqueStreamTypes));
                setStreamList(Array.from(uniqueStreamTypes).map(stream => ({ value: stream, label: stream })));

            } catch (err) {
                setError(err);
                //toast Logic
                if (err.response) {
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }
            } finally {
                setLoading(false);
                setBuffering(false);// End Buffering
            }
        };

        getDepartment();
        fetchEmployees();
    }, []);


    useEffect(() => {
        applyFilters();
    }, [selectedName, employees, selectedDep, selectedDesi, selectedMinYears, selectedMaxYears, selectedDegree, selectedStream]);


    const applyFilters = () => {
        let filteredData = employees;

        if (selectedName) {
            const lowercaseSelectedName = selectedName.toLowerCase();
            filteredData = filteredData.filter(employee => {
                const nameMatch = employee.name.toLowerCase().includes(lowercaseSelectedName);
                const empCodeMatch = employee.company_details.emp_code.toString().includes(selectedName);
                const emailMatch = employee.email.toLowerCase().includes(lowercaseSelectedName);
                return nameMatch || empCodeMatch || emailMatch;
                // return nameMatch || empCodeMatch;
            });
        }

        if (selectedDep) {
            filteredData = filteredData.filter(employee => employee.company_details.department_name === selectedDep);
        }

        if (selectedDesi) {
            const lowercaseSelectedName = selectedDesi.toLowerCase();
            filteredData = filteredData.filter(employee => {
                const desiMatch = employee.company_details.designation_name.toLowerCase().includes(lowercaseSelectedName);
                return desiMatch;
            });
        }

        // if (selectedYears !== '' || selectedMonths !== '') {
        //     const years = parseInt(selectedYears) || 0;
        //     const months = parseInt(selectedMonths) || 0;

        //     filteredData = filteredData.filter(employee => {
        //         const [employeeYears, employeeMonths] = employee.total_education.split(', ').map(str => parseInt(str));

        //         if (selectedYears !== '' && selectedMonths !== '') {
        //             // Both year and month filters are applied
        //             if (employeeYears === years && employeeMonths === months) {
        //                 return true;
        //             }

        //             // Include edge case where employee has (years - 1) and 12 months
        //             if (employeeYears === (years - 1) && employeeMonths === 12 && months === 0) {
        //                 return true;
        //             }

        //             return false;
        //         } else if (selectedYears !== '') {
        //             // Only year filter is applied
        //             if (employeeYears === years) {
        //                 return true;
        //             }

        //             // Include edge case where employee has (years - 1) and 12 months
        //             if (employeeYears === (years - 1) && employeeMonths === 12) {
        //                 return true;
        //             }

        //             return false;
        //         } else {
        //             // Only month filter is applied
        //             return employeeMonths === months;
        //         }
        //     });
        // }

        if (selectedMinYears !== '' || selectedMaxYears !== '') {
            const minYears = parseInt(selectedMinYears) || 0;
            const maxYears = parseInt(selectedMaxYears) || 0;

            filteredData = filteredData.filter(employee => {
                const [employeeYears, employeeMonths] = employee.total_education.split(', ').map(str => parseInt(str));

                if (selectedMinYears !== '' && selectedMaxYears !== '') {
                    // Both min and max year filters are applied
                    if (employeeYears >= minYears && employeeYears <= maxYears) {
                        return true;
                    }

                    // Include edge case where employee has (maxYears - 1) and 12 months
                    if (employeeYears === (maxYears - 1) && employeeMonths === 12 && minYears <= (maxYears - 1)) {
                        return true;
                    }

                    return false;
                } else if (selectedMinYears !== '') {
                    // Only min year filter is applied
                    if (employeeYears >= minYears) {
                        return true;
                    }

                    // Include edge case where employee has (minYears - 1) and 12 months
                    if (employeeYears === (minYears - 1) && employeeMonths === 12) {
                        return true;
                    }

                    return false;
                } else {
                    // Only max year filter is applied
                    if (employeeYears <= maxYears) {
                        return true;
                    }

                    // Include edge case where employee has (maxYears - 1) and 12 months
                    if (employeeYears === (maxYears - 1) && employeeMonths === 12) {
                        return true;
                    }

                    return false;
                }
            });
        }

        if (selectedDegree && selectedStream) {
            filteredData = filteredData.filter(employee =>
                employee.education_details?.some(education =>
                    education.department === selectedDegree && education.stream === selectedStream
                )
            );
        } else {
            if (selectedDegree) {
                filteredData = filteredData.filter(employee =>
                    employee.education_details?.some(education => education.department === selectedDegree)
                );
            }

            if (selectedStream) {
                filteredData = filteredData.filter(employee =>
                    employee.education_details?.some(education => education.stream === selectedStream)
                );
            }
        }


        setFilteredEmployee(filteredData);
    };

    const availableDegrees = [
        "10th",
        "12th",
        "B.A. (Bachelor of Arts)",
        "B.Com. (Bachelor of Commerce)",
        "M.Com. (Masters of Commerce)",
        "MBA (Master of Business Administration)",
        "M.Tech. (Master of Technology)",
        "M.E. (Master of Engineering )",
        "B.Tech. (Bachelor of Technology)",
        "B.E. (Bachelor of Engineering )",
        "B.Arch. (Bachelor of Architecture)",
        "Diploma",
        "PhD",
        "ITI",
        "Other",
    ];

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="attendance-history-cont">
                {loading || buffer ? (
                    <p>Loading Education Details... <div className="spinner-small"> </div></p> //buffering logic 
                ) : error ? (
                    <p>Error fetching data: {error.message}</p>
                ) : (
                    <>
                        <div>
                            <div className="attendance-heading  font-weight500    font-size-heading"> </div>
                            <div className="attendance-subcont">
                                <div className="field-cont">

                                    {PERMISSION_AUTHORITY ?
                                        <div title="Company Filter" className="field-cont-div">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="20"
                                                viewBox="0 0 14 14"
                                                fill="none"
                                            >
                                                <g clip-path="url(#clip0_650_3324)">
                                                    <path
                                                        d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                                                        fill="#707070"
                                                    />
                                                    <path
                                                        d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                                                        fill="#707070"
                                                    />
                                                    <path
                                                        d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                                                        fill="#707070"
                                                    />
                                                    <path
                                                        d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                                                        fill="#707070"
                                                    />
                                                    <path
                                                        d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                                                        fill="#707070"
                                                    />
                                                    <path
                                                        d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                                                        fill="#707070"
                                                    />
                                                    <path
                                                        d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                                                        fill="#707070"
                                                    />
                                                    <path
                                                        d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                                                        fill="#707070"
                                                    />
                                                    <path
                                                        d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                                                        fill="#707070"
                                                    />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_650_3324">
                                                        <rect width="14" height="14" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <select
                                                onChange={(e) => setToggleCompany(e.target.value)}
                                                value={toggleCompany}
                                                className="attendance-input-field width-10vw   date-field"
                                            // className="dropdown font-size-subheading  "
                                            >
                                                <option value="null">Select Sub Company</option>
                                                {SUB_COMPANIES.map((company) => (
                                                    <option value={company.id}>{company.title}</option>
                                                ))}
                                                ;
                                            </select>

                                            <hr className="field-cont-hr" />
                                        </div> : null}


                                    {/* <hr /> */}
                                    <div title='Search Filter' className="field-cont-div">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 11 12"
                                            fill="none"
                                        >
                                            <circle
                                                cx="5"
                                                cy="5"
                                                r="4.3"
                                                stroke="#707070"
                                                stroke-width="1.4"
                                            />
                                            <line
                                                x1="10.0101"
                                                y1="11"
                                                x2="8"
                                                y2="8.98995"
                                                stroke="#707070 "
                                                stroke-width="1.4"
                                                stroke-linecap="round"
                                            />
                                        </svg>
                                        <input className="attendance-input-field width-15vw  " placeholder='Name/ Code/ Email' type="text" value={selectedName}
                                            // </svg><input className="attendance-input-field width-10vw  " placeholder='Email / Code / RH' type="text" value={selectedName}
                                            onChange={(e) => setName(e.target.value)} />

                                        <hr className="field-cont-hr" />
                                    </div>


                                    <div title="Department Filter" className="field-cont-div">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                        >
                                            <g clip-path="url(#clip0_650_3324)">
                                                <path
                                                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                                                    fill="#707070"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_650_3324">
                                                    <rect width="14" height="14" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>

                                        <select
                                            className="attendance-input-field width-10vw   date-field"
                                            type="text"
                                            value={selectedDep}
                                            onChange={(e) => setDep(e.target.value)}
                                        >
                                            <option value=""> All Department</option>
                                            {/* {emp_code.sort((a, b) => a.emp_code - b.emp_code).map((i, index) => (<option value={i.emp_code}>{i.emp_code} - {i.name}</option>))}; */}
                                            {departmentname
                                                .sort((a, b) => a.title - b.title)
                                                .map((i, index) => (
                                                    <option value={i.title}>{i.title}</option>
                                                ))}
                                            ;
                                        </select>
                                        <hr className="field-cont-hr" />
                                    </div>

                                    <div title="Designation Filter" className="field-cont-div">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                        >
                                            <g clip-path="url(#clip0_650_3324)">
                                                <path
                                                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                                                    fill="#707070"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_650_3324">
                                                    <rect width="14" height="14" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <input
                                            className="attendance-input-field width-10vw  "
                                            placeholder="Designation"
                                            type="text"
                                            value={selectedDesi}
                                            onChange={(e) => setDesi(e.target.value)}
                                        />
                                        <hr className="field-cont-hr" />
                                    </div>

                                    {/* <div title="Year Filter" className="field-cont-div">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                        >
                                            <g clip-path="url(#clip0_650_3324)">
                                                <path
                                                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                                                    fill="#707070"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_650_3324">
                                                    <rect width="14" height="14" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <input
                                            className="attendance-input-field width-10vw  "
                                            placeholder="Years"
                                            type="number"
                                            min={0}
                                            value={selectedYears}
                                            onChange={(e) => setYears(e.target.value)}
                                        />
                                        <hr className="field-cont-hr" />
                                    </div>

                                    <div title="Month Filter" className="field-cont-div">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                        >
                                            <g clip-path="url(#clip0_650_3324)">
                                                <path
                                                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                                                    fill="#707070"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_650_3324">
                                                    <rect width="14" height="14" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <input
                                            className="attendance-input-field width-10vw  "
                                            placeholder="Months"
                                            type="number"
                                            min={0}
                                            max={12}
                                            value={selectedMonths}
                                            onChange={(e) => setMonths(e.target.value)}
                                        />
                                        <hr className="field-cont-hr" />
                                    </div> */}


                                    <div title="Min Year Filter" className="field-cont-div">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                        >
                                            <g clip-path="url(#clip0_650_3324)">
                                                <path
                                                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                                                    fill="#707070"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_650_3324">
                                                    <rect width="14" height="14" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <input
                                            className="attendance-input-field width-10vw  "
                                            placeholder="Min Years"
                                            type="number"
                                            min={0}
                                            max={selectedMaxYears}
                                            value={selectedMinYears}
                                            onChange={(e) => setMinYears(e.target.value)}
                                        />
                                        <hr className="field-cont-hr" />
                                    </div>

                                    <div title="Max Year Filter" className="field-cont-div">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                        >
                                            <g clip-path="url(#clip0_650_3324)">
                                                <path
                                                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                                                    fill="#707070"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_650_3324">
                                                    <rect width="14" height="14" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <input
                                            className="attendance-input-field width-10vw  "
                                            placeholder="Max Years"
                                            type="number"
                                            min={selectedMinYears}
                                            value={selectedMaxYears}
                                            onChange={(e) => setMaxYears(e.target.value)}
                                        />
                                        <hr className="field-cont-hr" />
                                    </div>

                                    <div title="Degree Type Filter" className="field-cont-div">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                        >
                                            <g clip-path="url(#clip0_650_3324)">
                                                <path
                                                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                                                    fill="#707070"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_650_3324">
                                                    <rect width="14" height="14" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>

                                        <select
                                            className="attendance-input-field width-10vw   date-field"
                                            type="text"
                                            value={selectedDegree}
                                            onChange={(e) => setDegree(e.target.value)}
                                        >
                                            <option value="">Select a Degree Type</option>
                                            {availableDegrees.map((degree) =>

                                                <option key={degree} value={degree}>
                                                    {degree}
                                                </option>
                                            )}
                                        </select>
                                        <hr className="field-cont-hr" />
                                    </div>


                                    <div title="Stream Type Filter" className="field-cont-div">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                        >
                                            <g clip-path="url(#clip0_650_3324)">
                                                <path
                                                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                                                    fill="#707070"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_650_3324">
                                                    <rect width="14" height="14" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>

                                        <Select
                                            className="attendance-input-field width-20vw date-field"
                                            options={streamlist}
                                            value={selectedStream ? { value: selectedStream, label: selectedStream } : null}
                                            onChange={(option) => setStream(option ? option.value : null)}
                                            placeholder="Select a Type"
                                            isClearable
                                        />
                                        {/* <select
                                            className="attendance-input-field width-10vw   date-field"
                                            type="text"
                                            value={selectedStream}
                                            onChange={(e) => setStream(e.target.value)}
                                        >
                                            <option value="">Select a Type</option>
                                            {streamlist.map((stream) =>

                                                <option key={stream} value={stream}>
                                                    {stream}
                                                </option>
                                            )}
                                        </select> */}
                                        <hr className="field-cont-hr" />
                                    </div>



                                </div>
                                <div className="btn-cont">
                                    <DownloadTableExcel
                                        filename="ActiveEmployees-EducationDetails "
                                        sheet="eduDetails"
                                        currentTableRef={tableRef.current}
                                    >
                                        <button className="model-button   font-weight500   model-button-print">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="download" fill="#fff">
                                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M6 11l4 4 4-4M10 1v14"></path>
                                                </g>
                                            </svg>
                                        </button>
                                    </DownloadTableExcel>

                                </div>
                            </div>
                        </div>
                        <div className='filter-showing'>
                            <div>Education details:    </div>
                            <div>Showing  {filteredEmployee.length} of {employees.length} </div>
                        </div>

                        <div className="table-css-white-background">
                            <table ref={tableRef} className="styled-table font-size-label table-css">
                                <thead>
                                    <tr>
                                        <th>Emp Code</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Reporting Head</th>
                                        <th>Department</th>
                                        <th>Designation</th>
                                        <th>Education Experience</th>
                                        <th>Education Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredEmployee.map(employee => (
                                        <tr key={employee.email}>
                                            <td>{employee.company_details.emp_code}</td>
                                            <td>{employee.name}</td>
                                            <td>{employee.email}</td>
                                            <td>{employee.company_details.reporting_head_name}</td>
                                            <td>{employee.company_details.department_name}</td>
                                            <td>{employee.company_details.designation_name}</td>
                                            <td>{employee.total_education}</td>
                                            <td>
                                                {employee.employee_education_details_details.length > 0 ? (
                                                    <table className="nested-table font-size-subheading">
                                                        <thead>
                                                            <tr>
                                                                <th>College</th>
                                                                <th>Degree Type</th>
                                                                <th>Stream</th>
                                                                <th>Grade</th>
                                                                <th>Institution Type</th>
                                                                <th>Start Date</th>
                                                                <th>End Date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {employee.employee_education_details_details.map(detail => (
                                                                <tr key={detail.id}>
                                                                    <td>{detail.college}</td>
                                                                    <td>{detail.department}</td>
                                                                    <td>{detail.stream}</td>
                                                                    <td>{detail.grade}</td>
                                                                    <td>{detail.institution_type}</td>
                                                                    <td>{detail.startDate}</td>
                                                                    <td>{detail.endDate}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                ) : (
                                                    <p>No education details available.</p>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};


const EmployeeDataExcelWork = () => {

    const tableRef = useRef(null);
    const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
    const [toggleCompany, setToggleCompany] = useState(
        sessionStorage.getItem("company_id")
    );
    const [selectedName, setName] = useState('');
    const [employees, setEmployees] = useState([]);
    const [filteredEmployee, setFilteredEmployee] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedDep, setDep] = useState('');
    const [selectedDesi, setDesi] = useState('');
    const [selectedYears, setYears] = useState('');
    const [selectedMonths, setMonths] = useState('');
    const [departmentname, setDepartmentname] = useState([]);

    const [buffer, setBuffering] = useState(true); //buffering logic
    const getDepartment = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(`${BASE_URL}/wfm/department/`);
            setDepartmentname(res.data);
        } catch (err) {
            //toast Logic
            if (err.response) {
                const errorData = err.response.data;

                if (typeof errorData.error === 'string') {
                    // Single error message
                    toast.error(`Error: ${errorData.error}`);
                } else if (typeof errorData === 'object' && errorData !== null) {
                    // Multiple error messages
                    Object.entries(errorData).forEach(([field, messages]) => {
                        messages.forEach(message => toast.error(`"${field}": ${message}`));
                    });
                } else {
                    toast.error('Error:- Failed to Process!');
                }
            } else {
                toast.error('Error processing your request.');
            }
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                // const res = await axios.get(
                //     PERMISSION_AUTHORITY ?
                //         `${BASE_URL}/userdata/ActiveEmployeesworkalldata/null/`
                //         :
                //         `${BASE_URL}/userdata/ActiveEmployeesworkalldata/${toggleCompany}/`
                // );
                const response = await axios.get(`${BASE_URL}/userdata/ActiveEmployeesworkalldata/`);
                const getAlphaNumericParts = (code) => {
                    const match = code.match(/^([A-Za-z]+)(\d+)$/); // Match alphabetic and numeric parts
                    if (match) {
                        return { alphaPart: match[1], numericPart: parseInt(match[2], 10) };
                    } else {
                        return { alphaPart: "", numericPart: 0 };
                    }
                };

                const sortedList = response.data.sort((a, b) => {
                    const { alphaPart: alphaA, numericPart: numericA } =
                        getAlphaNumericParts(a.company_details.emp_code);
                    const { alphaPart: alphaB, numericPart: numericB } =
                        getAlphaNumericParts(b.company_details.emp_code);

                    if (alphaA !== alphaB) {
                        return alphaA.localeCompare(alphaB); // Sort alphabetically
                    } else {
                        return numericA - numericB; // Then sort numerically
                    }
                });
                setEmployees(sortedList);
            } catch (err) {
                setError(err);
                //toast Logic
                if (err.response) {
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }
            } finally {
                setLoading(false);
                setBuffering(false);// End Buffering
            }
        };

        getDepartment();
        fetchEmployees();
    }, []);


    useEffect(() => {
        applyFilters();
    }, [selectedName, employees, selectedDep, selectedDesi, selectedYears, selectedMonths]);

    const applyFilters = () => {
        let filteredData = employees;
        if (selectedName) {
            const lowercaseSelectedName = selectedName.toLowerCase();
            filteredData = filteredData.filter(employee => {
                const nameMatch = employee.name.toLowerCase().includes(lowercaseSelectedName);
                const empCodeMatch = employee.company_details.emp_code.toString().includes(selectedName);
                const emailMatch = employee.email.toLowerCase().includes(lowercaseSelectedName);
                return nameMatch || empCodeMatch || emailMatch;
                // return nameMatch || empCodeMatch;
            });
        }

        if (selectedDep) {
            filteredData = filteredData.filter(employee => employee.company_details.department_name === selectedDep);
        }
        if (selectedDesi) {
            const lowercaseSelectedName = selectedDesi.toLowerCase();
            filteredData = filteredData.filter(employee => {
                const desiMatch = employee.company_details.designation_name.toLowerCase().includes(lowercaseSelectedName);
                return desiMatch;
            });
        }
        if (selectedYears !== '' || selectedMonths !== '') {
            const years = parseInt(selectedYears) || 0;
            const months = parseInt(selectedMonths) || 0;

            filteredData = filteredData.filter(employee => {
                const [employeeYears, employeeMonths] = employee.total_work_experience.split(', ').map(str => parseInt(str));

                if (selectedYears !== '' && selectedMonths !== '') {
                    // Both year and month filters are applied
                    if (employeeYears === years && employeeMonths === months) {
                        return true;
                    }

                    // Include edge case where employee has (years - 1) and 12 months
                    if (employeeYears === (years - 1) && employeeMonths === 12 && months === 0) {
                        return true;
                    }

                    return false;
                } else if (selectedYears !== '') {
                    // Only year filter is applied
                    if (employeeYears === years) {
                        return true;
                    }

                    // Include edge case where employee has (years - 1) and 12 months
                    if (employeeYears === (years - 1) && employeeMonths === 12) {
                        return true;
                    }

                    return false;
                } else {
                    // Only month filter is applied
                    return employeeMonths === months;
                }
            });
        }
        setFilteredEmployee(filteredData);
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="attendance-history-cont">
                {loading || buffer ? (
                    <p>Loading Work Details... <div className="spinner-small"> </div></p> //buffering logic 
                ) : error ? (
                    <p>Error fetching data: {error.message}</p>
                ) : (
                    <>
                        <div>
                            <div className="attendance-heading  font-weight500    font-size-heading"> </div>
                            <div className="attendance-subcont">
                                <div className="field-cont">

                                    {PERMISSION_AUTHORITY ?
                                        <div title="Company Filter" className="field-cont-div">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="20"
                                                viewBox="0 0 14 14"
                                                fill="none"
                                            >
                                                <g clip-path="url(#clip0_650_3324)">
                                                    <path
                                                        d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                                                        fill="#707070"
                                                    />
                                                    <path
                                                        d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                                                        fill="#707070"
                                                    />
                                                    <path
                                                        d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                                                        fill="#707070"
                                                    />
                                                    <path
                                                        d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                                                        fill="#707070"
                                                    />
                                                    <path
                                                        d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                                                        fill="#707070"
                                                    />
                                                    <path
                                                        d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                                                        fill="#707070"
                                                    />
                                                    <path
                                                        d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                                                        fill="#707070"
                                                    />
                                                    <path
                                                        d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                                                        fill="#707070"
                                                    />
                                                    <path
                                                        d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                                                        fill="#707070"
                                                    />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_650_3324">
                                                        <rect width="14" height="14" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <select
                                                onChange={(e) => setToggleCompany(e.target.value)}
                                                value={toggleCompany}
                                                className="attendance-input-field width-10vw   date-field"
                                            // className="dropdown font-size-subheading  "
                                            >
                                                <option value="null">Select Sub Company</option>
                                                {SUB_COMPANIES.map((company) => (
                                                    <option value={company.id}>{company.title}</option>
                                                ))}
                                                ;
                                            </select>

                                            <hr className="field-cont-hr" />
                                        </div> : null}
                                    {/* <hr /> */}
                                    <div title='Search Filter' className="field-cont-div">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 11 12"
                                            fill="none"
                                        >
                                            <circle
                                                cx="5"
                                                cy="5"
                                                r="4.3"
                                                stroke="#707070"
                                                stroke-width="1.4"
                                            />
                                            <line
                                                x1="10.0101"
                                                y1="11"
                                                x2="8"
                                                y2="8.98995"
                                                stroke="#707070 "
                                                stroke-width="1.4"
                                                stroke-linecap="round"
                                            />
                                        </svg>
                                        <input className="attendance-input-field width-15vw  " placeholder='Name/ Code/ Email' type="text" value={selectedName}
                                            // </svg><input className="attendance-input-field width-10vw  " placeholder='Email / Code / RH' type="text" value={selectedName}
                                            onChange={(e) => setName(e.target.value)} />

                                        <hr className="field-cont-hr" />
                                    </div>


                                    <div title="Department Filter" className="field-cont-div">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                        >
                                            <g clip-path="url(#clip0_650_3324)">
                                                <path
                                                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                                                    fill="#707070"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_650_3324">
                                                    <rect width="14" height="14" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>

                                        <select
                                            className="attendance-input-field width-10vw   date-field"
                                            type="text"
                                            value={selectedDep}
                                            onChange={(e) => setDep(e.target.value)}
                                        >
                                            <option value=""> All Department</option>
                                            {/* {emp_code.sort((a, b) => a.emp_code - b.emp_code).map((i, index) => (<option value={i.emp_code}>{i.emp_code} - {i.name}</option>))}; */}
                                            {departmentname
                                                .sort((a, b) => a.title - b.title)
                                                .map((i, index) => (
                                                    <option value={i.title}>{i.title}</option>
                                                ))}
                                            ;
                                        </select>
                                        <hr className="field-cont-hr" />
                                    </div>

                                    <div title="Designation Filter" className="field-cont-div">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                        >
                                            <g clip-path="url(#clip0_650_3324)">
                                                <path
                                                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                                                    fill="#707070"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_650_3324">
                                                    <rect width="14" height="14" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <input
                                            className="attendance-input-field width-10vw  "
                                            placeholder="Designation"
                                            type="text"
                                            value={selectedDesi}
                                            onChange={(e) => setDesi(e.target.value)}
                                        />
                                        <hr className="field-cont-hr" />
                                    </div>


                                    <div title="Year Filter" className="field-cont-div">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                        >
                                            <g clip-path="url(#clip0_650_3324)">
                                                <path
                                                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                                                    fill="#707070"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_650_3324">
                                                    <rect width="14" height="14" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <input
                                            className="attendance-input-field width-10vw  "
                                            placeholder="Years"
                                            type="number"
                                            min={0}
                                            value={selectedYears}
                                            onChange={(e) => setYears(e.target.value)}
                                        />
                                        <hr className="field-cont-hr" />
                                    </div>

                                    <div title="Month Filter" className="field-cont-div">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                        >
                                            <g clip-path="url(#clip0_650_3324)">
                                                <path
                                                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                                                    fill="#707070"
                                                />
                                                <path
                                                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                                                    fill="#707070"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_650_3324">
                                                    <rect width="14" height="14" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <input
                                            className="attendance-input-field width-10vw  "
                                            placeholder="Months"
                                            type="number"
                                            min={0}
                                            max={12}
                                            value={selectedMonths}
                                            onChange={(e) => setMonths(e.target.value)}
                                        />
                                        <hr className="field-cont-hr" />
                                    </div>
                                    {/* <div className="field-cont-div">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 14" fill="none">
                                        <g clip-path="url(#clip0_650_3324)">
                                            <path d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z" fill="#707070" />
                                            <path d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z" fill="#707070" />
                                            <path d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z" fill="#707070" />
                                            <path d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z" fill="#707070" />
                                            <path d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z" fill="#707070" />
                                            <path d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z" fill="#707070" />
                                            <path d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z" fill="#707070" />
                                            <path d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z" fill="#707070" />
                                            <path d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z" fill="#707070" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_650_3324">
                                                <rect width="14" height="14" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>

                                    <select className="attendance-input-field width-10vw   date-field" type="text"
                                        value={selectedDivision}
                                        onChange={(e) => (e.target.value)}
                                    >
                                        <option value=""> All Sub Companies</option>
                                        {SubCompany.sort((a, b) => a.title - b.title).map((i, index) => (<option value={i.title}>{i.title}</option>))};

                                    </select>
                                </div> */}
                                </div>
                                <div className="btn-cont">
                                    <DownloadTableExcel
                                        filename="ActiveEmployees-WorkDetails"
                                        sheet="workDetails"
                                        currentTableRef={tableRef.current}
                                    >
                                        <button className="model-button   font-weight500   model-button-print">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="download" fill="#fff">
                                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M6 11l4 4 4-4M10 1v14"></path>
                                                </g>
                                            </svg>
                                        </button>
                                    </DownloadTableExcel>

                                </div>
                            </div>
                        </div>
                        <div className='filter-showing'>
                            <div>Work Experience details:</div>
                            <div>Showing  {filteredEmployee.length} of {employees.length} </div>
                        </div>

                        <div className="table-css-white-background">
                            <table ref={tableRef} className="styled-table font-size-label table-css">
                                <thead>
                                    <tr>
                                        <th>Emp Code</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Reporting Head</th>
                                        <th>Department</th>
                                        <th>Designation</th>
                                        <th>Work Experience</th>
                                        <th>Work Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredEmployee.map(employee => (
                                        <tr key={employee.email}>
                                            <td>{employee.company_details.emp_code}</td>
                                            <td>{employee.name}</td>
                                            <td>{employee.email}</td>
                                            <td>{employee.company_details.reporting_head_name}</td>
                                            <td>{employee.company_details.department_name}</td>
                                            <td>{employee.company_details.designation_name}</td>
                                            <td>{employee.total_work_experience}</td>
                                            <td>
                                                {employee.work_experience_details.length > 0 ? (
                                                    <table className="nested-table font-size-subheading">
                                                        <thead>
                                                            <tr>
                                                                <th>Company</th>
                                                                <th>Jobposition</th>
                                                                <th>Job Type</th>
                                                                <th>Start Date</th>
                                                                <th>End Date</th>
                                                                <th>Monthly CTC</th>
                                                                <th>MonthlyInhand</th>
                                                                <th>Annual CTC</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {employee.work_experience_details.map(detail => (
                                                                <tr key={detail.id}>
                                                                    <td>{detail.company}</td>
                                                                    <td>{detail.jobposition}</td>
                                                                    <td>{detail.jobtype}</td>
                                                                    <td>{detail.startDate}</td>
                                                                    <td>{detail.endDate}</td>
                                                                    <td>{detail.monthlyCtc}</td>
                                                                    <td>{detail.monthlyInhand}</td>
                                                                    <td>{detail.annualCTC}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                ) : (
                                                    <p>No Work Experience available.</p>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

const EmployeeDatasheet = () => {
    const tableRef = useRef(null);
    const navigate = useNavigate();
    const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
    const [toggleCompany, setToggleCompany] = useState(
        sessionStorage.getItem("company_id")
    );
    const [employeeStatus, setEmployeeStatus] = useState('active');
    const [selectedName, setName] = useState('');
    const [employees, setEmployees] = useState([]);
    const [filteredEmployee, setFilteredEmployee] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedDep, setDep] = useState('');
    const [selectedDesi, setDesi] = useState('');
    const [selectedMinYears, setMinYears] = useState('');
    const [selectedMaxYears, setMaxYears] = useState('');
    const [selectedDegree, setDegree] = useState('');
    const [selectedStream, setStream] = useState('');
    const [departmentname, setDepartmentname] = useState([]);
    const [selectedFamily, setSelectedFamily] = useState('');
    const [selectedEmergency, setSelectedEmergency] = useState('');
    const [selectedDocument, setSelectedDocument] = useState('');
    const [selectedEducation, setEducation] = useState('');
    const [selectedWork, setWork] = useState('');
    const [selectedBank, setBank] = useState('');

    const [streamlist, setStreamList] = useState([]);



    const [bankdet, setBankdet] = useState(false);
    const [incrementdata, setIncrementdata] = useState(false);
    const [doc, setDoc] = useState(false);
    const [familydet, setFamilydet] = useState(false);
    const [jobposition, setJobposition] = useState(false);
    const [certification, setCertification] = useState(false);
    const [emergency, setEmergency] = useState(false);
    const [workexp, setWorkexp] = useState(false);
    const [edudetails, setEdudetails] = useState(false);

    const [formData, setFormData] = useState({
        family_details: familydet,
        emergency_details: emergency,
        document: doc,
        education_details: edudetails,
        work_experience_details: workexp,
        bank_details: bankdet,
        job_posting_details: jobposition,
        increment_data: incrementdata,
        certifications: certification,
    });

    const [selectedData, setSelectedData] = useState({ selectedDetails: [] });

    useEffect(() => {
        if (formData) { fetchEmployees() }
    }, [formData]);

    const generatesheet = () => {
        const dataMapping = [
            { name: "family_details", value: familydet },
            { name: "emergency_details", value: emergency },
            { name: "document", value: doc },
            { name: "education_details", value: edudetails },
            { name: "work_experience_details", value: workexp },
            { name: "bank_details", value: bankdet },
            { name: "job_posting_details", value: jobposition },
            { name: "increment_data", value: incrementdata },
            { name: "certifications", value: certification },
        ];

        const selectedDetails = dataMapping
            .filter(item => item.value === true)
            .map(item => item.name);

        setSelectedData({ selectedDetails });

        // const updatedFormData = { ...formData };
        const updatedFormData = {};
        selectedDetails.forEach((field) => {
            const selectedItem = dataMapping.find(item => item.name === field);
            if (selectedItem) {
                updatedFormData[field] = selectedItem.value;
            }
        });

        setFormData(updatedFormData);
    };


    const [buffer, setBuffering] = useState(true); //buffering logic
    const getDepartment = async () => {
        // setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(`${BASE_URL}/wfm/department/`);
            setDepartmentname(res.data);
        } catch (err) {
            //toast Logic
            console.log("err: getDepartment");
            console.log(err);
            handleErrorToast(err);
        } finally {
            // setBuffering(false);// End Buffering
        }
    };


    const fetchEmployees = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            //  const response = await axios.post(`${BASE_URL}/wfm/employeedatasheet/`, selectedData);
            const response = await axios.post(`${BASE_URL}/wfm/employeedatasheet/${toggleCompany}/${employeeStatus}/`, selectedData);
            const sortedData = customSortByKey(response.data, "employee.employee_details.emp_code");
            setEmployees(sortedData);

            const uniqueStreamTypes = new Set();
            response.data.forEach(entry => {
                entry.education_details?.forEach(education => {
                    uniqueStreamTypes.add(education.stream);
                });
            });
            setStreamList(Array.from(uniqueStreamTypes).map(stream => ({ value: stream, label: stream })));

        } catch (err) {
            console.log("err: fetchEmployees");
            console.log(err);
            // handleErrorToast(err)
            handleAllError(err)
        } finally {
            setLoading(false);
            setBuffering(false);// End Buffering
        }
    };


    useEffect(() => {

        getDepartment();
        fetchEmployees();
    }, [toggleCompany, employeeStatus]);

    useEffect(() => {
        applyFilters();
    }, [selectedName, employees, selectedDep, selectedDesi, selectedMinYears, selectedMaxYears, selectedDegree, selectedStream, selectedFamily, selectedEmergency, selectedDocument, selectedEducation, selectedWork, selectedBank,]);

    useEffect(() => {
    }, [bankdet, incrementdata, doc, familydet, jobposition, certification, emergency, workexp, edudetails]);


    const applyFilters = () => {
        let filteredData = employees;

        if (selectedName) {
            const lowercaseSelectedName = selectedName.toLowerCase();
            filteredData = filteredData.filter(emp => {
                const nameMatch = emp.employee.name.toLowerCase().includes(lowercaseSelectedName);
                const rhMatch = emp.employee.employee_details?.reporting_head_name?.toLowerCase().includes(lowercaseSelectedName);
                const emailMatch = emp.employee.email.toLowerCase().includes(lowercaseSelectedName);
                const empCodeMatch = emp.employee.employee_details?.emp_code.toString().toLowerCase().includes(selectedName);
                return nameMatch || empCodeMatch || emailMatch || rhMatch;
                // return nameMatch || empCodeMatch;
            });
        }

        if (selectedDep) {
            filteredData = filteredData.filter(emp => emp.employee.employee_details.department_name === selectedDep);
        }

        if (selectedDesi) {
            const lowercaseSelectedName = selectedDesi.toLowerCase();
            filteredData = filteredData.filter(emp => {
                const desiMatch = emp.employee.employee_details.designation_name.toLowerCase().includes(lowercaseSelectedName);
                return desiMatch;
            });
        }

        // Family Details Filter
        if (selectedFamily) {
            const lowercaseSelectedFamily = selectedFamily.toLowerCase();
            filteredData = filteredData.filter(emp => emp.family_details.some(family =>
                family.father_name?.toLowerCase().includes(lowercaseSelectedFamily) ||
                family.mother_name?.toLowerCase().includes(lowercaseSelectedFamily) ||
                family.current_address?.toLowerCase().includes(lowercaseSelectedFamily)
            ));
        }

        // Emergency Details Filter
        if (selectedEmergency) {
            const lowercaseSelectedEmergency = selectedEmergency.toLowerCase();
            filteredData = filteredData.filter(emp => emp.emergency_details.some(emergency =>
                emergency.person_name?.toLowerCase().includes(lowercaseSelectedEmergency) ||
                emergency.relation?.toLowerCase().includes(lowercaseSelectedEmergency) ||
                emergency.phone_number?.toString().toLowerCase().includes(selectedEmergency)
            ));
        }

        // Document Filter
        if (selectedDocument) {
            const lowercaseSelectedDocument = selectedDocument.toLowerCase();
            filteredData = filteredData.filter(emp => emp.document.some(doc =>
                doc.document_name?.toLowerCase().includes(lowercaseSelectedDocument)
            ));
        }

        //Education Specific Filter
        if (selectedDegree && selectedStream) {
            filteredData = filteredData.filter(emp =>
                emp.education_details.some(education =>
                    education.department === selectedDegree && education.stream === selectedStream
                )
            );
        }
        // else {
        //     if (selectedDegree) {
        //         filteredData = filteredData.filter(emp =>
        //             emp.education_details.some(education => education.department === selectedDegree)
        //         );
        //     }

        //     if (selectedStream) {
        //         filteredData = filteredData.filter(emp =>
        //             emp.education_details.some(education => education.stream === selectedStream)
        //         );
        //     }
        // }
        else if (selectedDegree) {
            filteredData = filteredData.filter(emp =>
                emp.education_details.some(education => education.department === selectedDegree)
            );
        }
        else if (selectedStream) {
            filteredData = filteredData.filter(emp =>
                emp.education_details.some(education => education.stream === selectedStream)
            );
        }

        if (selectedEducation) {
            const lowercaseSelectedEducation = selectedEducation.toLowerCase();
            filteredData = filteredData.filter(emp => emp.education_details.some(education =>
                education.college?.toLowerCase().includes(lowercaseSelectedEducation) ||
                education.institution_type?.toLowerCase().includes(lowercaseSelectedEducation)
            ));
        }

        //Work Info Specific Filter
        if (selectedMinYears !== '' || selectedMaxYears !== '') {
            filteredData = filteredData.filter(emp => {
                const totalWorkExperience = calculateTotalWorkExperience(emp.work_experience_details);
                const totalYears = totalWorkExperience.years + totalWorkExperience.months / 12;

                if (selectedMinYears && selectedMaxYears) {
                    return totalYears >= selectedMinYears && totalYears <= selectedMaxYears;
                } else if (selectedMinYears) {
                    return totalYears >= selectedMinYears;
                } else if (selectedMaxYears) {
                    return totalYears <= selectedMaxYears;
                }

                return true; // No filter applied
            });
        }
        if (selectedWork) {
            const lowercaseSelectedWork = selectedWork.toLowerCase();
            filteredData = filteredData.filter(emp => emp.work_experience_details.some(work =>
                work.company?.toLowerCase().includes(lowercaseSelectedWork) ||
                work.jobposition?.toLowerCase().includes(lowercaseSelectedWork)
            ));
        }

        // Bank Details Filter
        if (selectedBank) {
            const lowercaseSelectedBank = selectedBank.toLowerCase();
            filteredData = filteredData.filter(emp =>
                emp.bank_details?.bank_name?.toLowerCase().includes(lowercaseSelectedBank) ||
                emp.bank_details?.branch_address?.toLowerCase().includes(lowercaseSelectedBank) ||
                emp.bank_details?.account_number?.toString().toLowerCase().includes(lowercaseSelectedBank) ||
                emp.bank_details?.ifsc_code?.toString().toLowerCase().includes(lowercaseSelectedBank)
            );
        }
        setFilteredEmployee(filteredData);
    };

    const availableDegrees = [
        "10th",
        "12th",
        "B.A. (Bachelor of Arts)",
        "B.Com. (Bachelor of Commerce)",
        "M.Com. (Masters of Commerce)",
        "MBA (Master of Business Administration)",
        "M.Tech. (Master of Technology)",
        "M.E. (Master of Engineering )",
        "B.Tech. (Bachelor of Technology)",
        "B.E. (Bachelor of Engineering )",
        "B.Arch. (Bachelor of Architecture)",
        "Diploma",
        "PhD",
        "ITI",
        "Other",
    ];

    const handleDegreeChange = (selectedDegree) => {
        setDegree(selectedDegree);

        if (selectedDegree) {
            // If a degree is selected, filter streams based on the selected degree
            const filteredStreams = new Set();
            employees.forEach(emp => {
                emp.education_details?.forEach(education => {
                    if (education.department === selectedDegree) {
                        filteredStreams.add(education.stream);
                    }
                });
            });

            // Update Stream List Options Based on Filtered Data
            setStreamList(Array.from(filteredStreams).map(stream => ({
                value: stream,
                label: stream
            })));
        } else {
            // If no degree is selected, show all streams available across employees
            const allStreams = new Set();
            employees.forEach(emp => {
                emp.education_details?.forEach(education => {
                    allStreams.add(education.stream);
                });
            });

            // Set the complete list of streams if no degree is selected
            setStreamList(Array.from(allStreams).map(stream => ({
                value: stream,
                label: stream
            })));
        }

        // Clear the selected stream when the degree changes
        setStream(null);
    };

    const selectedCompany = toggleCompany !== "null"
        ? SUB_COMPANIES.find(company => company.id === toggleCompany)?.title
        : null;

    const handleDownloadEmployeeData = () => {
        downloadAsExcel(
            tableRef,
            "EmployeeDetails",
            selectedCompany ? `${selectedCompany}-EmployeeDataExcel.xls` : "EmployeeDataExcel.xls"
        );
    };

    const calculateTotalWorkExperience = (workExperienceDetails) => {
        let totalDays = 0;

        // Ensure workExperienceDetails is an array
        const experiences = workExperienceDetails || [];

        experiences.forEach((experience) => {
            const startDate = new Date(experience.startDate);
            const endDate = new Date(experience.endDate);

            // Check if startDate and endDate are valid dates
            if (!isNaN(startDate) && !isNaN(endDate)) {
                totalDays += Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24)); // Convert ms to days
            }
        });

        const years = Math.floor(totalDays / 365);
        const months = Math.floor((totalDays % 365) / 30);
        const days = totalDays % 30;

        return { years, months, days };
    };

    const currentUrl = window.location.href;
    const urlParts = currentUrl.split("/");
    const baseUrl = "/" + urlParts[3] + `/letterhead`;
    const ViewIncrementLetter = (emp_code, lettertype, index) => {
        console.log("Data show")
        console.log(emp_code)
        console.log(lettertype)
        console.log(index)
        navigate(`letterhead`, { state: { emp_code, lettertype, index } })
        // navigate(baseUrl, { state: { emp_code, lettertype, index } })
    }


    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="attendance-history-cont">

                <>
                    <div>
                        <div className="attendance-heading  font-weight500    font-size-heading"> </div>

                        <div className="attendance-subcont">
                            <div className="field-cont">
                                <div className="field-cont-div">
                                    <span className="input-color-light">Family Details</span>
                                    <input
                                        type="checkbox"
                                        checked={familydet}
                                        className='filter-checkbox'
                                        onChange={(e) => setFamilydet(e.target.checked)}
                                    />
                                    <hr className="field-cont-hr" />
                                </div>
                                <div className="field-cont-div">
                                    <span className="input-color-light">Emergency Contacts</span>
                                    <input
                                        type="checkbox"
                                        checked={emergency}
                                        className='filter-checkbox'
                                        onChange={(e) => setEmergency(e.target.checked)}
                                    />
                                    <hr className="field-cont-hr" />
                                </div>
                                <div className="field-cont-div">
                                    <span className="input-color-light">Documents</span>
                                    <input
                                        type="checkbox"
                                        checked={doc}
                                        className='filter-checkbox'
                                        onChange={(e) => setDoc(e.target.checked)}
                                    />
                                    <hr className="field-cont-hr" />
                                </div>
                                <div className="field-cont-div">
                                    <span className="input-color-light">Education Details</span>
                                    <input
                                        type="checkbox"
                                        checked={edudetails}
                                        className='filter-checkbox'
                                        onChange={(e) => setEdudetails(e.target.checked)}
                                    />
                                    <hr className="field-cont-hr" />
                                </div>
                                <div className="field-cont-div">
                                    <span className="input-color-light">Work Experience</span>
                                    <input
                                        type="checkbox"
                                        checked={workexp}
                                        className='filter-checkbox'
                                        onChange={(e) => setWorkexp(e.target.checked)}
                                    />
                                    <hr className="field-cont-hr" />
                                </div>
                                {
                                    sessionStorage.getItem('department_name') === 'Business Development' ? null :
                                        <>
                                            <div className="field-cont-div">
                                                <span className="input-color-light">Bank Details</span>
                                                <input
                                                    type="checkbox"
                                                    checked={bankdet}
                                                    className='filter-checkbox'
                                                    onChange={(e) => setBankdet(e.target.checked)}
                                                />
                                                <hr className="field-cont-hr" />
                                            </div>
                                            {/* <div className="field-cont-div">
                                    <span className="input-color-light">Job posting</span>
                                    <input
                                        type="checkbox"
                                        checked={jobposition}
                                        className='filter-checkbox'
                                        onChange={(e) => setJobposition(e.target.checked)}
                                    />
                                    <hr className="field-cont-hr" />
                                </div> */}
                                            <div className="field-cont-div">
                                                <span className="input-color-light">Increment Data</span>
                                                <input
                                                    type="checkbox"
                                                    checked={incrementdata}
                                                    className='filter-checkbox'
                                                    onChange={(e) => setIncrementdata(e.target.checked)}
                                                />
                                                <hr className="field-cont-hr" />
                                            </div>
                                            {/* <div className="field-cont-div">
                                    <span className="input-color-light">Certification</span>
                                    <input
                                        type="checkbox"
                                        checked={certification}
                                        className='filter-checkbox'
                                        onChange={(e) => setCertification(e.target.checked)}
                                    />
                                    <hr className="field-cont-hr" />
                                </div> */}
                                        </>
                                }
                            </div>

                            <div className="btn-cont">
                                <button
                                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                    onClick={generatesheet}
                                    style={{ padding: "10px 20px" }}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>

                        <div className="attendance-subcont">
                            <div className="field-cont">

                                {PERMISSION_AUTHORITY ?
                                    <div title="Company Filter" className="field-cont-div">
                                        <Office />
                                        <select
                                            onChange={(e) => setToggleCompany(e.target.value)}
                                            value={toggleCompany}
                                            className="attendance-input-field width-10vw   date-field"
                                        >
                                            <option value="null">Select Sub Company</option>
                                            {SUB_COMPANIES.map((company) => (
                                                <option value={company.id}>{company.title}</option>
                                            ))}
                                            ;
                                        </select>

                                        <hr className="field-cont-hr" />
                                    </div> : null}

                                <div className="field-cont-div">
                                    <Active />
                                    <select className="attendance-input-field  date-field" type="text"
                                        value={employeeStatus}
                                        onChange={(e) => setEmployeeStatus(e.target.value)}
                                    >
                                        <option value="null">All</option>
                                        <option value="active">Active</option>
                                        <option value="inactive">Inactive</option>
                                    </select>
                                    <hr className="field-cont-hr" />
                                </div>

                                <div title='Search Employee Details Filter' className="field-cont-div">
                                    <SearchBig />
                                    <input className="attendance-input-field width-20vw"
                                        placeholder='Name/ Code/ Email/ Reporting Head'
                                        type="text"
                                        value={selectedName}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    <hr className="field-cont-hr" />
                                </div>


                                <div title="Department Filter" className="field-cont-div">
                                    <CircularGrid />
                                    <select
                                        className="attendance-input-field width-10vw   date-field"
                                        value={selectedDep}
                                        onChange={(e) => setDep(e.target.value)}
                                    >
                                        <option value="">All Departments</option>
                                        {departmentname
                                            .filter(department => toggleCompany === "null" || department.sub_company === toggleCompany)
                                            .sort((a, b) => a.title.localeCompare(b.title)) // Sort by department title
                                            .map(department => (
                                                <option key={department.id} value={department.title}>
                                                    {toggleCompany === "null"
                                                        ? `${department.title} - ${department.sub_company_title}`
                                                        : department.title}
                                                </option>
                                            ))}
                                    </select>
                                    <hr className="field-cont-hr" />
                                </div>

                                <div title="Designation Filter" className="field-cont-div">
                                    <SearchBig />
                                    <input
                                        className="attendance-input-field width-10vw  "
                                        placeholder="Designation"
                                        type="text"
                                        value={selectedDesi}
                                        onChange={(e) => setDesi(e.target.value)}
                                    />
                                    <hr className="field-cont-hr" />
                                </div>

                                {formData.family_details &&
                                    <>
                                        <div title='Search Family (Address/ Father/Mother Name) Filter' className="field-cont-div">
                                            <SearchBig />
                                            <input className="attendance-input-field width-15vw"
                                                placeholder='Search by Family Details'
                                                type="text"
                                                value={selectedFamily}
                                                onChange={(e) => setSelectedFamily(e.target.value)}
                                            />
                                            <hr className="field-cont-hr" />
                                        </div>
                                    </>}

                                {formData.emergency_details &&
                                    <>
                                        <div title='Search Emergency (Name/Relation/Number) Filter' className="field-cont-div">
                                            <SearchBig />
                                            <input className="attendance-input-field width-15vw"
                                                placeholder='Search Emergency Contact'
                                                type="text"
                                                value={selectedEmergency}
                                                onChange={(e) => setSelectedEmergency(e.target.value)}
                                            />
                                            <hr className="field-cont-hr" />
                                        </div>
                                    </>}

                                {formData.document &&
                                    <>
                                        <div title='Search Document Filter' className="field-cont-div">
                                            <SearchBig />
                                            <input className="attendance-input-field width-15vw"
                                                placeholder='Search Document Name'
                                                type="text"
                                                value={selectedDocument}
                                                onChange={(e) => setSelectedDocument(e.target.value)}
                                            />
                                            <hr className="field-cont-hr" />
                                        </div>
                                    </>}

                                {formData.education_details &&
                                    <>
                                        <div title='Search Education (Institue name/type) Filter' className="field-cont-div">
                                            <SearchBig />
                                            <input className="attendance-input-field width-15vw"
                                                placeholder='Search Education Details'
                                                type="text"
                                                value={selectedEducation}
                                                onChange={(e) => setEducation(e.target.value)}
                                            />
                                            <hr className="field-cont-hr" />
                                        </div>

                                        <div title="Degree Type Filter" className="field-cont-div">
                                            <CircularGrid />
                                            <select
                                                className="attendance-input-field width-15vw   date-field"
                                                type="text"
                                                value={selectedDegree}
                                                // onChange={(e) => setDegree(e.target.value)}
                                                onChange={(e) => handleDegreeChange(e.target.value)}
                                            >
                                                <option value="">Select a Degree Type</option>
                                                {availableDegrees.map((degree) =>
                                                    <option key={degree} value={degree}>
                                                        {degree}
                                                    </option>
                                                )}
                                            </select>
                                            <hr className="field-cont-hr" />
                                        </div>

                                        <div title="Stream Type Filter" className="field-cont-div">
                                            <CircularGrid />
                                            <Select
                                                className="attendance-input-field width-20vw date-field"
                                                options={streamlist}
                                                value={selectedStream ? { value: selectedStream, label: selectedStream } : null}
                                                onChange={(option) => setStream(option ? option.value : null)}
                                                placeholder="Select a Stream"
                                                isClearable
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        color: '#707070',
                                                        outline: 'none',
                                                        border: 'none',
                                                        padding: '0.6rem 0.5rem',
                                                        textTransform: 'capitalize',
                                                        boxShadow: 'none',
                                                        '&:hover': {
                                                            border: 'none',
                                                        },
                                                    }),
                                                    placeholder: (provided) => ({
                                                        ...provided,
                                                        color: '#707070',
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        zIndex: 9999, // Ensure dropdown menu appears above other elements
                                                    }),
                                                    singleValue: (provided) => ({
                                                        ...provided,
                                                        color: '#707070',
                                                    }),
                                                    dropdownIndicator: (provided) => ({
                                                        ...provided,
                                                        filter: 'invert(50%)',
                                                        cursor: 'pointer',
                                                        left: '9.5rem',
                                                        width: '20px',
                                                        height: '20px',
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        zIndex: 9999, // Ensure dropdown menu appears above other elements
                                                    }),
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        color: state.isSelected ? '#2576bc' : '#707070',
                                                        backgroundColor: state.isSelected ? 'rgba(37,118,188,0.1)' : 'white',
                                                        cursor: 'pointer',
                                                        '&:hover': {
                                                            backgroundColor: '#2576bc',
                                                            color: 'white',
                                                        },
                                                    }),
                                                }}
                                            />
                                            <hr className="field-cont-hr" />
                                        </div>

                                    </>}

                                {formData.work_experience_details &&
                                    <>
                                        <div title="Min Year Filter" className="field-cont-div">
                                            <CircularGrid />
                                            <input
                                                className="attendance-input-field width-10vw  "
                                                placeholder="Min Years"
                                                type="number"
                                                min={0}
                                                max={selectedMaxYears}
                                                value={selectedMinYears}
                                                onWheel={(e) => e.target.blur()}
                                                onChange={(e) => setMinYears(e.target.value)}
                                            />
                                            <hr className="field-cont-hr" />
                                        </div>

                                        <div title="Max Year Filter" className="field-cont-div">
                                            <CircularGrid />
                                            <input
                                                className="attendance-input-field width-10vw  "
                                                placeholder="Max Years"
                                                type="number"
                                                min={selectedMinYears}
                                                value={selectedMaxYears}
                                                onWheel={(e) => e.target.blur()}
                                                onChange={(e) => setMaxYears(e.target.value)}
                                            />
                                            <hr className="field-cont-hr" />
                                        </div>
                                        <div title='Search Work Details (Company/Position) Filter' className="field-cont-div">
                                            <SearchBig />
                                            <input className="attendance-input-field width-15vw"
                                                placeholder='Search Work Details'
                                                type="text"
                                                value={selectedWork}
                                                onChange={(e) => setWork(e.target.value)}
                                            />
                                            <hr className="field-cont-hr" />
                                        </div>
                                    </>}

                                {formData.bank_details &&
                                    <>
                                        <div title='Search Bank Details (Bank Name/Address/Account Number/IFSC Code) Filter' className="field-cont-div">
                                            <SearchBig />
                                            <input className="attendance-input-field width-15vw"
                                                placeholder='Search Bank Details'
                                                type="text"
                                                value={selectedBank}
                                                onChange={(e) => setBank(e.target.value)}
                                            />
                                            <hr className="field-cont-hr" />
                                        </div>
                                    </>}
                            </div>
                            <div className="btn-cont">
                                {/* <button className="model-button-print" title='Employee Data Excel' onClick={handleDownloadEmployeeData}>
                                    <DownloadIcon />
                                </button> */}
                                <hr className="field-cont-hr" />
                                <DownloadTableExcel
                                    filename="ActiveEmployees-WorkDetails"
                                    sheet="workDetails"
                                    currentTableRef={tableRef.current}
                                >
                                    <button className="model-button   font-weight500   model-button-print">
                                        <DownloadIcon />
                                    </button>
                                </DownloadTableExcel>
                            </div>
                        </div>
                    </div>
                    <div className='filter-showing'>
                        <div>Employee Details: </div>
                        <div>Showing {filteredEmployee.length} of {employees.length} </div>
                    </div>

                    <div className="global-whitebg-table">
                        <div className="scrollfix-wrapper">
                            <table ref={tableRef} className="scrollfix-table font-size-label ">
                                {/* <table ref={tableRef} className="styled-table font-size-label table-css"> */}
                                <thead>
                                    <tr>
                                        <th>S.No.</th>
                                        <th>Emp Code</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Mobile</th>
                                        <th>Reporting Head</th>
                                        <th>Department</th>
                                        <th>Designation</th>
                                        {formData.family_details && <th>Family Details</th>}
                                        {formData.emergency_details && <th>Emergency Contacts</th>}
                                        {formData.document && <th>Documents</th>}
                                        {formData.education_details && <th>Education Details</th>}
                                        {formData.work_experience_details && <th>Work Exp Duration</th>}
                                        {formData.work_experience_details && <th>Work Experience</th>}
                                        {formData.bank_details && <th>Bank Details</th>}
                                        {formData.job_posting_details && <th>Job Position</th>}
                                        {formData.increment_data && <th>Increment History</th>}
                                        {formData.certifications && <th>Certifications</th>}
                                    </tr>
                                </thead>
                                <tbody className="tbody-wrapper">

                                    {buffer ?
                                        <div className="spinner"></div> :
                                        filteredEmployee.map((emp, index) => {
                                            const totalWorkExperience = calculateTotalWorkExperience(emp.work_experience_details);
                                            const totalExperienceString = `${totalWorkExperience.years} years, ${totalWorkExperience.months} months, ${totalWorkExperience.days} days`;

                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{emp.employee?.employee_details?.emp_code}</td>
                                                    <td>{emp.employee?.name}</td>
                                                    <td>{emp.employee?.email}</td>
                                                    <td>{emp.employee?.mobile}</td>
                                                    <td>{emp.employee?.employee_details?.reporting_head_name}</td>
                                                    <td>{emp.employee?.employee_details?.department}</td>
                                                    <td>{emp.employee?.employee_details?.designation}</td>

                                                    {formData.family_details && (
                                                        <td>
                                                            {
                                                                emp.family_details ? (
                                                                    <table className="nested-table font-size-subheading">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className='width-5vw'>Father Name</td>
                                                                                <td className='width-10vw'>{emp.family_details?.father_name ? emp.family_details?.father_name : "-"}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className='width-5vw'>Mother Name</td>
                                                                                <td className='width-10vw'>{emp.family_details?.mother_name ? emp.family_details?.mother_name : "-"}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className='width-5vw'>Marital Status</td>
                                                                                <td className='width-10vw'>{emp.family_details?.married_status ? emp.family_details?.married_status : "-"}</td>
                                                                            </tr>
                                                                            {emp.family_details?.married_status === "Married" ?
                                                                                <>
                                                                                    <tr>
                                                                                        <td className='width-5vw'>Marriage Date</td>
                                                                                        <td className='width-10vw'>{emp.family_details?.marriage_date ? formattedDateLong(emp.family_details?.marriage_date) : "-"}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className='width-5vw'>Child</td>
                                                                                        <td className='width-10vw'>{emp.family_details?.child ? emp.family_details?.child : "-"}</td>
                                                                                    </tr>
                                                                                </>
                                                                                : null}
                                                                            <tr>
                                                                                <td className='width-5vw'>Address</td>
                                                                                <td className='width-10vw'>{emp.family_details?.current_address ? emp.family_details?.current_address : "-"}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                ) :
                                                                    (<p>No Family Details Available</p>)
                                                            }
                                                        </td>
                                                    )}

                                                    {formData.emergency_details && (
                                                        <td>
                                                            {emp.emergency_details?.length ? (
                                                                <table className="nested-table font-size-subheading">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Contact Name</th>
                                                                            <th>Relation</th>
                                                                            <th>Phone Number</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {emp.emergency_details.map((emergency, emergencyIndex) => (
                                                                            <tr key={emergencyIndex}>
                                                                                <td>{emergency.person_name}</td>
                                                                                <td>{emergency.relation}</td>
                                                                                <td>{emergency.phone_number}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            ) : (
                                                                <p>No Emergency Contacts Available</p>
                                                            )}
                                                        </td>
                                                    )}

                                                    {formData.document && (
                                                        <td>
                                                            {emp.document?.length ? (
                                                                <ul>
                                                                    {emp.document.map((doc, docIndex) => (
                                                                        <li key={docIndex} className='flex-row'>
                                                                            {doc.document_name} : {" "}<ViewFile doc={doc} filename={doc.document_name} filesrc={doc.document_file} />
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            ) : (
                                                                <p>No Documents Available</p>
                                                            )}
                                                        </td>
                                                    )}

                                                    {formData.education_details && (
                                                        <td>
                                                            {emp.education_details?.length > 0 ? (
                                                                <table className="nested-table font-size-subheading">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>College</th>
                                                                            <th>Department</th>
                                                                            <th>Stream</th>
                                                                            <th>Institution Type</th>
                                                                            {/* <th>Degree</th> */}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {emp.education_details.map((education, educationIndex) => (
                                                                            <tr key={educationIndex}>
                                                                                <td>{education.college}</td>
                                                                                <td>{education.department}</td>
                                                                                <td>{education.stream}</td>
                                                                                <td>{education.institution_type}</td>
                                                                                {/* <td>{education.degree || "N/A"}</td> */}
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            ) : (
                                                                <p>No Education Details Available</p>
                                                            )}
                                                        </td>
                                                    )}

                                                    {formData.work_experience_details && (
                                                        <td>
                                                            {emp.work_experience_details?.length > 0 ? (
                                                                totalExperienceString
                                                            ) : (
                                                                <p>Fresher</p>
                                                            )}
                                                        </td>
                                                    )}
                                                    {formData.work_experience_details && (
                                                        <td>
                                                            {emp.work_experience_details?.length > 0 ? (
                                                                <table className="nested-table font-size-subheading">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Company</th>
                                                                            <th>Job Position</th>
                                                                            <th>Job Type</th>
                                                                            <th>Start Date</th>
                                                                            <th>End Date</th>
                                                                            <th>Monthly CTC</th>
                                                                            <th>Monthly Inhand</th>
                                                                            <th>Annual CTC</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {emp.work_experience_details.map((detail, workIndex) => (
                                                                            <tr key={workIndex}>
                                                                                <td>{detail.company}</td>
                                                                                <td>{detail.jobposition}</td>
                                                                                <td>{detail.jobtype}</td>
                                                                                <td>{formattedDateLong(detail.startDate)}</td>
                                                                                <td>{formattedDateLong(detail.endDate)}</td>
                                                                                <td>{detail.monthlyCtc}</td>
                                                                                <td>{detail.monthlyInhand}</td>
                                                                                <td>{detail.annualCTC}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            ) : (
                                                                <p>No Work Experience Available</p>
                                                            )}
                                                        </td>
                                                    )}

                                                    {formData.bank_details && (
                                                        <td>
                                                            {emp.bank_details ? (
                                                                <table className="nested-table font-size-subheading">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Account Number</td>
                                                                            <td>{emp.bank_details?.account_number}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>IFSC Code</td>
                                                                            <td>{emp.bank_details?.ifsc_code}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Bank Name</td>
                                                                            <td>{emp.bank_details?.bank_name}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Branch Address</td>
                                                                            <td>{emp.bank_details?.branch_address}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            ) : (
                                                                <p>No Bank Details Available</p>
                                                            )}
                                                        </td>
                                                    )}

                                                    {formData.job_posting_details && (
                                                        // <td>{emp.job_posting_details || "No Job Position Available"}</td>
                                                        <td>
                                                            {emp.job_posting_details?.length ? (
                                                                <ul>
                                                                    {emp.job_posting_details.map((job, jobIndex) => (
                                                                        <li key={jobIndex}>{job.job_name}</li>
                                                                    ))}
                                                                </ul>
                                                            ) : (
                                                                <p>No Job Position Available</p>
                                                            )}
                                                        </td>
                                                    )}
                                                    {formData.increment_data && (
                                                        <td>
                                                            {emp.increment_data?.length > 0 ? (
                                                                <table className="nested-table font-size-subheading">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Effective Date</th>
                                                                            <th>Increment Amount</th>
                                                                            <th>Increment Percentage</th>
                                                                            <th>Existing CTC</th>
                                                                            <th>Revised CTC</th>
                                                                            <th>Updated Designation</th>
                                                                            <th>Old Designation</th>
                                                                            <th>Previous Grade</th>
                                                                            <th>Update Grade</th>
                                                                            <th>Letter</th>
                                                                            <th>View</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {emp.increment_data
                                                                            .sort((a, b) => new Date(b.wefdate) - new Date(a.wefdate))
                                                                            .map((detail, workIndex) => (
                                                                                <tr key={workIndex}>
                                                                                    <td>{formattedDateLong(detail.wefdate)}</td>
                                                                                    <td>{detail.increment_amount || "N/A"}</td>
                                                                                    <td>{detail.increment_percentage || "N/A"}</td>
                                                                                    <td>{detail.existing_ctc || "N/A"}</td>
                                                                                    <td>{detail.revised_ctc || "N/A"}</td>
                                                                                    <td>{detail.updated_designation || "N/A"}</td>
                                                                                    <td>{detail.old_designation || "N/A"}</td>
                                                                                    <td>{detail.previous_grade || "N/A"}</td>
                                                                                    <td>{detail.update_grade || "N/A"}</td>
                                                                                    <td>{detail.letter_type || "N/A"}</td>
                                                                                    <td>
                                                                                        <button
                                                                                            className="document-download-button  width-5vw  width-5vw"
                                                                                            onClick={() => ViewIncrementLetter(detail.emp_code, detail.letter_type, workIndex)}
                                                                                        >
                                                                                            View
                                                                                        </button>
                                                                                    </td>

                                                                                </tr>
                                                                            ))}
                                                                    </tbody>
                                                                </table>
                                                            ) : (
                                                                <p>No Increment History Available</p>
                                                            )}
                                                        </td>
                                                    )}


                                                    {formData.certifications && (
                                                        <td>
                                                            {emp.certifications?.length ? (
                                                                <ul>
                                                                    {emp.certifications.map((cert, certIndex) => (
                                                                        <li key={certIndex}>{cert.certification_name}</li>
                                                                    ))}
                                                                </ul>
                                                            ) : (
                                                                <p>No Certifications Available</p>
                                                            )}
                                                        </td>
                                                    )}

                                                </tr>
                                            )
                                        })}
                                </tbody>
                            </table>

                        </div>
                    </div>

                </>

            </div>
        </>
    );
};

const BDEmployeeDataView = () => {
    return (
        <div>
            <Dashboardnavbarcopy name="Employee Data Management" url="Business Development" />
            <div className="content-tabs">
                <EmployeeDatasheet />

            </div>
        </div>
    )
};




const EmployeeDataExcel = () => {
    const [toggleState, setToggleState] = useState(3);
    const { PERMISSION_ROLE, PERMISSION_AUTHORITY } = usePermission(); // Use the custom hook
    return (
        <>
            <div>
                <div >
                    <div className="bloc-tabs-with-white-bg">

                        <button
                            className={toggleState === 1 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                            onClick={() => setToggleState(1)}
                        >
                            Education Data
                        </button>
                        <button
                            className={toggleState === 2 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                            onClick={() => setToggleState(2)}
                        >
                            Work Experience Data
                        </button>
                        <button
                            className={toggleState === 3 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                            onClick={() => setToggleState(3)}
                        >
                            Employee Data Sheet
                        </button>

                        {/* {PERMISSION_AUTHORITY ? (
                            <button
                                className={toggleState === 3 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                                onClick={() => setToggleState(3)}
                            >
                                Custom Data Table
                            </button>
                        ) : null} */}

                    </div>


                </div>

                <div className="content-tabs-without-margin">
                    {toggleState === 1 ? <EmployeeDataExcelEducation /> : null}
                    {toggleState === 2 ? <EmployeeDataExcelWork /> : null}
                    {toggleState === 3 ? <EmployeeDatasheet /> : null}
                </div>
            </div>
        </>
    )
}


export default EmployeeDataExcel;
export { BDEmployeeDataView, EmployeeDatasheet };
