

import React, { useEffect } from "react";
import { useCalendar } from './CalenderContext';

import DayView from "./Dayview";
import WeekView from "./Weekview";
import MonthView from "./Monthview";
import Dashboardnavbarcopy from '../../layout/Dashboardnavbarcopy';
import { DropdownArrow } from "../AllSvg";
import { CreateRoomModal, ViewRoomModal } from './CreateRoommodel';
import { ViewMeetingModal } from "./CreateMeetingModal";
function Calender() {
  const {
    currentMonthIdx,
    currentYear,
    currentMonth,
    events,
    view,
    selectedDate,
    createButtonModal,
    setcreateButtonModal,
    handlePrevMonth,
    handleNextMonth,
    handlePrevDay,
    handleNextDay,
    handleToday,
    handleDayClicksidebar,
    handleViewChange,
    handleCreateRoomButtonClick,
    handleCloseRoomModal,
    handleRoomCreated,
    createroomButtonModal,
    getRoomdata,
    showRoomModal,
    handlecloseRoomModal,
    handleopenRoomModal,
    roomData,

  } = useCalendar();

  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const today = new Date();
  const todayDate = today.getDate();
  const todayMonth = today.getMonth();
  const todayYear = today.getFullYear();
  return (
    <React.Fragment>
      <Dashboardnavbarcopy name="Meeting Scheduler" url="Meeting Scheduler" />
      <div className="content-tabs" style={{ borderRadius: '16px' }}>
        <header className="calendar-header">
          <div>
            <h1 className="calendar-title">Calendar</h1>
            <button onClick={handleToday} className="model-button-cancel model-button">Today</button>
          </div>
          <div>

            <div title="view" className="flex-column form-group-select" >

              <select
                id="View"
                name="View"
                onChange={handleViewChange} value={view}
                className="form-input form-group-selection "
                style={{ width: '10vw', marginTop: "20px" }}
              >
                <option value="day">Day</option>
                <option value="month">Month</option>
                <option value="week">Week</option>
              </select>

              <div>

              </div>
            </div>


            <CreateRoomModal
              isOpen={createroomButtonModal}
              onClose={() => handleCloseRoomModal()}
              handleShow={handleCreateRoomButtonClick}
              onRoomCreated={handleRoomCreated}
              getRoomdata={getRoomdata}
            />
            <ViewRoomModal
              isOpen={showRoomModal}
              onClose={() => handlecloseRoomModal()}
              handleShow={handleopenRoomModal}
              roomData={roomData}
              getRoomdata={getRoomdata}

            />
          </div>
          <div>
            {view === "day" ? <>      <button onClick={handlePrevDay} className="calendar-button prev-button small-button">
              <span className="material-icons-outlined">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M15 18l-6-6 6-6"></path>
                </svg>
              </span>
            </button>


              <p className="calendar-month">
                {selectedDate?.toLocaleDateString('en-US', {
                  // weekday: 'long',
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                })}
              </p>

              <button onClick={handleNextDay} className="calendar-button next-button small-button">
                <span className="material-icons-outlined">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M9 18l6-6-6-6"></path>
                  </svg>
                </span>
              </button></> : <>     <button onClick={handlePrevMonth} className="calendar-button prev-button small-button">                 <span className="material-icons-outlined">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M15 18l-6-6 6-6"></path>
                </svg>
              </span>
              </button>

              <button onClick={handleNextMonth} className="calendar-button next-button small-button">
                <span className="material-icons-outlined">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M9 18l6-6-6-6"></path>
                  </svg>
                </span>
              </button>
              <p className="calendar-month monthmainheader">

                {new Date(currentYear, currentMonthIdx).toLocaleString("default", {
                  month: "long",
                })}{" "}
                {currentYear}
              </p>
            </>}

          </div>
        </header>

        <div className="main-content">
          <aside className="sidebar-container">
            <div className="create-box">
              <button onClick={() => setcreateButtonModal(!createButtonModal)} className="upload-svg" title="Add Event">
                + Create New
              </button>
              {createButtonModal &&
                <ul className="create-button-div">
                  <li>Meeting</li>

                </ul>}
            </div>

            <div className="calendar-navigation">
              {view === "day" ? <>      <button onClick={handlePrevDay} className="calendar-button prev-button small-button">
                <span className="material-icons-outlined">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M15 18l-6-6 6-6"></path>
                  </svg>
                </span>
              </button>


                <p className="calendar-month">
                  {selectedDate?.toLocaleDateString('en-US', {
                    // weekday: 'long',
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                  })}
                </p>

                <button onClick={handleNextDay} className="calendar-button next-button small-button">
                  <span className="material-icons-outlined">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M9 18l6-6-6-6"></path>
                    </svg>
                  </span>
                </button></> : <>     <button onClick={handlePrevMonth} className="calendar-button prev-button small-button">                 <span className="material-icons-outlined">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M15 18l-6-6 6-6"></path>
                  </svg>
                </span>
                </button>
                <p className="calendar-month">

                  {new Date(currentYear, currentMonthIdx).toLocaleString("default", {
                    month: "long",
                  })}{" "}
                  {currentYear}
                </p>
                <button onClick={handleNextMonth} className="calendar-button next-button small-button">
                  <span className="material-icons-outlined">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M9 18l6-6-6-6"></path>
                    </svg>
                  </span>
                </button></>}

            </div>

            <div className="month-selector">


              {daysOfWeek.map((dayName, idx) => (
                <span key={idx} className="week-day">
                  {dayName.charAt(0)}
                </span>
              ))}


              {currentMonth.map((row, i) => (
                <React.Fragment key={i}>
                  {row.map((day, idx) => {

                    const isToday =
                      day &&
                      day.getDate() === todayDate &&
                      day.getMonth() === todayMonth &&
                      day.getFullYear() === todayYear;

                    const isSelected =
                      day && selectedDate && day.getDate() === selectedDate.getDate() &&
                      day.getMonth() === selectedDate.getMonth() &&
                      day.getFullYear() === selectedDate.getFullYear();



                    return (
                      <button
                        key={idx}
                        className="day-button-small"


                        onClick={() => (handleDayClicksidebar(day))}
                      >

                        <span className={`day-number ${isToday ? "today" : ""} 
                          ${isSelected ? "selected" : ""}`}>{day ? day.getDate() : ""}</span>


                      </button>
                    );
                  })}
                </React.Fragment>
              ))}
            </div>

          </aside>

          {view === "day" && <DayView selectedDate={selectedDate} />}
          {view === "week" && <WeekView selectedDate={selectedDate} />}
          {view === "month" && <MonthView selectedDate={selectedDate} />}



        </div>
      </div>
    </React.Fragment>
  );
}

export default Calender;

