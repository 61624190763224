import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { BASE_URL } from "../../config/axios";
import { ToastContainer, toast } from "react-toastify";
import { formattedDateLong, formattedDateNoYear } from "../Date";
import { AddwithBlueCircle, DeleteDustbin, DropdownArrowOption, UpdatePencil } from "../AllSvg";
import { customSortByKey, handleAllError } from "../CustomFunctions";
import usePermission from "../../config/permissions";

const AddStationaryItem = ({ getItemsList }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        item_name: "",
        sub_company: sessionStorage.getItem('company_id')
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["item_name"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.post(
                    `${BASE_URL}/wfm/stationary/`,
                    formData,
                );

                if (res.status === 200) {
                    await getItemsList();
                    setShow(false)
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <button title="Add Item" className="models-button model-add" onClick={handleShow}>
                <AddwithBlueCircle />
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <h6 className="task-Tab-heading   font-weight500    font-size-heading">Add Item</h6>

                            <form>
                                <div className="flex-column">
                                    <label htmlFor="item_name" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                        Name
                                    </label>
                                    <input
                                        id="item_name"
                                        type="item_name"
                                        name="item_name"
                                        maxLength={50}
                                        placeholder="Item Name"
                                        onChange={handleInputChange}
                                        value={formData.name}
                                        className={`form-input ${errors.item_name ? "error" : inputState.item_name ? "success" : ""
                                            }`}
                                    />
                                    {errors.item_name && (
                                        <span className="error-message font-size-text ">{errors.item_name}</span>
                                    )}
                                </div>

                            </form>
                            <div className="button-models">
                                <button className="model-button   font-weight500   " onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

const UpdateStationaryItem = ({ i, getItemsList }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setFormData(i)
        setShow(true)
    };

    const [formData, setFormData] = useState({
        item_name: "",
    });


    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["item_name"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(
                    `${BASE_URL}/wfm/stationary/${i.id}/`,
                    formData,

                );

                if (res.status === 200) {
                    await getItemsList();
                    setShow(false)
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }

            } finally {
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <button title="Update Item" className="model-edit-button" onClick={handleShow}>
                <UpdatePencil />
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <h6 className="task-Tab-heading   font-weight500    font-size-heading">Announcement</h6>

                            <form>
                                <div className="flex-column">
                                    <label htmlFor="item_name" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                        Name
                                    </label>
                                    <input
                                        id="item_name"
                                        type="item_name"
                                        name="item_name"
                                        placeholder="Item Name"
                                        onChange={handleInputChange}
                                        value={formData.item_name}
                                        className={`form-input ${errors.item_name ? "error" : inputState.item_name ? "success" : ""
                                            }`}
                                    />
                                    {errors.item_name && (
                                        <span className="error-message font-size-text ">{errors.item_name}</span>
                                    )}
                                </div>
                            </form>
                            <div className="button-models">
                                <button className="model-button   font-weight500   " onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

const DeleteStationaryItem = ({ i, getItemsList }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
        try {
            let res = await axios.delete(
                `${BASE_URL}/wfm/stationary/${i.id}/`
            );

            if (res.status === 200) {
                await getItemsList();
                setShow(false);
            } else {
                alert(res);
            }
        } catch (err) {

            //toast Logic
            if (err.response) {
                toast.dismiss(loadingToastId);
                const errorData = err.response.data;

                if (typeof errorData.error === 'string') {
                    // Single error message
                    toast.error(`Error: ${errorData.error}`);
                } else if (typeof errorData === 'object' && errorData !== null) {
                    // Multiple error messages
                    Object.entries(errorData).forEach(([field, messages]) => {
                        messages.forEach(message => toast.error(`"${field}": ${message}`));
                    });
                } else {
                    toast.error('Error:- Failed to Process!');
                }
            } else {
                toast.error('Error processing your request.');
            }
        } finally {
            toast.dismiss(loadingToastId);
        }
    };
    return (
        <>
            <button title="Delete Item" className="model-delete-button" onClick={handleShow}>
                <DeleteDustbin />
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>Delete Item {i.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        Are You Sure You Want to Delete Item {i.name}
                        <div className="button-models">
                            <button className="model-button   font-weight500    " onClick={handleClose}>
                                Cancel
                            </button>
                            <button
                                onClick={handleFormSubmitDelete}
                                className="model-button   font-weight500    model-button-delete"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};


const AddItemToStock = ({ getStockList, itemList }) => {
    const [show, setShow] = useState(false);
    const [buffer, setBuffering] = useState(true); //buffering logic

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
    };
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;

    const [formData, setFormData] = useState({
        item_id: "",
        quantity: 0,
        vendor_name: "",
        purchase_date: formattedDate,
        rate_per_item: 0,
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["item_id", "quantity", "vendor_name", "rate_per_item"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.post(
                    `${BASE_URL}/wfm/stationary-inventory-details/`, formData,);

                if (res.status === 200) {
                    await getStockList();
                    setShow(false)
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };


    return (
        <>
            <button title="Add Stock Item" className="models-button model-add" onClick={handleShow}>
                <AddwithBlueCircle />
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <h6 className="task-Tab-heading   font-weight500    font-size-heading">Stock Item</h6>

                            <form>

                                <div className="flex-column form-group-selectt">
                                    <label htmlFor="item_id" className="form-labels  font-weight500    font-size-subheading">
                                        Item<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="item_id"
                                        name="item_id"
                                        value={formData.item_id}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.item_id ? "error" : inputState.item_id ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select Item</option>
                                        {itemList.map((i) => (<>
                                            <option value={i.id}>{i.item_name}</option>
                                        </>))
                                        }
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <DropdownArrowOption />
                                    </div>
                                    <div>
                                        {errors.item_id && (
                                            <span className="error-message font-size-text ">{errors.item_id}</span>
                                        )}
                                    </div>
                                </div>
                                <div className="flex-column">
                                    <label htmlFor="quantity" className="form-labels  font-weight500    font-size-subheading">
                                        Quantity<span className="required">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        min={0}
                                        id="quantity"
                                        name="quantity"
                                        onChange={handleInputChange}
                                        value={formData.quantity}
                                        className={`form-text-trasformation-uppercase form-input ${errors.quantity ? "error" : inputState.quantity ? "success" : ""
                                            }`}
                                    />
                                    {errors.quantity && <span className="error-message font-size-text ">{errors.quantity}</span>}
                                </div>

                                <div className="flex-column">
                                    <label htmlFor="vendor_name" className="form-labels  font-weight500    font-size-subheading">
                                        Vendor<span className="required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="vendor_name"
                                        name="vendor_name"
                                        onChange={handleInputChange}
                                        value={formData.vendor_name}
                                        className={`form-input ${errors.vendor_name ? "error" : inputState.vendor_name ? "success" : ""
                                            }`}
                                    />
                                    {errors.vendor_name && <span className="error-message font-size-text ">{errors.vendor_name}</span>}
                                </div>

                                <div className="flex-column">
                                    <label htmlFor="rate_per_item" className="form-labels  font-weight500    font-size-subheading">
                                        Rate Per Item<span className="required">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        min={0}
                                        id="rate_per_item"
                                        name="rate_per_item"
                                        onChange={handleInputChange}
                                        value={formData.rate_per_item}
                                        className={`form-text-trasformation-uppercase form-input ${errors.rate_per_item ? "error" : inputState.rate_per_item ? "success" : ""
                                            }`}
                                    />
                                    {errors.rate_per_item && <span className="error-message font-size-text ">{errors.rate_per_item}</span>}
                                </div>

                                <div className="flex-column">
                                    <label htmlFor="purchase_date" className="form-labels  font-weight500    font-size-subheading">
                                        Purchase Date<span className="required">*</span>
                                    </label>
                                    <input
                                        type="date"
                                        id="purchase_date"
                                        name="purchase_date"
                                        onChange={handleInputChange}
                                        value={formData.purchase_date}
                                        className={`form-input ${errors.purchase_date ? "error" : inputState.purchase_date ? "success" : ""
                                            }`}
                                    />
                                    {errors.purchase_date && <span className="error-message font-size-text ">{errors.purchase_date}</span>}
                                </div>

                            </form>

                            <div className="button-models">
                                <button className="model-button   font-weight500   " onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

const UpdateItemInStock = ({ i, getStockList }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setFormData(i);
        setShow(true)
    };


    const [formData, setFormData] = useState({
        item_id: "",
        quantity: 0,
        vendor_name: "",
        purchase_date: "",
        rate_per_item: 0,
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["item_id", "quantity", "vendor_name", "rate_per_item", "purchase_date"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(
                    `${BASE_URL}/wfm/stationary-inventory-details/${i.id}/`,
                    formData,
                );

                if (res.status === 200) {
                    await getStockList();
                    setShow(false)
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }

            } finally {
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };


    return (
        <>
            <button title="Update Stock Item" className="model-edit-button" onClick={handleShow}>
                <UpdatePencil />
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <h6 className="task-Tab-heading   font-weight500    font-size-heading">Update : {i.item_name}</h6>

                            <form>


                                {/* <div className="flex-column">
                                        <label htmlFor="quantity" className="form-labels  font-weight500   ">
                                            Quantity<span className="required">*</span>
                                        </label>
                                        <input
                                            type="number"
                                            min={0}
                                            id="quantity"
                                            name="quantity"
                                            onChange={handleInputChange}
                                            value={formData.quantity}
                                            className={`form-text-trasformation-uppercase form-input ${errors.quantity ? "error" : inputState.quantity ? "success" : ""
                                                }`}
                                        />
                                        {errors.quantity && <span className="error-message">{errors.quantity}</span>}
                                    </div> */}

                                <div className="flex-column">
                                    <label htmlFor="vendor_name" className="form-labels  font-weight500    font-size-subheading">
                                        Vendor<span className="required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="vendor_name"
                                        name="vendor_name"
                                        onChange={handleInputChange}
                                        value={formData.vendor_name}
                                        className={`form-input ${errors.vendor_name ? "error" : inputState.vendor_name ? "success" : ""
                                            }`}
                                    />
                                    {errors.vendor_name && <span className="error-message font-size-text ">{errors.vendor_name}</span>}
                                </div>

                                <div className="flex-column">
                                    <label htmlFor="rate_per_item" className="form-labels  font-weight500    font-size-subheading">
                                        Rate Per Item<span className="required">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        min={0}
                                        id="rate_per_item"
                                        name="rate_per_item"
                                        onChange={handleInputChange}
                                        value={formData.rate_per_item}
                                        className={`form-text-trasformation-uppercase form-input ${errors.rate_per_item ? "error" : inputState.rate_per_item ? "success" : ""
                                            }`}
                                    />
                                    {errors.rate_per_item && <span className="error-message font-size-text ">{errors.rate_per_item}</span>}
                                </div>

                                <div className="flex-column">
                                    <label htmlFor="purchase_date" className="form-labels  font-weight500    font-size-subheading">
                                        Purchase Date<span className="required">*</span>
                                    </label>
                                    <input
                                        type="date"
                                        id="purchase_date"
                                        name="purchase_date"
                                        onChange={handleInputChange}
                                        value={formData.purchase_date}
                                        className={`form-input ${errors.purchase_date ? "error" : inputState.purchase_date ? "success" : ""
                                            }`}
                                    />
                                    {errors.purchase_date && <span className="error-message font-size-text ">{errors.purchase_date}</span>}
                                </div>


                            </form>
                            <div className="button-models">
                                <button className="model-button   font-weight500   " onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

const DeleteItemFromStock = ({ i, getStockList }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
        try {
            let res = await axios.delete(
                `${BASE_URL}/wfm/stationary-inventory-details/${i.id}/`
            );

            if (res.status === 200) {
                await getStockList();
                setShow(false);
            } else {
                alert(res);
            }
        } catch (err) {

            //toast Logic
            if (err.response) {
                toast.dismiss(loadingToastId);
                const errorData = err.response.data;

                if (typeof errorData.error === 'string') {
                    // Single error message
                    toast.error(`Error: ${errorData.error}`);
                } else if (typeof errorData === 'object' && errorData !== null) {
                    // Multiple error messages
                    Object.entries(errorData).forEach(([field, messages]) => {
                        messages.forEach(message => toast.error(`"${field}": ${message}`));
                    });
                } else {
                    toast.error('Error:- Failed to Process!');
                }
            } else {
                toast.error('Error processing your request.');
            }
        } finally {
            toast.dismiss(loadingToastId);
        }
    };
    return (
        <>
            <button title="Delete Stock Item" className="model-delete-button" onClick={handleShow}>
                <DeleteDustbin />
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>Delete {i.item_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        Are You Sure You Want to delete {i.item_name} from Inventory
                        <div className="button-models">
                            <button className="model-button   font-weight500    " onClick={handleClose}>
                                Cancel
                            </button>
                            <button
                                onClick={handleFormSubmitDelete}
                                className="model-button   font-weight500    model-button-delete"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};



const GiveEmployeeStationary = ({ getEmployeeItemsList, getStockList, stockItemList }) => {
    const [show, setShow] = useState(false);
    const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();

    const handleClose = () => setShow(false);
    const handleShow = () => {
        getAllEmp_code(toggleCompany)
        getStockList()
        setShow(true)
    };

    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;

    const [formData, setFormData] = useState({
        stat_inventory_details: "",
        employee: "",
        quantity: 0,
        recevied_date: formattedDate,
    });
    const [availableStock, setAvailableStock] = useState(0); // New state for available stock

    const [toggleCompany, setToggleCompany] = useState(sessionStorage.getItem("company_id"));

    const [empList, setEmpList] = useState([])
    const [buffer, setBuffering] = useState(true); //buffering logic
    const getAllEmp_code = async (toggleCompany) => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/wfm/ourcompanyempdetailsactive/${toggleCompany}/`
            );

            const sortedList = customSortByKey(res.data, "emp_code");

            setEmpList(sortedList);
        } catch (err) {
            //toast Logic
            handleAllError(err)
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["stat_inventory_details", "employee", "quantity", "recevied_date"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.post(
                    `${BASE_URL}/wfm/stationary-employee-details/`,
                    formData,
                );

                if (res.status === 200) {
                    await getStockList();
                    await getEmployeeItemsList();

                    setShow(false)
                } else {
                    // console.log("res.data")
                    // console.log(res.data)
                    // console.log("res.status")
                    // console.log(res.status)
                    if (res.data) {
                        toast.dismiss(loadingToastId);
                        const warningData = res.data;

                        if (typeof warningData.message === 'string') {
                            // Single error message
                            toast.warning(`Warning: ${warningData.message}`);
                        } else if (typeof warningData === 'object' && warningData !== null) {
                            // Multiple error messages
                            Object.entries(warningData).forEach(([field, messages]) => {
                                messages.forEach(message => toast.warning(`"${field}": ${message}`));
                            });
                        } else {
                            toast.warning('Warning:- something went wrong !');
                        }
                    } else {
                        toast.warning('Warning couldnt process your request.');
                    }
                }
            } catch (err) {
                handleAllError(err, loadingToastId)
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });


        if (name === "stat_inventory_details") {
            const selectedItem = stockItemList.find(item => item.id === value);
            if (selectedItem) {
                setAvailableStock(selectedItem.quantity - selectedItem.distributed_quantity);
            } else {
                setAvailableStock(0);
            }
        }
    };

    const handleSubCompanyChange = (e) => {
        const { value } = e.target;
        setToggleCompany(value)
        getAllEmp_code(value)

    };

    return (
        <>
            <button title="Issue Items to Employee" className="models-button model-add" onClick={handleShow}>
                <AddwithBlueCircle />
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">

                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <h6 className="task-Tab-heading   font-weight500    font-size-heading">Issue Item to Employee</h6>
                            <form>
                                {PERMISSION_AUTHORITY ?
                                    <div className="flex-column form-group-selectt">
                                        <label htmlFor="sub_company" className="form-labels  font-weight500    font-size-subheading">
                                            Sub Company<span className="required">*</span>
                                        </label>
                                        <br />
                                        <select
                                            id="sub_company"
                                            name="sub_company"
                                            value={toggleCompany}
                                            onChange={handleSubCompanyChange}
                                            className={`form-input form-group-selection ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""
                                                }`}
                                        >
                                            {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};
                                        </select>
                                        <div className="form-group-selection-arrow">
                                            <DropdownArrowOption />
                                        </div>
                                    </div>
                                    : null}
                                <div className="flex-column form-group-selectt">
                                    <label htmlFor="employee" className="form-labels  font-weight500    font-size-subheading">
                                        Employee<span className="required">*</span>
                                    </label>
                                    <br />
                                    {buffer ?
                                        <div className="form-loader">
                                            <div className="bar-loader"></div>
                                        </div>
                                        :
                                        <>
                                            <select
                                                id="employee"
                                                name="employee"
                                                value={formData.employee}
                                                onChange={handleInputChange}
                                                className={`form-input form-group-selection ${errors.employee ? "error" : inputState.employee ? "success" : ""
                                                    }`}
                                            >
                                                <option value="">Select Employee</option>
                                                {empList.map((i) => (<>
                                                    <option value={i.emp_code}>{i.emp_code}-{i.name}</option>
                                                </>))
                                                }
                                            </select>
                                            <div className="form-group-selection-arrow">
                                                <DropdownArrowOption />
                                            </div>
                                            <div>
                                                {errors.employee && (
                                                    <span className="error-message font-size-text ">{errors.employee}</span>
                                                )}
                                            </div>
                                        </>}
                                </div>

                                <div className="flex-column form-group-selectt">
                                    <label htmlFor="stat_inventory_details" className="form-labels  font-weight500    font-size-subheading">
                                        Item<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="stat_inventory_details"
                                        name="stat_inventory_details"
                                        value={formData.stat_inventory_details}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.stat_inventory_details ? "error" : inputState.stat_inventory_details ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select Item</option>
                                        {stockItemList.map((i) => (<>
                                            {(i.quantity - i.distributed_quantity) > 0 ?
                                                <option value={i.id}>{i.item_name}  |  Stock: {formattedDateNoYear(i.purchase_date)},  items left: {i.quantity - i.distributed_quantity}</option>
                                                : null
                                            }
                                        </>))
                                        }
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <DropdownArrowOption />
                                    </div>
                                    <div>
                                        {errors.stat_inventory_details && (
                                            <span className="error-message font-size-text ">{errors.stat_inventory_details}</span>
                                        )}
                                    </div>
                                </div>

                                <div className="flex-column">
                                    <label htmlFor="quantity" className="form-labels  font-weight500    font-size-subheading">
                                        Quantity<span className="required">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        min={0}
                                        id="quantity"
                                        max={availableStock} // Set max limit
                                        name="quantity"
                                        onChange={handleInputChange}
                                        value={formData.quantity}
                                        className={`form-text-trasformation-uppercase form-input ${errors.quantity ? "error" : inputState.quantity ? "success" : ""
                                            }`}
                                    />
                                    {errors.quantity && <span className="error-message font-size-text ">{errors.quantity}</span>}
                                </div>

                                <div className="flex-column">
                                    <label htmlFor="recevied_date" className="form-labels  font-weight500    font-size-subheading">
                                        Issue Date<span className="required">*</span>
                                    </label>
                                    <input
                                        type="date"
                                        id="recevied_date"
                                        name="recevied_date"
                                        onChange={handleInputChange}
                                        value={formData.recevied_date}
                                        className={`form-input ${errors.recevied_date ? "error" : inputState.recevied_date ? "success" : ""
                                            }`}
                                    />
                                    {errors.recevied_date && <span className="error-message font-size-text ">{errors.recevied_date}</span>}
                                </div>

                            </form>
                            <div className="button-models">
                                <button className="model-button   font-weight500   " onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

const UpdateEmployeeStationary = ({ i, getEmployeeItemsList, stockItemList }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setFormData(i);
        setShow(true)
    };


    const [formData, setFormData] = useState({
        stat_inventory_details: "",
        employee: "",
        quantity: 0,
        recevied_date: "",
    });

    useEffect(() => {
        setFormData(i);
    }, [i]);

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["stat_inventory_details", "employee", "quantity", "recevied_date"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(
                    `${BASE_URL}/wfm/stationary-employee-details/${i.id}/`,
                    formData,

                );

                if (res.status === 200) {
                    await getEmployeeItemsList();
                    setShow(false)
                } else {
                    if (res.data) {
                        toast.dismiss(loadingToastId);
                        const warningData = res.data;

                        if (typeof warningData.message === 'string') {
                            // Single error message
                            toast.warning(`Warning: ${warningData.message}`);
                        } else if (typeof warningData === 'object' && warningData !== null) {
                            // Multiple error messages
                            Object.entries(warningData).forEach(([field, messages]) => {
                                messages.forEach(message => toast.warning(`"${field}": ${message}`));
                            });
                        } else {
                            toast.warning('Warning:- something went wrong !');
                        }
                    } else {
                        toast.warning('Warning couldnt process your request.');
                    }
                }
            } catch (err) {
                //toast Logic
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }

            } finally {
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
            stat_inventory_details: i.stat_inventory_details.id,
        });
    };


    return (
        <>
            <button className="model-edit-button" onClick={handleShow}>
                <UpdatePencil />
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">

                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <h6 className="task-Tab-heading   font-weight500    font-size-heading">Update Issued Item for {i.employee} </h6>

                            <form>

                                <div className="flex-column form-group-selectt">
                                    <label htmlFor="stat_inventory_details" className="form-labels  font-weight500    font-size-subheading">
                                        Item<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        disabled
                                        id="stat_inventory_details"
                                        name="stat_inventory_details"
                                        value={formData.stat_inventory_details.id}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.stat_inventory_details ? "error" : inputState.stat_inventory_details ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select Item</option>
                                        {stockItemList.map((i) => (<>
                                            <option value={i.id}>{i.item_name} - (Stock : {formattedDateLong(i.purchase_date)})</option>
                                        </>))
                                        }
                                    </select>
                                    <div>
                                        {errors.stat_inventory_details && (
                                            <span className="error-message font-size-text ">{errors.stat_inventory_details}</span>
                                        )}
                                    </div>
                                </div>

                                <div className="flex-column">
                                    <label htmlFor="quantity" className="form-labels  font-weight500    font-size-subheading">
                                        Quantity<span className="required">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        min={0}
                                        id="quantity"
                                        name="quantity"
                                        onChange={handleInputChange}
                                        value={formData.quantity}
                                        className={`form-text-trasformation-uppercase form-input ${errors.quantity ? "error" : inputState.quantity ? "success" : ""
                                            }`}
                                    />
                                    {errors.quantity && <span className="error-message font-size-text ">{errors.quantity}</span>}
                                </div>

                                <div className="flex-column">
                                    <label htmlFor="recevied_date" className="form-labels  font-weight500    font-size-subheading">
                                        Issue Date<span className="required">*</span>
                                    </label>
                                    <input
                                        type="date"
                                        id="recevied_date"
                                        name="recevied_date"
                                        onChange={handleInputChange}
                                        value={formData.recevied_date}
                                        className={`form-input ${errors.recevied_date ? "error" : inputState.recevied_date ? "success" : ""
                                            }`}
                                    />
                                    {errors.recevied_date && <span className="error-message font-size-text ">{errors.recevied_date}</span>}
                                </div>
                            </form>
                            <div className="button-models">
                                <button className="model-button   font-weight500   " onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

const DeleteEmployeeStationary = ({ i, getEmployeeItemsList }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
        try {
            let res = await axios.delete(
                `${BASE_URL}/wfm/stationary-employee-details/${i.id}/`
            );

            if (res.status === 200) {
                await getEmployeeItemsList();
                setShow(false);
            } else {
                alert(res);
            }
        } catch (err) {

            //toast Logic
            if (err.response) {
                toast.dismiss(loadingToastId);
                const errorData = err.response.data;

                if (typeof errorData.error === 'string') {
                    // Single error message
                    toast.error(`Error: ${errorData.error}`);
                } else if (typeof errorData === 'object' && errorData !== null) {
                    // Multiple error messages
                    Object.entries(errorData).forEach(([field, messages]) => {
                        messages.forEach(message => toast.error(`"${field}": ${message}`));
                    });
                } else {
                    toast.error('Error:- Failed to Process!');
                }
            } else {
                toast.error('Error processing your request.');
            }
        } finally {
            toast.dismiss(loadingToastId);
        }
    };
    return (
        <>
            <button className="model-delete-button" onClick={handleShow}>
                <DeleteDustbin />
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>Delete {i.employee}'s Issue data of {i.recevied_date}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        Are You Sure You Want to delete issue data of item : {i.stat_inventory_details.item_name}
                        <div className="button-models">
                            <button className="model-button model-button-cancel font-weight500" onClick={handleClose}>
                                Cancel
                            </button>
                            <button
                                onClick={handleFormSubmitDelete}
                                className="model-button   font-weight500    model-button-delete"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

export {
    AddStationaryItem,
    UpdateStationaryItem,
    DeleteStationaryItem,
    AddItemToStock,
    UpdateItemInStock,
    DeleteItemFromStock,
    GiveEmployeeStationary,
    UpdateEmployeeStationary,
    DeleteEmployeeStationary
};
