import axios from 'axios';
import { BASE_URL, FRONTEND_URL } from "../../config/axios";
import React, { useState, useEffect } from 'react';
import { formatDate } from '../Date';



// Main Dashboard Component
const JobInformation1 = () => {

    const [alljobopenings, setAlljobopenings] = useState([]);

    useEffect(() => {
        const getAlljobopenings = async () => {
            try {
                const res = await axios.get(`${BASE_URL}/jd/jobpostfilter/active/${sessionStorage.getItem("company_id")}/`);
                setAlljobopenings(res.data);
            } catch (err) {
                // IMPORTANT: TURN THE BELOW COMMENT OFF!!!
                // alert(err.message);
            }
        };
        getAlljobopenings();
    }, []);



    return (
        <div className='backgroundmain-task'>
            <div className='dashboardcomponent-task width-40vw'>
                <div className="task-heading">
                    <div className='task-sub-heading  font-weight400  font-size-subheading  '>Job Opening</div>

                    <div className='task-hr' ></div>

                    <div className=" EmployeeDashboad-notification-cards EmployeeDashboad-notification-cards-wrap" >

                        {alljobopenings.map((data, index) => {

                            return (
                                <>
                                    <div className="EmployeeDashboad-notification-card dashcomp-bg-card-style">
                                        <h3 className='dashcomp-announcement-heading  font-weight500    font-size-subheading  '>{data.position_name}</h3>
                                        <span className="dashcomp-announcement-date   font-weight500   font-size-subheading  ">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className='dashcomp-announcement-date-svg'>
                                                <rect x="2.66602" y="3.3335" width="10.6667" height="10.6667" rx="2" stroke="#2576BC" stroke-width="2" />
                                                <path d="M10.666 1.3335L10.666 3.3335C10.666 4.2763 10.666 4.74771 10.3731 5.0406C10.0802 5.33349 9.60882 5.33349 8.66602 5.33349L7.33268 5.33349C6.38987 5.3335 5.91847 5.3335 5.62558 5.0406C5.33268 4.74771 5.33268 4.2763 5.33268 3.33349L5.33268 1.3335" stroke="#2576BC" stroke-width="2" stroke-linecap="round" />
                                                <path d="M6 9.3335L10 9.3335" stroke="black" stroke-width="2" stroke-linecap="round" />
                                            </svg>
                                            {formatDate(data.start_date)} - {formatDate(data.end_date)}
                                        </span>
                                        <br />
                                        <p className="dashcomp-announcement-p font-weight400  font-size-text">{data.experience}</p>
                                        <p className="dashcomp-announcement-p font-weight400  font-size-text">{data.job_type}</p>
                                        <p className="dashcomp-announcement-p font-weight400  font-size-text">{data.status}</p>
                                        <p className="dashcomp-announcement-p font-weight400  font-size-text">{data.location}</p>
                                        <div className="dashcomp-announcement-p font-weight400  font-size-text">{data.description}</div>
                                        {/* <a href={`${FRONTEND_URL}/personaldetails/${data.id}/`} target="_blank" rel="noopener noreferrer">Interview From Link</a> */}
                                        {/* <a href={`${FRONTEND_URL}/personaldetails/${data.id}/`} target="_blank" rel="noopener noreferrer">Interview From Link</a> */}
                                        <button
                                            onClick={() => {
                                                navigator.clipboard.writeText(
                                                    `${FRONTEND_URL}/personaldetails/${data.id}/`
                                                );
                                                const notification = document.createElement("div");
                                                notification.classList.add("notification");
                                                notification.textContent =
                                                    "Link copied to clipboard!";
                                                document.body.appendChild(notification);
                                                setTimeout(() => {
                                                    document.body.removeChild(notification);
                                                }, 2000); // Remove notification after 2 seconds
                                            }}
                                            title="Click to Copy Form link"
                                            className="Color-tag-active"
                                        >
                                            Get Link
                                        </button>
                                    </div>
                                </>
                            )
                        })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

// Option Component 2
const JobInformation = () => {
    const [alljobopenings, setAlljobopenings] = useState([]);

    useEffect(() => {
        const getAlljobopenings = async () => {
            try {
                const res = await axios.get(`${BASE_URL}/jd/jobpostfilter/active/${sessionStorage.getItem("company_id")}/`);
                setAlljobopenings(res.data);
            } catch (err) {
                // IMPORTANT: TURN THE BELOW COMMENT OFF!!!
                // alert(err.message);
            }
        };
        getAlljobopenings();
    }, []);

    return (
        <div className='backgroundmain-task'>
            <div className='dashboardcomponent-task width-40vw'>
                <div className="task-heading">
                    <div className='task-sub-heading  font-weight400  font-size-subheading  '>Job Opening</div>

                    <div className='task-hr' ></div>

                    <div className=" EmployeeDashboad-notification-cards EmployeeDashboad-notification-cards-wrap" >

                        {alljobopenings.map((data, index) => {

                            return (
                                <>
                                    <div className=' EmployeeDashboad-notification-card dashcomp-bg-card-style  field-cont-between'>
                                        <div className=' field-cont-div'>
                                            <div className=" ">
                                                <h3 className='dashcomp-announcement-heading  font-weight500    font-size-subheading  '>{data.position_name}</h3>
                                                <span className="dashcomp-announcement-date   font-weight500   font-size-subheading  ">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className='dashcomp-announcement-date-svg'>
                                                        <rect x="2.66602" y="3.3335" width="10.6667" height="10.6667" rx="2" stroke="#2576BC" stroke-width="2" />
                                                        <path d="M10.666 1.3335L10.666 3.3335C10.666 4.2763 10.666 4.74771 10.3731 5.0406C10.0802 5.33349 9.60882 5.33349 8.66602 5.33349L7.33268 5.33349C6.38987 5.3335 5.91847 5.3335 5.62558 5.0406C5.33268 4.74771 5.33268 4.2763 5.33268 3.33349L5.33268 1.3335" stroke="#2576BC" stroke-width="2" stroke-linecap="round" />
                                                        <path d="M6 9.3335L10 9.3335" stroke="black" stroke-width="2" stroke-linecap="round" />
                                                    </svg>
                                                    {formatDate(data.start_date)} - {formatDate(data.end_date)}
                                                </span>
                                                <br />
                                                <div className='flex-jobcard-items'>
                                                    <div className='new-flex'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" id="work">
                                                            <path fill="none" d="M0 0h24v24H0Z"></path>
                                                            <path fill="#525863" d="M19.8 7.2h-3.6V5.4a1.8 1.8 0 0 0-1.8-1.8H9.6a1.8 1.8 0 0 0-1.8 1.8v1.8H4.2A1.8 1.8 0 0 0 2.4 9v9.6a1.8 1.8 0 0 0 1.8 1.8h15.6a1.8 1.8 0 0 0 1.8-1.8V9a1.8 1.8 0 0 0-1.8-1.8ZM9.6 5.4h4.8v1.8H9.6Zm10.2 13.2H4.2V9h15.6Z"></path>
                                                        </svg>
                                                        <p className="dashcomp-announcement-p font-weight400  font-size-text">{data.experience}
                                                        </p>
                                                    </div>
                                                    <div className='new-flex'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 32 32" id="workdesk">
                                                            <path d="M18.5,4h4a.5.5,0,0,0,0-1h-4a.5.5,0,0,0,0,1Zm13,13H26.994a1.452,1.452,0,0,0,.1-.334l.9-8.11A.5.5,0,0,0,27.5,8H26.367a1.507,1.507,0,0,0-1.485,1.288L24.066,15H18.5A1.5,1.5,0,0,0,17,16.5V17H15.722A1.992,1.992,0,0,0,14,14H11.427L8.36,11.548A2.488,2.488,0,0,0,8,11.309V9.5a.472.472,0,0,0-.01-.051A3.941,3.941,0,0,0,9.616,4.318c-.007-.012-.012-.026-.02-.037A3.987,3.987,0,1,0,2.388,7.69l.009.017A4,4,0,0,0,4.01,9.449.472.472,0,0,0,4,9.5v1.551A2.5,2.5,0,0,0,2,13.5V24H1V16.5a.5.5,0,0,0-1,0v15a.5.5,0,0,0,1,0V25H7v6.5a.5.5,0,0,0,1,0V25H9v5a2,2,0,0,0,4,0V22.32a1.5,1.5,0,0,0-1.206-1.471l-1.811-.362A.477.477,0,0,0,10,20.4V17.75l.2.15a.5.5,0,0,0,.3.1H15V31.5a.5.5,0,0,0,1,0V20H29V31.5a.5.5,0,0,0,1,0V18h1.5a.5.5,0,0,0,0-1ZM3,6A2.991,2.991,0,0,1,8.4,4.217l-4,.793A.5.5,0,0,0,4,5.5V7H3.184A2.965,2.965,0,0,1,3,6Zm.78,2H4.5A.5.5,0,0,0,5,7.5V5.911l3.861-.766A2.959,2.959,0,0,1,9,6,2.989,2.989,0,0,1,3.78,8ZM7,9.858v1.159C6.933,11.012,6.866,11,6.8,11H5V9.858A3.954,3.954,0,0,0,6,10,3.954,3.954,0,0,0,7,9.858ZM11.6,21.83a.5.5,0,0,1,.4.49V30a1,1,0,0,1-2,0V24.5a.5.5,0,0,0-.5-.5H3V20.11ZM13,17H10.667L6.8,14.1a.5.5,0,1,0-.6.8L9,17H9v3.29l-6-1.2V13.5A1.5,1.5,0,0,1,4.5,12H6.8a1.5,1.5,0,0,1,.937.329l3.2,2.563a.5.5,0,0,0,.313.109H13Zm1,0V15h0a1,1,0,0,1,0,2Zm4-.5a.5.5,0,0,1,.5-.5h6a.5.5,0,0,0,.5-.429l.877-6.142a.5.5,0,0,1,.5-.429h.574l-.84,7.556a.5.5,0,0,1-.5.444H18ZM29,19H16V18H29ZM16.5,7h2.672l1.475,1.475A.5.5,0,0,0,21,8.621a.513.513,0,0,0,.1-.011.5.5,0,0,0,.362-.311l.5-1.3H24.5A1.5,1.5,0,0,0,26,5.5v-4A1.5,1.5,0,0,0,24.5,0h-8A1.5,1.5,0,0,0,15,1.5v4A1.5,1.5,0,0,0,16.5,7ZM16,1.5a.5.5,0,0,1,.5-.5h8a.5.5,0,0,1,.5.5v4a.5.5,0,0,1-.5.5H21.621a.5.5,0,0,0-.467.321l-.346.9L19.732,6.146A.5.5,0,0,0,19.379,6H16.5a.5.5,0,0,1-.5-.5Z"></path>
                                                        </svg>
                                                        <p className="dashcomp-announcement-p font-weight400  font-size-text">{data.job_type}
                                                        </p>
                                                    </div>
                                                    <div className='new-flex'>
                                                        <svg width="24" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M22 11.0857V12.0057C21.9988 14.1621 21.3005 16.2604 20.0093 17.9875C18.7182 19.7147 16.9033 20.9782 14.8354 21.5896C12.7674 22.201 10.5573 22.1276 8.53447 21.3803C6.51168 20.633 4.78465 19.2518 3.61096 17.4428C2.43727 15.6338 1.87979 13.4938 2.02168 11.342C2.16356 9.19029 2.99721 7.14205 4.39828 5.5028C5.79935 3.86354 7.69279 2.72111 9.79619 2.24587C11.8996 1.77063 14.1003 1.98806 16.07 2.86572M22 4L12 14.01L9 11.01" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                        <p className="dashcomp-announcement-p font-weight400  font-size-text">{data.status}
                                                        </p>
                                                    </div>
                                                </div>
                                                <p className="dashcomp-announcement-p font-weight400  font-size-text">{data.location}</p>
                                                <div className="dashcomp-announcement-p font-weight400  font-size-text">{data.description}</div>
                                            </div>
                                            {/* <a href={`${FRONTEND_URL}/personaldetails/${data.id}/`} target="_blank" rel="noopener noreferrer">Interview From Link</a> */}
                                            {/* <a href={`${FRONTEND_URL}/personaldetails/${data.id}/`} target="_blank" rel="noopener noreferrer">Interview From Link</a> */}
                                        </div>
                                        <div className=' field-cont-div'>
                                            <button
                                                onClick={() => {
                                                    navigator.clipboard.writeText(
                                                        `${FRONTEND_URL}/personaldetails/${data.id}/`
                                                    );
                                                    const notification = document.createElement("div");
                                                    notification.classList.add("notification");
                                                    notification.textContent =
                                                        "Link copied to clipboard!";
                                                    document.body.appendChild(notification);
                                                    setTimeout(() => {
                                                        document.body.removeChild(notification);
                                                    }, 2000); // Remove notification after 2 seconds
                                                }}
                                                title="Click to Copy Form link"
                                                className="Color-tag-active-job"
                                            >
                                                Get Link
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )
                        })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

// Option Component 3
const JobInformation3 = () => {

    const [alljobopenings, setAlljobopenings] = useState([]);

    useEffect(() => {
        const getAlljobopenings = async () => {
            try {
                const res = await axios.get(`${BASE_URL}/jd/jobpostfilter/active/${sessionStorage.getItem("company_id")}/`);
                setAlljobopenings(res.data);
            } catch (err) {
                // IMPORTANT: TURN THE BELOW COMMENT OFF!!!
                // alert(err.message);
            }
        };
        getAlljobopenings();
    }, []);



    return (
        <div className='backgroundmain-task'>
            <div className='dashboardcomponent-task width-40vw'>
                <div className="task-heading">
                    <div className='task-sub-heading  font-weight400  font-size-subheading  '>Job Opening</div>


                </div>
                <div className='task-hr' ></div>

                <div style={{ gap: '1em' }} className="EmployeeDashboad-notification-cards EmployeeDashboad-notification-cards-wrap" >

                    {alljobopenings.map((data, index) => {

                        return (
                            <>
                                <div className="EmployeeDashboad-notification-card" key={index}>
                                    <h6 className="dashcomp-announcement-heading  font-weight500   ">
                                        {index + 1}. {data.position_name}
                                    </h6>
                                    <p className="dashcomp-announcement-p font-weight400  font-size-text">{data.experience}</p>
                                    <p className="dashcomp-announcement-p font-weight400  font-size-text">{data.job_type}</p>
                                    <p className="dashcomp-announcement-p font-weight400  font-size-text">{data.status}</p>
                                    <p className="dashcomp-announcement-p font-weight400  font-size-text">{data.location}</p>
                                    <p className="dashcomp-announcement-p font-weight400  font-size-text">{data.description}</p>
                                    <a
                                        href={`${FRONTEND_URL}/personaldetails/${data.id}/`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="dashcomp-announcement-link"
                                    >
                                        Interview Form Link
                                    </a>
                                </div>
                            </>
                        )
                    })
                    }
                </div>
            </div>
        </div>
    )
}
export default JobInformation;