
import React, { useState, useEffect } from 'react';
import { useCalendar } from './CalenderContext';
import CreateEventModal from './CreateEventModal';
import { ViewMeetingModal } from "./CreateMeetingModal"



const DayView = () => {
  const { selectedDate, events, showModal, setShowModal, timeSlots, selectedTime, selectedTimeSlot, setSelectedTimeSlot, handleTimeSlotClick, modaltoogleOption, setCurrentTime, currentTime,
    handlechooseToogleoption, timeRange,
    handleTimeRangeChange,
    timeRangeOptions,
    formatTime, formatDate, meetingType, handleSelectMeetingType, showModalmeeting,
    closeMeetingModal, getMeetingData, meetingData } = useCalendar();


  const [timeSlotMeetings, setTimeSlotMeetings] = useState({});
  useEffect(() => {
    getMeetingData();
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);

    return () => clearInterval(timer);
  }, []);
  console.log(meetingData)
  useEffect(() => {
    if (meetingData != "") {
      const meetingsByTimeSlot = {};

      meetingData.forEach((event) => {
        const eventDate = new Date(event.meeting_date);
        const eventStartTime = new Date(`${selectedDate.toLocaleDateString()} ${event.start_time}`);
        const eventStartHour = eventStartTime.getHours();


        if (
          eventDate.getDate() === selectedDate.getDate() &&
          eventDate.getMonth() === selectedDate.getMonth() &&
          eventDate.getFullYear() === selectedDate.getFullYear()
        ) {

          if (!meetingsByTimeSlot[eventStartHour]) {
            meetingsByTimeSlot[eventStartHour] = [];
          }
          meetingsByTimeSlot[eventStartHour].push(event);
        }
      });

      setTimeSlotMeetings(meetingsByTimeSlot);
    }
  }, [selectedDate, meetingData]);


  const itemStyle = {
    // width: `${98 / totalMeetings}%`,
    // marginLeft: `${(index * 100) / totalMeetings}%`,
    // zIndex: index + 1,
  };

  const [showViewmeetingModal, setViewMeetingModal] = useState(false)
  const [eventId, setEventid] = useState(false)
  const handleShow = (id) => {
    setViewMeetingModal(true)
    setEventid(id)
  }

  const handleclose = () => {
    setViewMeetingModal(false)
  }

  return (
    <div className="day-view">
      <div className="day-header">
        <h2>{formatDate(selectedDate)}</h2>
      </div>
      <div className="time-grid">
        <div className="time-labels">
          {timeSlots.map(hour => (
            <div key={hour} className="time-label">
              {String(hour).padStart(2, '0')}:00
            </div>
          ))}
        </div>
        <div className="time-slots">
          {timeSlots.map(hour => {
            const meetingsAtHour = timeSlotMeetings[hour] || [];
            return (
              <div key={hour} className="time-slot"

                onClick={() => {
                  if (meetingsAtHour.length === 0) {
                    handleTimeSlotClick(hour);
                  }
                }}
              >
                {meetingsAtHour.length > 0 && (

                  <ViewMeetingModal showViewmeetingModal={showViewmeetingModal} handleShow={handleShow} handleclose={handleclose} meetingData={meetingsAtHour} selectedTimeslot={selectedTimeSlot} getMeetingData={getMeetingData} modaltoogleOption="meeting" view="day" />
                )}
              </div>
            )
          })}
        </div>
      </div>
      <CreateEventModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        selectedTime={selectedTimeSlot}
        selectedDate={selectedDate}
        modaltoogleOption={modaltoogleOption}
        handlechooseToogleoption={handlechooseToogleoption}
        timeRange={timeRange}
        timeRangeOptions={timeRangeOptions}
        handleTimeRangeChange={handleTimeRangeChange}
        formatTime={formatTime}
        formatDate={formatDate}
        meetingType={meetingType}
        handleSelectMeetingType={handleSelectMeetingType}
        showModalmeeting={showModalmeeting}
        closeMeetingModal={closeMeetingModal}
        getMeetingData={getMeetingData}
        selectedTimeslot={selectedTimeSlot}
        timeSlots={timeSlots}
      />
    </div>
  );
};

export default DayView;
