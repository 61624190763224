// config/permissions.js
import { useState, useEffect } from 'react';
import { BASE_URL } from './axios';
import axios from 'axios';
import { toast } from "react-toastify";

const usePermission = () => {
  const [PERMISSION_ROLE, setPermissionRole] = useState(false);
  const [PERMISSION_AUTHORITY, setPermissionAuthority] = useState(false);
  const [PERMISSION_HR_ADMIN, setPermissionHRAdmin] = useState(false);
  const [SUPER_ADMIN, setSuperAdmin] = useState(false);
  const [PERMISSION_RH, setPermissionIsRH] = useState(false);
  const [SUB_COMPANIES, setSubCompanies] = useState([]);

  const updatePermissions = () => {
    const role = sessionStorage.getItem('role');
    const code = sessionStorage.getItem('emp_code');
    const isRH = sessionStorage.getItem('is_rh');
    setPermissionRole(role === 'HR' || role === 'Admin');
    setPermissionAuthority(role === 'Admin' || role === 'SuperAdmin');
    setPermissionHRAdmin(code === 'CIPL1' || code === 'CIPL6' || code === 'CIPL10001');
    setSuperAdmin(code === 'CIPL1' || code === 'CIPL10001' || code === 'CIPL10007');
    setPermissionIsRH(isRH);
  };
  const getSubCompany = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/subcompanyfilterbysubcompanycount/null/active/`
      );
      setSubCompanies(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    }
  };
  useEffect(() => {
    updatePermissions();
    getSubCompany();
    const handleStorageChange = () => {
      updatePermissions();
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return { PERMISSION_ROLE, PERMISSION_AUTHORITY, PERMISSION_HR_ADMIN, SUPER_ADMIN, PERMISSION_RH, updatePermissions, SUB_COMPANIES };
};

export default usePermission;
