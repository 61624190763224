import React, { useEffect, useState } from 'react'
import Dashboardnavbarcopy from '../../layout/Dashboardnavbarcopy';
import { handleAllError } from '../CustomFunctions';
import { BASE_URL } from '../../config/axios';
import axios from 'axios';
import { formattedDateLong } from '../Date';


const PortalSettings = () => {

    const [settings, setPortalSettings] = useState({});
    const [buffer, setBuffering] = useState(true); //buffering logic

    const getSettings = async () => {
        try {
            setBuffering(true); //buffering logic // Start Buffering
            const res = await axios.get(`${BASE_URL}/wfm/assets/`);
            setPortalSettings(res.data);
        } catch (err) {
            handleAllError(err)
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };

    useEffect(() => {
        getSettings();
    }, []);


    const [formData, setFormData] = useState({
        day_limit_leave_min: "",
        day_limit_leave_max: "",
        day_limit_outduty_min: "",
        day_limit_outduty_max: "",
        request_approval: "",
        add_leaves_date: "",
        sandwich_rule: "",
        employee_details: "",

    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: checked,
        }));

    };


    return (
        <>
            <div className="table-css-white-background">
                <div className="table-heading-flex">
                    <div className="repo-heading font-weight500 font-size-heading">Settings:</div>
                    <div>
                        {formData !== settings ?

                            <button
                                type="submit"
                                className="model-button model-button-submit"
                            // onClick={handleFormSubmit}
                            // disabled={loading}
                            >
                                Submit
                            </button>
                            : null}

                    </div>
                </div>
                <ol>
                    <li>
                        <div className="flex-row">
                            <label className="form-labels font-weight500 announce-date">
                                Days Limit for Applying Leave:
                            </label>
                            {" "}
                            <label className="form-labels font-weight400 announce-date">
                                <div className="field-cont-div">
                                    <hr className="field-cont-hr" />
                                    <input
                                        id='day_limit_leave_min'
                                        name='day_limit_leave_min'
                                        className="attendance-input-field width-5vw date-field"
                                        placeholder="Before"
                                        type="number"
                                        value={formData.day_limit_leave_min}
                                        onChange={handleInputChange}
                                    />
                                    <hr className="field-cont-hr" />
                                </div>
                            </label>
                            {"<"}
                            <label className="form-labels font-weight400 announce-date">
                                <div className="field-cont-div">
                                    <hr className="field-cont-hr" />
                                    <input
                                        id='day_limit_leave_max'
                                        name='day_limit_leave_max'
                                        className="attendance-input-field width-5vw date-field"
                                        placeholder="After"
                                        type="number"
                                        value={formData.day_limit_leave_max}
                                        onChange={handleInputChange}
                                    />
                                    <hr className="field-cont-hr" />
                                </div>
                            </label>
                        </div>
                    </li>

                    <li>
                        <div className="flex-row">
                            <label className="form-labels font-weight500 announce-date">
                                Days Limit for Applying OutDuty:
                            </label>
                            <label className="form-labels font-weight400 announce-date">
                                <div className="field-cont-div">
                                    <hr className="field-cont-hr" />
                                    <input
                                        id='day_limit_outduty_min'
                                        name='day_limit_outduty_min'
                                        className="attendance-input-field width-5vw date-field"
                                        placeholder="Before"
                                        type="number"
                                        value={formData.day_limit_outduty_min}
                                        onChange={handleInputChange}
                                    />
                                    <hr className="field-cont-hr" />
                                </div>
                            </label>
                            {"<"}
                            <label className="form-labels font-weight400 announce-date">
                                <div className="field-cont-div">
                                    <hr className="field-cont-hr" />
                                    <input
                                        id='day_limit_outduty_max'
                                        name='day_limit_outduty_max'
                                        className="attendance-input-field width-5vw date-field"
                                        placeholder="After"
                                        type="number"
                                        value={formData.day_limit_outduty_max}
                                        onChange={handleInputChange}
                                    />
                                    <hr className="field-cont-hr" />
                                </div>
                            </label>
                        </div>
                    </li>

                    <li>
                        <div className="flex-row">
                            <label className="form-labels font-weight500 announce-date">
                                Leaves Add Date
                            </label>
                            <label className="form-labels font-weight400 announce-date">
                                <div className="field-cont-div">
                                    <hr className="field-cont-hr" />
                                    <input
                                        id='add_leaves_date'
                                        name='add_leaves_date'
                                        className="attendance-input-field width-10vw date-field"
                                        placeholder="To Date"
                                        type="date"
                                        value={formData.add_leaves_date}
                                        onChange={handleInputChange}
                                    />
                                    <hr className="field-cont-hr" />
                                </div>
                                {/* {formattedDateLong(settings.add_leaves_date)} */}
                            </label>
                        </div>
                    </li>

                    <li>
                        <div className="flex-row">
                            <div className="flex-row ">
                                <label htmlFor="" className="form-labels  font-weight500    font-size-subheading">
                                    Request Approval by {formData.request_approval ? "HR" : "Admin"}
                                </label>
                                <hr style={{ marginTop: '10px' }} className="field-cont-hr" />
                                <div className="checkbox-toggle">
                                    <input
                                        type="checkbox"
                                        id="toggle-requests"
                                        name="request_approval"
                                        checked={formData.request_approval}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label htmlFor="toggle-requests"></label>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div className="flex-row">
                            <div className="flex-row ">
                                <label htmlFor="" className="form-labels  font-weight500    font-size-subheading">
                                    Sandwich Rule {formData.sandwich_rule ? "with" : "without"} Holidays
                                </label>
                                <hr style={{ marginTop: '10px' }} className="field-cont-hr" />
                                <div className="checkbox-toggle">
                                    <input
                                        type="checkbox"
                                        id="toggle-sandwich"
                                        name="sandwich_rule"
                                        checked={formData.sandwich_rule}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label htmlFor="toggle-sandwich"></label>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div className="flex-row ">
                            <label htmlFor="" className="form-labels  font-weight500    font-size-subheading">
                                Employee Details {formData.employee_details ? "" : "non-"}Editable
                            </label>
                            <hr style={{ marginTop: '10px' }} className="field-cont-hr" />
                            <div className="checkbox-toggle">
                                <input
                                    type="checkbox"
                                    id="toggle-empdetails"
                                    name="employee_details"
                                    checked={formData.employee_details}
                                    onChange={handleCheckboxChange}
                                />
                                <label htmlFor="toggle-empdetails"></label>
                            </div>
                        </div>
                    </li>
                </ol>
            </div>
        </>
    )
}

export default PortalSettings
