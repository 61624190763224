// import React from 'react'
import React, { useEffect, useRef, useState } from "react";
import { BASE_URL } from "../../config/axios";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import { customSortByKey, downloadAsExcel, formatCurrencyIndian, formatMonthYear, handleErrorToast } from "../CustomFunctions";
import { ToastContainer } from "react-toastify";
import { Active, SearchSmall, CircularGrid, DownloadIcon, Employee, Eye, Filter, Office } from "../AllSvg";
import { formatDateTime, formattedDateLong } from "../Date";
import { handleAllError } from "../CustomFunctions";
import { AdvanceFormAccFinalize, AdvanceFormAdminApproval, AdvanceFormAdminRejection, AdvanceFormHODApproval, AdvanceFormHODRejection, AdvanceFormHRApproval, AdvanceFormHRRejection, AdvanceFormRHApproval, AdvanceFormRHRejection, ApplyAdvanceForm } from "./AdvanceComponents";
import { ApplySeprationForm } from "../SeprationManagement/SeprationComponents";

const EmployeeAdvanceSlipsTable = ({ title, email, emp_code }) => {

  const [userData, setUserData] = useState({})
  const [allrequest, setRequestData] = useState([]);
  const [buffer, setBuffering] = useState(true); //buffering logic

  const getUSerDetails = async () => {
    setBuffering(true); // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/wfm/ourcompanydetailsbyemp/${emp_code}/`);
      setUserData(res.data);

    } catch (err) {
      handleAllError(err)
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  const getAllrequest = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/wfm/advanceformsbyemployee/${emp_code}/`);
      setRequestData(res.data);
    } catch (err) {
      //   handleError
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  useEffect(() => {
    getUSerDetails();
    getAllrequest();
  }, []);

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

      <div className="table-css-white-background">
        <div className="table-heading-flex">
          <div className="repo-heading font-weight500 font-size-heading"> Advance Slip Request</div>
          <div>
            {userData.advance_form ?
              <div className="field-cont">
                Apply for Advance:
                <ApplyAdvanceForm
                  code={emp_code}
                  getAdvanceData={getAllrequest}
                />
              </div>
              : null}
            {/* {userData.sepration_form ?
              <div className="field-cont">
                Apply for Sepration:
                <ApplySeprationForm
                  code={emp_code}
                  getAdvanceData={getAllrequest}
                />
              </div>
              : null} */}
          </div>
        </div>
        <table className="table-css">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="table-heading">Sr no.</th>
              <th className="table-heading">Request Date</th>
              <th className="table-heading text-center">Advance Amount</th>
              <th className="table-heading width-10vw">Reason</th>
              <th className="table-heading text-center width-10vw">Approved Details</th>
              <th className="table-heading text-center width-5vw">Status</th>
              {/* <th className="table-heading text-center">Actions</th> */}
            </tr>
          </thead>
          {buffer ? <div className="spinner"></div> :
            <tbody>
              {allrequest
                ?.sort((a, b) => new Date(b.date_of_request) - new Date(a.date_of_request))
                .map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="custom-table-head-td">
                      <td className="table-body">{index + 1}</td>
                      <td className="table-body">
                        {i.date_of_request ? formattedDateLong(i.date_of_request) : '-'}
                      </td>
                      <td className="table-body text-center">
                        {i.advance_amount ? formatCurrencyIndian(i.advance_amount) : '-'}
                      </td>
                      <td className="table-body width-10vw">{i.reason_for_taking_advance}</td>
                      <td className="table-body">
                        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                          <tbody>
                            <tr>
                              <td className="table-body" style={{ paddingBottom: "5px" }}>Number of Installments</td>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right">{i.number_of_installments ? i.number_of_installments : '-'}</td>
                            </tr>
                            <tr>
                              <td className="table-body" style={{ paddingBottom: "5px" }}>Installment Timeline</td>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right">
                                {i.installment_start_month && i.installment_end_month ? `From: ${formatMonthYear(i.installment_start_month)} To: ${formatMonthYear(i.installment_end_month)}` : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td className="table-body" style={{ paddingBottom: "5px" }}>Total Amount to Claim</td>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right">{formatCurrencyIndian(i.total_amount_claimed)}</td>
                            </tr>

                          </tbody>
                        </table>
                      </td>
                      <td className="table-body width-5vw">
                        <span
                          request-status={i.hod_approval}
                          className="request-status"
                          title={`${i.hod_approval === "rejected" ? `HOD Reason For Rejection : \n${i.hod_log_details}` : ''}`}
                        >
                          {i.hod_approval === "pending"
                            ? "Pending For HOD Approval ⌛"
                            : i.hod_approval === "rejected"
                              ? <>HOD ✖,<br />HOD Reject Reason:<br />"{i.hod_log_details}"</>
                              : "Approved By HOD ✔,"}
                        </span>
                        {/* <br /> */}
                        <br />
                        <span
                          request-status={i.admin_approval}
                          className="request-status"
                          title={`${i.admin_approval === "rejected" ? `Admin Reason: ${i.admin_log_details}` : ''}`}
                        >
                          {i.admin_approval === "rejected"
                            ? <>Admin ✖,<br />Admin Reject Reason: "{i.admin_log_details}"</>
                            :
                            i.admin_approval === "pending"
                              ? "Pending For Admin Approval ⌛,"
                              :
                              i.admin_approval === "approved"
                                ? "Approved By Admin ✔,"
                                : "Admin Status: " + i.admin_approval}
                        </span>
                        {/* <br /> */}
                        <br />
                        <span
                          request-status={i.hr_approval}
                          className="request-status"
                          title={`${i.hr_approval === "rejected" ? `HR Reason: ${i.hr_log_details}` : ''}`}
                        >
                          {i.hr_approval === "rejected"
                            ? <>HR ✖,<br />HR Reject Reason:<br />"{i.hr_log_details}"</>
                            : i.hr_approval === "pending"
                              ? "Pending For HR Approval ⌛"
                              :
                              i.hr_approval === "approved"
                                ? "Approved By HR ✔,"
                                : "HR Status : " + i.hr_approval}
                        </span>
                        {/* <br /> */}
                        <br />
                        <span
                          request-status={i.account_approval}
                          className="request-status"
                          title={`${i.account_approval === "rejected" ? `Accounts Reason: ${i.acc_log_details}` : ''}`}
                        >
                          {i.account_approval === "rejected"
                            ? <>Accounts ✖,<br />Accounts Reject Reason:<br />"{i.acc_log_details}"</>
                            : i.account_approval === "pending"
                              ? "Pending For Accounts Approval ⌛"
                              :
                              i.account_approval === "approved"
                                ? <>
                                  <span style={{ cursor: "help" }} title={`Transaction/Cheque No.:\n${i.account_approval !== "pending" ? i.transaction_id : null}`}>
                                    Accounts Processed ✔,
                                  </span>
                                  {/* <span style={{ cursor: "help" }} title={`Transaction/Cheque No.:\n${i.account_approval !== "pending" ? i.transaction_id : null}`}>

                                    {i.account_approval === "approved" ? "Process Completed" : ""}

                                    <br />
                                    {i.file_upload ? (
                                      <a className="modal-button-black" href={i.file_upload} target="blank">
                                        View
                                      </a>
                                    ) :
                                      ("No Proof Attached")
                                    }
                                  </span> */}
                                </>
                                : "Accounts Status : " + i.account_approval}
                        </span>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
            </tbody>
          }
        </table>
      </div>
    </>
  );
};

const AdvanceFormsTableRH = () => {

  const [buffer, setBuffering] = useState(true); //buffering logic

  const [toggleStatus, setToggleStatus] = useState("pending");

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // *********** Employee Details Api Start ***********
  const [advanceFormList, setAdvanceFormList] = useState([]);

  const getAdvanceFormListRH = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        // `${BASE_URL}/wfm/advancefilterbyrh/${sessionStorage.getItem("emp_code")}/${toggleStatus}/`
        `${BASE_URL}/wfm/advancefilterbyhod/null/${toggleStatus}/`
      );
      const sortedData = customSortByKey(res.data, "emp_code");
      setAdvanceFormList(sortedData);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  // *********** Employee Details Api End ***********

  useEffect(() => {
    getAdvanceFormListRH();
  }, [toggleStatus]);

  return (
    <>
      <div className="attendance-history-cont">

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500    font-size-heading">Advance Requests</div>
            <div className="btn-cont">
              <div className="field-cont">
                {<div className="table-searchh dropdown-container field-cont-div">
                  <Filter />
                  <select onChange={(e) => setToggleStatus(e.target.value)} value={toggleStatus} className="dropdown font-size-subheading  ">
                    <option value="null">All Reqests</option>
                    <option value="pending">Pending</option>
                    <option value="approved">Approved</option>
                    <option value="rejected">Rejected</option>
                  </select>
                </div>}
                <div className="table-searchh">
                  <div>
                    <SearchSmall />
                  </div>
                  <div>
                    <input
                      placeholder="Search"
                      className="dropdown font-size-subheading   navbar-inputt"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <table className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="table-heading">Sr no.</th>
                <th className="table-heading">Employee</th>
                <th className="table-heading">Request Date</th>
                <th className="table-heading text-center">Advance Amount</th>
                <th className="table-heading width-10vw">Reason</th>
                <th className="table-heading text-center width-5vw">Status</th>
                <th className="table-heading text-center">Actions</th>
              </tr>
            </thead>
            {buffer ? (
              <div className="spinner"></div>
            ) : (
              <tbody>
                {advanceFormList
                  .sort((a, b) => new Date(a.date_of_request) - new Date(b.date_of_request))
                  .map((i, index) => (
                    <React.Fragment key={index}>
                      <tr className="custom-table-head-td">
                        <td className="table-body">{index + 1}</td>
                        <td className="table-body">{i.emp_code} - {i.emp_name}</td>
                        <td className="table-body">
                          {i.date_of_request ? formattedDateLong(i.date_of_request) : '-'}
                        </td>
                        <td className="table-body text-center">
                          {i.advance_amount ? formatCurrencyIndian(i.advance_amount) : '-'}
                        </td>
                        <td className="table-body width-10vw">{i.reason_for_taking_advance}</td>

                        <td className="table-body width-5vw ">
                          <span
                            request-status={i.hod_approval}
                            className="request-status"
                            title={`${i.hod_approval === "rejected" ? `HOD Reason For Rejection : \n${i.hod_log_details}` : ''}`}
                          >
                            {i.hod_approval === "pending"
                              ? "Pending For HOD Approval ⌛"
                              : i.hod_approval === "rejected"
                                ? <>HOD ✖,<br />HOD Reject Reason:<br />"{i.hod_log_details}"</>
                                : "Approved By HOD ✔,"}
                          </span>
                          {/* <br /> */}
                          <br />
                          <span
                            request-status={i.admin_approval}
                            className="request-status"
                            title={`${i.admin_approval === "rejected" ? `Admin Reason: ${i.admin_log_details}` : ''}`}
                          >
                            {i.admin_approval === "rejected"
                              ? <>Admin ✖,<br />Admin Reject Reason: "{i.admin_log_details}"</>
                              :
                              i.admin_approval === "pending"
                                ? "Pending For Admin Approval ⌛,"
                                :
                                i.admin_approval === "approved"
                                  ? "Approved By Admin ✔,"
                                  : "Admin Status: " + i.admin_approval}
                          </span>
                          {/* <br /> */}
                          <br />
                          <span
                            request-status={i.hr_approval}
                            className="request-status"
                            title={`${i.hr_approval === "rejected" ? `HR Reason: ${i.hr_log_details}` : ''}`}
                          >
                            {i.hr_approval === "rejected"
                              ? <>HR ✖,<br />HR Reject Reason:<br />"{i.hr_log_details}"</>
                              : i.hr_approval === "pending"
                                ? "Pending For HR Approval ⌛"
                                :
                                i.hr_approval === "approved"
                                  ? "Approved By HR ✔,"
                                  : "HR Status : " + i.hr_approval}
                          </span>
                          {/* <br /> */}
                          <br />
                          <span
                            request-status={i.account_approval}
                            className="request-status"
                            title={`${i.account_approval === "rejected" ? `Accounts Reason: ${i.acc_log_details}` : ''}`}
                          >
                            {i.account_approval === "rejected"
                              ? <>Accounts ✖,<br />Accounts Reject Reason:<br />"{i.acc_log_details}"</>
                              : i.account_approval === "pending"
                                ? "Pending For Accounts Approval ⌛"
                                :
                                i.account_approval === "approved"
                                  ? "Accounts Processed ✔,"
                                  : "Accounts Status : " + i.account_approval}
                          </span>
                        </td>
                        <td className="table-body text-center">
                          <AdvanceFormRHApproval i={i} getAdvanceData={getAdvanceFormListRH} />
                          <AdvanceFormRHRejection i={i} getAdvanceData={getAdvanceFormListRH} />
                        </td>

                      </tr >
                    </React.Fragment >
                  ))}
              </tbody >
            )}
          </table >
        </div >
      </div >
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

const AdvanceFormsTableHOD = () => {

  const [buffer, setBuffering] = useState(true); //buffering logic

  const [selectedDepartment, setDepartment] = useState(
    `${sessionStorage.getItem("department")}`
  );

  const [selectedSubCompany, setSelectedSubCompany] = useState(
    `${sessionStorage.getItem("company_id")}`
  );


  const [toggleStatus, setToggleStatus] = useState("pending");
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // *********** Employee Details Api Start ***********
  const [employeeList, setEmployeeList] = useState([]);
  const [advanceFormList, setAdvanceFormList] = useState([]);

  const getEmpList = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/ourcompanyempdetails/active/${selectedDepartment}/`);
      const sortedData = customSortByKey(res.data, "emp_code");
      setEmployeeList(sortedData);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  const getAdvanceFormListHOD = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/advancefilterbyhod/${selectedDepartment}/${toggleStatus}/ `
      );
      const sortedData = customSortByKey(res.data, "emp_code");
      setAdvanceFormList(sortedData);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  // *********** Employee Details Api End ***********

  useEffect(() => {
    getAdvanceFormListHOD();
    // getEmpList();
  }, [selectedSubCompany, toggleStatus]);



  return (
    <>
      <div className="attendance-history-cont">

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500    font-size-heading">Advance Requests</div>
            <div className="btn-cont">
              <div className="field-cont">
                {<div className="table-searchh dropdown-container field-cont-div">
                  <Filter />
                  <select onChange={(e) => setToggleStatus(e.target.value)} value={toggleStatus} className="dropdown font-size-subheading  ">
                    <option value="null">All Reqests</option>
                    <option value="pending">Pending</option>
                    <option value="approved">Approved</option>
                    <option value="rejected">Rejected</option>
                  </select>
                </div>}
                <div className="table-searchh">
                  <div>
                    <SearchSmall />
                  </div>
                  <div>
                    <input
                      placeholder="Search"
                      className="dropdown font-size-subheading   navbar-inputt"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <table className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="table-heading">Sr no.</th>
                <th className="table-heading">Employee</th>
                <th className="table-heading">Request Date</th>
                <th className="table-heading text-center">Advance Amount</th>
                <th className="table-heading width-10vw">Reason</th>
                <th className="table-heading text-center width-5vw">Status</th>
                <th className="table-heading text-center">Actions</th>
              </tr>
            </thead>
            {buffer ? (
              <div className="spinner"></div>
            ) : (
              <tbody>
                {advanceFormList
                  ?.filter(
                    (e) =>
                      e.emp_name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                      e.emp_code
                        .toString()
                        .includes(searchQuery.toLowerCase())
                  )
                  .sort((a, b) => new Date(a.date_of_request) - new Date(b.date_of_request))
                  .map((i, index) => (
                    <React.Fragment key={index}>
                      <tr className="custom-table-head-td">
                        <td className="table-body">{index + 1}</td>
                        <td className="table-body">{i.emp_code} - {i.emp_name}</td>
                        <td className="table-body">
                          {i.date_of_request ? formattedDateLong(i.date_of_request) : '-'}
                        </td>
                        <td className="table-body text-center">
                          {i.advance_amount ? formatCurrencyIndian(i.advance_amount) : '-'}
                        </td>
                        <td className="table-body width-10vw">{i.reason_for_taking_advance}</td>
                        <td className="table-body width-5vw ">
                          <span
                            request-status={i.hod_approval}
                            className="request-status"
                            title={`${i.hod_approval === "rejected" ? `HOD Reason For Rejection : \n${i.hod_log_details}` : ''}`}
                          >
                            {i.hod_approval === "pending"
                              ? "Pending For HOD Approval ⌛"
                              : i.hod_approval === "rejected"
                                ? <>HOD ✖,<br />HOD Reject Reason:<br />"{i.hod_log_details}"</>
                                : "Approved By HOD ✔,"}
                          </span>
                          {/* <br /> */}
                          <br />
                          <span
                            className="request-status"
                            request-status={i.admin_approval}
                            title={`${i.admin_approval === "rejected" ? `Admin Reason: ${i.admin_log_details}` : ''}`}
                          >
                            {i.admin_approval === "rejected"
                              ? <>Admin ✖,<br />Admin Reject Reason: "{i.admin_log_details}"</>
                              :
                              i.admin_approval === "pending"
                                ? "Pending For Admin Approval ⌛,"
                                :
                                i.admin_approval === "approved"
                                  ? "Approved By Admin ✔,"
                                  : "Admin Status: " + i.admin_approval}
                          </span>
                          {/* <br /> */}
                          <br />
                          <span
                            request-status={i.hr_approval}
                            className="request-status"
                            title={`${i.hr_approval === "rejected" ? `HR Reason: ${i.hr_log_details}` : ''}`}
                          >
                            {i.hr_approval === "rejected"
                              ? <>HR ✖,<br />HR Reject Reason:<br />"{i.hr_log_details}"</>
                              : i.hr_approval === "pending"
                                ? "Pending For HR Approval ⌛"
                                :
                                i.hr_approval === "approved"
                                  ? "Approved By HR ✔,"
                                  : "HR Status : " + i.hr_approval}
                          </span>
                          {/* <br /> */}
                          <br />
                          <span
                            request-status={i.account_approval}
                            className="request-status"
                            title={`${i.account_approval === "rejected" ? `Accounts Reason: ${i.acc_log_details}` : ''}`}
                          >
                            {i.account_approval === "rejected"
                              ? <>Accounts ✖,<br />Accounts Reject Reason:<br />"{i.acc_log_details}"</>
                              : i.account_approval === "pending"
                                ? "Pending For Accounts Approval ⌛"
                                :
                                i.account_approval === "approved"
                                  ? "Accounts Processed ✔,"
                                  : "Accounts Status : " + i.account_approval}
                          </span>
                        </td>
                        <td className="table-body text-center">
                          <AdvanceFormHODApproval i={i} getAdvanceData={getAdvanceFormListHOD} />
                          <AdvanceFormHODRejection i={i} getAdvanceData={getAdvanceFormListHOD} />
                        </td>

                      </tr >
                    </React.Fragment >
                  ))}
              </tbody >
            )}
          </table >
        </div >
      </div >
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

const AdvanceFormsTableAdmin = () => {

  const [buffer, setBuffering] = useState(true);

  const [buffer1, setBuffering1] = useState(true);
  const [employeeList, setEmployeeList] = useState([]);
  const [timeStampView, setTimeStampView] = useState(false);

  const [employeeGet, setEmployeeGet] = useState("");
  const [active, setActive] = useState("active");

  const [selectedDepartment, setDepartment] = useState(``);
  const [advanceFilteredList, setAdvanceFilteredList] = useState([]);

  const [toggleStatus, setToggleStatus] = useState("pending");
  const [selectedSubCompany, setSelectedSubCompany] = useState(
    `${sessionStorage.getItem("company_id")}`
  );

  const [SubCompany, setSubCompany] = useState([]);

  const getSubCompany = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/subcompany/`);
      setSubCompany(res.data);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  const [departmentname, setDepartmentname] = useState([]);

  const getDepartment = async () => {
    try {
      setBuffering1(true)
      const dep = await axios.get(`${BASE_URL}/wfm/departmentfilterbysubcompanycount/${selectedSubCompany || 'null'}/active/`);
      setDepartmentname(dep.data)
    } catch (err) {
    } finally {
      setBuffering1(false)
    }
  };

  // *********** Employee Details Api Start ***********
  const [advanceFormList, setAdvanceFormList] = useState([]);

  const getEmpList = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/ourcompanyempdetails/${active}/${selectedSubCompany}/`);
      const sortedData = customSortByKey(res.data, "emp_code");
      setEmployeeList(sortedData);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  const getAdvanceFormListAdmin = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/advancefilterbyadmin/${selectedSubCompany}/${toggleStatus}/ `
      );
      const sortedData = customSortByKey(res.data, "emp_code");
      setAdvanceFormList(sortedData);
      // setAdvanceFormList(res.data);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  // *********** Employee Details Api End ***********

  const applyFilters = () => {
    let filteredData = advanceFormList;

    if (selectedDepartment !== "") {
      filteredData = filteredData.filter(
        (employee) => employee.department === selectedDepartment
      );
    }

    if (employeeGet !== "") {
      filteredData = filteredData.filter(
        (employee) => employee.emp_code === employeeGet
      );
    }

    setAdvanceFilteredList(filteredData);
  };

  useEffect(() => {
    getAdvanceFormListAdmin();
  }, [toggleStatus, selectedSubCompany]);

  useEffect(() => {
    applyFilters();
  }, [advanceFormList, selectedDepartment, employeeGet]);

  useEffect(() => {
    getEmpList();
    getDepartment();
    getSubCompany();
  }, [selectedSubCompany]);


  const tableRef = useRef(null);
  const handleDownloadAdvanceRequests = () => {
    downloadAsExcel(tableRef, "advance",
      selectedDepartment === "" ? `AdvanceRequests` :
        `AdvanceRequests`);
  };
  return (
    <>
      <div className="attendance-history-cont">
        <div className="attendance-subcont">
          <div className="field-cont">

            <div className="field-cont-div">
              <Office />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedSubCompany}
                onChange={(e) => setSelectedSubCompany(e.target.value)}
              >
                {/* <option value="">All Sub Companies</option> */}
                {SubCompany.sort((a, b) => a.title - b.title).map((i, index) => (
                  <option value={i.id}>{i.title}</option>
                ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <CircularGrid />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedDepartment}
                onChange={(e) => setDepartment(e.target.value)}
              >
                <option value="">All Department</option>
                {departmentname
                  .sort((a, b) => a.title - b.title)
                  .map((i, index) => (
                    <option value={i.id}>{i.title}</option>
                  ))};
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Employee />
              <select
                className="attendance-input-field width-15vw   date-field"
                type="text"
                value={employeeGet}
                onChange={(e) => setEmployeeGet(e.target.value)}
              >
                <option value="">Select Employee Code</option>
                {employeeList
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Filter />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={toggleStatus}
                onChange={(e) => setToggleStatus(e.target.value)}
              >
                <option value="null">All Reqests</option>
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
              <hr className="field-cont-hr" />
            </div>


          </div>

          <div className="btn-cont">
            <button className=" model-button-print" title="Download in Excel Format" onClick={handleDownloadAdvanceRequests}>
              <DownloadIcon />
            </button>
          </div>
        </div>

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500 font-size-heading">Advance Requests</div>
            <div className="filter-showing">

            </div>
          </div>
          <table ref={tableRef} className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="table-heading">Sr no.</th>
                <th className="table-heading">Employee</th>
                <th className="table-heading">Request Date</th>
                <th className="table-heading text-center">Advance Amount</th>
                <th className="table-heading width-10vw">Reason</th>
                <th className="table-heading text-center width-5vw">Status</th>
                <th className="table-heading text-center">Actions</th>
              </tr>
            </thead>
            {buffer ? (
              <div className="spinner"></div>
            ) : (
              <tbody>
                {advanceFilteredList
                  .sort((a, b) => new Date(a.date_of_request) - new Date(b.date_of_request))
                  .map((i, index) => (
                    <React.Fragment key={index}>
                      <tr className="custom-table-head-td">
                        <td className="table-body">{index + 1}</td>
                        <td className="table-body">{i.emp_code} - {i.emp_name}</td>
                        <td className="table-body">
                          {i.date_of_request ? formattedDateLong(i.date_of_request) : '-'}
                        </td>
                        <td className="table-body text-center">
                          {i.advance_amount ? formatCurrencyIndian(i.advance_amount) : '-'}
                        </td>
                        <td className="table-body width-10vw">{i.reason_for_taking_advance}</td>
                        <td className="table-body width-5vw ">
                          <span
                            request-status={i.hod_approval}
                            className="request-status"
                            title={`${i.hod_approval === "rejected" ? `HOD Reason For Rejection : \n${i.hod_log_details}` : ''}`}
                          >
                            {i.hod_approval === "pending"
                              ? "Pending For HOD Approval ⌛"
                              : i.hod_approval === "rejected"
                                ? <>HOD ✖,<br />HOD Reject Reason:<br />"{i.hod_log_details}"</>
                                : "Approved By HOD ✔,"}
                          </span>
                          {/* <br /> */}
                          <br />
                          <span
                            request-status={i.admin_approval}
                            className="request-status"
                            title={`${i.admin_approval === "rejected" ? `Admin Reason: ${i.admin_log_details}` : ''}`}
                          >
                            {i.admin_approval === "rejected"
                              ? <>Admin ✖,<br />Admin Reject Reason: "{i.admin_log_details}"</>
                              :
                              i.admin_approval === "pending"
                                ? "Pending For Admin Approval ⌛,"
                                :
                                i.admin_approval === "approved"
                                  ? "Approved By Admin ✔,"
                                  : "Admin Status: " + i.admin_approval}
                          </span>
                          {/* <br /> */}
                          <br />
                          <span
                            request-status={i.hr_approval}
                            className="request-status"
                            title={`${i.hr_approval === "rejected" ? `HR Reason: ${i.hr_log_details}` : ''}`}
                          >
                            {i.hr_approval === "rejected"
                              ? <>HR ✖,<br />HR Reject Reason:<br />"{i.hr_log_details}"</>
                              : i.hr_approval === "pending"
                                ? "Pending For HR Approval ⌛"
                                :
                                i.hr_approval === "approved"
                                  ? "Approved By HR ✔,"
                                  : "HR Status : " + i.hr_approval}
                          </span>
                          {/* <br /> */}
                          <br />
                          <span
                            request-status={i.account_approval}
                            className="request-status"
                            title={`${i.account_approval === "rejected" ? `Accounts Reason: ${i.acc_log_details}` : ''}`}
                          >
                            {i.account_approval === "rejected"
                              ? <>Accounts ✖,<br />Accounts Reject Reason:<br />"{i.acc_log_details}"</>
                              : i.account_approval === "pending"
                                ? "Pending For Accounts Approval ⌛"
                                :
                                i.account_approval === "approved"
                                  ? "Accounts Processed ✔,"
                                  : "Accounts Status : " + i.account_approval}
                          </span>
                        </td>
                        <td className="table-body text-center">
                          <AdvanceFormAdminApproval i={i} getAdvanceData={getAdvanceFormListAdmin} />
                          <AdvanceFormAdminRejection i={i} getAdvanceData={getAdvanceFormListAdmin} />
                        </td>

                      </tr >
                    </React.Fragment >
                  ))}
              </tbody>
            )}
          </table>
        </div>
      </div >
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

const AdvanceFormsTableHR = () => {

  const [buffer, setBuffering] = useState(true);

  const [buffer1, setBuffering1] = useState(true);
  const [employeeList, setEmployeeList] = useState([]);
  const [timeStampView, setTimeStampView] = useState(false);

  const [employeeGet, setEmployeeGet] = useState("");
  const [active, setActive] = useState("active");

  const [selectedDepartment, setDepartment] = useState(``);
  const [advanceFilteredList, setAdvanceFilteredList] = useState([]);

  const [toggleStatus, setToggleStatus] = useState("pending");
  const [selectedSubCompany, setSelectedSubCompany] = useState(
    `${sessionStorage.getItem("company_id")}`
  );

  const [SubCompany, setSubCompany] = useState([]);

  const getSubCompany = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/subcompany/`);
      setSubCompany(res.data);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  const [departmentname, setDepartmentname] = useState([]);

  const getDepartment = async () => {
    try {
      setBuffering1(true)
      const dep = await axios.get(`${BASE_URL}/wfm/departmentfilterbysubcompanycount/${selectedSubCompany || 'null'}/active/`);
      setDepartmentname(dep.data)
    } catch (err) {
    } finally {
      setBuffering1(false)
    }
  };

  // *********** Employee Details Api Start ***********
  const [advanceFormList, setAdvanceFormList] = useState([]);

  const getEmpList = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/ourcompanyempdetails/${active}/${selectedSubCompany}/`);
      const sortedData = customSortByKey(res.data, "emp_code");
      setEmployeeList(sortedData);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  const getAdvanceFormListHR = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/advancefilterbyhr/${selectedSubCompany}/${toggleStatus}/ `
      );
      const sortedData = customSortByKey(res.data, "emp_code");
      setAdvanceFormList(sortedData);
      // setAdvanceFormList(res.data);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  // *********** Employee Details Api End ***********

  const applyFilters = () => {
    let filteredData = advanceFormList;

    if (selectedDepartment !== "") {
      filteredData = filteredData.filter(
        (employee) => employee.department === selectedDepartment
      );
    }

    if (employeeGet !== "") {
      filteredData = filteredData.filter(
        (employee) => employee.emp_code === employeeGet
      );
    }

    setAdvanceFilteredList(filteredData);
  };

  useEffect(() => {
    getAdvanceFormListHR();
  }, [toggleStatus, selectedSubCompany]);

  useEffect(() => {
    applyFilters();
  }, [advanceFormList, selectedDepartment, employeeGet]);

  useEffect(() => {
    getEmpList();
    getDepartment();
    getSubCompany();
  }, [selectedSubCompany]);


  const tableRef = useRef(null);
  const handleDownloadAdvanceRequests = () => {
    downloadAsExcel(tableRef, "advance",
      selectedDepartment === "" ? `AdvanceRequests` :
        `AdvanceRequests`);
  };
  return (
    <>
      <div className="attendance-history-cont">
        <div className="attendance-subcont">
          <div className="field-cont">

            <div className="field-cont-div">
              <Office />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedSubCompany}
                onChange={(e) => setSelectedSubCompany(e.target.value)}
              >
                {/* <option value="">All Sub Companies</option> */}
                {SubCompany.sort((a, b) => a.title - b.title).map((i, index) => (
                  <option value={i.id}>{i.title}</option>
                ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <CircularGrid />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedDepartment}
                onChange={(e) => setDepartment(e.target.value)}
              >
                <option value="">All Department</option>
                {departmentname
                  .sort((a, b) => a.title - b.title)
                  .map((i, index) => (
                    <option value={i.id}>{i.title}</option>
                  ))};
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Employee />
              <select
                className="attendance-input-field width-15vw   date-field"
                type="text"
                value={employeeGet}
                onChange={(e) => setEmployeeGet(e.target.value)}
              >
                <option value="">Select Employee Code</option>
                {employeeList
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Filter />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={toggleStatus}
                onChange={(e) => setToggleStatus(e.target.value)}
              >
                <option value="null">All Reqests</option>
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
              <hr className="field-cont-hr" />
            </div>


          </div>

          <div className="btn-cont">
            <button className=" model-button-print" title="Download in Excel Format" onClick={handleDownloadAdvanceRequests}>
              <DownloadIcon />
            </button>
          </div>
        </div>

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500 font-size-heading">Advance Requests</div>
            <div className="filter-showing">

            </div>
          </div>
          <table ref={tableRef} className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="table-heading">Sr no.</th>
                <th className="table-heading">Employee</th>
                <th className="table-heading">Request Date</th>
                <th className="table-heading text-center">Advance Amount</th>
                <th className="table-heading width-10vw">Reason</th>
                <th className="table-heading text-center width-5vw">Status</th>
                <th className="table-heading text-center">Actions</th>
              </tr>
            </thead>
            {buffer ? (
              <div className="spinner"></div>
            ) : (
              <tbody>
                {advanceFilteredList
                  .sort((a, b) => new Date(a.date_of_request) - new Date(b.date_of_request))
                  .map((i, index) => (
                    <React.Fragment key={index}>
                      <tr className="custom-table-head-td">
                        <td className="table-body">{index + 1}</td>
                        <td className="table-body">{i.emp_code} - {i.emp_name}</td>
                        <td className="table-body">
                          {i.date_of_request ? formattedDateLong(i.date_of_request) : '-'}
                        </td>
                        <td className="table-body text-center">
                          {i.advance_amount ? formatCurrencyIndian(i.advance_amount) : '-'}
                        </td>
                        <td className="table-body width-10vw">{i.reason_for_taking_advance}</td>
                        <td className="table-body width-5vw ">
                          <span
                            request-status={i.hod_approval}
                            className="request-status"
                            title={`${i.hod_approval === "rejected" ? `HOD Reason For Rejection : \n${i.hod_log_details}` : ''}`}
                          >
                            {i.hod_approval === "pending"
                              ? "Pending For HOD Approval ⌛"
                              : i.hod_approval === "rejected"
                                ? <>HOD ✖,<br />HOD Reject Reason:<br />"{i.hod_log_details}"</>
                                : "Approved By HOD ✔,"}
                          </span>
                          {/* <br /> */}
                          <br />
                          <span
                            request-status={i.admin_approval}
                            className="request-status"
                            title={`${i.admin_approval === "rejected" ? `Admin Reason: ${i.admin_log_details}` : ''}`}
                          >
                            {i.admin_approval === "rejected"
                              ? <>Admin ✖,<br />Admin Reject Reason: "{i.admin_log_details}"</>
                              :
                              i.admin_approval === "pending"
                                ? "Pending For Admin Approval ⌛,"
                                :
                                i.admin_approval === "approved"
                                  ? "Approved By Admin ✔,"
                                  : "Admin Status: " + i.admin_approval}
                          </span>
                          {/* <br /> */}
                          <br />
                          <span
                            request-status={i.hr_approval}
                            className="request-status"
                            title={`${i.hr_approval === "rejected" ? `HR Reason: ${i.hr_log_details}` : ''}`}
                          >
                            {i.hr_approval === "rejected"
                              ? <>HR ✖,<br />HR Reject Reason:<br />"{i.hr_log_details}"</>
                              : i.hr_approval === "pending"
                                ? "Pending For HR Approval ⌛"
                                :
                                i.hr_approval === "approved"
                                  ? "Approved By HR ✔,"
                                  : "HR Status : " + i.hr_approval}
                          </span>
                          {/* <br /> */}
                          <br />
                          <span
                            request-status={i.account_approval}
                            className="request-status"
                            title={`${i.account_approval === "rejected" ? `Accounts Reason: ${i.acc_log_details}` : ''}`}
                          >
                            {i.account_approval === "rejected"
                              ? <>Accounts ✖,<br />Accounts Reject Reason:<br />"{i.acc_log_details}"</>
                              : i.account_approval === "pending"
                                ? "Pending For Accounts Approval ⌛"
                                :
                                i.account_approval === "approved"
                                  ? "Accounts Processed ✔,"
                                  : "Accounts Status : " + i.account_approval}
                          </span>
                        </td>
                        <td className="table-body text-center">
                          <AdvanceFormHRApproval i={i} getAdvanceData={getAdvanceFormListHR} />
                          <AdvanceFormHRRejection i={i} getAdvanceData={getAdvanceFormListHR} />
                        </td>

                      </tr >
                    </React.Fragment >
                  ))}
              </tbody>
            )}
          </table>
        </div>
      </div >
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

const AdvanceFormsTableAcc = () => {

  const [buffer, setBuffering] = useState(true);

  const [buffer1, setBuffering1] = useState(true);
  const [employeeList, setEmployeeList] = useState([]);

  const [employeeGet, setEmployeeGet] = useState("null");
  const [active, setActive] = useState("active");

  const [selectedDepartment, setDepartment] = useState(``);
  const [advanceFilteredList, setAdvanceFilteredList] = useState([]);

  const [toggleStatus, setToggleStatus] = useState("pending");
  const [selectedSubCompany, setSelectedSubCompany] = useState(
    `${sessionStorage.getItem("company_id")}`
  );

  const [SubCompany, setSubCompany] = useState([]);

  const getSubCompany = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/subcompany/`);
      setSubCompany(res.data);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  const [departmentname, setDepartmentname] = useState([]);

  const getDepartment = async () => {
    try {
      setBuffering1(true)
      const dep = await axios.get(`${BASE_URL}/wfm/departmentfilterbysubcompanycount/${selectedSubCompany || 'null'}/active/`);
      setDepartmentname(dep.data)
    } catch (err) {
    } finally {
      setBuffering1(false)
    }
  };

  // *********** Employee Details Api Start ***********
  const [advanceFormList, setAdvanceFormList] = useState([]);

  const getEmpList = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/ourcompanyempdetails/${active}/${selectedSubCompany}/`);
      const sortedData = customSortByKey(res.data, "emp_code");
      setEmployeeList(sortedData);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  const getAdvanceFormListHR = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/advancefilterbyaccount/${selectedSubCompany}/${toggleStatus}/ `
      );
      const sortedData = customSortByKey(res.data, "emp_code");
      setAdvanceFormList(sortedData);
      // setAdvanceFilteredList(sortedData);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  // *********** Employee Details Api End ***********

  const applyFilters = () => {
    let filteredData = advanceFormList;

    if (selectedDepartment !== "") {
      filteredData = filteredData.filter(
        (employee) => employee.department === selectedDepartment
      );
    }

    setAdvanceFilteredList(filteredData);
  };

  useEffect(() => {
    getAdvanceFormListHR();
  }, [employeeGet, toggleStatus, selectedSubCompany]);

  useEffect(() => {
    applyFilters();
  }, [advanceFormList, selectedDepartment,]);

  useEffect(() => {
    getEmpList();
    getDepartment();
    getSubCompany();
  }, [selectedSubCompany]);

  const tableRef = useRef(null);
  const handleDownloadAdvanceRequests = () => {
    downloadAsExcel(tableRef, "advance",
      selectedDepartment === "" ? `AdvanceRequests` :
        `AdvanceRequests`);
  };
  return (
    <>
      <div className="attendance-history-cont">
        <div className="attendance-subcont">
          <div className="field-cont">

            <div className="field-cont-div">
              <Office />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedSubCompany}
                onChange={(e) => setSelectedSubCompany(e.target.value)}
              >
                {/* <option value="">All Sub Companies</option> */}
                {SubCompany.sort((a, b) => a.title - b.title).map((i, index) => (
                  <option value={i.id}>{i.title}</option>
                ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <CircularGrid />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedDepartment}
                onChange={(e) => setDepartment(e.target.value)}
              >
                <option value="">All Department</option>
                {departmentname
                  .sort((a, b) => a.title - b.title)
                  .map((i, index) => (
                    <option value={i.id}>{i.title}</option>
                  ))};
              </select>
              <hr className="field-cont-hr" />
            </div>

            {/* <div className="field-cont-div">
              <Employee />
              <select
                className="attendance-input-field width-15vw   date-field"
                type="text"
                value={employeeGet}
                onChange={(e) => setEmployeeGet(e.target.value)}
              >
                <option value="null">Select Employee Code</option>
                {employeeList
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div> */}

            <div className="field-cont-div">
              <Filter />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={toggleStatus}
                onChange={(e) => setToggleStatus(e.target.value)}
              >
                <option value="null">All Reqests</option>
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
              <hr className="field-cont-hr" />
            </div>


          </div>

          <div className="btn-cont">
            <button className=" model-button-print" title="Download in Excel Format" onClick={handleDownloadAdvanceRequests}>
              <DownloadIcon />
            </button>
          </div>
        </div>

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500 font-size-heading">Advance Requests</div>
            <div className="filter-showing">

            </div>
          </div>
          <table ref={tableRef} className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="table-heading">Sr no.</th>
                <th className="table-heading">Employee</th>
                <th className="table-heading">Request Date</th>
                <th className="table-heading text-center">Advance Amount</th>
                <th className="table-heading width-10vw">Reason</th>
                <th className="table-heading text-center width-5vw">Status</th>
                <th className="table-heading text-center">Actions</th>
              </tr>
            </thead>
            {buffer ? (
              <div className="spinner"></div>
            ) : (
              <tbody>
                {advanceFilteredList
                  .sort((a, b) => new Date(a.date_of_request) - new Date(b.date_of_request))
                  .map((i, index) => (
                    <React.Fragment key={index}>
                      <tr className="custom-table-head-td">
                        <td className="table-body">{index + 1}</td>
                        <td className="table-body">{i.emp_code} - {i.emp_name}</td>
                        <td className="table-body">
                          {i.date_of_request ? formattedDateLong(i.date_of_request) : '-'}
                        </td>
                        <td className="table-body text-center">
                          {i.advance_amount ? formatCurrencyIndian(i.advance_amount) : '-'}
                        </td>
                        <td className="table-body width-10vw">{i.reason_for_taking_advance}</td>
                        <td className="table-body width-5vw ">
                          <span
                            request-status={i.hod_approval}
                            className="request-status"
                            title={`${i.hod_approval === "rejected" ? `HOD Reason For Rejection : \n${i.hod_log_details}` : ''}`}
                          >
                            {i.hod_approval === "pending"
                              ? "Pending For HOD Approval ⌛"
                              : i.hod_approval === "rejected"
                                ? <>HOD ✖,<br />HOD Reject Reason:<br />"{i.hod_log_details}"</>
                                : "Approved By HOD ✔,"}
                          </span>
                          {/* <br /> */}
                          <br />
                          <span
                            request-status={i.admin_approval}
                            className="request-status"
                            title={`${i.admin_approval === "rejected" ? `Admin Reason: ${i.admin_log_details}` : ''}`}
                          >
                            {i.admin_approval === "rejected"
                              ? <>Admin ✖,<br />Admin Reject Reason: "{i.admin_log_details}"</>
                              :
                              i.admin_approval === "pending"
                                ? "Pending For Admin Approval ⌛,"
                                :
                                i.admin_approval === "approved"
                                  ? "Approved By Admin ✔,"
                                  : "Admin Status: " + i.admin_approval}
                          </span>
                          {/* <br /> */}
                          <br />
                          <span
                            request-status={i.hr_approval}
                            className="request-status"
                            title={`${i.hr_approval === "rejected" ? `HR Reason: ${i.hr_log_details}` : ''}`}
                          >
                            {i.hr_approval === "rejected"
                              ? <>HR ✖,<br />HR Reject Reason:<br />"{i.hr_log_details}"</>
                              : i.hr_approval === "pending"
                                ? "Pending For HR Approval ⌛"
                                :
                                i.hr_approval === "approved"
                                  ? "Approved By HR ✔,"
                                  : "HR Status : " + i.hr_approval}
                          </span>
                          {/* <br /> */}
                          <br />
                          <span
                            request-status={i.account_approval}
                            className="request-status"
                            title={`${i.account_approval === "rejected" ? `Accounts Reason: ${i.acc_log_details}` : ''}`}
                          >
                            {i.account_approval === "rejected"
                              ? <>Accounts ✖,<br />Accounts Reject Reason:<br />"{i.acc_log_details}"</>
                              : i.account_approval === "pending"
                                ? "Pending For Accounts Approval ⌛"
                                :
                                i.account_approval === "approved"
                                  ? "Accounts Processed ✔,"
                                  : "Accounts Status : " + i.account_approval}
                          </span>
                        </td>
                        <td className="table-body text-center">
                          <AdvanceFormAccFinalize i={i} getAdvanceData={getAdvanceFormListHR} />
                        </td>

                      </tr >
                    </React.Fragment >
                  ))}
              </tbody>
            )}
          </table>
        </div>
      </div >
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

export {
  EmployeeAdvanceSlipsTable,
  AdvanceFormsTableRH,
  AdvanceFormsTableHOD,
  AdvanceFormsTableAdmin,
  AdvanceFormsTableHR,
  AdvanceFormsTableAcc,
};
