import React, { useState, useEffect } from 'react'

import Dashboardnavbarcopy from '../../layout/Dashboardnavbarcopy';
import HodAttendance from './HodAttendance';
import EmployeeDetails from './HodEmployeeDetails'
import Requestmanagment from './HodRequestmanagment'
import HodOrgstructure from './HodOrgstructure';
import { RequestRequisitionTableRH } from '../RequisitionManagement/RequisitionManagementTables';
import { HODLogRecords } from './EmpLogRecords';
import { AppraisalFormsTableHOD, AppraisalFormsTableRHHOD } from '../AppraisalManagement/AppraisalTables';
import { AdvanceFormsTableHOD } from '../AdvanceManagement/AdvanceTables';
import { SeprationFormsTableHOD } from '../SeprationManagement/SeprationTables';

const HOD = () => {


  const [toggleState, setToggleState] = useState(1);


  const getSelectedTitle = () => {
    switch (toggleState) {
      case 1:
        return 'Organization Structure';
      case 2:
        return 'Department Employees';
      case 3:
        return 'Attendance';
      case 4:
        return 'Request management';
      case 5:
        return 'Requisition management';
      case 6:
        return 'History Logs';
      case 7:
        return 'Appraisal Forms';
      case 8:
        return 'Advance Requests';
      case 9:
        return 'Sepration Requests';
      default:
        return 'Organization Management';
    }
  };

  return (
    <div>
      <Dashboardnavbarcopy name={getSelectedTitle()} url="Workforce Managment" />
      <div className="bloc-tabss  wfm-margin">
        <button
          className={toggleState === 1 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss"}
          onClick={() => setToggleState(1)}
        >
          Organization Structure
        </button>
        <button
          className={toggleState === 2 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss"}
          onClick={() => setToggleState(2)}
        >
          Department Employees
        </button>
        <button
          className={toggleState === 3 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss"}
          onClick={() => setToggleState(3)}
        >
          Attendance
        </button>
        {/* <button
          className={toggleState === 4 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss"}
          onClick={() => setToggleState(4)}
        >
          Request Management
        </button> */}

        {/* <button
          className={toggleState === 5 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss"}
          onClick={() => setToggleState(5)}
        >
          Requisition Management
        </button> */}
        <button
          className={toggleState === 6 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
          onClick={() => setToggleState(6)}
        >
          History Logs
        </button>
        <button
          className={toggleState === 7 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss"}
          onClick={() => setToggleState(7)}
        >
          Department Appraisal Forms
        </button>
        <button
          className={toggleState === 8 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss"}
          onClick={() => setToggleState(8)}
        >
          Department Advance Requests
        </button>
        <button
          className={toggleState === 9 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss"}
          onClick={() => setToggleState(9)}
        >
          Department Sepration Requests
        </button>
      </div>
      <div className="content-tabs">

        {toggleState === 1 ? <HodOrgstructure /> : null}

        {toggleState === 2 ? <EmployeeDetails /> : null}

        {toggleState === 3 ? <HodAttendance /> : null}

        {toggleState === 4 ? <Requestmanagment /> : null}

        {toggleState === 5 ? <RequestRequisitionTableRH /> : null}

        {toggleState === 6 ? <HODLogRecords /> : null}


        {toggleState === 7 ?
          sessionStorage.getItem('company_id') === "910ee6d1-9fd8-4da1-a9cb-369b1ffecd7c" ?
            <AppraisalFormsTableHOD />
            :
            <AppraisalFormsTableRHHOD />
          : null}

        {toggleState === 8 ?
          <AdvanceFormsTableHOD />
          : null
        }

        {toggleState === 9 ? <SeprationFormsTableHOD /> : null}

      </div>
    </div>
  )
}

export default HOD
