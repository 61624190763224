import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { ModalBody } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { handleErrorToast } from "../../CustomFunctions";
import { formattedDateLong } from "../../Date";
import { AUTH, BASE_URL } from "../../../config/axios";
import { PDFIcon, Reciept, Attachment, CheckGreen, DropdownArrow } from "../../AllSvg";

const ExpenseDetailApproved = ({ i, getExpensedetail }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(`${BASE_URL}/wfm/expensedetailsupdate/${i.id}/`, {
        tl_approved_amt: i.item_amount,
        bill_approved_amt: i.item_amount,
      });

      if (res.status === 200) {
        await getExpensedetail();
        window.location.reload();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-edit-button hide-on-print" title="Approve asked bill amount" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          <div className="parent-div">

            <div className="bdy-div">
              <h1 className="task-Tab-heading align-center  font-weight500    font-size-heading"><Modal.Title>Approve Bill of : "{i.item_name}"</Modal.Title> </h1>
              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Date -
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {formattedDateLong(i.date)}
                </div>


              </div>

              <div className="flex-row">
                <label
                  htmlFor="item_name"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Item Name -
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.item_name}
                </div>
              </div>

              <div className="flex-row">
                <label htmlFor=" expense_type" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Expense Type -
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.expense_type}
                </div>
              </div>

              <div className="flex-row">
                <label
                  htmlFor="item_amount"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Amount -
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.item_amount}
                </div>
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-submit    font-size-heading"
                  onClick={handleFormSubmitPut}
                >
                  Submit
                </button>
              </div>

            </div>
          </div>

        </Modal.Body>
      </Modal >
    </>
  );
};

const ExpenseDetailPartialApproved = ({ i, getExpensedetail }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formData, setFormData] = useState({
    tl_approved_amt: i.item_amount,
    bill_approved_amt: i.item_amount,
    bill_amount_change_reason: "",
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["bill_amount_change_reason"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;

  //   setInputState({
  //     ...inputState,
  //     [name]: value.trim() ? "green" : "",
  //   });

  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name.startsWith('tl_approved_amt')) {
      const itemAmountKey = name.replace('tl_approved_amt', 'item_amount');
      const currentValue = i[itemAmountKey] !== undefined && i[itemAmountKey] !== null ? i[itemAmountKey] : 0;
      const maxLength = getNumberLength(currentValue);

      if (value.length <= maxLength) {
        const numericValue = value.trim() ? parseInt(value, 10) : 0;

        if (numericValue <= currentValue) {
          setInputState({
            ...inputState,
            [name]: value.trim() ? "success" : "",
          });

          setFormData({
            ...formData,
            [name]: value,
          });
          setErrors({
            ...errors,
            [name]: "",
          });
        } else {
          setErrors({
            ...errors,
            [name]: `Value must be less than or equal to ${currentValue}`,
          });
        }
      } else {
        setErrors({
          ...errors,
          [name]: `Maximum allowed length is ${maxLength} digits`,
        });
      }
    } else {
      // For `textarea` or other fields without length validation
      setInputState({
        ...inputState,
        [name]: value.trim() ? "success" : "",
      });

      setFormData({
        ...formData,
        [name]: value,
      });
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };



  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        let res = await axios.put(`${BASE_URL}/wfm/expensedetailsupdate/${i.id}/`, {
          tl_approved_amt: formData.tl_approved_amt,
          bill_approved_amt: formData.tl_approved_amt,
          bill_amount_change_reason: formData.bill_amount_change_reason,
        });

        if (res.status === 200) {
          await getExpensedetail();
          window.location.reload();
          // setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        toast.dismiss(loadingToastId);
      }
    }
  };


  function getNumberLength(value) {
    return value.toString().length;
  }

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-partial-button hide-on-print" title="Approve partial amount of this bill" onClick={handleShow}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 48 48" id="exclamation">
          <path fill="#F7941E" fill-rule="evenodd" d="M24 10C25.1046 10 26 10.8954 26 12L26 30C26 31.1046 25.1046 32 24 32 22.8954 32 22 31.1046 22 30L22 12C22 10.8954 22.8954 10 24 10zM24 38C25.1046 38 26 37.1046 26 36 26 34.8954 25.1046 34 24 34 22.8954 34 22 34.8954 22 36 22 37.1046 22.8954 38 24 38z" clip-rule="evenodd"></path></svg>
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        {/* <Modal.Header closeButton>
            <Modal.Title>Rejection Reason</Modal.Title>
          </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">

            <div className="bdy-div">
              <h1 className="task-Tab-heading align-center  font-weight500    font-size-heading"><Modal.Title>Change Approved Amount for : "{i.item_name}"</Modal.Title> </h1>

              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Date :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {formattedDateLong(i.date)}
                </div>
              </div>

              <div className="flex-row">
                <label htmlFor=" expense_type" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Expense Type -
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.expense_type}
                </div>
              </div>

              <div className="flex-column">
                <label
                  htmlFor="tl_approved_amt"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Approve Amount -
                </label>
                <input
                  id="tl_approved_amt"
                  type="number"
                  name="tl_approved_amt"
                  placeholder="0"
                  min={0}
                  maxLength={getNumberLength(i.item_amount)}
                  max={i.item_amount}
                  onWheel={(e) => e.target.blur()}
                  onChange={handleInputChange}
                  value={formData.tl_approved_amt}
                  className={`form-input ${errors.tl_approved_amt
                    ? "error"
                    : inputState.tl_approved_amt
                      ? "success"
                      : ""
                    }`}
                />
                {errors.tl_approved_amt && (
                  <span className="error-message font-size-text ">{errors.tl_approved_amt}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="bill_amount_change_reason" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Amount Change Reason -
                </label>
                <textarea
                  id="bill_amount_change_reason"
                  type="text"
                  name="bill_amount_change_reason"
                  placeholder="Your Reason"
                  onChange={handleInputChange}
                  maxLength={100}
                  value={formData.bill_amount_change_reason}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.bill_amount_change_reason
                    ? "error"
                    : inputState.bill_amount_change_reason
                      ? "success"
                      : ""
                    }`}
                />
                {errors.bill_amount_change_reason && (
                  <span className="error-message font-size-text ">{errors.bill_amount_change_reason}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-submit    font-size-heading"
                  onClick={handleFormSubmitPut}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const ExpenseDetailReject = ({ i, getExpenseDetail }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formData, setFormData] = useState({
    bill_rejection_reason: "",
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["rh_rejection_reason"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        let res = await axios.put(`${BASE_URL}/wfm/expensedetailsupdate/${i.id}/`, {
          rh_leave_status: "rejected",
          bill_rejection_reason: formData.bill_rejection_reason,
        });

        if (res.status === 200) {
          await getExpenseDetail();
          // setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-delete-button" title="Reject Bills" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        {/* <Modal.Header closeButton>
            <Modal.Title>Rejection Reason</Modal.Title>
          </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <h1 className="task-Tab-heading align-center  font-weight500    font-size-heading"><Modal.Title>Reject bil : "{i.item_name}"</Modal.Title> </h1>

              <div className="flex-column">
                {/* <label htmlFor="bill_rejection_reason" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Bill Rejection Reason
                </label> */}
                <textarea
                  id="bill_rejection_reason"
                  type="text"
                  name="bill_rejection_reason"
                  placeholder="Your Reason"
                  onChange={handleInputChange}
                  maxLength={100}
                  value={formData.bill_rejection_reason}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.bill_rejection_reason
                    ? "error"
                    : inputState.bill_rejection_reason
                      ? "success"
                      : ""
                    }`}
                />
                {errors.bill_rejection_reason && (
                  <span className="error-message font-size-text ">{errors.bill_rejection_reason}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-delete    font-size-heading"
                  onClick={handleFormSubmitPut}
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};

const BillAuthentication = ({ i, getExpenseDetail }) => {

  const [show, setShow] = useState(false);
  const [checked, setChecked] = useState(i.bill_approved);
  const [authentication, setAuthentication] = useState(i.bill_approved);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setFormData(i)
    // setChecked(!checked);
    setShow(true);
  }

  const isImage = (url) => {
    return /\.(jpg|jpeg|png|gif|bmp|webp)(\?.*)?$/.test(url);
  }

  const handleDummyReject = () => {
    setAuthentication("rejected")
  }


  const handleDummyApprove = () => {
    setAuthentication("approved");
  }


  const [formData, setFormData] = useState({
    bill_approved: "",
    bill_rejection_reason: i.bill_rejection_reason ? i.bill_rejection_reason : "",
  });

  // useEffect(() => {
  //   setFormData(i);
  // }, [i]);

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["bill_rejection_reason"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmitApprove = async (e) => {
    e.preventDefault();

    setLoading(true); //loading logic
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.put(`${BASE_URL}/wfm/expensedetailsupdate/${i.id}/`, {
        bill_approved: 'approved',
        bill_rejection_reason: "",
        bill_approved_amt: i.tl_approved_amt,
      });

      if (res.status === 200) {
        await getExpenseDetail();
        handleClose();
        window.location.reload();
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setLoading(false); //loading logic
      toast.dismiss(loadingToastId);
    }

  };

  const handleFormSubmitReject = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.put(`${BASE_URL}/wfm/expensedetailsupdate/${i.id}/`,
          {
            bill_approved: 'rejected',
            bill_rejection_reason: formData.bill_rejection_reason,
            bill_approved_amt: 0,
          }
          // formData
        );

        if (res.status === 200) {
          await getExpenseDetail();
          handleClose();
          window.location.reload();
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button onClick={handleShow}>
        {checked === "approved" ?
          <input
            title="Bill Approved"
            type="checkbox"
            checked={true}
            readOnly
            className="form-checkbox"
          />
          : checked === "rejected" ?
            <span title={`Bill Rejection Reason : \n${i.bill_rejection_reason}`} style={{ marginLeft: '8px' }}>❌</span>
            : <input
              title="Verify Bill"
              type="checkbox"
              checked={false}
              readOnly
              className="form-checkbox"
            />}
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Verify Bill of: {i.item_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
          <div className="parent-div">
            {/* 
            <div className="flex-column justify-center">
              <div className="jd-timeline">
                <div className="leave-radio-button-inner-div">
                  <input
                    onClick={(e) => setAuthentication(true)}
                    name="bill_approved"
                    type="radio"
                    value={true}
                    className="age-input"
                  />
                  <label className="age-label font-weight500 font-size-heading">Authentic</label></div>
                <div className="leave-radio-button-inner-div">
                  <input
                    onClick={(e) => setAuthentication(false)}
                    name="bill_approved"
                    type="radio"
                    value={false}
                    className="age-input"
                  />
                  <label className="age-label font-weight500 font-size-heading">Non Authentic</label>
                </div>
              </div>
            </div> */}

            <div className="flex-row justify-center">
              {isImage(i.bill) ? (
                <img className="bill-img" src={i.bill} alt={`Bill Image of ${i.item_name}`} />
              ) : (
                <a href={i.bill} target="_blank" rel="noopener noreferrer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-file-pdf" viewBox="0 0 16 16" color="#FF747C">
                    <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1" />
                    <path d="M4.603 12.087a.8.8 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.7 7.7 0 0 1 1.482-.645 20 20 0 0 0 1.062-2.227 7.3 7.3 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a11 11 0 0 0 .98 1.686 5.8 5.8 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.86.86 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.7 5.7 0 0 1-.911-.95 11.6 11.6 0 0 0-1.997.406 11.3 11.3 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.8.8 0 0 1-.58.029m1.379-1.901q-.25.115-.459.238c-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361q.016.032.026.044l.035-.012c.137-.056.355-.235.635-.572a8 8 0 0 0 .45-.606m1.64-1.33a13 13 0 0 1 1.01-.193 12 12 0 0 1-.51-.858 21 21 0 0 1-.5 1.05zm2.446.45q.226.244.435.41c.24.19.407.253.498.256a.1.1 0 0 0 .07-.015.3.3 0 0 0 .094-.125.44.44 0 0 0 .059-.2.1.1 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a4 4 0 0 0-.612-.053zM8.078 5.8a7 7 0 0 0 .2-.828q.046-.282.038-.465a.6.6 0 0 0-.032-.198.5.5 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822q.036.167.09.346z" />
                  </svg>
                  Open Bill Document
                </a>
              )}
            </div>


            <div className="flex-row justify-center">
              {authentication === "rejected" ? (
                <div className="flex-column ">
                  <div className="flex-row justify-between">
                    <label htmlFor="bill_rejection_reason" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                      Bill Rejection Reason<span className="required">*</span>
                    </label>
                  </div>
                  <div>
                    <textarea
                      id="bill_rejection_reason"
                      type="text"
                      name="bill_rejection_reason"
                      placeholder="Your Reason"
                      onChange={handleInputChange}
                      maxLength={100}
                      value={formData.bill_rejection_reason}
                      className={`form-input-textarea   font-weight400  font-size-subheading ${errors.bill_rejection_reason
                        ? "error"
                        : inputState.bill_rejection_reason
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.bill_rejection_reason && (
                      <span className="error-message font-size-text ">{errors.bill_rejection_reason}</span>
                    )}
                  </div>
                </div>
              ) : null}
            </div>


            {/* <br/>
            <br/> */}
            <div className="button-models">
              {authentication === 'rejected' ? (
                <>
                  <button
                    className="model-button model-button-delete font-weight500"
                    onClick={handleFormSubmitReject}
                  >
                    Reject
                  </button>

                  <button
                    className="model-button model-button-pending font-weight500"
                    onClick={handleDummyApprove}
                  >
                    Approve
                  </button>

                </>
              ) : (
                <>
                  <button
                    className="model-button model-button-pending font-weight500"
                    onClick={handleDummyReject}
                  >
                    Reject
                  </button>
                  <button
                    className="model-button model-button-submit font-weight500"
                    onClick={handleFormSubmitApprove}
                    disabled={loading}
                  >
                    Approve
                  </button>
                </>
              )}
            </div>

          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

// const BillApproval = ({ i, getExpenseDetail }) => {


//   const [show, setShow] = useState(false);
//   const [checked, setChecked] = useState(i.bill_approved);
//   const [authentication, setAuthentication] = useState(null);

//   const handleClose = () => setShow(false);
//   const handleShow = () => {
//     // setChecked(!checked);
//     setShow(true);
//   }

//   const [formData, setFormData] = useState({
//     bill_approved: "",
//   });

//   // useEffect(() => {
//   //   setFormData(i);
//   // }, [i]);

//   // !  ************** Validation start **************  ! //

//   const [errors, setErrors] = useState({});
//   const [inputState, setInputState] = useState({});

//   const validateForm = () => {
//     const newErrors = {};
//     const requiredFields = [];
//     requiredFields.forEach((field) => {
//       if (!formData[field]) {
//         newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
//           } is required !`;
//       }
//     });

//     setErrors(newErrors);

//     return Object.keys(newErrors).length === 0;
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;

//     setInputState({
//       ...inputState,
//       [name]: value.trim() ? "green" : "",
//     });

//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   // ?  ************** Validation End **************  ! //

//   const [loading, setLoading] = useState(false); //loading logic
//   const handleFormSubmitApprove = async (e) => {
//     e.preventDefault();
//     if (validateForm()) {
//       setLoading(true); //loading logic
//       const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
//       try {
//         let res = await axios.put(`${BASE_URL}/wfm/expensedetailsupdate/${i.id}/`, {
//           bill_approved: true,
//         });

//         if (res.status === 200) {
//           await getExpenseDetail();
//           handleClose();
//           window.location.reload();
//         } else {
//           alert(res);
//         }
//       } catch (err) {
//         //toast Logic
//         if (err.response) {
//           toast.dismiss(loadingToastId);
//           const errorData = err.response.data;

//           if (typeof errorData.error === 'string') {
//             // Single error message
//             toast.error(`Error: ${errorData.error}`);
//           } else if (typeof errorData === 'object' && errorData !== null) {
//             // Multiple error messages
//             Object.entries(errorData).forEach(([field, messages]) => {
//               messages.forEach(message => toast.error(`"${field}": ${message}`));
//             });
//           } else {
//             toast.error('Error:- Failed to Process!');
//           }
//         } else {
//           toast.error('Error processing your request.');
//         }
//       } finally {
//         setLoading(false); //loading logic
//         toast.dismiss(loadingToastId);
//       }
//     }
//   };

//   const handleFormSubmitReject = async (e) => {
//     e.preventDefault();
//     if (validateForm()) {
//       setLoading(true); //loading logic
//       const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
//       try {
//         let res = await axios.put(`${BASE_URL}/wfm/expensedetailsupdate/${i.id}/`,
//           {
//             bill_approved: false,
//             // bill_rejection_reason: formData.bill_rejection_reason,
//           }
//           // formData
//         );

//         if (res.status === 200) {
//           await getExpenseDetail();
//           handleClose();
//           window.location.reload();
//         } else {
//           alert(res);
//         }
//       } catch (err) {
//         //toast Logic
//         if (err.response) {
//           toast.dismiss(loadingToastId);
//           const errorData = err.response.data;

//           if (typeof errorData.error === 'string') {
//             // Single error message
//             toast.error(`Error: ${errorData.error}`);
//           } else if (typeof errorData === 'object' && errorData !== null) {
//             // Multiple error messages
//             Object.entries(errorData).forEach(([field, messages]) => {
//               messages.forEach(message => toast.error(`"${field}": ${message}`));
//             });
//           } else {
//             toast.error('Error:- Failed to Process!');
//           }
//         } else {
//           toast.error('Error processing your request.');
//         }
//       } finally {
//         setLoading(false); //loading logic
//         toast.dismiss(loadingToastId);
//       }
//     }
//   };
//   return (
//     <>


//       <button title={"Bill Details"} onClick={handleShow}>
//         {checked === true ?
//           <input
//             type="checkbox"
//             checked={true}
//             readOnly
//             className="form-checkbox"
//           />
//           : checked === false ?
//             <span style={{ marginLeft: '8px' }}>❌</span>
//             : <input
//               type="checkbox"
//               checked={false}
//               readOnly
//               className="form-checkbox"
//             />}
//       </button>


//       <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
//         <Modal.Header closeButton>
//           <Modal.Title>Verify Bill of: {i.item_name}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
//           <div className="parent-div">

//             <div className="flex-column justify-center">
//               <div className="jd-timeline">
//                 <div className="leave-radio-button-inner-div">
//                   <input
//                     onClick={(e) => setAuthentication(true)}
//                     name="bill_approved"
//                     type="radio"
//                     value={true}
//                     className="age-input"
//                   />
//                   <label className="age-label font-weight500 font-size-heading">Authentic</label></div>
//                 <div className="leave-radio-button-inner-div">
//                   <input
//                     onClick={(e) => setAuthentication(false)}
//                     name="bill_approved"
//                     type="radio"
//                     value={false}
//                     className="age-input"
//                   />
//                   <label className="age-label font-weight500 font-size-heading">Non Authentic</label>
//                 </div>
//               </div>
//             </div>

//             <div className="flex-row justify-center">
//               <img className="bill-img" src={i.bill} alt="" />
//             </div>


//             {/* <br/>
//             <br/> */}
//             <div className="button-models">
//               <button className="model-button model-button-cancel   font-weight500" onClick={handleClose}>
//                 Cancel
//               </button>


//               {authentication !== null ?
//                 authentication ?
//                   <button
//                     className="model-button  font-weight500    model-button-submit"
//                     onClick={handleFormSubmitApprove}
//                     disabled={loading}
//                   >
//                     Approve
//                   </button> :
//                   <button
//                     className="model-button   font-weight500    model-button-delete    font-size-heading"
//                     onClick={handleFormSubmitReject}
//                   >
//                     Reject
//                   </button>
//                 : null
//               }
//             </div>
//           </div>
//         </Modal.Body>
//       </Modal>
//     </>
//   );
// }

const ViewBill = ({ i }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  }

  const isImage = (url) => {
    return /\.(jpg|jpeg|png|gif|bmp|webp)(\?.*)?$/.test(url);
  }
  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button title={"View Bill"} onClick={handleShow}>
        <Reciept />
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Bill of: {i.item_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="flex-row justify-center">
              {/* <img className="bill-img" src={i.bill} alt={`Bill Image of ${i.item_name}`} /> */}
              {isImage(i.bill) ? (
                <img className="bill-img" src={i.bill} alt={`Bill Image of ${i.item_name}`} />
              ) : (
                <a href={i.bill} target="_blank" rel="noopener noreferrer">
                  <PDFIcon />
                  Open Bill Document
                </a>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}


const UpdateBill = ({ i, getExpenses, getSpecificExpenses, date }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {

    setFormData({ bill: "" });
    handleClear();
    setShow(false)
  };
  const handleShow = () => {
    // setFormData(i)
    setShow(true);
  }

  const [formData, setFormData] = useState({
    bill: "",
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["bill"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };


  const [fileName, setFileName] = useState("");
  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];
    if (file) {
      setFormData({ ...formData, [name]: file, });
      setFileName(file.name);
    } else {
      setFileName("");
    }
  };

  const handleClear = () => {
    setFormData(prevState => ({
      ...prevState,
      bill: ""
    }));
    setFileName("");
  }

  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();
  const formatttedDatee = `${day.toString().padStart(2, "0")}`;
  const [selecteddate, setSelecteddate] = useState(`${formatttedDatee}`);
  const [monthh, setMonthh] = useState(`${month}`);
  const [yearr, setYearr] = useState(`${year}`);


  const [loading, setLoading] = useState(false); //loading logic
  const handleBillUpload = async (e) => {
    e.preventDefault();
    if (validateForm()) {

      const symbols = '!@#$%^&*()_-+=';
      const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
      const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const numbers = '0123456789';
      const now = new Date();
      const date = now.toLocaleDateString('en-GB').split('/').reverse().join('-'); // Format: YYYY-MM-DD
      const time = now.toLocaleTimeString('en-GB', { hour12: false }).replace(/:/g, '-'); // Format: HH-MM-SS
      const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
      let randomCode = '';
      for (let i = 0; i < 8; i++) {
        const randomIndex = Math.floor(Math.random() * allChars.length);
        randomCode += allChars[randomIndex];
      }
      const originalFile = formData.bill;
      const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile.name}`;
      const customFile = new File([originalFile], customFileName, { type: originalFile.type });

      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/expensedetailsupdate/${i.id}/`, {
          bill: customFile,
          bill_approved: 'pending',
          bill_approved_amt: i.tl_approved_amt,
        }, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (res.status === 200) {
          await getExpenses(date);
          await getSpecificExpenses();
          handleClose();
          // setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button title="Upload Bill" className=" model-button-print" onClick={handleShow} >
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 22" id="upload">
          <g fill="none" fill-rule="evenodd" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
            <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
          </g>
        </svg>
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Upload Bill for: {i.item_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="flex-row justify-center">
              {/* <img className="bill-img" src={i.bill} alt={`Bill Image of ${ i.item_name }`} /> */}

              <div className="flex-column">
                <label
                  htmlFor="bill"
                  // className="form-labels announce-date font-weight500  form-inputss"
                  className="form-labels   font-weight500    announce-date font-size-heading"
                  style={{ width: '8vw' }}
                >
                  Attach Bill
                </label>
                <label
                  title={`${fileName && `${fileName}`}`}
                  htmlFor="file-upload"
                  className={`custom - doc - upload ${errors.bill
                    ? "error"
                    : inputState.bill
                      ? "success"
                      : ""
                    } `}
                >
                  <div htmlFor="file-upload" className="">
                    <div className={`align-center pdf-input  ${errors.bill ? 'error' : ''}`}>
                      <Attachment />
                    </div>
                  </div>
                </label>
                <input
                  id="file-upload"
                  type="file"
                  name="bill"
                  onChange={handleFileChange}
                  style={{ display: "none", position: "relative" }}
                  className="width-5vw"
                />

                <div className="justify-evenly">
                  <div>
                    <br />
                    {fileName && <p className="width-10vw">{fileName}</p>}
                  </div>

                  {formData.bill !== "" ?
                    <div>
                      <br />
                      <button className="file-clear  model-button-cancel" onClick={handleClear}>Clear</button>
                    </div>
                    : null
                  }
                </div>
                {errors.bill && (
                  <span className="error-message font-size-text ">{errors.bill}</span>
                )}
              </div>
            </div>

            <div className="button-models">
              <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                Cancel
              </button>
              <button className="model-button-download model-button font-weight500" onClick={handleBillUpload}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                  <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                  </g>
                </svg>
                {"  "} Upload
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

const TLExpenseApprove = ({ i, j, getExpenseData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  console.log("i")
  console.log(i)
  console.log("j")
  console.log(j)

  const sum = j.some(expense => expense.bill_approved_amt === null)
    ? j.reduce((acc, item) => acc + parseFloat(item.item_amount || 0), 0)
    : j.reduce((acc, item) => acc + parseFloat(item.bill_approved_amt || 0), 0);
  const total =
    Number(i.approved_transportation) +
    Number(i.approved_accomodation) +
    Number(i.approved_fooding) +
    (i.other_details?.length > 0
      ? i.other_details.reduce((total, other) => total + Number(other.approved_other || 0), 0)
      : 0)


  // console.log("sum")
  // console.log(sum)
  // console.log("total")
  // console.log(total)
  // console.log("total-sum")
  // console.log(total - sum)

  const handleTLApproval = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(`${BASE_URL}/wfm/expense/${i.id}/`, {
        rh_status: "approved",
        account_status_a: "pending",
        partially_change: false,
        utilized_amount: sum,
        remaining_amount: total - sum,
        rh_update_datetime: new Date().toISOString(),
      });

      if (res.status === 200) {
        await getExpenseData();
        window.location.reload();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>

      <button className="model-button  font-weight500 model-button-submit" title="TL Expense Approve" onClick={handleShow}>
        Approve
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        <Modal.Body>
          <div className="parent-div">

            <div className="bdy-div">
              <h6 className="task-Tab-heading align-center font-weight500 font-size-heading">Confirm Approval of Expense : "{i.name}" </h6>
              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Date :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {formattedDateLong(i.date)}
                </div>


              </div>

              <div className="flex-row">
                <label
                  htmlFor="project_name"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Expense against Imprest :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.project_name}
                </div>
              </div>

              {/* <hr className="line" /> */}
              <hr className="line" />
              <div className="children">
                <div>
                  <label htmlFor="fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                    Utilized Amount
                  </label>
                  <p className="utilize   font-weight500   -amount align-center" style={{ color: "red" }}>
                    {/* {i.utilized_amount} */}
                    {sum}
                  </p>
                </div>

                <div>
                  <label htmlFor="fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                    Remaining Amount
                  </label>
                  <p className="remaining-amount  font-weight500    align-center" style={{ color: "#2576bc" }}>
                    {/* {i.remaining_amount} */}
                    {total - sum}
                  </p>
                </div>
              </div>



              <div className="button-models">
                <button className="model-button model-button-cancel  font-weight500" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button font-weight500 model-button-leave  font-weight500    font-size-heading"
                  onClick={handleTLApproval}
                >
                  Approve
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal >
    </>
  );
};

const TLExpenseReject = ({ i, j, getExpenseData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const sum = j.some(expense => expense.bill_approved_amt === null)
    ? j.reduce((acc, item) => acc + parseFloat(item.item_amount || 0), 0)
    : j.reduce((acc, item) => acc + parseFloat(item.bill_approved_amt || 0), 0);
  const total =
    Number(i.approved_transportation) +
    Number(i.approved_accomodation) +
    Number(i.approved_fooding) +
    (i.other_details?.length > 0
      ? i.other_details.reduce((total, other) => total + Number(other.approved_other || 0), 0)
      : 0)

  const [formData, setFormData] = useState({
    rh_rejection_reason: "",
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["rh_rejection_reason"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleTLRejection = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        let res = await axios.put(`${BASE_URL}/wfm/expense/${i.id}/`, {
          rh_status: "rejected",
          rh_rejection_reason: formData.rh_rejection_reason,
          utilized_amount: sum,
          remaining_amount: total - sum,
          submit_for_approval: false,
          rh_update_datetime: new Date().toISOString(),
        });

        if (res.status === 200) {
          await getExpenseData();
          window.location.reload();
          // setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

      <button className="model-button  font-weight500 model-button-delete" title="TL Expense Reject" onClick={handleShow}>
        Reject
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          <div className="parent-div">

            <div className="bdy-div">
              <h6 className="task-Tab-heading align-center font-weight500 font-size-heading">Confirm Rejection of Expense : "{i.name}" </h6>

              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Date :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {formattedDateLong(i.date)}
                </div>


              </div>

              <div className="flex-row">
                <label
                  htmlFor="project_name"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Expense against Imprest :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.project_name}
                </div>
              </div>

              {/* <hr className="line" /> */}
              <hr className="line" />
              <div className="children">
                <div>
                  <label htmlFor="fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                    Utilized Amount
                  </label>
                  <p className="utilize   font-weight500   -amount align-center" style={{ color: "red" }}>
                    {sum}
                  </p>
                </div>

                <div>
                  <label htmlFor="fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                    Remaining Amount
                  </label>
                  <p className="remaining-amount  font-weight500    align-center" style={{ color: "#2576bc" }}>
                    {total - sum}
                  </p>
                </div>
              </div>
              <hr className="line" />

              <div className="flex-column">
                <label htmlFor="rh_rejection_reason" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Reporting Head Rejection Reason
                </label>
                <textarea
                  id="rh_rejection_reason"
                  type="text"
                  name="rh_rejection_reason"
                  placeholder="Your Reason"
                  onChange={handleInputChange}
                  maxLength={100}
                  value={formData.rh_rejection_reason}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.rh_rejection_reason
                    ? "error"
                    : inputState.rh_rejection_reason
                      ? "success"
                      : ""
                    }`}
                />
                {errors.rh_rejection_reason && (
                  <span className="error-message font-size-text ">{errors.rh_rejection_reason}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel  font-weight500" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button font-weight500 model-button-delete  font-weight500    font-size-heading"
                  onClick={handleTLRejection}
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal >
    </>
  );
};

const AdminExpenseApprove = ({ i, j, getExpenseData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const sum = j.some(expense => expense.bill_approved_amt === null)
    ? j.reduce((acc, item) => acc + parseFloat(item.item_amount || 0), 0)
    : j.reduce((acc, item) => acc + parseFloat(item.bill_approved_amt || 0), 0);
  const total =
    Number(i.approved_transportation) +
    Number(i.approved_accomodation) +
    Number(i.approved_fooding) +
    (i.other_details?.length > 0
      ? i.other_details.reduce((total, other) => total + Number(other.approved_other || 0), 0)
      : 0)

  const handleAdminApproval = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(`${BASE_URL}/wfm/expense/${i.id}/`, {
        admin_status: "approved",
        utilized_amount: sum,
        remaining_amount: total - sum,
        admin_update_datetime: new Date().toISOString(),
        admin_update_by: sessionStorage.getItem('emp_code'),
      }, AUTH);

      if (res.status === 200) {
        await getExpenseData();
        window.location.reload();
        // setShow(false);
      } else {
        alert(res);
      }
    }
    catch (err) {
      handleErrorToast(err, loadingToastId);
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

      <button className="model-button  font-weight500 model-button-submit" title="Admin Expense Approve" onClick={handleShow}>
        Approve
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          <div className="parent-div">

            <div className="bdy-div">
              <h6 className="task-Tab-heading align-center font-weight500 font-size-heading">Confirm Admin Approval of Expense : "{i.name}" </h6>
              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Date :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {formattedDateLong(i.date)}
                </div>


              </div>

              <div className="flex-row">
                <label
                  htmlFor="project_name"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Expense against Imprest :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.project_name}
                </div>
              </div>

              {/* <hr className="line" /> */}
              <hr className="line" />
              <div className="children">
                <div>
                  <label htmlFor="fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                    Utilized Amount
                  </label>
                  <p className="utilize   font-weight500   -amount align-center" style={{ color: "red" }}>
                    {sum}
                  </p>
                </div>

                <div>
                  <label htmlFor="fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                    Remaining Amount
                  </label>
                  <p className="remaining-amount  font-weight500    align-center" style={{ color: "#2576bc" }}>
                    {total - sum}
                  </p>
                </div>
              </div>



              <div className="button-models">
                <button className="model-button model-button-cancel  font-weight500" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button font-weight500 model-button-leave  font-weight500    font-size-heading"
                  onClick={handleAdminApproval}
                >
                  Approve
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal >



    </>
  );
};

const AdminExpenseReject = ({ i, j, getExpenseData, backroute }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const sum = j.some(expense => expense.bill_approved_amt === null)
    ? j.reduce((acc, item) => acc + parseFloat(item.item_amount || 0), 0)
    : j.reduce((acc, item) => acc + parseFloat(item.bill_approved_amt || 0), 0);
  const total =
    Number(i.approved_transportation) +
    Number(i.approved_accomodation) +
    Number(i.approved_fooding) +
    (i.other_details?.length > 0
      ? i.other_details.reduce((total, other) => total + Number(other.approved_other || 0), 0)
      : 0)

  const [formData, setFormData] = useState({
    admin_rejection_reason: "",
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["admin_rejection_reason"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleAdminRejection = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        let res = await axios.put(`${BASE_URL}/wfm/expense/${i.id}/`, {
          admin_status: "rejected",
          account_status_a: "pending",
          admin_rejection_reason: formData.admin_rejection_reason,
          utilized_amount: sum,
          remaining_amount: total - sum,
          admin_update_datetime: new Date().toISOString(),
          admin_update_by: sessionStorage.getItem('emp_code'),
        });

        if (res.status === 200) {
          await getExpenseData();
          window.location.reload();
          // setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        handleErrorToast(err, loadingToastId)
      } finally {
        toast.dismiss(loadingToastId);
      }
    }

  };


  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

      <button className="model-button  font-weight500 model-button-delete" title="Admin Expense Reject" onClick={handleShow}>
        Reject
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          <div className="parent-div">

            <div className="bdy-div">
              <h6 className="task-Tab-heading align-center font-weight500 font-size-heading">Confirm Rejection of Expense : "{i.name}" </h6>

              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Date :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {formattedDateLong(i.date)}
                </div>


              </div>

              <div className="flex-row">
                <label
                  htmlFor="project_name"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Expense against Imprest :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.project_name}
                </div>
              </div>

              {/* <hr className="line" /> */}
              <hr className="line" />
              <div className="children">
                <div>
                  <label htmlFor="fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                    Utilized Amount
                  </label>
                  <p className="utilize   font-weight500   -amount align-center" style={{ color: "red" }}>
                    {sum}
                  </p>
                </div>

                <div>
                  <label htmlFor="fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                    Remaining Amount
                  </label>
                  <p className="remaining-amount  font-weight500    align-center" style={{ color: "#2576bc" }}>
                    {total - sum}
                  </p>
                </div>
              </div>
              <hr className="line" />

              <div className="flex-column">
                <label htmlFor="admin_rejection_reason" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Admin Rejection Reason
                </label>
                <textarea
                  id="admin_rejection_reason"
                  type="text"
                  name="admin_rejection_reason"
                  placeholder="Your Reason"
                  onChange={handleInputChange}
                  maxLength={100}
                  value={formData.admin_rejection_reason}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.admin_rejection_reason
                    ? "error"
                    : inputState.admin_rejection_reason
                      ? "success"
                      : ""
                    }`}
                />
                {errors.admin_rejection_reason && (
                  <span className="error-message font-size-text ">{errors.admin_rejection_reason}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel  font-weight500" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button font-weight500 model-button-delete  font-weight500    font-size-heading"
                  onClick={handleAdminRejection}
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal >
    </>
  );
};

const AccountAExpenseApprove = ({ i, j, getExpenseData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const sum = j.some(expense => expense.bill_approved_amt === null)
    ? j.reduce((acc, item) => acc + parseFloat(item.item_amount || 0), 0)
    : j.reduce((acc, item) => acc + parseFloat(item.bill_approved_amt || 0), 0);
  const total =
    Number(i.approved_transportation) +
    Number(i.approved_accomodation) +
    Number(i.approved_fooding) +
    (i.other_details?.length > 0
      ? i.other_details.reduce((total, other) => total + Number(other.approved_other || 0), 0)
      : 0)



  const handleAccAApproval = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(`${BASE_URL}/wfm/expense/${i.id}/`, {
        account_status_a: "approved",
        admin_status: "pending",
        utilized_amount: sum,
        remaining_amount: total - sum,
        partially_change: false,
        account_update_datetime: new Date().toISOString(),
        account_update_by: sessionStorage.getItem('emp_code'),
      });

      if (res.status === 200) {
        await getExpenseData();
        window.location.reload();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

      <button className="model-button  font-weight500 model-button-submit" title="Account A Expense Approve" onClick={handleShow}>
        Approve
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          <div className="parent-div">

            <div className="bdy-div">
              <h6 className="task-Tab-heading align-center font-weight500 font-size-heading">Confirm Account Approval of Expense : "{i.name}" </h6>
              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Date :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {formattedDateLong(i.date)}
                </div>


              </div>

              <div className="flex-row">
                <label
                  htmlFor="project_name"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Expense against Imprest :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.project_name}
                </div>
              </div>

              {/* <hr className="line" /> */}
              <hr className="line" />
              <div className="children">
                <div>
                  <label htmlFor="fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                    Utilized Amount
                  </label>
                  <p className="utilize   font-weight500   -amount align-center" style={{ color: "red" }}>
                    {sum}
                  </p>
                </div>

                <div>
                  <label htmlFor="fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                    Remaining Amount
                  </label>
                  <p className="remaining-amount  font-weight500    align-center" style={{ color: "#2576bc" }}>
                    {total - sum}
                  </p>
                </div>
              </div>



              <div className="button-models">
                <button className="model-button model-button-cancel  font-weight500" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button font-weight500 model-button-leave  font-weight500    font-size-heading"
                  onClick={handleAccAApproval}
                >
                  Approve
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal >



    </>
  );
};

const AccountAPartialEdit = ({ i, j, getExpenseData, backUrl, accfirsttoggle }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate()

  const sum = j.some(expense => expense.bill_approved_amt === null)
    ? j.reduce((acc, item) => acc + parseFloat(item.item_amount || 0), 0)
    : j.reduce((acc, item) => acc + parseFloat(item.bill_approved_amt || 0), 0);
  const total =
    Number(i.approved_transportation) +
    Number(i.approved_accomodation) +
    Number(i.approved_fooding) +
    (i.other_details?.length > 0
      ? i.other_details.reduce((total, other) => total + Number(other.approved_other || 0), 0)
      : 0)

  const backroute = (accfirsttoggle) => {
    navigate(backUrl, { state: { accfirsttoggle } })
  }

  const handleEditRejected = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(`${BASE_URL}/wfm/expense/${i.id}/`, {
        partially_change: false,
        account_update_datetime: new Date().toISOString(),
        account_update_by: sessionStorage.getItem('emp_code'),
      });

      if (res.status === 200) {
        await getExpenseData();
        window.location.reload();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  const handleEditApproval = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(`${BASE_URL}/wfm/expense/${i.id}/`, {
        // account_status_a: "pending",
        partially_change: true,
      });

      if (res.status === 200) {
        await getExpenseData();
        backroute(accfirsttoggle);
        window.location.reload();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

      <button className="model-button  font-weight500 model-button-pending" title="Account A Expense Approve" onClick={handleShow}>
        {/* Edit Access */}
        Permit Bill Update
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          <div className="parent-div">

            <div className="bdy-div">
              <h6 className="task-Tab-heading align-center font-weight500 font-size-heading">Confirm Approval for Bill Addition in Expense : "{i.name}" </h6>
              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Date :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {formattedDateLong(i.date)}
                </div>


              </div>

              <div className="flex-row">
                <label
                  htmlFor="project_name"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Expense against Imprest :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.project_name}
                </div>
              </div>

              <div className="flex-row">
                <label
                  htmlFor="project_name"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Current No. of Bills :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {j.length}
                </div>
              </div>

              {/* <hr className="line" /> */}
              <hr className="line" />
              <div className="children">
                <div>
                  <label htmlFor="fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                    Utilized Amount
                  </label>
                  <p className="utilize   font-weight500   -amount align-center" style={{ color: "red" }}>
                    {sum}
                  </p>
                </div>

                <div>
                  <label htmlFor="fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                    Remaining Amount
                  </label>
                  <p className="remaining-amount  font-weight500    align-center" style={{ color: "#2576bc" }}>
                    {total - sum}
                  </p>
                </div>
              </div>



              <div className="button-models">
                {/* <button className="model-button model-button-cancel  font-weight500" onClick={handleClose}>
                  Cancel
                </button> */}
                {
                  i.partially_change ?
                    <button
                      type="submit"
                      className="model-button font-weight500 model-button-delete  font-weight500    font-size-heading"
                      onClick={handleEditRejected}
                    >
                      Lock Edit
                    </button>
                    :
                    <button
                      type="submit"
                      className="model-button font-weight500 model-button-cancel  font-weight500    font-size-heading"
                      onClick={handleEditApproval}
                    >
                      Unlock Edit
                    </button>

                }
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal >



    </>
  );
};

const AccountAExpenseReject = ({ i, j, getExpenseData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const sum = j.some(expense => expense.bill_approved_amt === null)
    ? j.reduce((acc, item) => acc + parseFloat(item.item_amount || 0), 0)
    : j.reduce((acc, item) => acc + parseFloat(item.bill_approved_amt || 0), 0);
  const total =
    Number(i.approved_transportation) +
    Number(i.approved_accomodation) +
    Number(i.approved_fooding) +
    (i.other_details?.length > 0
      ? i.other_details.reduce((total, other) => total + Number(other.approved_other || 0), 0)
      : 0)

  const [formData, setFormData] = useState({
    account1_rejection_reason: "",
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["account1_rejection_reason"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleTLRejection = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        let res = await axios.put(`${BASE_URL}/wfm/expense/${i.id}/`, {
          rh_status: "pending",
          account_status_a: "rejected",
          account1_rejection_reason: formData.account1_rejection_reason,
          utilized_amount: sum,
          remaining_amount: total - sum,
          account_update_datetime: new Date().toISOString(),
          account_update_by: sessionStorage.getItem('emp_code'),
        });

        if (res.status === 200) {
          await getExpenseData();
          window.location.reload();
          // setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

      <button className="model-button  font-weight500 model-button-delete" title="Account A Expense Reject" onClick={handleShow}>
        Reject
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          <div className="parent-div">

            <div className="bdy-div">
              <h6 className="task-Tab-heading align-center font-weight500 font-size-heading">Confirm Rejection of Expense : "{i.name}" </h6>

              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Date :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {formattedDateLong(i.date)}
                </div>

              </div>

              <div className="flex-row">
                <label
                  htmlFor="project_name"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Expense against Imprest :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.project_name}
                </div>
              </div>

              {/* <hr className="line" /> */}
              <hr className="line" />
              <div className="children">
                <div>
                  <label htmlFor="fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                    Utilized Amount
                  </label>
                  <p className="utilize   font-weight500   -amount align-center" style={{ color: "red" }}>
                    {sum}
                  </p>
                </div>

                <div>
                  <label htmlFor="fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                    Remaining Amount
                  </label>
                  <p className="remaining-amount  font-weight500    align-center" style={{ color: "#2576bc" }}>
                    {total - sum}
                  </p>
                </div>
              </div>
              <hr className="line" />

              <div className="flex-column">
                <label htmlFor="account1_rejection_reason" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Accounts Rejection Reason
                </label>
                <textarea
                  id="account1_rejection_reason"
                  type="text"
                  name="account1_rejection_reason"
                  placeholder="Your Reason"
                  onChange={handleInputChange}
                  maxLength={100}
                  value={formData.account1_rejection_reason}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.account1_rejection_reason
                    ? "error"
                    : inputState.account1_rejection_reason
                      ? "success"
                      : ""
                    }`}
                />
                {errors.account1_rejection_reason && (
                  <span className="error-message font-size-text ">{errors.account1_rejection_reason}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel  font-weight500" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button font-weight500 model-button-delete  font-weight500    font-size-heading"
                  onClick={handleTLRejection}
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal >



    </>
  );
};

const AccountBExpenseFinalize = ({ i, j, getExpanseData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = async () => {
    await getExpenseDetails()
    setShow(true)
  };

  const [formData, setFormData] = useState({
    account_status_b: "",
    decision: "",
    settlement: "",
    settlement_amount: "",
    settlement_bill: "",
  });

  const [fileName, setFileName] = useState("");

  const [transportation, setTransportation] = useState(0);
  const [accomodation, setAccomodation] = useState(0);
  const [fooding, setFooding] = useState(0);
  const [other, setOther] = useState(0);

  const [buffer, setBuffering] = useState(false); //buffering logic

  const getExpenseDetails = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/wfm/expensedetailsbyexpense/${i.id}`);
      let trans = 0;
      let acc = 0;
      let food = 0;
      let oth = 0;

      res.data.forEach((item) => {
        const approvedAmount = parseFloat(item.bill_approved_amt);
        switch (item.expense_type) {
          case "transportation":
            trans += approvedAmount;
            break;
          case "accomodation":
            acc += approvedAmount;
            break;
          case "fooding":
            food += approvedAmount;
            break;
          case "other":
            oth += approvedAmount;
            break;
          default:
            break;
        }
      });
      setTransportation(trans);
      setAccomodation(acc);
      setFooding(food);
      setOther(oth);
      console.log(trans, acc, food, oth);
    } catch (err) {
      alert(err)
    }
    finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };


  const remaining_transportation = i.remaining_transportation !== null ? i.remaining_transportation - transportation : i.approved_transportation - transportation;
  const remaining_accomodation = i.remaining_accomodation !== null ? i.remaining_accomodation - accomodation : i.approved_accomodation - accomodation;
  const remaining_fooding = i.remaining_fooding !== null ? i.remaining_fooding - fooding : i.approved_fooding - fooding;
  const remaining_other = i.remaining_other !== null ? i.remaining_other - other : i.approved_other - other;

  const totalRemaining = remaining_transportation + remaining_accomodation + remaining_fooding + remaining_other;
  const imprest_open = totalRemaining >= 0;

  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];

    if (file) {
      setFormData({ ...formData, [name]: file, });
      setFileName(file.name);
    } else {
      setFileName("");
    }
  };

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "decision",
      formData.decision === 'Paid' || formData.decision === 'Salary' ? "settlement_amount" : null,
      formData.decision === 'Paid' ? "settlement_bill" : null,
    ].filter(Boolean);

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleClear = () => {
    setFormData(prevState => ({
      ...prevState,
      settlement_amount: "",
      settlement_bill: "",
    }));
    setFileName("");

  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    // if (name === "decision" && value !== "Paid") {
    //   handleClear();
    // }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAccountFinalize = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      const formDataa = new FormData();

      const symbols = '!@#$%^&*()_-+=';
      const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
      const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const numbers = '0123456789';
      const now = new Date();
      const date = now.toLocaleDateString('en-GB').split('/').reverse().join('-'); // Format: YYYY-MM-DD
      const time = now.toLocaleTimeString('en-GB', { hour12: false }).replace(/:/g, '-'); // Format: HH-MM-SS
      const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
      let randomCode = '';
      for (let i = 0; i < 8; i++) {
        const randomIndex = Math.floor(Math.random() * allChars.length);
        randomCode += allChars[randomIndex];
      }
      const originalFile = formData.settlement_bill;
      const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile.name}`;
      const customFile = new File([originalFile], customFileName, { type: originalFile.type });
      formDataa.append("settlement_bill", customFile);

      try {
        let res = await axios.put(`${BASE_URL}/wfm/expense/${i.id}/`, {
          account_status_b: "approved",
          decision: formData.decision,
          settlement: formDataa.settlement_bill !== "" ? true : false,
          settlement_amount: formData.settlement_amount,
          settlement_bill: formDataa.settlement_bill,
          partially_change: false,
          final_update_datetime: new Date().toISOString(),
          final_update_by: sessionStorage.getItem('emp_code'),
        }, AUTH);

        if (res.status === 200) {
          let imp = await axios.put(`${BASE_URL}/wfm/imprest/${i.imprest}/`, {
            // imprest_open: true,
            // remaining_transportation: i.remaining_transportation !== null ? i.remaining_transportation - transportation : i.approved_transportation - transportation,
            // remaining_accomodation: i.remaining_accomodation !== null ? i.remaining_accomodation - accomodation : i.approved_accomodation - accomodation,
            // remaining_fooding: i.remaining_fooding !== null ? i.remaining_fooding - fooding : i.approved_fooding - fooding,
            // remaining_other: i.remaining_other !== null ? i.remaining_other - other : i.approved_other - other,

            imprest_open: false,
            // imprest_open: imprest_open,
            remaining_transportation: remaining_transportation,
            remaining_accomodation: remaining_accomodation,
            remaining_fooding: remaining_fooding,
            remaining_other: remaining_other,
          });
          if (imp.status === 200) {
            await getExpanseData();
            setShow(false);
          } else {
            console.log("Imprest Opening Error")
            alert(res);
          }
        } else {
          alert(res);
        }
      } catch (err) {
        handleErrorToast(err, loadingToastId);
      } finally {
        toast.dismiss(loadingToastId);
      }
    }
  };

  // console.log("FormData")
  // console.log(formData)

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-edit-button" title="Accounts Final Decision" onClick={handleShow}>
        <CheckGreen />
      </button>
      {buffer ? (
        <div className="spinner-z"></div> //buffering logic
      ) :
        <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
          <Modal.Body>
            <div className="parent-div">

              <div className="bdy-div">
                <h6 className="task-Tab-heading align-center font-weight500 font-size-heading">Confirm Decision for Expense : "{i.name}" </h6>

                <div className="flex-row">
                  <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                    Date :
                  </label>
                  <div className="form-labels announce-date font-size-heading">
                    {formattedDateLong(i.date)}
                  </div>


                </div>

                <div className="flex-row">
                  <label
                    htmlFor="project_name"
                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                  >
                    Expense against Imprest :
                  </label>
                  <div className="form-labels announce-date font-size-heading">
                    {i.project_name}
                  </div>
                </div>
                <div className="flex-row">
                  <label
                    htmlFor="project_name"
                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                  >
                    By -
                  </label>
                  <div className="form-labels announce-date font-size-heading">
                    {i.imprest_data?.imprest_name}({i.imprest_data?.empcode})
                  </div>
                </div>

                {/* <hr className="line" /> */}
                <hr className="line" />
                <div className="children">
                  <div>
                    <label htmlFor="fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                      Utilized Amount
                    </label>
                    <p className="utilize   font-weight500   -amount align-center" style={{ color: "red" }}>
                      {i.utilized_amount}
                    </p>
                  </div>

                  <div>
                    <label htmlFor="fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                      Remaining Amount
                    </label>
                    <p className="remaining-amount  font-weight500    align-center" style={{ color: "#2576bc" }}>
                      {i.remaining_amount}
                    </p>
                  </div>
                </div>
                <hr className="line" />

                <div className="flex-column form-group-selectt">
                  <label htmlFor="decision" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                    Final Decision
                  </label>
                  <br />
                  <select
                    id="decision"
                    name="decision"
                    value={formData.decision}
                    onChange={(e) => {
                      handleInputChange(e);
                      handleClear();
                    }}
                    className={`form-input form-group-selection ${errors.decision ? "error" : formData.decision ? "success" : ""}`}
                  >
                    <option value="">Select Decision</option>
                    <option value="Paid">Paid</option>
                    <option value="Salary">To Salary</option>
                    <option value="Balance">To Balance</option>
                  </select>
                  <DropdownArrow />
                </div>
                <br />
                {errors.decision && (
                  <span className="error-message font-size-text ">{errors.decision}</span>
                )}


                {formData.decision === 'Paid' && (
                  <>
                    <div className="flex-column">
                      <label
                        htmlFor="settlement_amount"
                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                      >
                        Settlement Amount
                      </label>
                      <input
                        id="settlement_amount"
                        name="settlement_amount"
                        type="number"
                        min={0}
                        placeholder="Settlement Amount"
                        onWheel={(e) => e.target.blur()}
                        onChange={handleInputChange}
                        value={formData.settlement_amount}
                        className={`form-input ${errors.settlement_amount
                          ? "error"
                          : inputState.settlement_amount
                            ? "success"
                            : ""
                          }`}
                      />
                      {errors.settlement_amount && (
                        <span className="error-message font-size-text ">{errors.settlement_amount}</span>
                      )}
                    </div>

                    <div className="flex-row">
                      <label htmlFor="decision" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                        Settlement Bill :
                      </label>
                      <div className="flex-column" >
                        <label
                          htmlFor="settlement_bill"
                          className={`align-center  form-inputss custom-file-upload 
                    ${errors.settlement_bill
                              ? "error"
                              : inputState.settlement_bill
                                ? "success"
                                : ""
                            }
                       `}
                        >
                          <div className={`align-center pdf-input  ${errors.settlement_bill ? 'error' : ''}`}>
                            <Attachment />
                          </div>
                        </label>
                        <input
                          id="settlement_bill"
                          name="settlement_bill"
                          type="file"
                          onChange={handleFileChange}
                          onFocus={handleClear}
                          className="file-inputs align-center"
                          style={{ display: "none", position: "relative" }}
                        />
                        <br />
                        <div className="flex-row justify-evenly">
                          <div>
                            {fileName && <p style={{ margin: "0px" }}>{fileName}</p>}
                          </div>
                        </div>
                      </div>
                      {formData.settlement_bill ?
                        <div>
                          <button className="model-button" onClick={handleClear}>Clear</button>
                        </div>
                        : null
                      }
                      {errors.settlement_bill && (
                        <span className="error-message font-size-text ">{errors.settlement_bill}</span>
                      )}
                    </div>

                    {/* <div className="flex-column">
                    <label htmlFor="decision" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                      Settlement Bill
                    </label>
                    <div className="flex-row" >
                      <label
                        htmlFor="settlement_bill"
                        className={`align-center  form-inputss custom-file-upload 
                    ${errors.settlement_bill
                            ? "error"
                            : inputState.settlement_bill
                              ? "success"
                              : ""
                          }
                       `}
                      >
                        <svg
                          className="pdf-input"
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                            stroke="#707070"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </label>
                      <input
                        id="settlement_bill"
                        name="settlement_bill"
                        type="file"
                        onChange={handleFileChange}
                        onFocus={handleClear}
                        className="file-inputs align-center"
                        style={{ display: "none", position: "relative" }}
                      />
                      <br />
                      <div className="flex-row justify-evenly">
                        <div>
                          {fileName && <p style={{ margin: "0px" }}>{fileName}</p>}
                        </div>
                        {formData.settlement_bill !== "" ?
                          <div>
                            <button className="model-button" onClick={handleClear}>Clear</button>
                          </div>
                          : null
                        }
                      </div>
                    </div>
                    {errors.settlement_bill && (
                      <span className="error-message font-size-text ">{errors.settlement_bill}</span>
                    )}
                  </div> */}
                  </>
                )}

                {formData.decision === 'Salary' && (
                  <>
                    <div className="flex-column">
                      <label
                        htmlFor="settlement_amount"
                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                      >
                        Settlement Amount
                      </label>
                      <input
                        id="settlement_amount"
                        name="settlement_amount"
                        type="number"
                        min={0}
                        placeholder="Settlement Amount"
                        onWheel={(e) => e.target.blur()}
                        onChange={handleInputChange}
                        value={formData.settlement_amount}
                        className={`form-input ${errors.settlement_amount
                          ? "error"
                          : inputState.settlement_amount
                            ? "success"
                            : ""
                          }`}
                      />
                      {errors.settlement_amount && (
                        <span className="error-message font-size-text ">{errors.settlement_amount}</span>
                      )}
                    </div>
                  </>
                )}

                {/* <div className="flex-column form-group-selectt">
                <label htmlFor="department" className="form-labels  font-weight500    font-size-subheading">
                  Department<span className="required">*</span>
                </label>
                <br />
                <select
                  id="department"
                  name="department"
                  value={formData.account1_rejection_reason}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.department ? "error" : inputState.department ? "success" : ""
                    }`}
                >
                  <option value="">Select Department</option>

                </select>
                <div className="form-group-selection-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    class="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
                <div>
                  {errors.department && (
                    <span className="error-message font-size-text ">{errors.department}</span>
                  )}
                </div>
              </div> */}
                <br />
                <div className="button-models">
                  <button className="model-button model-button-cancel  font-weight500" onClick={handleClose}>
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="model-button font-weight500 model-button-submit  font-weight500    font-size-heading"
                    onClick={handleAccountFinalize}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal >
      }
    </>
  );
};


export {
  ExpenseDetailApproved,
  ExpenseDetailPartialApproved,
  ExpenseDetailReject,
  BillAuthentication,

  UpdateBill,
  ViewBill,

  TLExpenseApprove,
  TLExpenseReject,
  AdminExpenseApprove,
  AdminExpenseReject,
  AccountAExpenseApprove,
  AccountAPartialEdit,
  AccountAExpenseReject,

  AccountBExpenseFinalize,
}