import { Modal } from 'react-bootstrap';
import { ToastContainer, toast } from "react-toastify";
import { CreateMeetingModal } from './CreateMeetingModal';
const CreateEventModal = ({ isOpen, onClose, selectedTime, selectedDate, modaltoogleOption, timeRange, timeRangeOptions, handleTimeRangeChange,
  handlechooseToogleoption, meetingType, handleSelectMeetingType, showModalmeeting, selectedTimeslot,
  closeMeetingModal, getMeetingData, timeSlots, showViewmeetingModal,
  handleViewMeetingdetailsModal,
  handleCloseMeetingdetailsModal, }) => {
  if (!isOpen) return null;
  console.log(showModalmeeting)
  console.log(isOpen)
  const modalClass = `calendar-meeting-modal right-side ${isOpen ? 'show' : ''}`;
  return (
    <>


      <div className={`calendar-meeting-modal create-event-modal ${isOpen ? 'show' : 'noshow'}`}>
        <div className="calendar-meeting-modal-content">
          <div className="calendar-meeting-modal-header">
            <h2>
              {selectedDate?.toLocaleDateString('en-US', {
                weekday: 'long',
                month: 'long',
                day: 'numeric',
                year: 'numeric',
              })}
              <span className='time-title'> {selectedTimeslot?.start}-{selectedTimeslot?.end}</span>
            </h2>
            <span onClick={onClose} className='close-meeting-modal'>x</span>
          </div>
          <div className='.calendar-meeting-modal-body'>
            <div className="event-options">
              <button
                className="modal-button meeting-btn"
                onClick={(e) => {
                  e.preventDefault()
                  handlechooseToogleoption('meeting')

                }}
              >
                <span className="icon">📅</span>
                Create meeting
              </button>
              <button className="modal-button event-btn">
                <span className="icon">🎯</span>
                Create event
              </button>
              <button className="modal-button task-btn">
                <span className="icon">✓</span>
                Create task
              </button>
              <button className="modal-button reminder-btn">
                <span className="icon">⏰</span>
                Set reminder
              </button>
            </div>

          </div>
        </div>
      </div>
      <CreateMeetingModal meetingType={meetingType} handleSelectMeetingType={handleSelectMeetingType} showModalmeeting={showModalmeeting} closeMeetingModal={closeMeetingModal} modaltoogleOption={modaltoogleOption} selectedTime={selectedTime} getMeetingData={getMeetingData} selectedTimeslot={selectedTimeslot} timeSlots={timeSlots} />
    </>

  );
};

export default CreateEventModal