import axios from 'axios';
import { BASE_URL, IMAGE_URL } from "../../config/axios";
import React, { useState, useEffect } from 'react';
import Calaneder from "../../assets/svg/desk.svg";


// Job Openings
const Newemployee = () => {
    const [newEmployee, setNewEmployee] = useState([]);

    useEffect(() => {
        const getNewEmployee = async () => {
            try {
                const res = await axios.get(`${BASE_URL}/attandance/jobopening/`);
                setNewEmployee(res.data);
            } catch (err) {
                // IMPORTANT: TURN THE BELOW COMMENT OFF!!!
                // alert(err.message);
            }
        };
        getNewEmployee();
    }, []);

    const createMarkup = (content) => {
        const linkRegex = /(?:https?|ftp):\/\/[^\s]+/g;
        return {
            __html: content.replace(linkRegex, (url) => {
                return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
            }),
        };
    };


    return (
        <div className="dashcomp-bg-cards" >

            {newEmployee.map((data, index) => {
                const formattedDate = new Date(data.date).toLocaleString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                });
                return (
                    <>
                        <div className="EmployeeDashboad-notification-card">
                            <h3 className='dashcomp-announcement-heading font-weight500    font-size-subheading  '>{index + 1}.{"  "}{data.title}</h3>
                            <span className="dashcomp-announcement-date  font-weight500   font-size-subheading  ">
                                {/* <img src={} alt="Desk Icon"
                          style={{ color: 'red', marginRight: '8px',
                          width: '16px', height: '16px', marginTop: "-4px" }}
                      /> */}
                                {formattedDate}
                            </span>
                            <p className="dashcomp-announcement-p font-weight400  font-size-text">
                                <div dangerouslySetInnerHTML={createMarkup(data.description)} />
                            </p>
                        </div>
                    </>
                )
            })
            }
        </div>
    )
}


// Main Component
const NewEmployee = () => {

    const [anniversary, setAnniversary] = useState([]);
    // const [selectedImage, setSelectedImage] = useState('');

    useEffect(() => {
        const getBirthdayData = async () => {
            try {
                const res = await axios.get(`${BASE_URL}/wfm/joinies/`);
                setAnniversary(res.data);
            } catch (err) {
                console.error(err.message);
            }
        };
        getBirthdayData();
    }, []);

    return (
        <div className='backgroundmain'>
            <div className='dashboardcomponent box-width'>
                <div className="task-heading">
                    <div className='task-sub-heading  font-weight400  font-size-subheading  '>New Joining Employees</div>
                    {Newemployee}
                </div>
                <div className='birthdaywrap EmployeeDashboad-notification-cards EmployeeDashboad-notification-cards-wrap'>
                    {anniversary
                        // .filter(data => data.division === sessionStorage.getItem('company_id'))
                        // .sort((a, b) => Date(b.joining_date) - Date(a.joining_date))
                        .sort((a, b) => new Date(b.joining_date) - new Date(a.joining_date))
                        .map((data, index) => {

                            const formattedEndDate = new Date(data.joining_date).toLocaleString('en-US', {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric',
                            });


                            return (
                                <>
                                    {/* <Modal show={show} onHide={handleClose} dialogClassName="birthdayimagemodel" >
                                        <Modal.Body>
                                            <div style={{ background: randomBackground, backgroundRepeat: "no-repeat", backgroundSize: "contain" }} className='dashcomp-bgimagee' >
                                                <img src={selectedImage} alt="profile" className='dashcomp-imagee width-5vw' />
                                            </div>
                                        </Modal.Body>
                                    </Modal> */}
                                    <div key={index} className="dashcomp-bg-card dashcomp-bg-card-styling">
                                        <div className='new-flex'>
                                            <img src={data.profilepic ? `${IMAGE_URL}${data.profilepic}` : 'https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'} alt="profile" className=' width-5vw image-radius '
                                            // onClick={() => handleShow(data.profilepic)} 
                                            />
                                            <div>
                                                <h3 className='task-sub-heading  font-weight400  font-size-subheading  ' >Emp. Code: {data.emp_code}</h3>
                                                <h3 className='dashcomp-announcement-heading font-weight500    font-size-subheading  ' >{data.name}</h3>
                                            </div>
                                        </div>
                                        {/* <img src={`${data.image_reward}` ? `${data.image_reward}` : 'https://images.unsplash.com/photo-1578269174936-2709b6aeb913?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'} alt="profile" className='dashcomp-image width-5vw  '
                                        // onClick={() => handleShow(data.profilepic)} 
                                        /> */}
                                        {/* <h3 className='dashcomp-announcement-heading font-weight500    font-size-subheading  ' >{data.title}</h3> */}
                                        <span className="dashcomp-announcement-date  font-weight500   font-size-subheading  ">
                                            <img src={Calaneder} alt="Desk Icon" style={{
                                                color: 'red',
                                                marginRight: '8px', width: '16px', height: '16px', marginTop: "-4px"
                                            }}
                                            />
                                            {formattedEndDate}

                                        </span>
                                        <br />
                                        <div className='new-flex'>
                                            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.5 7H10.25M7.5 11H10.25M7.5 15H10.25M13.75 7H16.5M13.75 11H16.5M13.75 15H16.5M20 21V6.2C20 5.0799 20 4.51984 19.782 4.09202C19.5903 3.71569 19.2843 3.40973 18.908 3.21799C18.4802 3 17.9201 3 16.8 3H7.2C6.07989 3 5.51984 3 5.09202 3.21799C4.71569 3.40973 4.40973 3.71569 4.21799 4.09202C4 4.51984 4 5.0799 4 6.2V21M22 21H2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                            <p className="dashcomp-announcement-p font-weight400  font-size-text">{data.division_name}</p>
                                        </div>
                                        <p className="dashcomp-announcement-p font-weight400  font-size-text">{data.title}</p>
                                        <div className='new-flex'>
                                            <div className='new-flex'>
                                                <svg className='flex-shrink-0' width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M20 21C20 19.6044 20 18.9067 19.8278 18.3389C19.44 17.0605 18.4395 16.06 17.1611 15.6722C16.5933 15.5 15.8956 15.5 14.5 15.5H9.5C8.10444 15.5 7.40665 15.5 6.83886 15.6722C5.56045 16.06 4.56004 17.0605 4.17224 18.3389C4 18.9067 4 19.6044 4 21M16.5 7.5C16.5 9.98528 14.4853 12 12 12C9.51472 12 7.5 9.98528 7.5 7.5C7.5 5.01472 9.51472 3 12 3C14.4853 3 16.5 5.01472 16.5 7.5Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                                <p className="dashcomp-announcement-p font-weight400  font-size-text">{data.designation_name}</p>
                                            </div>
                                            <div className='new-flex'>
                                                <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M16 7C16 6.07003 16 5.60504 15.8978 5.22354C15.6204 4.18827 14.8117 3.37962 13.7765 3.10222C13.395 3 12.93 3 12 3C11.07 3 10.605 3 10.2235 3.10222C9.18827 3.37962 8.37962 4.18827 8.10222 5.22354C8 5.60504 8 6.07003 8 7M5.2 21H18.8C19.9201 21 20.4802 21 20.908 20.782C21.2843 20.5903 21.5903 20.2843 21.782 19.908C22 19.4802 22 18.9201 22 17.8V10.2C22 9.07989 22 8.51984 21.782 8.09202C21.5903 7.71569 21.2843 7.40973 20.908 7.21799C20.4802 7 19.9201 7 18.8 7H5.2C4.07989 7 3.51984 7 3.09202 7.21799C2.71569 7.40973 2.40973 7.71569 2.21799 8.09202C2 8.51984 2 9.07989 2 10.2V17.8C2 18.9201 2 19.4802 2.21799 19.908C2.40973 20.2843 2.71569 20.5903 3.09202 20.782C3.51984 21 4.0799 21 5.2 21Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                                <p className="dashcomp-announcement-p font-weight400  font-size-text">{data.department_name}</p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                </div>
            </div>
        </div>
    )
}

export default NewEmployee;