import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL, FRONTEND_URL, IMAGE_URL } from "../../../config/axios";
import {
  RequestDelete,
  Requestapproved,
  RequestOutdutyapproved,
  RequestOutdutyDelete,
  RequestImprestApproved,
  RequestImprestPartialApprove,
  RequestImprestReject,
  RequestExpenseApproved,
  RequestExpenseReject,
  CompoffShortLeaveReject,
  CompoffShortLeaveApproved,
  RequisitionApprovedRH,
  RequisitionRejectRH,
  RequisitionApprovedTLAdmin,
  RequisitionRejectTLAdmin,
  ClockInApprovedTL,
  ClockInRejectTL,
} from "../.././workforcemanagement/Requestapproved";
import { formatDate, formatDateTime, formattedDate, formattedDateLong } from "../../Date";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { customSortByKey, formatCurrencyIndian, formatRoundoff2D, handleAllError, handleErrorToast } from "../../CustomFunctions";
import usePermission from "../../../config/permissions";
import { Active, CircularGrid, Employee, Eye, Filter } from "../../AllSvg";

const Requestoutdutytable = () => {
  const [checkedItems, setCheckedItems] = useState([]);

  // *********** Employee Details Api Start ***********
  const [allemployee, setAllemployee] = useState([]);
  const [outdutyname, setOutdutyName] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState("pending");

  const [employeeGet, setEmployeeGet] = useState("null");
  const [fromdate, setFromDate] = useState("null");
  const [todate, setToDate] = useState("null");
  const [buffer, setBuffering] = useState(true); //buffering logic

  const getoutdutyname = async () => {
    setBuffering(true); //buffering logic // Start Buffering 
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/odbyrh/${sessionStorage.getItem(
          "emp_code"
        )}/${selectedStatus}/${fromdate}/${todate}/${employeeGet}/`
      );
      setOutdutyName(res.data);
      console.log(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;
        if (typeof errorData.error === 'string') {
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  useEffect(() => {
    getoutdutyname();
  }, [fromdate, todate, selectedStatus, employeeGet]);

  const getEmpcode = async () => {
    setBuffering(true); //buffering logic // Start Buffering 
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/ourcompanysRH/${sessionStorage.getItem("email")}/`
      );
      const sortedData = customSortByKey(res.data, "emp_code");
      setAllemployee(sortedData);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;
        if (typeof errorData.error === 'string') {
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  useEffect(() => {
    getEmpcode();
  }, []);
  // *********** Employee Details Api End ***********

  const handleCheckboxChange = (id) => {
    const currentIndex = checkedItems.indexOf(id);
    const newCheckedItems = [...checkedItems];

    if (currentIndex === -1) {
      newCheckedItems.push(id);
    } else {
      newCheckedItems.splice(currentIndex, 1);
    }

    setCheckedItems(newCheckedItems);
  };

  const updatedate = checkedItems.map((status) => {
    return {
      id: status,
      rh_leave_status: "approved",
    };
  });

  const updatedatee = checkedItems.map((status) => {
    return {
      id: status,
      rh_leave_status: "rejected",
    };
  });

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmitApprovePut = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic

    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.put(
        `${BASE_URL}/wfm/multipleoutdutyupdate/`,
        updatedate
      );

      if (res.status === 200) {
        await getoutdutyname();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setLoading(false); //loading logic
      toast.dismiss(loadingToastId);
    }
  };

  const handleFormSubmitRejectedPut = async (e) => {
    e.preventDefault();

    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.put(
        `${BASE_URL}/wfm/multipleoutdutyupdate/`,
        updatedatee
      );

      if (res.status === 200) {
        await getoutdutyname();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"> </div>
        <div className="attendance-subcont">
          <div className="field-cont">
            <div className="field-cont-div">
              <div className="  date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={fromdate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>

              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <div className="  date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={todate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />
            </div>
            {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="38"
                viewBox="0 0 17 17"
                fill="none"
              >
                <g clip-path="url(#clip0_650_3351)">
                  <mask
                    id="mask0_650_3351"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="40"
                    height="38"
                  >
                    <path d="M17 0H0V17H17V0Z" fill="white" />
                  </mask>
                  <g mask="url(#mask0_650_3351)">
                    <path
                      d="M8.50009 17.0057L8.00425 16.5807C7.32071 16.0083 1.35229 10.8538 1.35229 7.08334C1.35229 5.18763 2.10536 3.36956 3.44583 2.02909C4.78631 0.688616 6.60437 -0.0644531 8.50009 -0.0644531C10.3958 -0.0644531 12.2139 0.688616 13.5543 2.02909C14.8948 3.36956 15.6479 5.18763 15.6479 7.08334C15.6479 10.8538 9.67946 16.0083 8.99875 16.5835L8.50009 17.0057ZM8.50009 1.48113C7.0148 1.48282 5.59084 2.07359 4.54059 3.12384C3.49033 4.1741 2.89956 5.59806 2.89787 7.08334C2.89787 9.452 6.56987 13.236 8.50009 14.9678C10.4303 13.2352 14.1023 9.44917 14.1023 7.08334C14.1006 5.59806 13.5098 4.1741 12.4596 3.12384C11.4093 2.07359 9.98537 1.48282 8.50009 1.48113Z"
                      fill="#707070"
                    />
                    <path
                      d="M8.50008 9.91666C7.9397 9.91666 7.39191 9.7505 6.92597 9.43917C6.46003 9.12784 6.09687 8.68533 5.88242 8.16761C5.66798 7.64988 5.61186 7.08019 5.7212 6.53058C5.83052 5.98097 6.10036 5.47612 6.49662 5.07986C6.89286 4.68362 7.39771 4.41376 7.94734 4.30444C8.49695 4.19512 9.06663 4.25123 9.58436 4.46568C10.1021 4.68013 10.5446 5.04329 10.8559 5.50922C11.1672 5.97516 11.3334 6.52295 11.3334 7.08334C11.3334 7.83478 11.0349 8.55545 10.5036 9.0868C9.97221 9.61816 9.25154 9.91666 8.50008 9.91666ZM8.50008 5.66666C8.2199 5.66666 7.946 5.74975 7.71302 5.90542C7.48006 6.06109 7.29848 6.28234 7.19125 6.5412C7.08404 6.80007 7.05597 7.08491 7.11063 7.35972C7.1653 7.63452 7.30022 7.88694 7.49835 8.08507C7.69648 8.2832 7.9489 8.41811 8.2237 8.47279C8.49851 8.52745 8.78336 8.49939 9.04221 8.39217C9.30108 8.28494 9.52233 8.10337 9.678 7.8704C9.83367 7.63742 9.91675 7.36353 9.91675 7.08334C9.91675 6.70761 9.7675 6.34728 9.50181 6.08161C9.23614 5.81592 8.87581 5.66666 8.50008 5.66666Z"
                      fill="#707070"
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_650_3351">
                    <rect width="40" height="38" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <select
                className="attendance-input-field width-5vw   date-field"
                type="text"
                value={selectedRhStatus}
                onChange={(e) => setSelectedRHStatus(e.target.value)}
              >
                <option value="null">All</option>
  
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
  
              </select> */}
            <div className="field-cont-div">
              <Filter />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="null">All</option>

                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Employee />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={employeeGet}
                onChange={(e) => setEmployeeGet(e.target.value)}
              >
                <option value="">Select Employee Code</option>
                {allemployee
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
              </select>
            </div>
          </div>
          <div className="btn-cont">
            {/* <button className="attendance-btn" onClick={getoutdutyname}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 11 12"
                  fill="none"
                >
                  <circle
                    cx="5"
                    cy="5"
                    r="4.3"
                    stroke="#FFFFFF"
                    stroke-width="1.4"
                  />
                  <line
                    x1="10.0101"
                    y1="11"
                    x2="8"
                    y2="8.98995"
                    stroke="#FFFFFF"
                    stroke-width="1.4"
                    stroke-linecap="round"
                  />
                </svg>
                Search
              </button> */}
          </div>
        </div>

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500    font-size-heading">Out Duty </div>
            {/* <div className="flex-row">
              <button
                classname="accept  font-weight300 font-size-text "
                onClick={handleFormSubmitApprovePut}
                disabled={loading}
              >
                Accept
              </button>
              <button
                classname="accept-rej   font-weight300 font-size-text "
                onClick={handleFormSubmitRejectedPut}
              >
                Rejected
              </button>
            </div> */}
          </div>
          <table className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="align-left">Sr.no</th>
                {/* <th className="align-center">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        const ids = outdutyname.map((data) => data.id);
                        setCheckedItems(ids);
                      } else {
                        setCheckedItems([]);
                      }
                    }}
                  />
                </th> */}

                {/* <th className="align-center">Date</th> */}
                <th className="align-center">Empolyee Name</th>
                <th className="align-center">Outduty Start</th>
                <th className="align-center">Outduty End</th>
                <th className="align-center">Remarks</th>
                <th className="align-center">Outduty Type</th>
                <th className="align-right width-5vw ">Action</th>
              </tr>
            </thead>
            {buffer ? <div className="spinner"></div> : //buffering logic
              <tbody>
                {outdutyname.map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="6"></td>
                    </tr>
                    <tr className="custom-table-head-td">
                      <td className="align-left">{index + 1}</td>
                      {/* <td className="align-center">
                        <input
                          type="checkbox"
                          checked={checkedItems.indexOf(i.id) !== -1}
                          onChange={() => handleCheckboxChange(i.id)}
                        />
                      </td> */}
                      {/* <td className="align-center">{i.date}</td> */}
                      <td className="align-center">
                        <img
                          className="profile-img"
                          src={
                            `${i.profilepic}`
                              ? `${IMAGE_URL}${i.profilepic}`
                              : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          }
                          alt="profilepic"
                        />
                        {i.name}
                      </td>
                      <td className="align-center">{formatDate(i.date)}</td>
                      <td className="align-center">{formatDate(i.end_date)}</td>
                      <td className="align-center">{i.remarks}</td>
                      <td className="align-center form-text-trasformation-uppercase">
                        {i.outduty_Duration}
                      </td>
                      <td className="align-center width-5vw">
                        {i.admin_leave_status === "pending"
                          // && i.rh_leave_status === "pending" 
                          ? (
                            <div className="flex-row">
                              <RequestOutdutyapproved
                                i={i}
                                getoutdutyname={getoutdutyname}
                              />
                              <RequestOutdutyDelete
                                i={i}
                                getoutdutyname={getoutdutyname}
                              />
                            </div>
                          ) :
                          <>
                            <span
                              className="request-status align-center form-text-trasformation-uppercase"
                              request-status={i.rh_leave_status}
                            >
                              RH : {i.rh_leave_status}
                            </span>
                            <br />
                            {/* <span
                              className="request-status align-center"
                              request-status={i.admin_leave_status}
                            >
                              Admin: {i.admin_leave_status}
                            </span> */}

                          </>
                        }

                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>}
          </table>
        </div>
      </div>
    </>
  );
};

const RequestLeavetable = () => {
  const [checkedItems, setCheckedItems] = useState([]);

  // *********** Employee Details Api Start ***********
  const [allemployee, setAllemployee] = useState([]);
  const [outdutyname, setOutdutyName] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState("pending");

  const [employeeGet, setEmployeeGet] = useState("null");
  const [fromdate, setFromDate] = useState("null");
  const [todate, setToDate] = useState("null");
  const [buffer, setBuffering] = useState(true); //buffering logic

  const getoutdutyname = async () => {
    setBuffering(true); //buffering logic // Start Buffering 
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/leavebyrh/${sessionStorage.getItem(
          "emp_code"
        )}/${selectedStatus}/${fromdate}/${todate}/${employeeGet}/`
      );
      setOutdutyName(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;
        if (typeof errorData.error === 'string') {
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  useEffect(() => {
    getoutdutyname();
  }, [fromdate, todate, selectedStatus, employeeGet]);

  const getEmpcode = async () => {
    setBuffering(true); //buffering logic // Start Buffering 
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/ourcompanysRH/${sessionStorage.getItem("email")}/`
      );
      const sortedData = customSortByKey(res.data, "emp_code");
      setAllemployee(sortedData);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;
        if (typeof errorData.error === 'string') {
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };
  useEffect(() => {
    getEmpcode();
  }, []);

  // *********** Employee Details Api End ***********

  const handleCheckboxChange = (id) => {
    const currentIndex = checkedItems.indexOf(id);
    const newCheckedItems = [...checkedItems];

    if (currentIndex === -1) {
      newCheckedItems.push(id);
    } else {
      newCheckedItems.splice(currentIndex, 1);
    }

    setCheckedItems(newCheckedItems);
  };

  const updatedate = checkedItems.map((status) => {
    return {
      id: status,
      rh_leave_status: "approved",
    };
  });

  const updatedatee = checkedItems.map((status) => {
    return {
      id: status,
      rh_leave_status: "rejected",
    };
  });

  const handleFormSubmitApprovePut = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(
        `${BASE_URL}/wfm/multipleleaveupdate/`,
        updatedate
      );

      if (res.status === 200) {
        await getoutdutyname();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  const handleFormSubmitRejectedPut = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(
        `${BASE_URL}/wfm/multipleleaveupdate/`,
        updatedatee
      );

      if (res.status === 200) {
        await getoutdutyname();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"> </div>
        <div className="attendance-subcont">
          <div className="field-cont">
            <div className="field-cont-div">
              <div className="  date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={fromdate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>

              <hr className="field-cont-hr" />
            </div>
            <div className="field-cont-div">
              <div className="  date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={todate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />
            </div>
            <div className="field-cont-div">
              <Filter />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="null">All</option>

                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Employee />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={employeeGet}
                onChange={(e) => setEmployeeGet(e.target.value)}
              >
                <option value="">Select Employee Code</option>
                {allemployee
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
              </select>
            </div>
          </div>
          <div className="btn-cont">
            {/* <button className="attendance-btn" onClick={getoutdutyname}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 11 12"
                fill="none"
              >
                <circle
                  cx="5"
                  cy="5"
                  r="4.3"
                  stroke="#FFFFFF"
                  stroke-width="1.4"
                />
                <line
                  x1="10.0101"
                  y1="11"
                  x2="8"
                  y2="8.98995"
                  stroke="#FFFFFF"
                  stroke-width="1.4"
                  stroke-linecap="round"
                />
              </svg>
              Search
            </button> */}
          </div>
        </div>

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500    font-size-heading">Leave </div>
            {/* <div className="flex-row">
              <button classname="accept  font-weight300font-size-text " onClick={handleFormSubmitApprovePut}>
                Accept
              </button>
              <button
                classname="accept-rej   font-weight300 font-size-text "
                onClick={handleFormSubmitRejectedPut}
              >
                Rejected
              </button>
            </div> */}
          </div>
          <table className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="align-left">Sr.no</th>
                {/* <th className="align-center">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        const ids = outdutyname.map((data) => data.id);
                        setCheckedItems(ids);
                      } else {
                        setCheckedItems([]);
                      }
                    }}
                  />
                </th> */}

                {/* <th className="align-center">Date</th> */}
                <th className="align-center">Empolyee Name</th>
                <th className="align-center">Leave Start</th>
                <th className="align-center">Leave End</th>
                <th className="align-center">Leave Duration</th>
                <th className="align-center">Leave Type</th>
                <th className="align-center">Leave Balance</th>
                <th className="align-center">Remarks</th>
                <th className="align-right ">Action</th>
              </tr>
            </thead>
            {buffer ? <div className="spinner"></div> : //buffering logic
              <tbody>
                {outdutyname.map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="6"></td>
                    </tr>
                    <tr title={i.rh_leave_status} className="custom-table-head-td">
                      <td className="align-left">{index + 1}</td>
                      {/* <td className="align-center">
                        <input
                          type="checkbox"
                          checked={checkedItems.indexOf(i.id) !== -1}
                          onChange={() => handleCheckboxChange(i.id)}
                        />
                      </td> */}
                      {/* <td className="align-center">{i.date}</td> */}
                      <td className="align-center">
                        <img
                          className="profile-img"
                          src={
                            `${i.profilepic}`
                              ? `${IMAGE_URL}${i.profilepic}`
                              : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          }
                          alt="profilepic"
                        />
                        {i.name}
                      </td>
                      <td className="align-center">{formatDate(i.date)}</td>
                      <td className="align-center">{formatDate(i.end_date)}</td>
                      <td className="align-center form-text-trasformation-uppercase">
                        {i.leave_Duration}
                      </td>
                      <td className="align-center form-text-trasformation-uppercase">
                        {i.leave_type ? i.leave_type : "Previous leaves"}
                      </td>
                      <td
                        className="align-center"
                        title={`${i.leave_type ? i.leave_type : "previous leaves"
                          }`}
                      >
                        {i.leave_type == "sick leave" ? i.sick_leave : ""}
                        {i.leave_type == "casual leave" ? i.casual_leave : ""}
                        {i.leave_type == "earn leave" ? i.earn_leave : ""}
                        {i.leave_type == "previous leave" ? i.leavebalance : ""}
                        {i.leave_type == "" || i.leave_type == null
                          ? i.leavebalance
                          : ""}
                      </td>
                      <td className="align-center">{i.remarks}</td>
                      {/* <td className="align-right flex-row ">
                        <Requestapproved i={i} getoutdutyname={getoutdutyname} />
                        <RequestDelete i={i} getoutdutyname={getoutdutyname} />
                      </td> */}
                      <td className="align-center width-5vw">
                        {i.admin_leave_status === "pending"
                          ? (
                            <div className="flex-row">
                              <Requestapproved i={i} getoutdutyname={getoutdutyname} />
                              <RequestDelete i={i} getoutdutyname={getoutdutyname} />
                            </div>
                          ) :
                          <>
                            <span
                              className="request-status align-center form-text-trasformation-uppercase"
                              request-status={i.rh_leave_status}
                            >
                              RH : {i.rh_leave_status}
                            </span>
                          </>
                        }

                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            }
          </table>
        </div>
      </div>
    </>
  );
};

const RequestMisspunchtable = () => {
  const [checkedItems, setCheckedItems] = useState([]);
  const [employeeGet, setEmployeeGet] = useState("null");

  // *********** Employee Details Api Start ***********
  const [allemployee, setAllemployee] = useState([]);
  const [outdutyname, setOutdutyName] = useState([]);

  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [startdate, setStartDate] = useState("");

  const getoutdutyname = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/department/`);
      // setAllemployee(res.data);
      setOutdutyName(res.data);
    } catch (err) {
      // alert(err.message);
    }
  };
  useEffect(() => {
    getoutdutyname();
  }, []);

  const getEmpcode = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/ourcompanysRH/${sessionStorage.getItem("email")}/`
      );
      const sortedData = customSortByKey(res.data, "emp_code");
      setAllemployee(sortedData);
    } catch (err) {
      // alert(err.message);
    }
  };
  useEffect(() => {
    getEmpcode();
  }, []);

  // *********** Employee Details Api End ***********

  const handleCheckboxChange = (id) => {
    const currentIndex = checkedItems.indexOf(id);
    const newCheckedItems = [...checkedItems];

    if (currentIndex === -1) {
      newCheckedItems.push(id);
    } else {
      newCheckedItems.splice(currentIndex, 1);
    }

    setCheckedItems(newCheckedItems);
  };

  const updatedate = checkedItems.map((status) => {
    return {
      id: status,
      rh_leave_status: "approved",
    };
  });

  const handleFormSubmitApprovePut = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.put(
        `${BASE_URL}/wfm/multiplemispunchupdate/`,
        updatedate
      );

      if (res.status === 200) {
        await getoutdutyname();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  const updatedatee = checkedItems.map((status) => {
    return {
      id: status,
      rh_leave_status: "rejected",
    };
  });

  const handleFormSubmitRejectedPut = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.put(
        `${BASE_URL}/wfm/multiplemispunchupdate/`,
        updatedatee
      );

      if (res.status === 200) {
        await getoutdutyname();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"> </div>
        <div className="attendance-subcont">
          <div className="field-cont">
            <div className="field-cont-div">
              <div className="  date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={startdate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>

              <hr className="field-cont-hr" />
            </div>


            <div className="field-cont-div">
              <div className="attendance-input-field width-10vw   date-uppercase">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 19 19"
                  fill="none"
                >
                  <g clip-path="url(#clip0_915_905)">
                    <path
                      d="M14.4907 3.3374H3.99072C3.1623 3.3374 2.49072 4.00898 2.49072 4.8374V15.3374C2.49072 16.1658 3.1623 16.8374 3.99072 16.8374H14.4907C15.3191 16.8374 15.9907 16.1658 15.9907 15.3374V4.8374C15.9907 4.00898 15.3191 3.3374 14.4907 3.3374Z"
                      stroke="#707070"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12.2407 1.8374V4.8374"
                      stroke="#707070"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.24072 1.8374V4.8374"
                      stroke="#707070"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2.49072 7.8374H15.9907"
                      stroke="#707070"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_915_905">
                      <rect
                        width="18"
                        height="18"
                        fill="white"
                        transform="translate(0.240723 0.337402)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>

              <hr className="field-cont-hr" />
            </div>
            <div className="field-cont-div">
              <Active />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="">Status</option>

                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
              {/* <hr />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 14 14"
              fill="none"
            >
              <g clip-path="url(#clip0_650_3324)">
                <path
                  d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                  fill="#707070"
                />
                <path
                  d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                  fill="#707070"
                />
                <path
                  d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                  fill="#707070"
                />
                <path
                  d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                  fill="#707070"
                />
                <path
                  d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                  fill="#707070"
                />
                <path
                  d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                  fill="#707070"
                />
                <path
                  d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                  fill="#707070"
                />
                <path
                  d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                  fill="#707070"
                />
                <path
                  d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                  fill="#707070"
                />
              </g>
              <defs>
                <clipPath id="clip0_650_3324">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <input
              className="attendance-input-field width-5vw  "
              placeholder="Job Title"
              type="text"
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
            />*/}

              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Employee />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={employeeGet}
                onChange={(e) => setEmployeeGet(e.target.value)}
              >
                <option value="">Select Employee Code</option>
                {allemployee
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
              </select>
            </div>
          </div>

          <div className="btn-cont">
            {/* <button className="attendance-btn" onClick={getoutdutyname}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 11 12"
                fill="none"
              >
                <circle
                  cx="5"
                  cy="5"
                  r="4.3"
                  stroke="#FFFFFF"
                  stroke-width="1.4"
                />
                <line
                  x1="10.0101"
                  y1="11"
                  x2="8"
                  y2="8.98995"
                  stroke="#FFFFFF"
                  stroke-width="1.4"
                  stroke-linecap="round"
                />
              </svg>
              Search
            </button> */}
          </div>
        </div>

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500    font-size-heading">Miss Punch </div>
            <div className="flex-row">
              <dir classname="accept  font-weight300font-size-text ">
                <a href="">Accept</a>
              </dir>
              <div classname="accept-rej   font-weight300 font-size-text ">
                <a href="">Rejected</a>
              </div>
            </div>
          </div>
          <table className="table-css">
            <thead>
              <tr className="table-css-head-tr">
                <th className="align-left">Sr.no</th>
                <th className="align-left">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        const ids = allemployee.map((data) => data.id);
                        setCheckedItems(ids);
                      } else {
                        setCheckedItems([]);
                      }
                    }}
                  />
                </th>

                <th className="align-center">Date</th>
                <th className="align-center">Empolyee Name</th>
                <th className="align-center">OD applied</th>
                <th className="align-center">OD end</th>
                <th className="align-center">Remarks</th>
                <th className="align-center">Leave type</th>
                <th className="align-right ">Action</th>
              </tr>
            </thead>
            <tbody>
              {allemployee.map((i, index) => (
                <React.Fragment key={index}>
                  <tr className="tr-border-bottom">
                    <td colSpan="6"></td>
                  </tr>
                  <tr className="custom-table-head-td">
                    <td className="align-left">{index + 1}</td>
                    <td className="align-left">
                      <input
                        type="checkbox"
                        checked={checkedItems.indexOf(i.id) !== -1}
                        onChange={() => handleCheckboxChange(i.id)}
                      />
                    </td>
                    <td className="align-center">{i.date}</td>
                    <td className="align-center">{i.empoloyee_name}</td>
                    <td className="align-center">{i.odapplied}</td>
                    <td className="align-center">{i.odend}</td>
                    <td className="align-center">{i.remark}</td>
                    <td className="align-center">{i.leavetype}</td>
                    <td className="align-right flex-row ">
                      <Requestapproved i={i} getoutdutyname={getoutdutyname} />
                      <RequestDelete i={i} getoutdutyname={getoutdutyname} />
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

const RequestCompensatorytable = ({ }) => {
  const [checkedItems, setCheckedItems] = useState([]);
  // const [date, setDate] = useState();
  const [employeeList, setEmployeeList] = useState([]);
  const [date, setDate] = useState(`${formattedDate}`);
  const [fromdate, setFromDate] = useState("null");
  const [todate, setToDate] = useState("null");
  const [selectedStatus, setSelectedStatus] = useState("pending");
  const [employeeGet, setEmployeeGet] = useState("null");
  const [buffer, setBuffering] = useState(true); //buffering logic

  // *********** Employee Details Api Start ***********
  const [compensatoryList, setCompensatoryList] = useState([]);
  const [filteredCompensatoryList, setfilteredCompensatoryList] = useState([]);

  const getEmpList = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/ourcompanysRH/${sessionStorage.getItem("email")}/`
      );
      const sortedData = customSortByKey(res.data, "emp_code");
      setEmployeeList(sortedData);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  const getCompensatoryList = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      // const res = await axios.get(`${BASE_URL}/wfm/shortleaveandcompoff/${sessionStorage.getItem("emp_code")}/`);
      const res = await axios.get(
        // `${BASE_URL}/wfm/shortandcompoff/${fromdate}/${todate}/${sessionStorage.getItem("emp_code")}/${selectedStatus}/${employeeGet}/${sessionStorage.getItem('company_id')}/active/`
        `${BASE_URL}/wfm/shortandcompoff/${fromdate}/${todate}/${sessionStorage.getItem("emp_code")}/${selectedStatus}/${employeeGet}/null/active/`
      );
      // const res = await axios.get(`${BASE_URL}/wfm/shortleaveandcompoff/${sessionStorage.getItem("email")}/`);
      setCompensatoryList(res.data);
      // setOutdutyName(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  // *********** Employee Details Api End ***********

  useEffect(() => {
    getEmpList();
    getCompensatoryList();
    // applyFilters();
  }, [employeeGet, date, fromdate, todate, selectedStatus]);

  const handleCheckboxChange = (id) => {
    const currentIndex = checkedItems.indexOf(id);
    const newCheckedItems = [...checkedItems];

    if (currentIndex === -1) {
      newCheckedItems.push(id);
    } else {
      newCheckedItems.splice(currentIndex, 1);
    }
    setCheckedItems(newCheckedItems);
  };

  const updatedate = checkedItems.map((status) => {
    return {
      id: status,
      rh_leave_status: "approved",
    };
  });

  const handleFormSubmitApprovePut = async (e) => {
    e.preventDefault();

    try {
      console.log("Update Approve")
      console.log(updatedate)
      toast.warning("Work in progress!");

      // let res = await axios.put(
      //   `${BASE_URL}/wfm/multiplecompoffupdate/`,
      //   updatedate
      // );

      // if (res.status === 200) {
      //   await getCompensatoryList();
      //   // setShow(false);
      // } else {
      //   alert(res);
      // }
    } catch (err) {
      handleErrorToast(err)
    }
  };

  const updatedatee = checkedItems.map((status) => {
    return {
      id: status,
      rh_leave_status: "rejected",
    };
  });

  const handleFormSubmitRejectedPut = async (e) => {
    e.preventDefault();

    try {
      console.log("Update Reject")
      console.log(updatedatee)
      toast.warning("Work in progress!");
      // let res = await axios.put(
      //   `${BASE_URL}/wfm/multiplecompoffupdate/`,
      //   updatedatee
      // );

      // if (res.status === 200) {
      //   await getCompensatoryList();
      //   // setShow(false);
      // } else {
      //   alert(res);
      // }
    } catch (err) {
      handleErrorToast(err)
    }
  };


  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"> </div>
        <div className="attendance-subcont">
          <div className="field-cont">
            <div className="field-cont-div">
              <div className=" date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={fromdate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <div className="  date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={todate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Filter />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="null">All</option>

                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Employee />
              <select
                className="attendance-input-field width-15vw   date-field"
                type="text"
                value={employeeGet}
                onChange={(e) => setEmployeeGet(e.target.value)}
              >
                <option value="">Select Employee Code</option>
                {employeeList
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
              </select>
            </div>
          </div>

          <div className="btn-cont"></div>
        </div>

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500 font-size-heading">Compensatory leave </div>
            <div className="flex-row">
              {/* <button
                classname="model-button model-button-cancel"
                onClick={handleFormSubmit}
              >
                Accept
              </button> */}
              {(checkedItems.length > 0) ?
                <>
                  <div className="align-center">
                    <div className=" font-size-heading">{`Selected : ${checkedItems.length}`} </div>
                  </div>
                  <hr className="field-cont-hr" />
                </>
                : null
              }
              {/* <button className="model-button model-button-cancel   font-weight500"
                onClick={handleFormSubmitApprovePut}
              >
                Accept
              </button>
              <hr className="field-cont-hr" />
              <button className="model-button model-button-delete   font-weight500"
                onClick={handleFormSubmitRejectedPut}
              >
                Rejected
              </button> */}
              <br />
              {/* <button
                classname="model-button model-button-cancel"
                onClick={() => handleFormSubmitRejectedPut()}
              >
                Rejected
              </button> */}
              {/* <div classname=" model-button accept-rej font-weight300 font-size-text "><a href="">Rejected</a></div> */}
            </div>
          </div>
          <table className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="align-center">Sr.no</th>
                {/* <th className="align-left">
                  <input type="checkbox" onChange={(e) => {
                    if (e.target.checked) {
                      const ids = compensatoryList.map((data) => data.id);
                      setCheckedItems(ids);
                    } else {
                      setCheckedItems([]);
                    }
                  }} />
                </th> */}

                {/* <th className="align-center">Date</th> */}
                <th className="align-center">Empolyee Name</th>
                <th className="align-center">Date</th>
                <th className="align-center">In-Time</th>
                <th className="align-center">Out-Time</th>
                <th className="align-center">Working Hours</th>
                <th className="align-center">Compensatory Type</th>
                <th className="align-right ">Action</th>
              </tr>
            </thead>
            {buffer ? <div className="spinner"></div> : //buffering logic
              <tbody>
                {compensatoryList.map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="6"></td>
                    </tr>
                    <tr className="custom-table-head-td">
                      <div className="width-5vw">

                        <td className="align-left">{index + 1}</td>
                        {/* <td className="align-left ">
                          <input
                            type="checkbox"
                            checked={checkedItems.indexOf(i.id) !== -1}
                            onChange={() => handleCheckboxChange(i.id)}
                          />
                        </td> */}
                      </div>
                      {/* <td className="align-center">{i.date}</td> */}
                      <td className="align-center">
                        <img
                          className="profile-img"
                          src={
                            `${i.profilepic}`
                              ? `${IMAGE_URL}${i.profilepic}`
                              : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          }
                          alt="profilepic"
                        />
                        {i.empcode}-{i.name}
                      </td>
                      {/* <td className="align-center">{i.empcode}-{i.name}</td> */}
                      <td className="align-center">
                        {formattedDateLong(i.date)}
                      </td>
                      <td className="align-center">{i.in_time}</td>
                      <td className="align-center">{i.out_time}</td>
                      <td className="align-center">{i.work_duration}</td>
                      <td className="align-center form-text-trasformation-uppercase">
                        {i.duration}
                      </td>
                      <td className="align-right ">
                        {i.rh_leave_status === "pending" ? (
                          <div className="flex-row align-center width-5vw">
                            <CompoffShortLeaveApproved
                              i={i}
                              getCompensatoryList={getCompensatoryList}
                            />
                            <CompoffShortLeaveReject
                              i={i}
                              getCompensatoryList={getCompensatoryList}
                            />
                          </div>
                        ) : (
                          i.rh_leave_status === "rejected" ?
                            <div className="flex-row align-center width-5vw">
                              <CompoffShortLeaveApproved
                                i={i}
                                getCompensatoryList={getCompensatoryList}
                              />
                            </div>
                            : null
                        )}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            }
          </table>
        </div>
      </div>
    </>
  );
};

const TeamLeaveLogTable = () => {
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
  const [checkedItems, setCheckedItems] = useState([]);
  // const [date, setDate] = useState();
  const [buffer, setBuffering] = useState(true); //buffering logic
  const [employeeList, setEmployeeList] = useState([]);
  // const [date, setDate] = useState(`${formattedDate}`);
  const [fromdate, setFromDate] = useState("null");
  const [todate, setToDate] = useState("null");
  const [selectedStatus, setSelectedStatus] = useState("null");
  const [employeeGet, setEmployeeGet] = useState("null");
  const [active, setActive] = useState("active");
  const [selectedDivision, setDivision] = useState(
    `${sessionStorage.getItem("company_id")}`
  );

  const [SubCompany, setSubCompany] = useState([]);

  const getSubCompany = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/subcompany/`);
      setSubCompany(res.data);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  // *********** Employee Details Api Start ***********
  const [leaveLogList, setLeaveLogList] = useState([]);
  const [filteredleaveLogList, setfilteredleaveLogList] = useState([]);

  const getEmpList = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/ourcompanyempdetails/${active}/${selectedDivision}/`
      );
      const sortedData = customSortByKey(res.data, "emp_code");

      setEmployeeList(sortedData);
    } catch (err) {
      handleAllError(err)
    }
  };

  const getLeaveLogList = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/shortandcompoff/${fromdate}/${todate}/null/${selectedStatus}/${employeeGet}/${selectedDivision}/${active}/`
      );
      setLeaveLogList(res.data);
      // setOutdutyName(res.data);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  // *********** Employee Details Api End ***********

  useEffect(() => {
    getLeaveLogList();
    getEmpList();
    getSubCompany();
  }, [employeeGet, fromdate, todate, selectedStatus, active, selectedDivision]);

  useEffect(() => {
  }, []);

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"> </div>
        <div className="attendance-subcont">
          <div className="field-cont">

            <div className="field-cont-div">
              <div className="date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={fromdate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />

            </div>

            <div className="field-cont-div">
              <div className=" date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={todate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />

            </div>

            <div className="field-cont-div">
              <Filter />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="null">All</option>

                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
              <hr className="field-cont-hr" />

            </div>

            <div className="field-cont-div">
              <Employee />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={employeeGet}
                onChange={(e) => setEmployeeGet(e.target.value)}
              >
                <option value="">Select Employee Code</option>
                {employeeList
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
              </select>

              <hr className="field-cont-hr" />

            </div>

            <div className="field-cont-div">
              <Active />
              <select
                className="attendance-input-field width-10vw  "
                type="text"
                value={active}
                onChange={(e) => setActive(e.target.value)}
              >
                <option value="null">All</option>
                <option value="active">Active</option>
                <option value="inactive">In Active</option>
              </select>
              <hr className="field-cont-hr" />
            </div>

          </div>

          <div className="btn-cont"></div>
        </div>

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500    font-size-heading">Team Leave History </div>
          </div>
          <table className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="align-left">Sr.no</th>
                <th className="align-center">Date - Time</th>
                <th className="align-center">Leave Type</th>
                <th className="align-center">Balance Before</th>
                <th className="align-center">Leaves Affected</th>
                <th className="align-center">Balance After</th>
                <th className="align-center width-20vw">Description</th>
              </tr>
            </thead>
            {buffer ? (
              <div className="spinner"></div>
            ) : (
              <tbody>
                {leaveLogList.map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="6"></td>
                    </tr>
                    <tr className="custom-table-head-td">
                      <td className="align-left">{index + 1}</td>
                      <td className="align-center">{formattedDateLong(i.date)}-{i.in_time}</td>
                      <td className="align-center form-text-trasformation-uppercase">{i.duration}</td>
                      <td className="align-center">{i.out_time}</td>
                      <td className="align-center">{i.work_duration}</td>
                      <td className="align-center">{i.rh_assigned}</td>
                      <td className="align-center form-text-trasformation-uppercase">{i.duration}</td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </>
  );
};

const RequestImpresttable = () => {
  const [checkedItems, setCheckedItems] = useState([]);
  const [buffer, setBuffering] = useState(true); //buffering logic

  // *********** Employee Details Api Start ***********
  const [allemployee, setAllemployee] = useState([]);
  const [outdutyname, setOutdutyName] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState("pending");

  const [employeeGet, setEmployeeGet] = useState("null");
  const [fromdate, setFromDate] = useState("null");
  const [todate, setToDate] = useState("null");

  const getimprestname = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/imprestbyrh/${sessionStorage.getItem(
          "emp_code"
        )}/${selectedStatus}/${fromdate}/${todate}/${employeeGet}/`
      );
      setOutdutyName(res.data);
      console.log(res.data);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getimprestname();
  }, [fromdate, todate, selectedStatus, employeeGet]);

  const getEmpcode = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/ourcompanysRH/${sessionStorage.getItem("email")}/`
      );
      const sortedData = customSortByKey(res.data, "emp_code");
      setAllemployee(sortedData);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getEmpcode();
  }, []);
  // *********** Employee Details Api End ***********


  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"> </div>
        <div className="attendance-subcont">
          <div className="field-cont">

            <div className="field-cont-div">
              <div className=" date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={fromdate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>

              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <div className=" date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={todate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Filter />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="null">All</option>
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Employee />
              <select
                className="attendance-input-field width-15vw   date-field"
                type="text"
                value={employeeGet}
                onChange={(e) => setEmployeeGet(e.target.value)}
              >
                <option value="null">Select Employee Code </option>
                {allemployee
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>
          </div>
          <div className="btn-cont"></div>
        </div>

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            {/* <div className="repo-heading font-weight500    font-size-heading">Imprest </div> */}
            {/* <div className="flex-row">
              <button classname="accept  font-weight300font-size-text " onClick={handleFormSubmitApprovePut}>Accept</button>
              <button classname="accept-rej   font-weight300 font-size-text " onClick={handleFormSubmitRejectedPut}>Rejected</button>
            </div> */}
          </div>
          <table className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="table-heading">S. No.</th>
                <th className="table-heading" style={{ textAlign: "center" }}>Employee</th>
                <th className="table-heading" style={{ textAlign: "center" }}>Date</th>
                <th className="table-heading">Project Name</th>
                <th className="table-heading" style={{ textAlign: "center" }}>Total Amount</th>
                <th className="table-heading">Days And Employee Count</th>
                <th className="table-heading" style={{ textAlign: "center" }}>Person Name</th>
                <th className="table-heading">Remarks</th>
                <th className="table-heading">Status</th>
                <th className="table-heading width-5vw" style={{ textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            {buffer ? <div className="spinner"></div> : //buffering logic
              <tbody>
                {outdutyname.map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="6"></td>
                    </tr>
                    <tr className="custom-table-head-td" title={`Team Leader Status : ${i.rh_leave_status}`}>
                      <td className="table-body">{index + 1}</td>

                      <td className="table-body" style={{ textAlign: "center" }}>
                        <img
                          className="profile-img"
                          src={
                            `${i.profilepic}`
                              ? `${IMAGE_URL}${i.profilepic}`
                              : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          }
                          alt="profilepic"
                        />
                        <p>{i.empcode} {i.imprest_name}</p>
                      </td>

                      <td className="table-body">{formatDate(i.date)}</td>
                      <td className="table-body">{i.project_name}</td>
                      {i.rh_leave_status === "approved" ?
                        <td
                          className="align-leftt"
                          title={`Reason For Change : \n${i.amount_change_reason}`}
                        >
                          <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                            <tbody>
                              <tr>
                                <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Transportation</td>
                                <td style={{ padding: "0px 4px" }}>:</td>
                                <td className="align-right">
                                  {formatCurrencyIndian(i.approved_transportation || 0)}
                                </td>
                              </tr>
                              <tr>
                                <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Accommodation</td>
                                <td style={{ padding: "0px 4px" }}>:</td>
                                <td className="align-right">
                                  {formatCurrencyIndian(i.approved_accomodation || 0)}
                                </td>
                              </tr>
                              <tr>
                                <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Food</td>
                                <td style={{ padding: "0px 4px" }}>:</td>
                                <td className="align-right">
                                  {formatCurrencyIndian(i.approved_fooding || 0)}
                                </td>
                              </tr>
                              <tr>
                                <b>
                                  <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Others</td>
                                </b>
                                <td style={{ padding: "0px 4px" }}>:</td>
                                <td className="align-right table-body" style={{ paddingBottom: "5px" }}>
                                  <b>
                                    {formatCurrencyIndian(
                                      i.other_details?.reduce(
                                        (sum, other) =>
                                          sum + (parseFloat(other.approved_other) || 0),
                                        0
                                      )
                                    )}
                                  </b>
                                </td>
                              </tr>
                              {i.other_details?.length > 0 ? (
                                <>
                                  {i.other_details.map((other, index) => (
                                    <tr key={index}>
                                      <td className="table-body" style={{ paddingLeft: '5px', paddingBottom: "5px" }}>
                                        {other.other_name
                                          ? other.other_name
                                          : `Other-${index + 1}`}
                                      </td>
                                      <td style={{ padding: "0px 4px" }}>:</td>
                                      <td className="align-right" style={{ paddingBottom: "5px" }}>
                                        {formatCurrencyIndian(other.approved_other || 0)}
                                      </td>
                                    </tr>
                                  ))}
                                </>
                              ) : (
                                <tr>
                                  <td style={{ paddingBottom: "5px", paddingLeft: "5px" }} colSpan={3}>No Other Amount</td>
                                </tr>
                              )}
                              <tr>
                                <td>
                                  <b>Total</b>
                                </td>
                                <td style={{ padding: "0px 4px" }}>:</td>
                                <td className="align-right">
                                  <b>
                                    {formatCurrencyIndian(
                                      Number(i.approved_transportation) +
                                      Number(i.approved_accomodation) +
                                      Number(i.approved_fooding) +
                                      (i.other_details?.length > 0
                                        ? i.other_details.reduce(
                                          (total, other) =>
                                            total + Number(other.approved_other || 0),
                                          0
                                        )
                                        : 0)
                                    )}
                                  </b>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        :
                        <td className="table-body">
                          <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                            <tbody>
                              <tr>
                                <td className="table-body" style={{ paddingBottom: "5px" }}>Transportation</td>
                                <td style={{ padding: "0px 4px" }}>:</td>
                                <td className="align-right">{formatCurrencyIndian(i.transportation)}</td>
                              </tr>
                              <tr>
                                <td className="table-body" style={{ paddingBottom: "5px" }}>Accommodation</td>
                                <td style={{ padding: "0px 4px" }}>:</td>
                                <td className="align-right">{formatCurrencyIndian(i.accomodation)}</td>
                              </tr>
                              <tr>
                                <td className="table-body" style={{ paddingBottom: "5px" }}>Food</td>
                                <td style={{ padding: "0px 4px" }}>:</td>
                                <td className="align-right">{formatCurrencyIndian(i.fooding)}</td>
                              </tr>
                              <tr>
                                <b>
                                  <td className="table-body" style={{ paddingBottom: "5px" }}>Others</td>
                                </b>
                                <td style={{ padding: "0px 4px" }}>
                                  :
                                </td>
                                <td className="align-right">
                                  <b>
                                    {i.other_details
                                      .reduce((sum, other) => sum + (parseFloat(other.amount) || 0), 0)
                                      .toLocaleString()}
                                  </b>
                                </td>
                              </tr>
                              {i.other_details?.length > 0 ? (
                                <>
                                  {i.other_details.map((other, index) => (
                                    <>
                                      <tr>
                                        <td style={{ paddingLeft: "5px", paddingBottom: "5px" }} className="table-body" >
                                          {other.other_name ? other.other_name : `Other-${index + 1}`}
                                        </td>
                                        <td style={{ padding: "0px 4px" }}>:</td>

                                        <td className="align-right">
                                          {formatCurrencyIndian(other.amount || 0)}
                                        </td>
                                      </tr>
                                    </>
                                  ))}
                                </>
                              ) : (
                                <td style={{ paddingBottom: "5px", paddingLeft: "5px" }}>
                                  No Other Amount
                                </td>
                              )}
                              <tr>
                                <td className="table-body" style={{ paddingBottom: "5px" }} >
                                  <b>Total </b>
                                </td>
                                <td style={{ padding: "0px 4px" }}>:</td>
                                <td className="align-right">
                                  <b>
                                    {formatCurrencyIndian(
                                      Number(i.transportation) +
                                      Number(i.accomodation) +
                                      Number(i.fooding) +
                                      (i.other_details?.length > 0
                                        ? i.other_details.reduce((total, other) => total + Number(other.amount || 0), 0)
                                        : 0)
                                    )}
                                  </b>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>}

                      <td className="table-body">For <b>{i.no_of_days}</b> Days with <br /> <b>{i.person}</b> {i.person > 1 ? "people." : "person."}</td>
                      <td className="table-body">
                        <ul style={{ paddingLeft: "20px" }}>
                          {i.multiple_person_details?.map((items) => {
                            return (
                              <li>
                                {items.emp_code} - {items.name}
                                <br />
                              </li>
                            );
                          })}
                        </ul>
                      </td>
                      <td className="table-body">{i.remarks}</td>
                      <td className="table-body"  >
                        <span
                          request-status={i.rh_leave_status}
                          className="request-status"
                          title={`${i.rh_leave_status === "rejected" ? `TL Reason For Rejection : \n${i.rejection_reason}` : ''}`}
                        >
                          {i.rh_leave_status === "pending"
                            ? "Pending For Rh Approval ⌛"
                            : i.rh_leave_status === "rejected"
                              ? <>Rh ✖,<br />Rh Reject Reason:<br />"{i.rejection_reason}"</>
                              : "Approved By Rh ✔,"}
                        </span>
                        <br />
                        <span
                          request-status={i.admin_leave_status}
                          className="request-status"
                          title={`${i.admin_leave_status === "rejected" ? `Admin Reason: ${i.admin_rejection_reason}` : ''}`}
                        >
                          {i.admin_leave_status === "rejected"
                            ? <>Admin ✖,<br />Admin Reject Reason: "{i.admin_rejection_reason}"</>
                            :
                            i.admin_leave_status === "pending"
                              ? "Pending For Admin Approval ⌛,"
                              :
                              i.admin_leave_status === "approved"
                                ? "Approved By Admin ✔,"
                                : "Admin Status: " + i.admin_leave_status}
                        </span>
                        <br />
                        <span
                          request-status={i.account_leave_status}
                          className="request-status"
                          title={`${i.account_leave_status === "rejected" ? `Account Reason: ${i.account_rejection_reason}` : ''}`}
                        >
                          {i.account_leave_status === "rejected"
                            ? <>Accounts ✖,<br />Accounts Reject Reason:<br />"{i.account_rejection_reason}"</>
                            : i.account_leave_status === "pending"
                              ? "Pending For Account Approval ⌛"
                              :
                              i.account_leave_status === "approved"
                                ? "Approved By Accounts ✔,"
                                : "Accounts Status : " + i.account_leave_status}
                        </span>
                      </td>
                      <td className="table-body" style={{ display: 'flex' }}>
                        {i.rh_leave_status === 'approved' ? null :
                          <div>
                            <RequestImprestApproved
                              i={i}
                              getImprestname={getimprestname}
                            />
                            <RequestImprestPartialApprove
                              i={i}
                              getImprestname={getimprestname}
                            />
                          </div>}
                        {i.rh_leave_status === 'rejected' ? null :
                          <div>
                            <RequestImprestReject
                              i={i}
                              getImprestname={getimprestname}
                            />
                          </div>}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>}
          </table>
        </div>
      </div >
    </>
  );
};

const RequestExpensetable = () => {
  const [checkedItems, setCheckedItems] = useState([]);
  const [buffer, setBuffering] = useState(true); //buffering logic

  // *********** Employee Details Api Start ***********
  const [allemployee, setAllemployee] = useState([]);
  const [outdutyname, setOutdutyName] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState("pending");

  const [employeeGet, setEmployeeGet] = useState("null");
  const [fromdate, setFromDate] = useState("null");
  const [todate, setToDate] = useState("null");

  const getexpensename = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/expensebyrh/${sessionStorage.getItem("emp_code")}/${selectedStatus}/${fromdate}/${todate}/${employeeGet}/`
      );
      setOutdutyName(res.data);
      console.log(res.data);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getexpensename();
  }, [fromdate, todate, selectedStatus, employeeGet]);

  const getEmpcode = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/ourcompanysRH/${sessionStorage.getItem("email")}/`
      );
      const sortedData = customSortByKey(res.data, "emp_code");
      setAllemployee(sortedData);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getEmpcode();
  }, []);
  // *********** Employee Details Api End ***********

  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");
  const baseUrl = "/" + urlParts[3] + `/requestExpenseSlip/`;
  const opener = "tl"
  const openExenseSlip = (i) => {
    navigate(baseUrl + i.id + '/', { state: { i, opener } });
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"> </div>
        <div className="attendance-subcont">
          <div className="field-cont">
            <div className="field-cont-div">
              <div className=" date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={fromdate}
                  onChange={(e) => setFromDate(e.target.value === "" ? "null" : e.target.value)}
                />
              </div>

              <hr className="field-cont-hr" />
            </div>
            <div className="field-cont-div">

              <div className="  date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={todate}
                  onChange={(e) => setToDate(e.target.value === "" ? "null" : e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">

              <Filter />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="null">All</option>

                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Employee />
              <select
                className="attendance-input-field width-15vw   date-field"
                type="text"
                value={employeeGet}
                onChange={(e) => setEmployeeGet(e.target.value)}
              >
                <option value="">Select Employee Code </option>
                {allemployee
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>
          </div>
          <div className="btn-cont"></div>
        </div>

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            {/* <div className="repo-heading font-weight500 font-size-heading">Expense </div> */}
            {/* <div className="flex-row">
              <button classname="accept  font-weight300font-size-text " onClick={handleFormSubmitApprovePut}>Accept</button>
              <button classname="accept-rej   font-weight300 font-size-text " onClick={handleFormSubmitRejectedPut}>Rejected</button>
            </div> */}
          </div>
          <table className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="table-heading">S. No.</th>
                <th className="table-heading" style={{ textAlign: "center" }}>Employee</th>
                <th className="table-heading">Date</th>
                <th className="width-10vw table-heading ">Expense <b>Against</b> Imprest</th>
                <th className="table-heading" style={{ textAlign: "center" }}>Imprest Amount</th>
                <th className="table-heading">Utilized Money</th>
                <th className="table-heading">Remaining Money</th>
                <th className="table-heading">Status</th>
                <th className="table-heading width-15vw">Remarks</th>
                <th className="table-heading">View/Action</th>
                {/* <th className="align-right  ">Action</th> */}
              </tr>
            </thead>
            {buffer ? <div className="spinner"></div> : //buffering logic
              <tbody>
                {outdutyname.map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="6"></td>
                    </tr>
                    <tr className="custom-table-head-td">
                      <td className="table-body">{index + 1}</td>
                      <td className="table-body" style={{ textAlign: "center" }}>
                        <img
                          className="profile-img"
                          src={
                            `${i.profilepic}`
                              ? `${IMAGE_URL}${i.profilepic}`
                              : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          }
                          alt="profilepic"
                        />
                        <p>{i.empcode} {i.emp_name}</p>
                      </td>
                      <td className="table-body">{formatDate(i.date)}</td>
                      <td className="table-body width-10vw"><span>{i.name}</span>
                        <br />
                        <b> Against </b>
                        <br />
                        <span>{i.project_name}</span>
                      </td>
                      <td className="table-body">
                        <table style={{ borderCollapse: "collapse", width: "100%" }}>
                          <tbody>
                            <tr>
                              <td className="table-body" style={{ paddingBottom: "5px" }}>Transportation</td>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right">
                                {formatCurrencyIndian(i.approved_transportation || 0)}
                              </td>
                            </tr>
                            <tr>
                              <td className="table-body" style={{ paddingBottom: "5px" }}>Accommodation</td>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right">
                                {formatCurrencyIndian(i.approved_accomodation || 0)}
                              </td>
                            </tr>
                            <tr>
                              <td className="table-body" style={{ paddingBottom: "5px" }}>Food</td>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right">
                                {formatCurrencyIndian(i.approved_fooding || 0)}
                              </td>
                            </tr>
                            <tr>
                              <b>
                                <td className="table-body" style={{ paddingBottom: "5px" }}>Others</td>
                              </b>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right">
                                <b>
                                  {formatCurrencyIndian(
                                    i.other_details?.reduce(
                                      (sum, other) => sum + Number(other.approved_other || 0),
                                      0
                                    )
                                  )}
                                </b>
                              </td>
                            </tr>
                            {i.other_details?.length > 0 ? (
                              <>
                                {i.other_details.map((other, index) => (
                                  <tr key={index}>
                                    <td className="table-body" style={{ paddingLeft: "5px", paddingBottom: "5px" }} >
                                      {other.other_name ? other.other_name : `Other-${index + 1}`}
                                    </td>
                                    <td style={{ padding: "0px 4px" }}>:</td>
                                    <td className="align-right">
                                      {formatCurrencyIndian(other.approved_other || 0)}
                                    </td>
                                  </tr>
                                ))}
                              </>
                            ) : (
                              <tr>
                                <td style={{ padding: "0px 4px", paddingBottom: "5px" }} colSpan={3}>No Other Expense</td>
                              </tr>
                            )}
                            <tr>
                              <td className="table-body" style={{ paddingBottom: "5px" }}>
                                <b>Total</b>
                              </td>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right">
                                <b>
                                  {formatCurrencyIndian(
                                    Number(i.approved_transportation) +
                                    Number(i.approved_accomodation) +
                                    Number(i.approved_fooding) +
                                    (i.other_details?.length > 0
                                      ? i.other_details.reduce(
                                        (total, other) => total + Number(other.approved_other || 0),
                                        0
                                      )
                                      : 0)
                                  )}
                                </b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td className="table-body align-center">{i.utilized_amount ? formatRoundoff2D(i.utilized_amount) : '-'} Rs</td>
                      <td className="table-body align-center">{i.remaining_amount ? formatRoundoff2D(i.remaining_amount) : '-'} Rs</td>
                      <td className="table-body">
                        <span
                          request-status={i.rh_status}
                          className="request-status align-center"
                          title={i.rh_status === "rejected" ? `Rh Reject Reason : ${i.rh_rejection_reason}` : ""}
                        >
                          {i.rh_status === "pending"
                            ? "Pending For Rh Approval ⌛"
                            : i.rh_status === "rejected"
                              ? "Rh ✖, "
                              : "Rh ✔, "}
                        </span>
                        <br />
                        <span
                          request-status={i.account_status_a}
                          className="request-status align-center"
                          title={i.account_status_a === "rejected" ? `Account Reject Reason : ${i.account1_rejection_reason}` : ""}
                        >
                          {i.rh_status === "rejected" &&
                            i.admin_status === "rejected" &&
                            i.account_status_a === "rejected"
                            ? "Account ✖, "
                            : i.rh_status === "approved" &&
                              i.admin_status === "approved" &&
                              i.account_status_a === "pending"
                              ? "Pending For Account Approval ⌛"
                              : i.rh_status === "approved" &&
                                i.admin_status === "approved" &&
                                i.account_status_a === "rejected"
                                ? "Accounts ✖, "
                                : i.rh_status === "approved" &&
                                  i.admin_status === "approved" &&
                                  i.account_status_a === "approved"
                                  ? "Accounts ✔, "
                                  : "Accounts Stage 1: " + i.account_status_a}
                        </span>
                        <br />
                        <span
                          request-status={i.admin_status}
                          className="request-status align-center"
                          title={i.admin_status === "rejected" ? `Admin Reject Reason : ${i.admin_rejection_reason}` : ""}
                        >
                          {i.rh_status === "rejected" &&
                            i.admin_status === "rejected"
                            ? "Admin ✖, "
                            : i.rh_status === "approved" &&
                              i.admin_status === "pending"
                              ? "Pending For Admin Approval ⌛,"
                              : i.rh_status === "approved" &&
                                i.admin_status === "rejected"
                                ? "Admin ✖, "
                                : i.rh_status === "approved" &&
                                  i.admin_status === "approved"
                                  ? "Admin ✔, "
                                  : "Admin Status: " + i.admin_status}
                        </span>
                        <br />
                        <span
                          request-status={i.account_status_b}
                          className="request-status align-center"
                        >
                          {i.rh_status === "rejected" &&
                            i.admin_status === "rejected" &&
                            i.account_status_b === "rejected"
                            ? "Account ✖, "
                            : i.rh_status === "approved" &&
                              i.admin_status === "approved" &&
                              i.account_status_b === "pending"
                              ? "Pending For Account Approval ⌛"
                              : i.rh_status === "approved" &&
                                i.admin_status === "approved" &&
                                i.account_status_b === "rejected"
                                ? "Accounts ✖, "
                                : i.rh_status === "approved" &&
                                  i.admin_status === "approved" &&
                                  i.account_status_b === "approved"
                                  ? "Accounts ✔, "
                                  : "Accounts Stage 2: " + i.account_status_b}
                        </span>
                      </td>
                      <td title="Remarks and Approval Dates" className="table-body">
                        <span
                          request-status={i.rh_status}
                          className="request-status "
                        >
                          {i.rh_status === "pending"
                            ? "-"
                            : i.rh_status === "rejected"
                              ? `Rh Reject Reason : ${i.rh_rejection_reason}`
                              : `Rh${i.rh_assigned ? `(${i.rh_assigned}) ` : ''}: Verified Amounts for Bills ${i.rh_update_datetime ? `At: ${formatDateTime(i.rh_update_datetime).date}` : ''}`}
                        </span>
                        <br />
                        <span
                          request-status={i.account_status_a}
                          className="request-status "
                        >
                          {i.account_status_a === "pending"
                            ? "-"
                            : i.account_status_a === "rejected"
                              ? `Accounts Reject Reason : ${i.account1_rejection_reason}`
                              : `Accounts${i.account_update_by ? `(${i.account_update_by}) ` : ''}: Bills Verified ${i.account_update_datetime ? `At: ${formatDateTime(i.account_update_datetime).date}` : ''}`}
                        </span>
                        <br />
                        <span
                          request-status={i.admin_status}
                          className="request-status "
                          title={i.admin_status === "rejected" ? `Admin Reject Reason : ${i.admin_rejection_reason}` : ""}
                        >
                          {i.admin_status === "pending"
                            ? "-"
                            : i.admin_status === "rejected"
                              ? `Admin Rejection Reason : ${i.admin_rejection_reason}`
                              : `Admin${i.admin_update_by ? `(${i.admin_update_by}) ` : ''}: Approved After Final Verification  ${i.admin_update_datetime ? `At: ${formatDateTime(i.admin_update_datetime).date}` : ''}`}
                        </span>
                        <br />
                        <span
                          request-status={i.account_status_b}
                          className="request-status "
                        >
                          {i.account_status_b === "pending"
                            ? "-"
                            : i.account_status_b === "rejected"
                              ? `Final Rejection Reason : ${i.account2_rejection_reason}`
                              : `Final${i.final_update_by ? `(${i.final_update_by}) ` : ''}: Approved And Settled ${i.final_update_datetime ? `At: ${formatDateTime(i.final_update_datetime).date}` : ''}`}
                        </span>
                      </td>
                      <td className="table-body align-center" >
                        <button
                          className="allproject-dashboard"
                          title="View using Navigate"
                          onClick={(e) => openExenseSlip(i)}
                        >
                          <Eye />
                        </button>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>}
          </table>
        </div>
      </div>
    </>
  );
};

const RequestRequisitionTable = () => {
  const [checkedItems, setCheckedItems] = useState([]);

  // *********** Employee Details Api Start ***********
  const [allemployee, setAllemployee] = useState([]);
  const [outdutyname, setOutdutyName] = useState([]);

  const [buffer, setBuffering] = useState(true); //buffering logic
  const [selectedStatus, setSelectedStatus] = useState("pending");

  const [employeeGet, setEmployeeGet] = useState("null");
  const [fromdate, setFromDate] = useState("null");
  const [todate, setToDate] = useState("null");

  const getRequisitions = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/jd/requisitionbyrh/${sessionStorage.getItem(
          "emp_code"
        )}/${selectedStatus}/${fromdate}/${todate}/${employeeGet}/`
      );
      setOutdutyName(res.data);
      console.log(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getRequisitions();
  }, [fromdate, todate, selectedStatus, employeeGet]);

  const getEmpcode = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/ourcompanysRH/${sessionStorage.getItem("email")}/`
      );
      const sortedData = customSortByKey(res.data, "emp_code");
      setAllemployee(sortedData);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getEmpcode();
  }, []);
  // *********** Employee Details Api End ***********



  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"> </div>
        <div className="attendance-subcont">
          <div className="field-cont">

            <div className="field-cont-div">

              <div className=" date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={fromdate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>

              <hr />
            </div>
            <div className="field-cont-div">

              <div className=" date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={todate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />
            </div>


            <div className="field-cont-div">
              <Filter />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="null">All</option>

                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Employee />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={employeeGet}
                onChange={(e) => setEmployeeGet(e.target.value)}
              >
                <option value="">Select Employee Code </option>
                {allemployee
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
              </select>
            </div>
          </div>
          <div className="btn-cont"></div>
        </div>

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500    font-size-heading">Requisition Request</div>
            {/* <div className="flex-row">
              <button classname="accept  font-weight300font-size-text " onClick={handleFormSubmitApprovePut}>Accept</button>
              <button classname="accept-rej   font-weight300 font-size-text " onClick={handleFormSubmitRejectedPut}>Rejected</button>
            </div> */}
          </div>
          <table className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="align-left">S. No.</th>
                <th className="align-center">Requested On Date</th>
                <th className="align-center">Department</th>
                <th className="align-center">Designation</th>
                <th className="align-center">Status</th>
                {/* <th className="align-center">Hired</th> */}
                <th className="align-right ">Action</th>
              </tr>
            </thead>
            {buffer ? <div className="spinner"></div> : //buffering logic
              <tbody>
                {outdutyname.map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="6"></td>
                    </tr>
                    <tr className="custom-table-head-td">
                      <td className="align-left">{index + 1}</td>

                      <td className="align-center">{formatDate(i.date)}</td>
                      <td className="align-center ">{i.department_name}</td>
                      <td className="align-center ">{i.position_name}</td>

                      <td className="align-center">
                        <span
                          request-status={i.rh_status}
                          className="request-status align-center"
                        >
                          {i.rh_status === "pending"
                            ? "Pending For Rh Approval ⌛"
                            : i.rh_status === "rejected"
                              ? "Rh ✖, "
                              : "Rh ✔, "}
                        </span>
                        <br />
                        <span
                          request-status={i.admin_status}
                          className="request-status align-center"
                        >
                          {i.rh_status === "rejected" &&
                            i.admin_status === "rejected"
                            ? "Admin ✖, "
                            : i.rh_status === "approved" &&
                              i.admin_status === "pending"
                              ? "Pending For Admin Approval ⌛,"
                              : i.rh_status === "approved" &&
                                i.admin_status === "rejected"
                                ? "Admin ✖, "
                                : i.rh_status === "approved" &&
                                  i.admin_status === "approved"
                                  ? "Admin ✔, "
                                  : "Admin Status: " + i.admin_status}
                        </span>
                        <br />
                        <span
                          request-status={i.hr_status}
                          className="request-status align-center"
                        >
                          {i.rh_status === "rejected" &&
                            i.admin_status === "rejected" &&
                            i.hr_status === "rejected"
                            ? "HR ✖, "
                            : i.rh_status === "approved" &&
                              i.admin_status === "approved" &&
                              i.hr_status === "pending"
                              ? "Pending For HR Approval ⌛"
                              : i.rh_status === "approved" &&
                                i.admin_status === "approved" &&
                                i.hr_status === "rejected"
                                ? "HR ✖, "
                                : i.rh_status === "approved" &&
                                  i.admin_status === "approved" &&
                                  i.hr_status === "approved"
                                  ? "HR ✔, "
                                  : "HR Status: " + i.hr_status}
                        </span>
                      </td>
                      {/* <td className="align-left ">
                      <input type="checkbox" checked={i.hired} readOnly className="form-checkbox" />
                    </td> */}

                      <td className="align-right flex-row ">
                        {i.rh_assigned === sessionStorage.getItem("emp_code") ? (
                          <>
                            <RequisitionApprovedTLAdmin
                              i={i}
                              getRequisitions={getRequisitions}
                            />
                            <RequisitionRejectTLAdmin
                              i={i}
                              getRequisitions={getRequisitions}
                            />
                          </>
                        ) : (
                          <>
                            <RequisitionApprovedRH
                              i={i}
                              getRequisitions={getRequisitions}
                            />
                            <RequisitionRejectRH
                              i={i}
                              getRequisitions={getRequisitions}
                            />
                          </>
                        )}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            }
          </table>
        </div>
      </div>
    </>
  );
};

const RequestClockInTable = () => {
  const [checkedItems, setCheckedItems] = useState([]);
  const [employeeGet, setEmployeeGet] = useState("null");
  const [fromdate, setFromDate] = useState("null");
  const [todate, setToDate] = useState("null");
  const [selectedStatus, setSelectedStatus] = useState("pending");
  const [buffer, setBuffering] = useState(true); //buffering logic

  // *********** Employee Details Api Start ***********
  const [clockInList, setClockInList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);

  const getEmpList = async () => {
    setBuffering(true); //buffering logic // Start Buffering 
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/ourcompanysRH/${sessionStorage.getItem("email")}/`
      );
      // const res = await axios.get(`${BASE_URL}/wfm/ourcompanysRH/sumit.bhati@civilmantra.com/`);
      const sortedData = customSortByKey(res.data, "emp_code");
      setEmployeeList(sortedData);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;
        if (typeof errorData.error === 'string') {
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  const getClockInList = async () => {
    setBuffering(true); //buffering logic // Start Buffering 
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/clockinrequest/${fromdate}/${todate}/${sessionStorage.getItem(
          "emp_code"
        )}/${selectedStatus}/${employeeGet}/`
      );
      // const res = await axios.get(`${BASE_URL}/wfm/clockinrequest/${fromdate}/${todate}/6/${selectedStatus}/${employeeGet}/`);
      setClockInList(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;
        if (typeof errorData.error === 'string') {
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };
  useEffect(() => {
    getEmpList();
    getClockInList();
  }, [selectedStatus, employeeGet, fromdate, todate]);

  // *********** Employee Details Api End ***********

  const handleCheckboxChange = (id) => {
    const currentIndex = checkedItems.indexOf(id);
    const newCheckedItems = [...checkedItems];

    if (currentIndex === -1) {
      newCheckedItems.push(id);
    } else {
      newCheckedItems.splice(currentIndex, 1);
    }

    setCheckedItems(newCheckedItems);
  };

  const calculateTimeDifference = (inTime, outTime) => {
    const parseTime = (time) => {
      const [hours, minutes, seconds] = time.split(":").map(Number);
      return new Date(0, 0, 0, hours, minutes, seconds);
    };

    const inTimeDate = parseTime(inTime);
    const outTimeDate = parseTime(outTime);

    const differenceInMilliseconds = outTimeDate - inTimeDate;
    const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);

    return differenceInHours.toFixed(2) + " hrs";
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendancpositione-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"> </div>
        <div className="attendance-subcont">
          <div className="field-cont">
            <div className="field-cont-div">

              <div className=" date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={fromdate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>

              <hr />
            </div>

            <div className="field-cont-div">

              <div className=" date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={todate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />
            </div>
            <div className="field-cont-div">
              <Filter />
              <select
                title="Request Status"
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="null">All</option>

                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Employee />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={employeeGet}
                onChange={(e) => setEmployeeGet(e.target.value)}
              >
                <option value="null">Select Employee Code</option>
                {employeeList
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
              </select>
            </div>
          </div>

          <div className="btn-cont">
            {/* <button className="attendance-btn" onClick={getClockInList}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 11 12"
                fill="none"
              >
                <circle
                  cx="5"
                  cy="5"
                  r="4.3"
                  stroke="#FFFFFF"
                  stroke-width="1.4"
                />
                <line
                  x1="10.0101"
                  y1="11"
                  x2="8"
                  y2="8.98995"
                  stroke="#FFFFFF"
                  stroke-width="1.4"
                  stroke-linecap="round"
                />
              </svg>
              Search
            </button> */}
          </div>
        </div>

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500    font-size-heading">Clock In Requests</div>
            {/* <div className="flex-row">
              <dir classname="accept  font-weight300font-size-text "><a href="">Accept</a></dir>
              <div classname="accept-rej   font-weight300 font-size-text "><a href="">Rejected</a></div>
            </div> */}
          </div>
          <table className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="align-left">Sr.no</th>
                {/* <th className="align-left">
                  <input type="checkbox" onChange={(e) => {
                    if (e.target.checked) {
                      const ids = clockInList.map((data) => data.id);
                      setCheckedItems(ids);
                    } else {
                      setCheckedItems([]);
                    }
                  }} />
                </th> */}

                {/* <th className="align-center">Date</th> */}
                <th className="align-center">Empolyee Name</th>
                <th className="align-center">Date</th>
                <th className="align-center">Clock In</th>
                <th className="align-center">Clock Out</th>
                <th className="align-center">Working Hours</th>
                <th className="align-center">Log</th>
                {/* <th className="align-center">Location</th> */}
                <th className="align-right ">Action</th>
              </tr>
            </thead>
            {buffer ? <div className="spinner"></div> : //buffering logic
              <tbody>
                {clockInList.map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="6"></td>
                    </tr>
                    <tr className="custom-table-head-td">
                      <td className="align-left">{index + 1}</td>

                      {/* <td className="align-center">{i.date}</td> */}
                      <td className="align-center">
                        <img
                          className="profile-img"
                          src={
                            i.profilepic
                              ? `${IMAGE_URL}${i.profilepic}`
                              : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          }
                          alt="profilepic"
                        />
                        <p>
                          {i.empcode}-{i.name}
                        </p>
                      </td>
                      {/* <td className="align-center">{i.empcode}-{i.name}</td> */}
                      <td className="align-center">
                        {formattedDateLong(i.date)}
                      </td>
                      <td className="align-center">
                        <p>{i.in_time}</p>
                        <p>{i.in_time_location}</p>
                      </td>
                      {/* <td className="align-center">{i.out_time}</td> */}
                      {/* <td className="align-center">{i.work_duration}</td> */}
                      <td className="align-center form-text-trasformation-uppercase">
                        <p>{i.out_time}</p>
                        <p>{i.out_time_location}</p>
                      </td>
                      <td className="align-center">
                        {calculateTimeDifference(i.in_time, i.out_time)}
                      </td>
                      <td className="align-center form-text-trasformation-uppercase">
                        <ClockInLogTable log={i.logs} />
                      </td>
                      <td className="align-right flex-row ">
                        {i.rh_leave_status === "pending" ? (
                          i.date === formattedDate ? (
                            "Available Tommorow"
                          ) : (
                            <>
                              <ClockInApprovedTL
                                i={i}
                                getClockInList={getClockInList}
                              />
                              <ClockInRejectTL
                                i={i}
                                getClockInList={getClockInList}
                              />
                            </>
                          )
                        ) : (
                          <span
                            request-status={i.rh_leave_status}
                            className="request-status align-center"
                          >
                            {i.rh_leave_status === "pending"
                              ? "Pending For Rh Approval ⌛"
                              : i.rh_leave_status === "rejected"
                                ? "Rh Rejected ✖, "
                                : "Rh Approved ✔, "}
                          </span>
                        )}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>}
          </table>
        </div>
      </div>
    </>
  );
};

const ClockInLogTable = ({ log }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    // getDesignationEmployees(designation.id);
  };
  // const [designationEmployees, setDesignationEmployees] = useState([]);

  // const getDesignationEmployees = async (designationId) => {
  //   try {
  //     const res = await axios.get(`${BASE_URL}/wfm/employeedetailsbydesignation/${designationId}/`);
  //     setDesignationEmployees(res.data);
  //   } catch (err) {
  //     // alert(err.message);
  //   }
  // };

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // useEffect(() => {
  //   getDesignationEmployees();
  // }, []);

  const navigate = useNavigate();
  const Viewdetails = (emp_code, user_detail) => {
    navigate(`/hr/userdetailscrud/${emp_code}/${user_detail}/`);
  };
  return (
    <>
      <button
        className=""
        title="View Employees"
        style={{ paddingLeft: "5px", color: "blue", fontSize: "14px" }}
        onClick={handleShow}
      >
        View logs
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          <>
            <div className="table-css-white-background">
              <div className="table-heading-flex">
                <div className="repo-heading font-weight500    font-size-heading">Clock In Log</div>
                <div className="flex-row">
                  <div className="table-searchh">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="12"
                        viewBox="0 0 11 12"
                        fill="none"
                      >
                        <circle
                          cx="5"
                          cy="5"
                          r="4.3"
                          stroke="#2B3674"
                          strokeWidth="1.4"
                        />
                        <line
                          x1="10.0101"
                          y1="11"
                          x2="8"
                          y2="8.98995"
                          stroke="#2B3674"
                          strokeWidth="1.4"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <div>
                      <input
                        placeholder="Search Place"
                        className="navbar-inputt font-weight400  font-size-text"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <table className="table-css">
                <thead>
                  <tr className="custom-table-head-tr">
                    <th className="align-left">Sr. No.</th>
                    <th className="align-left">Picture</th>

                    <th className="align-center">Punch Time</th>
                    <th className="align-center">Punch Location</th>
                    {/* <th className="align-center">Employee View</th> */}
                  </tr>
                </thead>
                <tbody>
                  {log
                    .filter((e) =>
                      e.placename
                        .toString()
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                    )
                    ?.sort((a, b) => a.punch_time - b.punch_time)
                    .map((i, index) => (
                      <React.Fragment key={index}>
                        {/* <tr className="tr-border-bottom">
                          <td colSpan="6"></td>
                        </tr> */}
                        <tr className="custom-table-head-td">
                          <td className="align-left">{index + 1}</td>
                          <td className="align-center">
                            <img
                              className="profile-img"
                              style={{ height: "50px", width: "50px" }}
                              src={
                                `${i.image}`
                                  ? `${i.image}`
                                  : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                              }
                              alt="Profile Pic"
                            />
                          </td>
                          <td className="align-center">{i.punch_time}</td>
                          <td className="align-center">{i.placename}</td>

                          {/* <td className="align-center">
                            <button className='employee-edit' onClick={() => Viewdetails(i.emp_code, i.user_detail)} title="View Profile">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 30 30"
                                fill="none">

                                <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                                <path
                                  d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                  fill="#2576BC"
                                />

                              </svg></button>
                          </td> */}
                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export {
  Requestoutdutytable,
  RequestLeavetable,
  RequestMisspunchtable,
  RequestCompensatorytable,
  TeamLeaveLogTable,
  RequestImpresttable,
  RequestExpensetable,
  RequestRequisitionTable,
  RequestClockInTable,
};
