import React, { useState, useEffect } from "react";
import axios from "axios";

import {
  RadialBarChart,
  RadialBar,
  Tooltip,
  ResponsiveContainer,
} from "recharts";


import { BASE_URL } from "../../../config/axios";


const LeaveChart = ({ emp_code }) => {


  const [statData, setStatData] = useState({});
  const getStatdata = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/requestsapplied/${emp_code}`);
      console.log(res.data)
      setStatData(res.data);
    } catch (err) {
      alert(err.message);
    }
  };

  const [graphData, setGraphData] = useState({});
  const getGraphdata = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/requeststatus/${emp_code}`);
      console.log(res.data)
      setGraphData(res.data);
    } catch (err) {
      alert(err.message);
    }
  };


  useEffect(() => {
    getStatdata();
    getGraphdata();
  }, []);

  const data = [
    {
      name: "Short Day Leave",
      uv: statData.leave_shortleave,
      fill: "#165DFF",
    },
    {
      name: "HalfDay Leave",
      uv: statData.leave_halfdayleave,
      fill: "#F7BA1E",
    },
    {
      name: "Single Day Leave",
      uv: statData.leave_single,
      fill: "#FF747C",
    },
    {
      name: "Multi Day Leave",
      uv: statData.leave_multiple,
      fill: "#30C9C9",
    },
  ];

  return (
    <ResponsiveContainer width="90%" height={205} className="radialchart">
      <div className="leavechart-heading">
        <div className="repo-heading-a font-weight500   font-size-subheading">Total Leaves -  {statData.allleave}</div>
        <div>
          {data.map((item) => {
            return (
              <div key={item.name} className="radialchart-legend">
                <div
                  className="radialchart-dot"
                  style={{ backgroundColor: item.fill }}
                ></div>
                <div className="radialchart-subheading font-weight600  font-size-text ">
                  {item.name} - {item.uv}
                </div>
              </div>
            );
          })}
        </div>

      </div>

      <RadialBarChart
        innerRadius={30}
        outerRadius={125}
        barSize={13}
        startAngle={90}
        endAngle={450}
        data={data}
      >
        <RadialBar
          minAngle={15}
          background
          clockWise="true"
          cornerRadius={10}
          dataKey="uv"
        ></RadialBar>
        {/* <Tooltip /> */}
      </RadialBarChart>
    </ResponsiveContainer>
  );
};

export default LeaveChart;