
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../config/axios';
import { DropdownArrow } from '../AllSvg';
import { Modal } from 'react-bootstrap';
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";

import { ViewEmployeeList, handleErrorToast } from '../CustomFunctions';


const CreateMeetingModal = ({ meetingType, handleSelectMeetingType, showModalmeeting,
  closeMeetingModal, modaltoogleOption, selectedTime, getMeetingData, selectedTimeslot, timeSlots }) => {
  const [formData, setFormData] = useState({
    title: "",

    participants: [],
    created_at: "",
    discription: "",
    organizer: sessionStorage.getItem("emp_code"),
    meeting_date: "",
    start_time: "",
    end_time: "",
    venue: "",
    mandatory: false,
    participants_addition_type: "",
    decline_reason: null,
    optional_participents: [],
    room: [],
    isteam: 'No',
    is_outsude: "false",
    outsideemail: '',
    team_name: "",
    department: "",
    participants: [],
    participantsteam: '',
  });

  const [roomData, setRoomData] = useState('')
  const getRoomdata = async () => {
    const result = await axios.get(`${BASE_URL}/meetingscheduler/meetingroom/`);

    setRoomData(result.data);

  };

  const [allemployee, setAllemployee] = useState([]);
  const getAllEmployee = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/ourcompanyRH/${sessionStorage.getItem("email")}/`);
      setAllemployee(res.data)
      // setFilteredEmployee(res.data)
    } catch (err) {
      // alert(err.message);
    }
  };

  const [departmentname, setDepartmentname] = useState([]);
  const [selectedSubCompany, setSelectedSubCompany] = useState('');
  const getDepartment = async () => {
    try {
      const result = await axios.get(`${BASE_URL}/wfm/departmentfilterbysubcompanycount/null/active/`);

      setDepartmentname(result.data)
    } catch (err) {

    } finally {

    }
  };

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const [loading, setLoading] = useState(false);
  const handleInputChange = (e) => {
    const { name, value, type, checked, options, files } = e.target;

    let newValue;
    if (type === "select-multiple") {
      newValue = Array.from(options)
        .filter((option) => option.selected)
        .map((option) => option.value);
    } else if (type === "checkbox") {
      newValue = checked;
    } else if (type === "file") {
      newValue = files.length > 0 ? files[0] : null;
    } else {
      newValue = value;
    }


    if (name === "meeting_date" && value) {
      const selectedDate = new Date(value);
      const currentDate = new Date();

      // console.log(selectedDate < currentDate, currentDate >= selectedDate)
      // if (selectedDate < currentDate) {
      //   setErrors((prevErrors) => ({
      //     ...prevErrors,
      //     [name]: "Meeting date cannot be in the past.",
      //   }));
      //   return;
      // }



    }

    setInputState((prevState) => ({
      ...prevState,
      [name]:
        type === "checkbox"
          ? checked
            ? "green"
            : ""
          : newValue
            ? "green"
            : "",
    }));


    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        [name]: newValue,
      };

      return updatedFormData;
    });
    if (value.trim()) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };
  const validateform = () => {
    const newErrors = {};
    const requiredFields = [
      "title",
      "start_time",
      "end_time",
      "meeting_date",
      "room",
    ];

    requiredFields.forEach((field) => {
      console.log(formData[field])
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });

    setErrors(newErrors);
    // setIsSubmitted(true);
    return Object.keys(newErrors).length === 0;
  };
  const validateformTeam = () => {
    const newErrors = {};
    const requiredFields = [
      "team_name",
      "department",
      "participants",

    ];

    requiredFields.forEach((field) => {
      console.log(formData[field])
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });

    setErrors(newErrors);
    // setIsSubmitted(true);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        start_time: selectedTimeslot?.start,
        end_time: selectedTimeslot?.end
      };

      return updatedFormData;
    });
  }, [selectedTimeslot])
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (validateform()) {
      const loadingToastId = toast.loading("Loading: Please wait...");
      try {
        const dataToSend = {};

        dataToSend.title = formData?.title;
        dataToSend.description = formData?.discription;

        dataToSend.created_at = formData?.created_at;
        dataToSend.organizer = formData?.organizer;
        dataToSend.meeting_date = formData?.meeting_date;
        dataToSend.start_time = formData?.start_time;
        dataToSend.end_time = formData?.end_time;

        dataToSend.mandatory = formData?.mandatory;

        dataToSend.decline_reason = formData?.decline_reason;
        dataToSend.optional_participents = formData?.optional_participents;
        if (formData.is_outsude === "true") {
          dataToSend.venue = formData?.venue;
        } else {
          dataToSend.room = formData?.room;
        }
        // if(formData.isteam==="true"){
        // dataToSend.team_name = formData.team_name;
        // dataToSend.department = formData.department;
        // dataToSend.participantsteam = formData.participantsteam;
        // }

        dataToSend.is_team = formData.isteam === "true" ? true : false;
        dataToSend.is_outside = formData.is_outsude === "true" ? true : false;
        if (formData.is_outsude === "true") {
          dataToSend.outside_email = formData.outsideemail;
          dataToSend.participants_addition_type = formData?.participants_addition_type;
        } else {
          dataToSend.participants = formData?.participants;
        }



        let res = await axios.post(
          `${BASE_URL}/meetingscheduler/meeting/`,
          dataToSend,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (res.status === 201 || res.status === 200) {
          getMeetingData()
          closeMeetingModal()
        } else {

        }


      } catch (err) {
        //toast Logic
        handleErrorToast(err);
      } finally {
        // setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };
  const handleSubmitteam = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (validateformTeam()) {
      const loadingToastId = toast.loading("Loading: Please wait...");
      try {

        const dataToSend = {};

        dataToSend.team_name = formData?.team_name;
        dataToSend.department = formData?.department;
        dataToSend.participants = formData?.participantsteam;


        let res = await axios.post(
          `${BASE_URL}/meetingscheduler/team/`,
          dataToSend,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (res.status === 201 || res.status === 200) {
          // onRoomCreated(res.data);
          // onClose();
        } else {

        }


      } catch (err) {
        //toast Logic
        handleErrorToast(err);
      } finally {
        // setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };
  useEffect(() => {
    if (modaltoogleOption === "meeting") {
      getRoomdata();
      getAllEmployee();
      getDepartment();
    }
  }, [modaltoogleOption])

  const options = allemployee
    .sort((a, b) => parseInt(a.emp_code) - parseInt(b.emp_code))
    .map((e) => ({
      value: e.emp_code,
      label: `${e.emp_code} - ${e.name}`,
    }));



  const selectEmployee = (selected) => {
    // const assignedto = selected.length > 0 ? selected?.map((item) => item.value) : "";
    const participants = selected?.map((item) => item.value) || [];
    setFormData((prevState) => ({
      ...prevState,
      participants,
    }));
  };


  const selectEmployeeoutside = (selected) => {
    // const assignedto = selected.length > 0 ? selected?.map((item) => item.value) : "";
    const participants_addition_type = selected?.map((item) => item.value) || [];
    setFormData((prevState) => ({
      ...prevState,
      participants_addition_type,
    }));
  };



  const selectTeamparticipint = (selected) => {
    // const assignedto = selected.length > 0 ? selected?.map((item) => item.value) : "";
    const participantsteam = selected?.map((item) => item.value) || [];
    setFormData((prevState) => ({
      ...prevState,
      participantsteam,
    }));
  };


  return (
    <>



      <div
        className={`calendar-meeting-modal meeting-create ${showModalmeeting ? 'show' : 'noshow'}`}
      >
        <div
          className="calendar-meeting-modal-content"
        >
          <div
            className="calendar-meeting-modal-header"
          >

            <h2>Create Meeting</h2>
            <span onClick={closeMeetingModal} className='close-meeting-modal'>x</span>

          </div>
          <div className='calendar-meeting-modal-body'>
            <form className="register-form">
              <div className="form-flex-wrap">


                <div title="is_outsude" className="flex-column form-group-selectt">
                  <label htmlFor="is_outsude" className="form-labels  font-weight500    font-size-subheading">
                    Meeting Type
                  </label>
                  <br />
                  <select
                    id="is_outsude"
                    name="is_outsude"
                    value={formData.is_outsude}
                    onChange={handleInputChange}
                    className="form-input form-group-selection"
                  >
                    <option value="">Select Meeting Type</option>
                    <option value="false">Inside</option>
                    <option value="true">Outside</option>
                  </select>
                  <DropdownArrow />
                  <div>
                    {errors.is_outsude && (
                      <span className="error-message font-size-text ">{errors.is_outsude}</span>
                    )}
                  </div>
                </div>




                <div title="title" className="flex-column">
                  <label htmlFor="title" className="form-labels font-weight500 font-size-subheading">
                    Meeting Agenda
                  </label>
                  <input
                    type="text"
                    id="title"
                    name="title"
                    placeholder="Meeting Agenda"
                    onChange={handleInputChange}
                    value={formData.title}
                    className={`form-input ${errors.title ? "error" : inputState.title ? "success" : ""}`}
                  />
                  {errors.title && (
                    <span className="error-message font-size-text">{errors.title}</span>
                  )}
                </div>
                <div title="Discription" className="flex-column">
                  <label htmlFor="Discription" className="form-labels font-weight500 font-size-subheading">
                    Meeting   Discription
                  </label>
                  <input
                    type="text"
                    id="discription"
                    name="discription"
                    placeholder="Meeting Description"
                    onChange={handleInputChange}
                    value={formData.discription}
                    className={`form-input ${errors.discription ? "error" : inputState.discription ? "success" : ""}`}
                  />
                  {errors.discription && (
                    <span className="error-message font-size-text">{errors.discription}</span>
                  )}
                </div>



                {/* Meeting Date */}
                <div title="meeting_date" className="flex-column">
                  <label htmlFor="meeting_date" className="form-labels font-weight500 font-size-subheading">
                    Meeting Date <span className="required">*</span>
                  </label>
                  <input
                    type="date"
                    id="meeting_date"
                    name="meeting_date"
                    onChange={handleInputChange}
                    value={formData.meeting_date}
                    className={`form-input ${errors.meeting_date ? "error" : inputState.meeting_date ? "success" : ""}`}
                  />
                  {errors.meeting_date && (
                    <span className="error-message font-size-text">{errors.meeting_date}</span>
                  )}
                </div>

                {/* Start Time */}
                <div title="isteam" className="flex-column form-group-selectt">
                  <label htmlFor="isteam" className="form-labels  font-weight500    font-size-subheading">
                    Start Time <span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="start_time"
                    name="start_time"
                    value={formData.start_time}
                    onChange={handleInputChange}
                    className="form-input form-group-selection"
                  >

                    {/* {timeSlots.map(hour => (


                      <option key={hour} value={`${String(hour).padStart(2, '0')}:00:00`}>
                        {`${String(hour).padStart(2, '0')}:00:00`}
                      </option>
                    ))} */}
                  </select>
                  <DropdownArrow />
                  <div>
                    {errors.start_time && (
                      <span className="error-message font-size-text ">{errors.start_time}</span>
                    )}
                  </div>
                </div>

                {/* End Time */}
                <div title="end_time" className="flex-column form-group-selectt">
                  <label htmlFor="end_time" className="form-labels font-weight500 font-size-subheading">
                    End Time <span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="start_time"
                    name="start_time"
                    value={formData.end_time}
                    onChange={handleInputChange}
                    className="form-input form-group-selection"
                  >

                    {timeSlots.map(hour => (


                      <option key={hour} value={`${String(hour).padStart(2, '0')}:00:00`}>
                        {`${String(hour).padStart(2, '0')}:00:00`}
                      </option>
                    ))}
                  </select>
                  <DropdownArrow />
                  <div>
                    {errors.end_time && (
                      <span className="error-message font-size-text ">{errors.end_time}</span>
                    )}
                  </div>
                </div>

                {/* Venue */}
                {
                  formData.is_outsude === "false" ?
                    <div className="flex-column form-group-selectt">
                      <label className="form-labels  font-weight500 font-size-subheading">
                        Room<span className="required">*</span>
                      </label>
                      <br />
                      {roomData.length === 0 ?
                        <div className="form-loader">
                          <div className="bar-loader"></div>
                        </div>
                        : <>
                          <select
                            name="room"
                            onChange={handleInputChange}
                            value={formData.room}
                            className={`form-input form-group-selection ${errors.room
                              ? "error"
                              : inputState.room
                                ? "success"
                                : ""
                              }`}
                          >
                            <option value="">Select venue</option>
                            {roomData?.map((room) => (
                              <option value={room.id}>{room.name}</option>
                            ))}

                          </select>
                          <DropdownArrow />
                          <div>
                            {errors.venue && (
                              <span className="error-message font-size-text ">{errors.venue}</span>
                            )}
                          </div>
                        </>}
                    </div>
                    :
                    <div title="venue" className="flex-column">
                      <label htmlFor="venue" className="form-labels font-weight500 font-size-subheading">
                        Venue
                      </label>
                      <input
                        type="text"
                        id="venue"
                        name="venue"
                        placeholder="Venue"
                        onChange={handleInputChange}
                        value={formData.venue}
                        className={`form-input ${errors.venue ? "error" : inputState.venue ? "success" : ""}`}
                      />
                      {errors.venue && (
                        <span className="error-message font-size-text">{errors.venue}</span>
                      )}
                    </div>}
                {/*      {timeSlots.map(hour => (
            <div key={hour} className="time-label">
              {String(hour).padStart(2, '0')}:00
            </div>
          ))} */}
                {/* Participants */}
                <div title="isteam" className="flex-column form-group-selectt">
                  <label htmlFor="isteam" className="form-labels  font-weight500    font-size-subheading">
                    Create Team
                  </label>
                  <br />
                  <select
                    id="isteam"
                    name="isteam"
                    value={formData.isteam}
                    onChange={handleInputChange}
                    className="form-input form-group-selection"
                  >
                    <option value="">Create Team</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                  <DropdownArrow />
                  <div>
                    {errors.isteam && (
                      <span className="error-message font-size-text ">{errors.isteam}</span>
                    )}
                  </div>
                </div>
                {
                  formData.is_outsude === "false" ? (<>


                    {formData.isteam === "Yes" ? (<>
                      <div style={{ width: '100%' }}>
                        <div className="jd-heading-outer  heading-bg-color-white">
                          <div className="jd-heading-outer-flex ">
                            <h2 className="jd-heading-main font-weight600  font-size-heading">
                              Create Team{" "}
                            </h2>
                            <div>

                              <button title="Add Documents" onClick={handleSubmitteam}
                                className="upload-svg" type="button" style={{ float: 'right' }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><g clip-path="url(#clip0_1415_3177)"><path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z" fill="white"></path></g><defs><clipPath id="clip0_1415_3177"><rect width="24" height="24" fill="white"></rect></clipPath></defs></svg> Create Team</button>
                              <br />
                            </div>
                          </div>
                          <div>
                            <div className="jd-heading-bottom-bold"></div>
                            <div className="jd-heading-bottom-light"></div>
                          </div>
                        </div>
                      </div>
                      <div className="form-flex-wrap" style={{ width: "100%", JustifyContent: 'space-around', gap: "40px" }}>
                        <div title="venue" className="flex-column" style={{ width: "10vw" }} >
                          <label
                            htmlFor="team_name"
                            className="form-labels announce-date font-weight500 font-size-heading"
                          >
                            Team Name <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            id="team_name"
                            name="team_name"
                            placeholder="Enter Team Name"
                            value={formData.team_name}
                            onChange={handleInputChange}
                            className={`form-input ${errors.team_name ? "error" : ""}`}
                            style={{ width: "20vw" }}
                          />
                          {errors.team_name && (
                            <span className="error-message font-size-text">
                              {errors.team_name}
                            </span>
                          )}
                        </div>

                        {/* Department */}
                        <div className="flex-column form-group-selectt">
                          <label className="form-labels  font-weight500 font-size-subheading">
                            Department Name<span className="required">*</span>
                          </label>
                          <br />
                          {departmentname.length === 0 ?
                            <div className="form-loader">
                              <div className="bar-loader"></div>
                            </div>
                            : <>
                              <select
                                name="department"
                                onChange={handleInputChange}
                                value={formData.department}
                                className={`form-input form-group-selection ${errors.department
                                  ? "error"
                                  : inputState.department
                                    ? "success"
                                    : ""
                                  }`}
                              >
                                <option value="">Select Department</option>
                                {departmentname?.map((department) => (
                                  <option value={department.id}>{department.sub_company_name}</option>
                                ))}

                              </select>
                              <DropdownArrow />
                              <div>
                                {errors.department && (
                                  <span className="error-message font-size-text ">{errors.department}</span>
                                )}
                              </div>
                            </>}
                        </div>

                        {/* Participants */}
                        <div title="participants" className="flex-column">
                          <label htmlFor="participants" className="form-labels font-weight500 font-size-subheading">
                            Participants
                          </label>
                          <Select
                            options={options}
                            isMulti
                            onChange={selectTeamparticipint}
                            value={formData.assignedto?.map((code) => ({
                              value: code,
                              label: options.find((opt) => opt.value === code)?.label || code,
                            }))}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '20vw',
                                minHeight: '50px',
                                borderRadius: '10px',
                                border: '1px solid #e3e3e3',
                                margin: '10px 0px 20px 0px',
                                color: '#4a4a4a',
                                backgroundColor: '#f7f8fa',
                                padding: '0px 10px',
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: '#4a4a4a',
                              }),
                              menu: (provided) => ({
                                ...provided,
                                borderRadius: '10px',
                                marginTop: '0',
                              }),
                              input: (provided) => ({
                                ...provided,
                                color: '#4a4a4a',
                              }),
                              singleValue: (provided) => ({
                                ...provided,
                                color: '#4a4a4a',
                              }),
                            }}
                          />
                          {errors.participants && (
                            <span className="error-message font-size-text">{errors.participants}</span>
                          )}
                        </div>

                        {/* Submit Button */}
                        <div className="button-models" style={{ width: '100%' }}>

                        </div>
                      </div>
                    </>) : (<>
                      <div title="participants" className="flex-column">
                        <label htmlFor="participants" className="form-labels font-weight500 font-size-subheading">
                          Participants
                        </label>
                        <Select
                          options={options}
                          isMulti
                          onChange={selectEmployee}
                          value={formData.assignedto?.map((code) => ({
                            value: code,
                            label: options.find((opt) => opt.value === code)?.label || code,
                          }))}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              width: '30vw',
                              minHeight: '50px',
                              borderRadius: '10px',
                              border: '1px solid #e3e3e3',
                              margin: '10px 0px 20px 0px',
                              color: '#4a4a4a',
                              backgroundColor: '#f7f8fa',
                              padding: '0px 10px',
                            }),
                            placeholder: (provided) => ({
                              ...provided,
                              color: '#4a4a4a',
                            }),
                            menu: (provided) => ({
                              ...provided,
                              borderRadius: '10px',
                              marginTop: '0',
                            }),
                            input: (provided) => ({
                              ...provided,
                              color: '#4a4a4a',
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              color: '#4a4a4a',
                            }),
                          }}
                        />
                        {errors.participants && (
                          <span className="error-message font-size-text">{errors.participants}</span>
                        )}
                      </div>
                    </>)}

                  </>) : <>

                    <div title="outsideemail" className="flex-column">
                      <label htmlFor="outsideemail" className="form-labels font-weight500 font-size-subheading">
                        Outside Person Email
                      </label>
                      <input
                        type="text"
                        id="outsideemail"
                        name="outsideemail"
                        placeholder="Outside Person Email"
                        onChange={handleInputChange}
                        value={formData.outsideemail}
                        className={`form-input ${errors.outsideemail ? "error" : inputState.outsideemail ? "success" : ""}`}
                      />
                      {errors.outsideemail && (
                        <span className="error-message font-size-text">{errors.outsideemail}</span>
                      )}
                    </div>
                  </>
                }



                {/* Participants Addition Type */}
                {
                  formData.is_outsude === "true" && <div title="participants" className="flex-column">
                    <label htmlFor="participants" className="form-labels font-weight500 font-size-subheading">
                      Participants
                    </label>
                    <Select
                      options={options}
                      isMulti
                      onChange={selectEmployeeoutside}
                      value={formData.assignedto?.map((code) => ({
                        value: code,
                        label: options.find((opt) => opt.value === code)?.label || code,
                      }))}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: '33vw',
                          minHeight: '50px',
                          borderRadius: '10px',
                          border: '1px solid #e3e3e3',
                          margin: '10px 0px 20px 0px',
                          color: '#4a4a4a',
                          backgroundColor: '#f7f8fa',
                          padding: '0px 10px',
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          color: '#4a4a4a',
                        }),
                        menu: (provided) => ({
                          ...provided,
                          borderRadius: '10px',
                          marginTop: '0',
                        }),
                        input: (provided) => ({
                          ...provided,
                          color: '#4a4a4a',
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: '#4a4a4a',
                        }),
                      }}
                    />
                    {errors.participants && (
                      <span className="error-message font-size-text">{errors.participants}</span>
                    )}
                  </div>

                }



                {/* Submit Button */}
                <div className="button-models" style={{ width: '100%' }}>
                  <button
                    onClick={handleSubmit}
                    className="model-button font-weight500 model-button-submit"
                  >
                    Add
                  </button>
                </div>
              </div>




            </form>
          </div>
        </div>
      </div>
    </>
  );
};



const ViewMeetingModal = ({ showViewmeetingModal, handleShow, handleclose, meetingData, view, id, selectedTimeslot, getMeetingData, modaltoogleOption }) => {

  const [editmeeting, SetEditMeeting] = useState(false);
  const EditMeeting = () => {
    SetEditMeeting(!editmeeting)
  }
  const [formData, setFormData] = useState({
    title: "",

    participants: [],
    created_at: "",
    discription: "",
    organizer: sessionStorage.getItem("emp_code"),
    meeting_date: "",
    start_time: "",
    end_time: "",
    venue: "",
    mandatory: false,
    participants_addition_type: "",
    decline_reason: null,
    optional_participents: [],
    room: [],
    isteam: '',
    is_outsude: "",
    outsideemail: '',
    team_name: "",
    department: "",
    participants: [],
    participantsteam: '',
  });
  console.log(meetingData)
  useEffect(() => {
    setFormData(meetingData[0])
  }, [meetingData])

  const [roomData, setRoomData] = useState('')
  const getRoomdata = async () => {
    const result = await axios.get(`${BASE_URL}/meetingscheduler/meetingroom/`);

    setRoomData(result.data);

  };

  const [allemployee, setAllemployee] = useState([]);
  const getAllEmployee = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/ourcompanyRH/${sessionStorage.getItem("email")}/`);
      setAllemployee(res.data)
      // setFilteredEmployee(res.data)
    } catch (err) {
      // alert(err.message);
    }
  };

  const [departmentname, setDepartmentname] = useState([]);
  const [selectedSubCompany, setSelectedSubCompany] = useState('');
  const getDepartment = async () => {
    try {
      const result = await axios.get(`${BASE_URL}/wfm/departmentfilterbysubcompanycount/null/active/`);

      setDepartmentname(result.data)
    } catch (err) {

    } finally {

    }
  };

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const [loading, setLoading] = useState(false);
  const handleInputChange = (e) => {
    const { name, value, type, checked, options, files } = e.target;

    let newValue;
    if (type === "select-multiple") {
      newValue = Array.from(options)
        .filter((option) => option.selected)
        .map((option) => option.value);
    } else if (type === "checkbox") {
      newValue = checked;
    } else if (type === "file") {
      newValue = files.length > 0 ? files[0] : null;
    } else {
      newValue = value;
    }



    setInputState((prevState) => ({
      ...prevState,
      [name]:
        type === "checkbox"
          ? checked
            ? "green"
            : ""
          : newValue
            ? "green"
            : "",
    }));


    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        [name]: newValue,
      };

      return updatedFormData;
    });
    if (value.trim()) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };
  const validateform = () => {
    const newErrors = {};
    const requiredFields = [
      "title",
      "start_time",
      "end_time",
      "meeting_date",
      "room",
    ];

    requiredFields.forEach((field) => {
      console.log(formData[field])
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });

    setErrors(newErrors);
    // setIsSubmitted(true);
    return Object.keys(newErrors).length === 0;
  };
  const validateformTeam = () => {
    const newErrors = {};
    const requiredFields = [
      "team_name",
      "department",
      "participants",

    ];

    requiredFields.forEach((field) => {
      console.log(formData[field])
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });

    setErrors(newErrors);
    // setIsSubmitted(true);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        start_time: selectedTimeslot?.start,
        end_time: selectedTimeslot?.end
      };

      return updatedFormData;
    });
  }, [selectedTimeslot])
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (validateform()) {
      const loadingToastId = toast.loading("Loading: Please wait...");
      try {
        const dataToSend = {};

        dataToSend.title = formData?.title;
        dataToSend.description = formData?.discription;

        dataToSend.created_at = formData?.created_at;
        dataToSend.organizer = formData?.organizer;
        dataToSend.meeting_date = formData?.meeting_date;
        dataToSend.start_time = formData?.start_time;
        dataToSend.end_time = formData?.end_time;

        dataToSend.mandatory = formData?.mandatory;

        dataToSend.decline_reason = formData?.decline_reason;
        dataToSend.optional_participents = formData?.optional_participents;
        if (formData.is_outsude === "true") {
          dataToSend.venue = formData?.venue;
        } else {
          dataToSend.room = formData?.room;
        }
        // if(formData.isteam==="true"){
        // dataToSend.team_name = formData.team_name;
        // dataToSend.department = formData.department;
        // dataToSend.participantsteam = formData.participantsteam;
        // }

        dataToSend.is_team = formData.isteam === "true" ? true : false;
        dataToSend.is_outside = formData.is_outsude === "true" ? true : false;
        if (formData.is_outsude === "true") {
          dataToSend.outside_email = formData.outsideemail;
          dataToSend.participants_addition_type = formData?.participants_addition_type;
        } else {
          dataToSend.participants = formData?.participants;
        }



        let res = await axios.put(
          `${BASE_URL}/meetingscheduler/meeting/`,
          dataToSend,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (res.status === 201 || res.status === 200) {
          getMeetingData()
          handleclose()
        } else {

        }


      } catch (err) {
        //toast Logic
        handleErrorToast(err);
      } finally {
        // setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };
  const handleSubmitteam = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (validateformTeam()) {
      const loadingToastId = toast.loading("Loading: Please wait...");
      try {

        const dataToSend = {};

        dataToSend.team_name = formData?.team_name;
        dataToSend.department = formData?.department;
        dataToSend.participants = formData?.participantsteam;


        let res = await axios.post(
          `${BASE_URL}/meetingscheduler/team/`,
          dataToSend,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (res.status === 201 || res.status === 200) {
          // onRoomCreated(res.data);
          // onClose();
        } else {

        }


      } catch (err) {
        //toast Logic
        handleErrorToast(err);
      } finally {
        // setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };
  useEffect(() => {
    if (modaltoogleOption === "meeting") {
      getRoomdata();
      getAllEmployee();
      getDepartment();
    }
  }, [modaltoogleOption])

  const options = allemployee
    .sort((a, b) => parseInt(a.emp_code) - parseInt(b.emp_code))
    .map((e) => ({
      value: e.emp_code,
      label: `${e.emp_code} - ${e.name}`,
    }));



  const selectEmployee = (selected) => {
    // const assignedto = selected.length > 0 ? selected?.map((item) => item.value) : "";
    const participants = selected?.map((item) => item.value) || [];
    setFormData((prevState) => ({
      ...prevState,
      participants,
    }));
  };


  const selectEmployeeoutside = (selected) => {
    // const assignedto = selected.length > 0 ? selected?.map((item) => item.value) : "";
    const participants_addition_type = selected?.map((item) => item.value) || [];
    setFormData((prevState) => ({
      ...prevState,
      participants_addition_type,
    }));
  };



  const selectTeamparticipint = (selected) => {
    // const assignedto = selected.length > 0 ? selected?.map((item) => item.value) : "";
    const participantsteam = selected?.map((item) => item.value) || [];
    setFormData((prevState) => ({
      ...prevState,
      participantsteam,
    }));
  };
  return (<>

    {/* {meetingData.length > 0 && meetingData.map((meeting, index) => (
        <>
            <div key={meeting.id} className="eventitem">
                   
                   <span>{meeting.title}</span>
                 </div>
        </>
      ))} */}
    {view === "month" ? (<>
      <div onClick={handleShow}>
        {meetingData.map((meeting, index) => (
          <div key={meeting.id} className="meeting-month-view">

            <span>{meeting.title}</span>
            <span>{meeting.start_time}</span>
          </div>
        ))}
      </div>
    </>) : (<>
      <div
        onClick={handleShow}>
        {meetingData.map((meeting, index) => (
          <div className="meetingItem meetingitemdayview">
            <div className="meetingTitle">

              {meeting.title}
            </div>
            <div className="meetingTime">{meeting.start_time}</div>
            <div className="meetingParticipants">
              {meeting?.participant_details?.map((participant, idx) => (
                <span key={idx}>
                  {participant?.name}
                  {idx < meeting.participant_details.length - 1 ? ', ' : ''}
                </span>
              ))}
            </div>
          </div>
        ))}
      </div></>)}






    <div
      className={`calendar-meeting-modal meeting-view-details ${view === "day" ? "day-meeting-details" : ""} ${showViewmeetingModal && meetingData.filter((meeting) => meeting.id === id) ? 'show' : 'noshow'}`}
    >
      <div
        className="calendar-meeting-modal-content"
      >
        <div
          className="calendar-meeting-modal-header"
        >
          {editmeeting ? "Edit Meeting Details" : "View Meeting Details"}

          <span className='close-meeting-modal'>
            <button class="model-edit-button" onClick={EditMeeting}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"><path d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z" fill="#145650"></path></svg></button>
            <button onClick={handleclose}> x</button>
          </span>

        </div>
        <div className='calendar-meeting-modal-body'>
          {/* <table className="custom-table">
            <thead className="table-heading">
              <tr className="custom-table-head-tr font-weight400 font-size-subheading">
                <th className="table-heading-text">Sr no.</th>
                <th className="text-center table-heading-text">Meeting Title</th>
                <th className="text-center table-heading-text">Meeting Date</th>
                <th className="text-center table-heading-text">Start Time</th>
                <th className="text-center table-heading-text">End Time</th>
                <th className="text-center table-heading-text">Inside</th>
                <th className="text-center table-heading-text">Participants</th>
                <th className="text-center table-heading-text">Venue</th>
                <th className="text-center table-heading-text">Actions</th>
              </tr>
            </thead>
            <tbody>
              {meetingData.length > 0 && meetingData.map((meeting, index) => (
                <React.Fragment key={index}>
                  <tr className="custom-table-head-td">
                    <td className="table-body">{index + 1}</td>
                    <td className="table-body text-center">{meeting.title}</td>
                    <td className="table-body text-center">{new Date(meeting.meeting_date).toLocaleDateString()}</td>
                    <td className="table-body text-center">{meeting.start_time}</td>
                    <td className="table-body text-center">{meeting.end_time}</td>
                    <td className="table-body text-center">{meeting.is_outside ? "No" : "Yes"}</td>
                    <td className="table-body text-center width-10vw">{meeting?.participant_details?.map((par) => {
                      return (
                        <>{par?.name}{meeting?.participant_details.length > 1 ? "," : ""}</>
                      )
                    })} </td>
                    <td className="table-body text-center">{meeting.room_name}</td>
                    <td className="text-center table-body width-5vw">
                      <div className="flex-row">
                    
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table> */}
          <form className="register-form">
            <div className="form-flex-wrap">


              <div title="is_outsude" className="flex-column form-group-selectt">
                <label htmlFor="is_outsude" className="form-labels  font-weight500    font-size-subheading">
                  Meeting Type
                </label>
                <br />
                <select
                  id="is_outsude"
                  name="is_outsude"
                  value={formData.is_outsude}
                  onChange={handleInputChange}
                  readOnly={!editmeeting}
                  className="form-input form-group-selection"
                >
                  <option value="">Select Meeting Type</option>
                  <option value="false">Inside</option>
                  <option value="true">Outside</option>
                </select>
                <DropdownArrow />
                <div>
                  {errors.is_outsude && (
                    <span className="error-message font-size-text ">{errors.is_outsude}</span>
                  )}
                </div>
              </div>




              <div title="title" className="flex-column">
                <label htmlFor="title" className="form-labels font-weight500 font-size-subheading">
                  Meeting Agenda
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Meeting Agenda"
                  onChange={handleInputChange}
                  value={formData.title}
                  className={`form-input ${errors.title ? "error" : inputState.title ? "success" : ""}`}
                />
                {errors.title && (
                  <span className="error-message font-size-text">{errors.title}</span>
                )}
              </div>
              <div title="Discription" className="flex-column">
                <label htmlFor="Discription" className="form-labels font-weight500 font-size-subheading">
                  Meeting   Discription
                </label>
                <input
                  type="text"
                  id="discription"
                  name="discription"
                  placeholder="Meeting Description"
                  onChange={handleInputChange}
                  value={formData.discription}
                  className={`form-input ${errors.discription ? "error" : inputState.discription ? "success" : ""}`}
                />
                {errors.discription && (
                  <span className="error-message font-size-text">{errors.discription}</span>
                )}
              </div>



              {/* Meeting Date */}
              <div title="meeting_date" className="flex-column">
                <label htmlFor="meeting_date" className="form-labels font-weight500 font-size-subheading">
                  Meeting Date <span className="required">*</span>
                </label>
                <input
                  type="date"
                  id="meeting_date"
                  name="meeting_date"
                  onChange={handleInputChange}
                  value={formData.meeting_date}
                  className={`form-input ${errors.meeting_date ? "error" : inputState.meeting_date ? "success" : ""}`}
                />
                {errors.meeting_date && (
                  <span className="error-message font-size-text">{errors.meeting_date}</span>
                )}
              </div>

              {/* Start Time */}
              <div title="isteam" className="flex-column form-group-selectt">
                <label htmlFor="isteam" className="form-labels  font-weight500    font-size-subheading">
                  Start Time <span className="required">*</span>
                </label>
                <br />
                <select
                  id="start_time"
                  name="start_time"
                  value={formData.start_time}
                  onChange={handleInputChange}
                  className="form-input form-group-selection"
                >

                  {/* {timeSlots.map(hour => (


                    <option key={hour} value={`${String(hour).padStart(2, '0')}:00:00`}>
                      {`${String(hour).padStart(2, '0')}:00:00`}
                    </option>
                  ))} */}
                </select>
                <DropdownArrow />
                <div>
                  {errors.start_time && (
                    <span className="error-message font-size-text ">{errors.start_time}</span>
                  )}
                </div>
              </div>

              {/* End Time */}
              <div title="end_time" className="flex-column form-group-selectt">
                <label htmlFor="end_time" className="form-labels font-weight500 font-size-subheading">
                  End Time <span className="required">*</span>
                </label>
                <br />
                <select
                  id="start_time"
                  name="start_time"
                  value={formData.end_time}
                  onChange={handleInputChange}
                  className="form-input form-group-selection"
                >

                  {/* {timeSlots.map(hour => (


                    <option key={hour} value={`${String(hour).padStart(2, '0')}:00:00`}>
                      {`${String(hour).padStart(2, '0')}:00:00`}
                    </option>
                  ))} */}
                </select>
                <DropdownArrow />
                <div>
                  {errors.end_time && (
                    <span className="error-message font-size-text ">{errors.end_time}</span>
                  )}
                </div>
              </div>

              {/* Venue */}
              {
                formData.is_outsude === "false" ?
                  <div className="flex-column form-group-selectt">
                    <label className="form-labels  font-weight500 font-size-subheading">
                      Room<span className="required">*</span>
                    </label>
                    <br />
                    {roomData.length === 0 ?
                      <div className="form-loader">
                        <div className="bar-loader"></div>
                      </div>
                      : <>
                        <select
                          name="room"
                          onChange={handleInputChange}
                          value={formData.room}
                          className={`form-input form-group-selection ${errors.room
                            ? "error"
                            : inputState.room
                              ? "success"
                              : ""
                            }`}
                        >
                          <option value="">Select venue</option>
                          {roomData?.map((room) => (
                            <option value={room.id}>{room.name}</option>
                          ))}

                        </select>
                        <DropdownArrow />
                        <div>
                          {errors.venue && (
                            <span className="error-message font-size-text ">{errors.venue}</span>
                          )}
                        </div>
                      </>}
                  </div>
                  :
                  <div title="venue" className="flex-column">
                    <label htmlFor="venue" className="form-labels font-weight500 font-size-subheading">
                      Venue
                    </label>
                    <input
                      type="text"
                      id="venue"
                      name="venue"
                      placeholder="Venue"
                      onChange={handleInputChange}
                      value={formData.venue}
                      className={`form-input ${errors.venue ? "error" : inputState.venue ? "success" : ""}`}
                    />
                    {errors.venue && (
                      <span className="error-message font-size-text">{errors.venue}</span>
                    )}
                  </div>}
              {/*      {timeSlots.map(hour => (
            <div key={hour} className="time-label">
              {String(hour).padStart(2, '0')}:00
            </div>
          ))} */}
              {/* Participants */}
              <div title="isteam" className="flex-column form-group-selectt">
                <label htmlFor="isteam" className="form-labels  font-weight500    font-size-subheading">
                  Create Team
                </label>
                <br />
                <select
                  id="isteam"
                  name="isteam"
                  value={formData.isteam}
                  onChange={handleInputChange}
                  className="form-input form-group-selection"
                >
                  <option value="">Create Team</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
                <DropdownArrow />
                <div>
                  {errors.isteam && (
                    <span className="error-message font-size-text ">{errors.isteam}</span>
                  )}
                </div>
              </div>
              {
                formData.is_outsude === "false" ? (<>


                  {formData.isteam === "Yes" ? (<>
                    <div style={{ width: '100%' }}>
                      <div className="jd-heading-outer  heading-bg-color-white">
                        <div className="jd-heading-outer-flex ">
                          <h2 className="jd-heading-main font-weight600  font-size-heading">
                            Create Team{" "}
                          </h2>
                          <div>

                            <button title="Add Documents" onClick={handleSubmitteam}
                              className="upload-svg" type="button" style={{ float: 'right' }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><g clip-path="url(#clip0_1415_3177)"><path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z" fill="white"></path></g><defs><clipPath id="clip0_1415_3177"><rect width="24" height="24" fill="white"></rect></clipPath></defs></svg> Create Team</button>
                            <br />
                          </div>
                        </div>
                        <div>
                          <div className="jd-heading-bottom-bold"></div>
                          <div className="jd-heading-bottom-light"></div>
                        </div>
                      </div>
                    </div>
                    <div className="form-flex-wrap" style={{ width: "100%", JustifyContent: 'space-around', gap: "40px" }}>
                      <div title="venue" className="flex-column" style={{ width: "10vw" }} >
                        <label
                          htmlFor="team_name"
                          className="form-labels announce-date font-weight500 font-size-heading"
                        >
                          Team Name <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          id="team_name"
                          name="team_name"
                          placeholder="Enter Team Name"
                          value={formData.team_name}
                          onChange={handleInputChange}
                          className={`form-input ${errors.team_name ? "error" : ""}`}
                          style={{ width: "20vw" }}
                        />
                        {errors.team_name && (
                          <span className="error-message font-size-text">
                            {errors.team_name}
                          </span>
                        )}
                      </div>

                      {/* Department */}
                      <div className="flex-column form-group-selectt">
                        <label className="form-labels  font-weight500 font-size-subheading">
                          Department Name<span className="required">*</span>
                        </label>
                        <br />
                        {departmentname.length === 0 ?
                          <div className="form-loader">
                            <div className="bar-loader"></div>
                          </div>
                          : <>
                            <select
                              name="department"
                              onChange={handleInputChange}
                              value={formData.department}
                              className={`form-input form-group-selection ${errors.department
                                ? "error"
                                : inputState.department
                                  ? "success"
                                  : ""
                                }`}
                            >
                              <option value="">Select Department</option>
                              {departmentname?.map((department) => (
                                <option value={department.id}>{department.sub_company_name}</option>
                              ))}

                            </select>
                            <DropdownArrow />
                            <div>
                              {errors.department && (
                                <span className="error-message font-size-text ">{errors.department}</span>
                              )}
                            </div>
                          </>}
                      </div>

                      {/* Participants */}
                      <div title="participants" className="flex-column">
                        <label htmlFor="participants" className="form-labels font-weight500 font-size-subheading">
                          Participants
                        </label>
                        <Select
                          options={options}
                          isMulti
                          onChange={selectTeamparticipint}
                          value={formData.assignedto?.map((code) => ({
                            value: code,
                            label: options.find((opt) => opt.value === code)?.label || code,
                          }))}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              width: '20vw',
                              minHeight: '50px',
                              borderRadius: '10px',
                              border: '1px solid #e3e3e3',
                              margin: '10px 0px 20px 0px',
                              color: '#4a4a4a',
                              backgroundColor: '#f7f8fa',
                              padding: '0px 10px',
                            }),
                            placeholder: (provided) => ({
                              ...provided,
                              color: '#4a4a4a',
                            }),
                            menu: (provided) => ({
                              ...provided,
                              borderRadius: '10px',
                              marginTop: '0',
                            }),
                            input: (provided) => ({
                              ...provided,
                              color: '#4a4a4a',
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              color: '#4a4a4a',
                            }),
                          }}
                        />
                        {errors.participants && (
                          <span className="error-message font-size-text">{errors.participants}</span>
                        )}
                      </div>

                      {/* Submit Button */}
                      <div className="button-models" style={{ width: '100%' }}>

                      </div>
                    </div>
                  </>) : (<>
                    <div title="participants" className="flex-column">
                      <label htmlFor="participants" className="form-labels font-weight500 font-size-subheading">
                        Participants
                      </label>
                      <Select
                        options={options}
                        isMulti
                        onChange={selectEmployee}
                        value={formData.assignedto?.map((code) => ({
                          value: code,
                          label: options.find((opt) => opt.value === code)?.label || code,
                        }))}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: '30vw',
                            minHeight: '50px',
                            borderRadius: '10px',
                            border: '1px solid #e3e3e3',
                            margin: '10px 0px 20px 0px',
                            color: '#4a4a4a',
                            backgroundColor: '#f7f8fa',
                            padding: '0px 10px',
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: '#4a4a4a',
                          }),
                          menu: (provided) => ({
                            ...provided,
                            borderRadius: '10px',
                            marginTop: '0',
                          }),
                          input: (provided) => ({
                            ...provided,
                            color: '#4a4a4a',
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            color: '#4a4a4a',
                          }),
                        }}
                      />
                      {errors.participants && (
                        <span className="error-message font-size-text">{errors.participants}</span>
                      )}
                    </div>
                  </>)}

                </>) : <>

                  <div title="outsideemail" className="flex-column">
                    <label htmlFor="outsideemail" className="form-labels font-weight500 font-size-subheading">
                      Outside Person Email
                    </label>
                    <input
                      type="text"
                      id="outsideemail"
                      name="outsideemail"
                      placeholder="Outside Person Email"
                      onChange={handleInputChange}
                      value={formData.outsideemail}
                      className={`form-input ${errors.outsideemail ? "error" : inputState.outsideemail ? "success" : ""}`}
                    />
                    {errors.outsideemail && (
                      <span className="error-message font-size-text">{errors.outsideemail}</span>
                    )}
                  </div>
                </>
              }



              {/* Participants Addition Type */}
              {
                formData.is_outsude === "true" && <div title="participants" className="flex-column">
                  <label htmlFor="participants" className="form-labels font-weight500 font-size-subheading">
                    Participants
                  </label>
                  <Select
                    options={options}
                    isMulti
                    onChange={selectEmployeeoutside}
                    value={formData.assignedto?.map((code) => ({
                      value: code,
                      label: options.find((opt) => opt.value === code)?.label || code,
                    }))}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: '33vw',
                        minHeight: '50px',
                        borderRadius: '10px',
                        border: '1px solid #e3e3e3',
                        margin: '10px 0px 20px 0px',
                        color: '#4a4a4a',
                        backgroundColor: '#f7f8fa',
                        padding: '0px 10px',
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: '#4a4a4a',
                      }),
                      menu: (provided) => ({
                        ...provided,
                        borderRadius: '10px',
                        marginTop: '0',
                      }),
                      input: (provided) => ({
                        ...provided,
                        color: '#4a4a4a',
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: '#4a4a4a',
                      }),
                    }}
                  />
                  {errors.participants && (
                    <span className="error-message font-size-text">{errors.participants}</span>
                  )}
                </div>

              }



              {/* Submit Button */}
              <div className="button-models" style={{ width: '100%' }}>
                <button
                  onClick={handleSubmit}
                  className="model-button font-weight500 model-button-submit"
                >
                  Add
                </button>
              </div>
            </div>




          </form>

        </div>
      </div>
    </div>
  </>)
}


export { CreateMeetingModal, ViewMeetingModal };
