

import React, { useState, useEffect } from 'react';
import { useCalendar } from './CalenderContext';
import CreateEventModal from './CreateEventModal';

const WeekView = () => {
  const { currentMonth, currentYear, selectedDate, setSelectedDate, weekDays, setWeekDays, getStartOfWeek, addDays, handleTimeSlotClick, showModal, selectedTimeSlot, setSelectedTimeSlot } = useCalendar();

  const [currentDate, setCurrentDate] = useState(selectedDate);

  // const [currentTime, setCurrentTime] = useState(new Date());



  const hours = Array.from({ length: 24 }, (_, i) => i);

  const formatTime = (hour) => {
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const displayHour = hour % 12 || 12;
    return `${displayHour}${ampm}`;
  };

  const formatDay = (date) => {
    return new Intl.DateTimeFormat('en-US', { weekday: 'short' }).format(date);
  };

  const formatMonth = (date) => {
    return new Intl.DateTimeFormat('en-US', { month: 'long', year: 'numeric' }).format(date);
  };





  const isSameDay = (date1, date2) => {
    return date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear();
  };

  useEffect(() => {
    const days = [];
    const start = getStartOfWeek(currentDate);

    for (let i = 0; i < 7; i++) {
      days.push(addDays(start, i));
    }
    setWeekDays(days);
  }, [currentDate]);

  const handlePrevWeek = () => {
    setCurrentDate((prevDate) => addDays(prevDate, -7));
    // setSelectedDate(addDays(currentDate, -7)); 
  };

  const handleNextWeek = () => {
    setCurrentDate((prevDate) => addDays(prevDate, 7));
    // setSelectedDate(addDays(currentDate, 7)); 
  };

  const isCurrentDay = (date) => {
    return isSameDay(date, new Date());
  };


  return (
    <div className="calendar-container-week">
      <div className="calendar-header-week">
        <button onClick={handlePrevWeek} className="calendar-button">
          <span className="material-icons-outlined">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M15 18l-6-6 6-6"></path>
            </svg>
          </span>
        </button>
        <h2 className="calendar-title-week">
          {/* {formatMonth(new Date(currentYear, currentMonth))} */}
        </h2>
        <button onClick={handleNextWeek} className="calendar-button">
          <span className="material-icons-outlined">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M9 18l6-6-6-6"></path>
            </svg>
          </span>
        </button>
      </div>

      <div className="calendar-body-week">
        <div className="calendar-time-column-week">
          <div className="empty-header"></div>
          {hours.map((hour) => (
            <div key={hour} className="time-slot">
              {formatTime(hour)}
            </div>
          ))}
        </div>

        <div className="calendar-days-week">
          <div className="week-days-week">
            {weekDays.map((day) => (
              <div key={day.toISOString()} className="day-column">
                <div className={`day-header ${isCurrentDay(day) ? 'current-day' : ''}`}>
                  <div className="day-name">{formatDay(day)}</div>
                  <div
                  //   className={`day-date ${isCurrentDay(day) ? 'current-day-text' : ''}`}
                  >
                    {day.getDate()}
                  </div>
                </div>
                {hours.map((hour) => (
                  <div
                    key={hour}
                    onClick={() => handleTimeSlotClick(day, hour)}
                    className="time-slot"
                  />
                ))}
              </div>
            ))}
          </div>
          <CreateEventModal
            isOpen={showModal}
            // onClose={() => setShowModal(false)}
            selectedTime={selectedTimeSlot}
            selectedDate={selectedDate}
          />
        </div>
      </div>
    </div>
  );
};

export default WeekView;
