import React from 'react';
import { CalendarProvider } from "./CalenderContext";
// import Calender from "./Calendermain";
import Calender from './Calendermain';
// import "./Documentation/Table.css"
function CalenderContextprovider() {
  return (
    <CalendarProvider>
      <Calender />
    </CalendarProvider>
  );
}

export default CalenderContextprovider;