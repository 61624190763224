
import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from "../../config/axios";
const CalendarContext = createContext();

export const useCalendar = () => {
  return useContext(CalendarContext);
};

export const CalendarProvider = ({ children }) => {

  const [currentMonthIdx, setCurrentMonthIdx] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [view, setView] = useState("month");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [createButtonModal, setcreateButtonModal] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [weekDays, setWeekDays] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalmeeting, setShowModalmeeting] = useState(false);
  const [showRoomModal, setShowRoomModal] = useState(false);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);

  const [createroomButtonModal, setCreateroomButtonModal] = useState(false);
  const [modaltoogleOption, SetModaltoogleoption] = useState('');
  const [meetingType, setMeetingtype] = useState("");
  const [timeRange, setTimeRange] = useState("");
  const [room, Setroom] = useState([]);


  const formatTime = (hour) => {
    const time = new Date();
    time.setHours(hour, 0, 0);
    return time.toLocaleString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    });
  };


  const formatDate = (date) => {
    return date?.toLocaleDateString('en-US', {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  };

  const createTimeRangeOptions = () => {
    const options = [];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j += 60) {
        const startTime = formatTime(i, j);
        const endTime = formatTime(i + 1, j);
        options.push(`${startTime} - ${endTime}`);
      }
    }
    return options;
  };


  const timeRangeOptions = createTimeRangeOptions();
  const generateMonth = (monthIndex, year) => {
    const firstDayOfMonth = new Date(year, monthIndex, 1);
    const daysInMonth = new Date(year, monthIndex + 1, 0).getDate();
    const startDayOfWeek = firstDayOfMonth.getDay();

    const monthDays = [];
    let week = [];

    for (let i = 0; i < startDayOfWeek; i++) {
      week.push(null);
    }


    for (let day = 1; day <= daysInMonth; day++) {
      week.push(new Date(year, monthIndex, day));

      if (week.length === 7) {
        monthDays.push(week);
        week = [];
      }
    }

    if (week.length > 0) {
      while (week.length < 7) {
        week.push(null);
      }
      monthDays.push(week);
    }

    return monthDays;
  };

  useEffect(() => {

    setCurrentMonth(generateMonth(currentMonthIdx, currentYear));
  }, [currentMonthIdx, currentYear]);
  const getStartOfWeek = (date) => {
    const d = new Date(date);
    const day = d.getDay();
    d.setDate(d.getDate() - day);
    return d;
  };
  const handlePrevMonth = () => {
    setCurrentMonthIdx((prevIdx) => {
      let newMonth = prevIdx - 1;
      let newYear = currentYear;

      if (newMonth < 0) {
        newMonth = 11;
        newYear = currentYear - 1;
      }

      setCurrentYear(newYear);
      return newMonth;
    });
  };

  const handleNextMonth = () => {
    setCurrentMonthIdx((prevIdx) => {
      let newMonth = prevIdx + 1;
      let newYear = currentYear;

      if (newMonth > 11) {
        newMonth = 0;
        newYear = currentYear + 1;
      }

      setCurrentYear(newYear);
      return newMonth;
    });
  };

  const handleToday = () => {
    const today = new Date();


    setSelectedDate(today);


    setCurrentMonthIdx(today.getMonth());
    setCurrentYear(today.getFullYear());


  };


  const handleDayClick = (date) => {
    setSelectedDate(date);
    setCurrentTime(new Date());
    setShowModal(true);
  };

  const handleDayClicksidebar = (date) => {
    setSelectedDate(date);
    setCurrentTime(new Date());
    setView('day')
  };

  const handleViewChange = (event) => {
    setView(event.target.value);
  };

  const addDays = (date, days) => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + days);
    return newDate;
  };

  const handleopenRoomModal = (date) => {

    setShowRoomModal(true);
  };

  const closeMeetingModal = () => {
    console.log('clicked')
    setShowModalmeeting(false)

  };

  const handlecloseRoomModal = (date) => {

    setShowRoomModal(false);
  };

  useEffect(() => {
    const days = [];
    const start = getStartOfWeek(currentDate);

    for (let i = 0; i < 7; i++) {
      days.push(addDays(start, i));
    }
    setWeekDays(days);
  }, [currentDate]);
  const handleTimeSlotClick = (hour) => {
    console.log(hour)
    const clickedStartTime = new Date(selectedDate);


    clickedStartTime.setHours(hour, 0, 0, 0);
    console.log("Start Time: ", clickedStartTime.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit' }));

    const clickedEndTime = new Date(clickedStartTime);
    clickedEndTime.setHours(clickedStartTime.getHours() + 1);
    console.log("End Time: ", clickedEndTime.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit' }));



    setSelectedTimeSlot({
      start: clickedStartTime.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit' }),
      end: clickedEndTime.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit' })
    });


    setShowModal(true);
  };


  const timeSlots = Array.from({ length: 24 }, (_, i) => i);

  const handleCreateRoomButtonClick = () => {
    console.log('clicked')
    setCreateroomButtonModal(true);
  };


  const handleCloseRoomModal = () => {
    setCreateroomButtonModal(false);
  };


  const handleRoomCreated = (roomData) => {
    Setroom([...room, roomData]);
    setCreateroomButtonModal(false);
  };

  const handlechooseToogleoption = (Option) => {
    console.log(Option, "clicked")
    setShowModalmeeting(true);
    SetModaltoogleoption(Option)
    // setShowModal(false)

  }

  const handleTimeRangeChange = (e) => {
    setTimeRange(e.target.value);
  };

  const handleSelectMeetingType = (e) => {
    setMeetingtype(e.target.value);
  };

  const handlePrevDay = () => {
    setSelectedDate((prevDate) => {
      const newDate = new Date(prevDate);


      newDate.setDate(newDate.getDate() - 1);


      const newMonthIdx = newDate.getMonth();
      const newYear = newDate.getFullYear();

      setCurrentMonthIdx(newMonthIdx);
      setCurrentYear(newYear);


      return newDate;
    });
  };

  const handleNextDay = () => {
    setSelectedDate((prevDate) => {
      const newDate = new Date(prevDate);


      newDate.setDate(newDate.getDate() + 1);

      const newMonthIdx = newDate.getMonth();
      const newYear = newDate.getFullYear();

      setCurrentMonthIdx(newMonthIdx);
      setCurrentYear(newYear);

      return newDate;
    });
  };






  const [roomData, setRoomData] = useState('')
  const getRoomdata = async () => {
    const result = await axios.get(`${BASE_URL}/meetingscheduler/meetingroom/`);

    setRoomData(result.data);

  };

  const [meetingData, setMeetingData] = useState('')
  const getMeetingData = async () => {
    const result = await axios.get(`${BASE_URL}/meetingscheduler/meeting/`);
    setMeetingData(result.data);

  };

  useEffect(() => {
    getRoomdata();
    getMeetingData();
  }, [])


  return (
    <CalendarContext.Provider
      value={{
        currentMonthIdx,
        currentYear,
        currentMonth,
        events,
        view,
        weekDays,
        selectedDate,
        createButtonModal,
        showModal,
        timeSlots,
        selectedTimeSlot,
        createroomButtonModal,
        modaltoogleOption,
        currentTime,
        timeRange,
        timeRangeOptions,
        meetingType,
        showRoomModal,
        roomData,
        showModalmeeting,
        closeMeetingModal,
        setSelectedTimeSlot,
        setWeekDays,
        getStartOfWeek,
        addDays,
        setSelectedDate,
        setShowModal,
        setcreateButtonModal,
        handlePrevMonth,
        handleNextMonth,
        handlePrevDay,
        handleNextDay,
        handleToday,
        handleDayClick,
        handleDayClicksidebar,
        handleViewChange,
        handleTimeSlotClick,
        handleCreateRoomButtonClick,
        handleCloseRoomModal,
        handleRoomCreated,
        handlechooseToogleoption,
        setCurrentTime,
        handleTimeRangeChange,
        formatTime,
        formatDate,
        handleSelectMeetingType,
        handlecloseRoomModal,
        handleopenRoomModal,
        getRoomdata,
        meetingData,
        getMeetingData,
        setShowRoomModal,
      }}
    >
      {children}

    </CalendarContext.Provider>
  );
};
