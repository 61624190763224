
import React, { useEffect, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from 'react-toastify';
import { BASE_URL } from '../../config/axios';
import axios from 'axios';
import { formatCurrencyIndian, formatMonthYear, handleAllError, inputMaxLimit } from '../CustomFunctions';
import { formattedDate, formattedDateLong } from '../Date';
import { Attachment, CheckGreen, CrossRed } from '../AllSvg';

const ApplyAdvanceForm = ({ code, getAdvanceData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setFormData({
      emp_code: "",
      department: sessionStorage.getItem("department"),
      // rh_assigned: sessionStorage.getItem("rh"),
      advance_amount: "",
      reason_for_taking_advance: "",
      date_of_request: formattedDate,
    })
    setErrors({})
    setShow(false)
  };
  const handleShow = () => {
    setFormData({
      emp_code: code || sessionStorage.getItem("emp_code"),
      department: sessionStorage.getItem("department"),
      // rh_assigned: sessionStorage.getItem("rh"),
      advance_amount: "",
      reason_for_taking_advance: "",
      date_of_request: formattedDate,
    })
    setShow(true)
  };

  const [formData, setFormData] = useState({
    emp_code: code || sessionStorage.getItem("emp_code"),
    department: sessionStorage.getItem("department"),
    advance_amount: "",
    reason_for_taking_advance: "",
    date_of_request: formattedDate,
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
      "department",
      // "rh_assigned",
      "advance_amount",
      "reason_for_taking_advance",
      "date_of_request",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    // console.log("newErrors")
    // console.log(newErrors)
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    if (value.trim()) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };


  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.post(
          `${BASE_URL}/wfm/advanceforms/`,
          formData
        );

        if (res.status === 200) {
          await getAdvanceData();
          handleClose()
        } else {
          alert(res);
        }
      } catch (err) {
        handleAllError(err)
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  const getCurrentMonth = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure two digits
    return `${year}-${month}`;
  };

  return (
    <>
      <button title="Apply Advance Form" className="model-view-button model-add" onClick={handleShow}>
        {/* <AddwithBlueCircle /> */}
        <svg
          width="24"
          height="25"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 64 64"
          id="savings-account"
        >
          <path d="M10.5 19h5.5c.55 0 1-.45 1-1s-.45-1-1-1h-3v-2c0-.55-.45-1-1-1s-1 .45-1 1v2h-.5c-1.93 0-3.5 1.57-3.5 3.5s1.57 3.5 3.5 3.5h3c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-5.5c-.55 0-1 .45-1 1s.45 1 1 1h3v2c0 .55.45 1 1 1s1-.45 1-1v-2h.5c1.93 0 3.5-1.57 3.5-3.5s-1.57-3.5-3.5-3.5h-3c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5zM31 16h-10c-.55 0-1 .45-1 1s.45 1 1 1h10c.55 0 1-.45 1-1s-.45-1-1-1zM31 22h-10c-.55 0-1 .45-1 1s.45 1 1 1h10c.55 0 1-.45 1-1s-.45-1-1-1zM31 28h-10c-.55 0-1 .45-1 1s.45 1 1 1h10c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
          <path d="m38,37c2.21,0,4-1.79,4-4v-4.37c1.82.87,3.85,1.37,6,1.37,7.72,0,14-6.28,14-14s-6.28-14-14-14c-5.17,0-9.68,2.82-12.11,7H6c-2.21,0-4,1.79-4,4v20c0,2.21,1.79,4,4,4h13v13h-3c-.55,0-1,.45-1,1v7c0,2.21,1.79,4,4,4h34c2.21,0,4-1.79,4-4v-27c0-.55-.45-1-1-1s-1,.45-1,1v27c0,1.1-.9,2-2,2h-2c-1.1,0-2-.9-2-2v-7c0-.55-.45-1-1-1h-27v-13h17Zm3.29-20.71c.39-.39,1.02-.39,1.41,0l3.25,3.25,7.3-8.21c.37-.41,1-.45,1.41-.08.41.37.45,1,.08,1.41l-8,9c-.18.21-.44.33-.72.33,0,0-.02,0-.03,0-.26,0-.52-.1-.71-.29l-4-4c-.39-.39-.39-1.02,0-1.41ZM6,35c-1.1,0-2-.9-2-2V13c0-1.1.9-2,2-2h28.94c-.6,1.56-.94,3.24-.94,5,0,4.75,2.38,8.94,6,11.47v5.53c0,1.1-.9,2-2,2H6Z"></path>
          <path d="M48 40h-20c-.55 0-1 .45-1 1s.45 1 1 1h20c.55 0 1-.45 1-1s-.45-1-1-1zM48 45h-20c-.55 0-1 .45-1 1s.45 1 1 1h20c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">

        <Modal.Header closeButton>
          <Modal.Title>Apply for Advance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-column">
                <label
                  htmlFor="advance_amount"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Advance Amount<span className="required">*</span>
                </label>
                <input
                  id="advance_amount"
                  type="number"
                  name="advance_amount"
                  placeholder="0"
                  min={0}
                  onWheel={(e) => e.target.blur()}
                  onChange={handleInputChange}
                  value={formData.advance_amount}
                  className={`form-input ${errors.advance_amount
                    ? "error"
                    : inputState.advance_amount
                      ? "success"
                      : ""
                    }`}
                />
                {errors.advance_amount && (
                  <span className="error-message font-size-text ">{errors.advance_amount}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="reason_for_taking_advance" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Reason for Taking Advance<span className="required">*</span>
                </label>
                <textarea
                  id="reason_for_taking_advance"
                  type="text"
                  name="reason_for_taking_advance"
                  placeholder="Your Reason"
                  onChange={handleInputChange}
                  // maxLength={200}
                  value={formData.reason_for_taking_advance}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.reason_for_taking_advance
                    ? "error"
                    : inputState.reason_for_taking_advance
                      ? "success"
                      : ""
                    }`}
                />
                {errors.reason_for_taking_advance && (
                  <span className="error-message font-size-text ">{errors.reason_for_taking_advance}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};

const AdvanceFormRHApproval = ({ i, getAdvanceData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
    })
    setErrors({})
    setShow(false)
  };
  const handleShow = () => {
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "RH reviewed the advance form.",
      action_datetime: new Date().toISOString(),
    })
    setShow(true)
  };

  const [formData, setFormData] = useState({
    emp_code: sessionStorage.getItem("emp_code"),
    action_description: "RH reviewed the advance form.",
    action_datetime: new Date().toISOString(),
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
      "action_description",
      "action_datetime",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    // console.log("newErrors")
    // console.log(newErrors)
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    if (value.trim()) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };


  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const updatedFormData = {
      rh_approval: "approved",
      rh_log: formData,
    };
    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/updatelogsapiview/${i.id}/`,
          updatedFormData
        );

        if (res.status === 200) {
          await getAdvanceData();
          handleClose()
        } else {
          alert(res);
        }
      } catch (err) {
        handleAllError(err)
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  return (
    <>
      <button title="RH Advance Approval" className="model-edit-button" onClick={handleShow}>
        <CheckGreen />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">

        <Modal.Header closeButton>
          <Modal.Title>RH Advance Approval</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Request Date :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.date_of_request ? formattedDateLong(i.date_of_request) : '-'}
                </div>
              </div>
              <div className="flex-row">
                <label
                  htmlFor="project_name"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Advance Amount :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.advance_amount ? formatCurrencyIndian(i.advance_amount) : '-'}
                </div>
              </div>
              <div className="flex-column">
                <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Reason for Advance :
                </label>
                {i.reason_for_taking_advance}
                <div className="form-labels announce-date font-size-heading">
                </div>
              </div>
              {/* <div className="flex-column">
                <label htmlFor="action_description" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Your Remarks:<span className="required">*</span>
                </label>
                <textarea
                  id="action_description"
                  type="text"
                  name="action_description"
                  placeholder="Your Remarks"
                  onChange={handleInputChange}
                  // maxLength={200}
                  value={formData.action_description}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.action_description
                    ? "error"
                    : inputState.action_description
                      ? "success"
                      : ""
                    }`}
                />
                {errors.action_description && (
                  <span className="error-message font-size-text ">{errors.action_description}</span>
                )}
              </div> */}

              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Approve
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};

const AdvanceFormRHRejection = ({ i, getAdvanceData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
    })
    setErrors({})
    setShow(false)
  };
  const handleShow = () => {
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
    })
    setShow(true)
  };

  const [formData, setFormData] = useState({
    emp_code: sessionStorage.getItem("emp_code"),
    action_description: "",
    action_datetime: new Date().toISOString(),
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
      "action_description",
      "action_datetime",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    // console.log("newErrors")
    // console.log(newErrors)
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    if (value.trim()) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };


  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const updatedFormData = {
      rh_approval: "rejected",
      rh_log: formData,
    };
    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/updatelogsapiview/${i.id}/`,
          updatedFormData
        );

        if (res.status === 200) {
          await getAdvanceData();
          handleClose()
        } else {
          alert(res);
        }
      } catch (err) {
        handleAllError(err)
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  return (
    <>
      <button title="RH Advance Rejection" className="model-delete-button" onClick={handleShow}>
        <CrossRed />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">

        <Modal.Header closeButton>
          <Modal.Title>RH Advance Rejection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Request Date :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.date_of_request ? formattedDateLong(i.date_of_request) : '-'}
                </div>
              </div>
              <div className="flex-row">
                <label
                  htmlFor="project_name"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Advance Amount :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.advance_amount ? formatCurrencyIndian(i.advance_amount) : '-'}
                </div>
              </div>
              <div className="flex-column">
                <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Reason for Advance :
                </label>
                {i.reason_for_taking_advance}
                <div className="form-labels announce-date font-size-heading">
                </div>
              </div>
              <div className="flex-column">
                <label htmlFor="action_description" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Reason for Rejection:<span className="required">*</span>
                </label>
                <textarea
                  id="action_description"
                  type="text"
                  name="action_description"
                  placeholder="Your Reason"
                  onChange={handleInputChange}
                  // maxLength={200}
                  value={formData.action_description}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.action_description
                    ? "error"
                    : inputState.action_description
                      ? "success"
                      : ""
                    }`}
                />
                {errors.action_description && (
                  <span className="error-message font-size-text ">{errors.action_description}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-delete  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};

const AdvanceFormHODApproval = ({ i, getAdvanceData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
    })
    setErrors({})
    setShow(false)
  };
  const handleShow = () => {
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "HOD approved the advance form.",
      action_datetime: new Date().toISOString(),
    })
    setShow(true)
  };

  const [formData, setFormData] = useState({
    emp_code: sessionStorage.getItem("emp_code"),
    action_description: "HOD approved the advance form.",
    action_datetime: new Date().toISOString(),
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
      "action_description",
      "action_datetime",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    // console.log("newErrors")
    // console.log(newErrors)
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    if (value.trim()) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };


  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const updatedFormData = {
      hod_approval: "approved",
      hod_log: formData,
    };
    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/updatelogsapiview/${i.id}/`,
          updatedFormData
        );

        if (res.status === 200) {
          await getAdvanceData();
          handleClose()
        } else {
          alert(res);
        }
      } catch (err) {
        handleAllError(err)
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  return (
    <>
      <button title="HOD Advance Approval" className="model-edit-button" onClick={handleShow}>
        <CheckGreen />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">

        <Modal.Header closeButton>
          <Modal.Title>HOD Advance Approval</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Request Date :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.date_of_request ? formattedDateLong(i.date_of_request) : '-'}
                </div>
              </div>
              <div className="flex-row">
                <label
                  htmlFor="project_name"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Advance Amount :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.advance_amount ? formatCurrencyIndian(i.advance_amount) : '-'}
                </div>
              </div>
              <div className="flex-column">
                <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Reason for Advance :
                </label>
                {i.reason_for_taking_advance}
                <div className="form-labels announce-date font-size-heading">
                </div>
              </div>
              {/* <div className="flex-column">
                <label htmlFor="action_description" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Your Remarks:<span className="required">*</span>
                </label>
                <textarea
                  id="action_description"
                  type="text"
                  name="action_description"
                  placeholder="Your Remarks"
                  onChange={handleInputChange}
                  // maxLength={200}
                  value={formData.action_description}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.action_description
                    ? "error"
                    : inputState.action_description
                      ? "success"
                      : ""
                    }`}
                />
                {errors.action_description && (
                  <span className="error-message font-size-text ">{errors.action_description}</span>
                )}
              </div> */}

              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Approve
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};

const AdvanceFormHODRejection = ({ i, getAdvanceData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
    })
    setErrors({})
    setShow(false)
  };
  const handleShow = () => {
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
    })
    setShow(true)
  };

  const [formData, setFormData] = useState({
    emp_code: sessionStorage.getItem("emp_code"),
    action_description: "",
    action_datetime: new Date().toISOString(),
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
      "action_description",
      "action_datetime",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    // console.log("newErrors")
    // console.log(newErrors)
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    if (value.trim()) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };


  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const updatedFormData = {
      hod_approval: "rejected",
      hod_log: formData,
    };
    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/updatelogsapiview/${i.id}/`,
          updatedFormData
        );

        if (res.status === 200) {
          await getAdvanceData();
          handleClose()
        } else {
          alert(res);
        }
      } catch (err) {
        handleAllError(err)
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  return (
    <>
      <button title="HOD Advance Rejection" className="model-delete-button" onClick={handleShow}>
        <CrossRed />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">

        <Modal.Header closeButton>
          <Modal.Title>HOD Advance Rejection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Request Date :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.date_of_request ? formattedDateLong(i.date_of_request) : '-'}
                </div>
              </div>
              <div className="flex-row">
                <label
                  htmlFor="project_name"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Advance Amount :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.advance_amount ? formatCurrencyIndian(i.advance_amount) : '-'}
                </div>
              </div>
              <div className="flex-column">
                <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Reason for Advance :
                </label>
                {i.reason_for_taking_advance}
                <div className="form-labels announce-date font-size-heading">
                </div>
              </div>
              <div className="flex-column">
                <label htmlFor="action_description" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Reason for Rejection:<span className="required">*</span>
                </label>
                <textarea
                  id="action_description"
                  type="text"
                  name="action_description"
                  placeholder="Your Reason"
                  onChange={handleInputChange}
                  // maxLength={200}
                  value={formData.action_description}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.action_description
                    ? "error"
                    : inputState.action_description
                      ? "success"
                      : ""
                    }`}
                />
                {errors.action_description && (
                  <span className="error-message font-size-text ">{errors.action_description}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-delete  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};

const AdvanceFormAdminApproval = ({ i, getAdvanceData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
    })
    setErrors({})
    setShow(false)
  };
  const handleShow = () => {
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "Admin finalized the advance form.",
      action_datetime: new Date().toISOString(),
    })
    setShow(true)
  };

  const [formData, setFormData] = useState({
    emp_code: sessionStorage.getItem("emp_code"),
    action_description: "Admin finalized the advance form.",
    action_datetime: new Date().toISOString(),
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
      "action_description",
      "action_datetime",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    // console.log("newErrors")
    // console.log(newErrors)
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    if (value.trim()) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };


  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const updatedFormData = {
      admin_approval: "approved",
      admin_log: formData,
    };
    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/updatelogsapiview/${i.id}/`,
          updatedFormData
        );

        if (res.status === 200) {
          await getAdvanceData();
          handleClose()
        } else {
          alert(res);
        }
      } catch (err) {
        handleAllError(err)
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  return (
    <>
      <button title="Admin Advance Approval" className="model-edit-button" onClick={handleShow}>
        <CheckGreen />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">

        <Modal.Header closeButton>
          <Modal.Title>Admin Advance Approval</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Request Date :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.date_of_request ? formattedDateLong(i.date_of_request) : '-'}
                </div>
              </div>
              <div className="flex-row">
                <label
                  htmlFor="project_name"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Advance Amount :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.advance_amount ? formatCurrencyIndian(i.advance_amount) : '-'}
                </div>
              </div>
              <div className="flex-column">
                <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Reason for Advance :
                </label>
                {i.reason_for_taking_advance}
                <div className="form-labels announce-date font-size-heading">
                </div>
              </div>
              {/* <div className="flex-column">
                <label htmlFor="action_description" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Your Remarks:<span className="required">*</span>
                </label>
                <textarea
                  id="action_description"
                  type="text"
                  name="action_description"
                  placeholder="Your Remarks"
                  onChange={handleInputChange}
                  // maxLength={200}
                  value={formData.action_description}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.action_description
                    ? "error"
                    : inputState.action_description
                      ? "success"
                      : ""
                    }`}
                />
                {errors.action_description && (
                  <span className="error-message font-size-text ">{errors.action_description}</span>
                )}
              </div> */}

              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Approve
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};

const AdvanceFormAdminRejection = ({ i, getAdvanceData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
    })
    setErrors({})
    setShow(false)
  };
  const handleShow = () => {
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
    })
    setShow(true)
  };

  const [formData, setFormData] = useState({
    emp_code: sessionStorage.getItem("emp_code"),
    action_description: "",
    action_datetime: new Date().toISOString(),
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
      "action_description",
      "action_datetime",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    // console.log("newErrors")
    // console.log(newErrors)
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    if (value.trim()) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };


  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const updatedFormData = {
      admin_approval: "rejected",
      admin_log: formData,
    };
    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/updatelogsapiview/${i.id}/`,
          updatedFormData
        );

        if (res.status === 200) {
          await getAdvanceData();
          handleClose()
        } else {
          alert(res);
        }
      } catch (err) {
        handleAllError(err)
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  return (
    <>
      <button title="Admin Advance Rejection" className="model-delete-button" onClick={handleShow}>
        <CrossRed />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">

        <Modal.Header closeButton>
          <Modal.Title>Admin Advance Rejection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Request Date :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.date_of_request ? formattedDateLong(i.date_of_request) : '-'}
                </div>
              </div>
              <div className="flex-row">
                <label
                  htmlFor="project_name"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Advance Amount :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.advance_amount ? formatCurrencyIndian(i.advance_amount) : '-'}
                </div>
              </div>
              <div className="flex-column">
                <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Reason for Advance :
                </label>
                {i.reason_for_taking_advance}
                <div className="form-labels announce-date font-size-heading">
                </div>
              </div>
              <div className="flex-column">
                <label htmlFor="action_description" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Reason for Rejection:<span className="required">*</span>
                </label>
                <textarea
                  id="action_description"
                  type="text"
                  name="action_description"
                  placeholder="Your Reason"
                  onChange={handleInputChange}
                  // maxLength={200}
                  value={formData.action_description}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.action_description
                    ? "error"
                    : inputState.action_description
                      ? "success"
                      : ""
                    }`}
                />
                {errors.action_description && (
                  <span className="error-message font-size-text ">{errors.action_description}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-delete  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};

const AdvanceFormHRApproval = ({ i, getAdvanceData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setFormData({
      hr_approval: "",
      number_of_installments: "",
      installment_start_month: "",
      installment_end_month: "",
      total_amount_claimed: "",
      approval_date: "",
      action_description: "",
    })
    setErrors({})
    setShow(false)
  };
  const handleShow = () => {
    setFormData({
      hr_approval: "approved",
      number_of_installments: "",
      installment_start_month: "",
      installment_end_month: "",
      total_amount_claimed: i.advance_amount || "",
      approval_date: formattedDate,
      action_description: "",
    })
    setShow(true)
  };

  const [formData, setFormData] = useState({
    hr_approval: "approved",
    number_of_installments: "",
    installment_start_month: "",
    installment_end_month: "",
    total_amount_claimed: "",
    approval_date: formattedDate,
    action_description: "",
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "hr_approval",
      "number_of_installments",
      "installment_start_month",
      "installment_end_month",
      "total_amount_claimed",
      Number(formData.total_amount_claimed) < Number(i.advance_amount) ? "action_description" : null,
    ].filter(Boolean);

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    // console.log("newErrors")
    // console.log(newErrors)
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "number_of_installments") {
      setFormData({
        ...formData,
        [name]: value,
        installment_start_month: "",
        installment_end_month: "",
      });
    }
    else if (name === "total_amount_claimed" && Number(value) >= Number(i.advance_amount)) {
      setFormData({
        ...formData,
        [name]: value,
        action_description: "",
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    if (value.trim()) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };

  // const getEndMonth = () => {
  //   const startMonth = formData.installment_start_month;
  //   const startDate = new Date(startMonth);
  //   const numberOfInstallments = Number(formData.number_of_installments);
  //   startDate.setMonth(startDate.getMonth() + numberOfInstallments);

  //   const endYear = startDate.getFullYear();
  //   const endMonth = String(startDate.getMonth() + 1).padStart(2, '0');

  //   setFormData({
  //     ...formData,
  //     installment_end_month: `${endYear}-${endMonth}`,
  //   });
  // };

  useEffect(() => {
    console.log("Updated formData:", formData);
  }, [formData]);

  const getEndMonth = ({ installments, startMonth }) => {
    const numberOfInstallments = Number(installments);

    const startDate = new Date(startMonth);
    startDate.setMonth(startDate.getMonth() + numberOfInstallments); // Add the number of installments to the start month

    // Check if the date is valid
    if (isNaN(startDate)) {
      console.log("Invalid start date:", startMonth);
      setFormData((prevFormData) => ({
        ...prevFormData,
        number_of_installments: numberOfInstallments,
        installment_start_month: startMonth,
        installment_end_month: null,
      }));
      return;
    }

    const endYear = startDate.getFullYear();
    const endMonth = String(startDate.getMonth() + 1).padStart(2, '0'); // Convert month back to 1-indexed

    setFormData((prevFormData) => ({
      ...prevFormData,
      number_of_installments: numberOfInstallments,
      installment_start_month: startMonth,
      installment_end_month: `${endYear}-${endMonth}`,
    }));
  };

  // useEffect to call getEndMonth only when installment_start_month is set
  useEffect(() => {
    if (formData.installment_start_month) {
      console.log("useeffect - getEndMonth")
      getEndMonth({
        installments: formData.number_of_installments,
        startMonth: formData.installment_start_month,
      });
    }
  }, [formData.installment_start_month]); // Run when installment_start_month changes



  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const updatedFormData = {
      ...formData,
      hr_log: {
        emp_code: sessionStorage.getItem("emp_code"),
        action_description: formData.action_description !== "" ? formData.action_description : "HR processed the advance form.",
        action_datetime: new Date().toISOString(),
      }
    };
    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/updatelogsapiview/${i.id}/`,
          updatedFormData
        );

        if (res.status === 200) {
          await getAdvanceData();
          handleClose()
        } else {
          alert(res);
        }
      } catch (err) {
        handleAllError(err)
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button title="HR Advance Approval" className="model-edit-button" onClick={handleShow}>
        <CheckGreen />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>HR Advance Approval</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Request Date :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.date_of_request ? formattedDateLong(i.date_of_request) : '-'}
                </div>
              </div>
              <div className="flex-row">
                <label
                  htmlFor="project_name"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Advance Amount :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.advance_amount ? formatCurrencyIndian(i.advance_amount) : '-'}
                </div>
              </div>
              <div className="flex-column">
                <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Reason for Advance :
                </label>
                {i.reason_for_taking_advance}
                <div className="form-labels announce-date font-size-heading">
                </div>
              </div>

              <div className="flex-column">
                <label
                  htmlFor="number_of_installments"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Number of Installments<span className="required">*</span>
                </label>
                <input
                  id="number_of_installments"
                  type="number"
                  name="number_of_installments"
                  placeholder="0"
                  min={0}
                  onWheel={(e) => e.target.blur()}
                  onChange={handleInputChange}
                  value={formData.number_of_installments}
                  className={`form-input ${errors.number_of_installments
                    ? "error"
                    : inputState.number_of_installments
                      ? "success"
                      : ""
                    }`}
                />
                {errors.number_of_installments && (
                  <span className="error-message font-size-text ">{errors.number_of_installments}</span>
                )}
              </div>

              <div className="flex-row multi-day-input">
                <div className="flex-column multi-day">
                  <label className="form-labels font-weight500 font-size-subheading">Start Month<span className="required">*</span></label>
                  <input
                    id="installment_start_month"
                    name="installment_start_month"
                    type="month"
                    // min={getCurrentMonth()}
                    max={formData.installment_end_month}
                    onChange={handleInputChange}
                    value={formData.installment_start_month}
                    className={`multi-day-input1 form-input-background ${errors.installment_start_month
                      ? "error"
                      : inputState.installment_start_month
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors.installment_start_month && (
                    <span className="error-message font-size-text ">{errors.installment_start_month}</span>
                  )}
                </div>

                <div className="flex-column multi-day">
                  <label className="form-labels font-weight500 font-size-subheading">End Month<span className="required"> (Auto)</span></label>
                  <input
                    id="installment_end_month"
                    name="installment_end_month"
                    type="month"
                    min={formData.installment_start_month}
                    onWheel={(e) => e.target.blur()}
                    value={formData.installment_end_month}
                    style={{ cursor: 'not-allowed' }}
                    // onChange={handleInputChange}
                    className={`multi-day-input1 form-input-background ${errors.installment_end_month
                      ? "error"
                      : inputState.installment_end_month
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors.installment_end_month && (
                    <span className="error-message font-size-text ">{errors.installment_end_month}</span>
                  )}
                </div>
              </div>

              <div className="flex-column">
                <label
                  htmlFor="total_amount_claimed"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Total Amount to Claim<span className="required">*</span>
                </label>
                <input
                  id="total_amount_claimed"
                  type="number"
                  name="total_amount_claimed"
                  placeholder="0"
                  min={0}
                  max={i.advance_amount}
                  onWheel={(e) => e.target.blur()}
                  onChange={handleInputChange}
                  value={formData.total_amount_claimed}
                  className={`form-input ${errors.total_amount_claimed
                    ? "error"
                    : inputState.total_amount_claimed
                      ? "success"
                      : ""
                    }`}
                />
                {errors.total_amount_claimed && (
                  <span className="error-message font-size-text ">{errors.total_amount_claimed}</span>
                )}
              </div>

              {Number(formData.total_amount_claimed) < Number(i.advance_amount) ?
                <div className="flex-column">
                  <label htmlFor="action_description" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                    Reason for Changed Amount:<span className="required">*</span>
                  </label>
                  <textarea
                    id="action_description"
                    type="text"
                    name="action_description"
                    placeholder="Your Reason"
                    onChange={handleInputChange}
                    // maxLength={200}
                    value={formData.action_description}
                    className={`form-input-textarea   font-weight400  font-size-subheading ${errors.action_description
                      ? "error"
                      : inputState.action_description
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors.action_description && (
                    <span className="error-message font-size-text ">{errors.action_description}</span>
                  )}
                </div>
                : null
              }

              {(() => {
                const { success, error } = inputMaxLimit({
                  name: "total_amount_claimed",
                  value: formData.total_amount_claimed,
                  maxValue: i.advance_amount,
                });
                return !success && (
                  <span className="error-message font-size-text">{error}</span>
                );
              })()}

              <div className="button-models">
                <button className="model-button model-button-cancel font-weight500" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500 model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  // disabled={loading}
                  disabled={
                    !inputMaxLimit({
                      name: "total_amount_claimed",
                      value: formData.total_amount_claimed,
                      maxValue: i.advance_amount,
                    }).success || loading
                  }
                >
                  Approve
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};

const AdvanceFormHRRejection = ({ i, getAdvanceData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
    })
    setErrors({})
    setShow(false)
  };
  const handleShow = () => {
    setFormData({
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "",
      action_datetime: new Date().toISOString(),
    })
    setShow(true)
  };

  const [formData, setFormData] = useState({
    emp_code: sessionStorage.getItem("emp_code"),
    action_description: "",
    action_datetime: new Date().toISOString(),
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
      "action_description",
      "action_datetime",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    // console.log("newErrors")
    // console.log(newErrors)
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    if (value.trim()) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };


  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const updatedFormData = {
      hr_approval: "rejected",
      hr_log: formData,
    };
    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/updatelogsapiview/${i.id}/`,
          updatedFormData
        );

        if (res.status === 200) {
          await getAdvanceData();
          handleClose()
        } else {
          alert(res);
        }
      } catch (err) {
        handleAllError(err)
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  return (
    <>
      <button title="HR Advance Rejection" className="model-delete-button" onClick={handleShow}>
        <CrossRed />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">

        <Modal.Header closeButton>
          <Modal.Title>HR Advance Rejection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Request Date :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.date_of_request ? formattedDateLong(i.date_of_request) : '-'}
                </div>
              </div>
              <div className="flex-row">
                <label
                  htmlFor="project_name"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Advance Amount :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.advance_amount ? formatCurrencyIndian(i.advance_amount) : '-'}
                </div>
              </div>
              <div className="flex-column">
                <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Reason for Advance :
                </label>
                {i.reason_for_taking_advance}
                <div className="form-labels announce-date font-size-heading">
                </div>
              </div>
              <div className="flex-column">
                <label htmlFor="action_description" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Reason for Rejection:<span className="required">*</span>
                </label>
                <textarea
                  id="action_description"
                  type="text"
                  name="action_description"
                  placeholder="Your Reason"
                  onChange={handleInputChange}
                  // maxLength={200}
                  value={formData.action_description}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.action_description
                    ? "error"
                    : inputState.action_description
                      ? "success"
                      : ""
                    }`}
                />
                {errors.action_description && (
                  <span className="error-message font-size-text ">{errors.action_description}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-delete  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};

const AdvanceFormAccFinalize = ({ i, getAdvanceData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setFormData({
      account_approval: "",
      transaction_id: "",
      file_upload: "",
    })
    setErrors({})
    setFileName("");
    setShow(false)
  };
  const handleShow = () => {
    setFormData({
      account_approval: "approved",
      transaction_id: "",
      file_upload: "",
    })
    setShow(true)
  };

  const [formData, setFormData] = useState({
    account_approval: "approved",
    transaction_id: "",
    file_upload: "",
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const [fileName, setFileName] = useState("");

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "account_approval",
      "transaction_id",
      // "file_upload",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    // console.log("newErrors")
    // console.log(newErrors)
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    if (value.trim()) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }

  };

  const handleFileChange = (e) => {
    const { name, value } = e.target;
    const file = e.target.files[0];

    if (file) {
      setFormData({ ...formData, [name]: file, });
      setFileName(file.name);
    } else {
      setFileName("");
    }

    if (value.trim()) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }

  };

  const handleClear = () => {
    setFormData(prevState => ({
      ...prevState,
      // transaction_id: "",
      file_upload: "",
    }));
    setFileName("");
  }

  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formDataLog = {
      emp_code: sessionStorage.getItem("emp_code"),
      action_description: "Check Attachment",
      action_datetime: new Date().toISOString(),
    };

    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      const updatedData = {
        ...formData,
        acc_log: formDataLog,
      };

      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/updatelogsapiview/${i.id}/`,
          updatedData, {
          headers: {
            // "Content-Type": "multipart/form-data",
            "Content-Type": "application/json", // Ensure JSON format
          },
        }
        );

        if (res.status === 200) {
          await getAdvanceData();
          handleClose()
        } else {
          alert(res);
        }
      } catch (err) {
        handleAllError(err)
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  return (
    <>
      <button title="Accounts Advance Finalize" className="model-edit-button" onClick={handleShow}>
        <CheckGreen />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">

        <Modal.Header closeButton>
          <Modal.Title>Finalize Advance for "{i.emp_name}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Request Date :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.date_of_request ? formattedDateLong(i.date_of_request) : '-'}
                </div>
              </div>
              <div className="flex-row">
                <label
                  htmlFor="project_name"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Asked Amount :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.advance_amount ? formatCurrencyIndian(i.advance_amount) : '-'}
                </div>
              </div>
              <div className="flex-column">
                <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Reason for Advance :
                </label>
                {i.reason_for_taking_advance}
                <div className="form-labels announce-date font-size-heading">
                </div>
              </div>

              <hr className="line" />
              <h5
                htmlFor="project_name"
                className="announce-date font-weight500"
              >
                Approved Details By HR-
              </h5>
              <div className="flex-row">
                <label
                  htmlFor="project_name"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Number of Installments :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.number_of_installments ? i.number_of_installments : '-'}
                </div>
              </div>
              <div className="flex-row">
                <label
                  htmlFor="project_name"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Installment Timeline :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.installment_start_month && i.installment_end_month ? `From "${formatMonthYear(i.installment_start_month)}" To "${formatMonthYear(i.installment_end_month)}"` : "-"}
                </div>
              </div>
              <div className="flex-row">
                <label
                  htmlFor="project_name"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Approved Amount to be Claimed :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.total_amount_claimed ? formatCurrencyIndian(i.total_amount_claimed) : '-'}
                </div>
              </div>

              <hr className="line" />

              <div className="flex-column">
                <label
                  htmlFor="transaction_id"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Transaction ID / Cheque no.
                </label>
                <input
                  id="transaction_id"
                  name="transaction_id"
                  placeholder="Final Approval Details (Transaction / Cheque no.)"
                  onChange={handleInputChange}
                  value={formData.transaction_id}
                  className={`form-input ${errors.transaction_id
                    ? "error"
                    : inputState.transaction_id
                      ? "success"
                      : ""
                    }`}
                />
                {errors.transaction_id && (
                  <span className="error-message font-size-text ">{errors.transaction_id}</span>
                )}
              </div>

              {/* <div className="flex-row">
                <label htmlFor="decision" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  File Upload :
                </label>
                <div className="flex-column" >
                  <label
                    htmlFor="file_upload"
                    className={`align-center  form-inputss custom-file-upload 
                    ${errors.file_upload
                        ? "error"
                        : inputState.file_upload
                          ? "success"
                          : ""
                      }
                       `}
                  >
                    <div className={`align-center pdf-input  ${errors.file_upload ? 'error' : ''}`}>
                      <Attachment />
                    </div>
                  </label>
                  <input
                    id="file_upload"
                    name="file_upload"
                    type="file"
                    onChange={handleFileChange}
                    onFocus={handleClear}
                    className="file-inputs align-center"
                    style={{ display: "none", position: "relative" }}
                  />
                  <br />
                  <div className="flex-row justify-evenly">
                    <div>
                      {fileName && <p style={{ margin: "0px" }}>{fileName}</p>}
                    </div>
                  </div>
                </div>
                {formData.file_upload ?
                  <div>
                    <button className="model-button model-button-black" onClick={handleClear}>Clear</button>
                  </div>
                  : null
                }
                {errors.file_upload && (
                  <span className="error-message font-size-text ">{errors.file_upload}</span>
                )}
              </div> */}



              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};

export {
  ApplyAdvanceForm,

  AdvanceFormRHApproval,
  AdvanceFormRHRejection,

  AdvanceFormHODApproval,
  AdvanceFormHODRejection,

  AdvanceFormAdminApproval,
  AdvanceFormAdminRejection,

  AdvanceFormHRApproval,
  AdvanceFormHRRejection,

  AdvanceFormAccFinalize,
};
