
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../config/axios';
import { handleErrorToast } from '../CustomFunctions';
// import Baseurl from './Baseurl';
import { Modal } from 'react-bootstrap';
import { ToastContainer, toast } from "react-toastify";
import { AddwithWhiteCircle, Eye, View } from "../AllSvg";


const UpdateRoomModal = ({ i, getRoomdata, onClose }) => {
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    capacity: '',
  })

  const handleShow = () => {
    setShow(true)
    setFormData(i)

  };
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const [loading, setLoading] = useState(false);
  const handleInputChange = (e) => {
    const { name, value, type, checked, options, files } = e.target;

    let newValue;
    if (type === "select-multiple") {
      newValue = Array.from(options)
        .filter((option) => option.selected)
        .map((option) => option.value);
    } else if (type === "checkbox") {
      newValue = checked;
    } else if (type === "file") {
      newValue = files.length > 0 ? files[0] : null;
    } else {
      newValue = value;
    }

    setInputState((prevState) => ({
      ...prevState,
      [name]:
        type === "checkbox"
          ? checked
            ? "green"
            : ""
          : newValue
            ? "green"
            : "",
    }));


    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        [name]: newValue,
      };

      return updatedFormData;
    });
    if (value.trim()) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };
  const validateform = () => {
    const newErrors = {};
    const requiredFields = [
      "name",
      "capacity",
    ];

    requiredFields.forEach((field) => {
      console.log(formData[field])
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });

    setErrors(newErrors);
    // setIsSubmitted(true);
    return Object.keys(newErrors).length === 0;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (validateform()) {
      try {

        let data = {
          name: formData.name,
          capacity: formData.capacity
        };
        let res = await axios.put(
          `${BASE_URL}/meetingscheduler/meetingroomupdate/${i.id}/`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (res.status === 201 || res.status === 200) {
          await getRoomdata()
          handleClose()
        } else {

        }


      } catch (error) {
        console.error('Error creating room', error);
      } finally {
        setLoading(false);
      }
    }
  };


  const handleClose = () => {

    setErrors({});
    setInputState({});
    setShow(false)
  };
  return (
    <>

      <button class="model-edit-button" onClick={handleShow}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"><path d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z" fill="#145650"></path></svg></button>



      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="room-view-modal"
      >
        <Modal.Header
          closeButton
          className="justify-center task-Tab-heading font-weight500 font-size-heading"
        >
          <Modal.Title>
            Update Room
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="register-form" style={{ height: "70vh" }}>
            <div className="form-flex-wrap">
              <div title="name" className="flex-column">
                <label htmlFor="name" className="form-labels font-weight500 font-size-subheading">
                  Name <span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter Room Name"
                  onChange={handleInputChange}
                  value={formData.name}

                  className={`form-input ${errors.name
                    ? "error"
                    : inputState.name
                      ? "success"
                      : ""
                    }`}
                />
                {errors.name && (
                  <span className="error-message font-size-text">{errors.name}</span>
                )}
              </div>
              <div title="capacity" className="flex-column">
                <label htmlFor="capacity" className="form-labels font-weight500 font-size-subheading">
                  Capacity <span className="required">*</span>
                </label>
                <input
                  type="number"
                  id="capacity"
                  name="capacity"
                  placeholder="Capacity"
                  onChange={handleInputChange}
                  value={formData.capacity}
                  onWheel={(e) => e.target.blur()}
                  className={`form-input ${errors.capacity
                    ? "error"
                    : inputState.capacity
                      ? "success"
                      : ""
                    }`}
                />
                {errors.capacity && (
                  <span className="error-message font-size-text">{errors.capacity}</span>
                )}
              </div>
              <div className="button-models" style={{ width: '100%' }}>
                <button
                  onClick={handleSubmit}
                  className="model-button   font-weight500    model-button-submit"
                //   disabled={loading}
                >

                  Update
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

const DeleteRoomModal = ({ i, getRoomdata }) => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  // delete crud 
  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait...");

    try {
      // keep base url same just change api endpoint and pass its id also
      let res = await axios.delete(
        `${BASE_URL}/detail-design/example/${i.id}/`
      );

      if (res.status === 200) {
        // close modal if res.status ===200 means there is no error and data gets delete
        getRoomdata();
        setShow(false);

        //  call back function to get new data again 
        // await get();

      } else {

      }
    } catch (err) {
      handleErrorToast(err, loadingToastId)
    } finally {
      toast.dismiss(loadingToastId)
    }
  };



  return (
    <>
      {/* <button
        title="Add Detail Design"
        className="upload-svg"
        onClick={handleShow}
      >
       
        Create Room
      </button> */}

      <button
        title="Delete Room"
        className="model-delete-button"
        onClick={handleShow}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="room-view-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Room {i.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div" style={{ height: "70vh" }}>
            Are You Sure You Want to delete Room {i.name}
            <div className="button-models">
              <button
                className="model-button   font-weight500  model-button-cancel  "
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>

        </Modal.Body>
      </Modal>
    </>
  );
};

const CreateRoomModal = ({ isOpen, onClose, onRoomCreated, handleShow, getRoomdata }) => {


  const [formData, setFormData] = useState({
    name: "",
    capacity: '',
  })
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const [loading, setLoading] = useState(false);
  const handleInputChange = (e) => {
    const { name, value, type, checked, options, files } = e.target;

    let newValue;
    if (type === "select-multiple") {
      newValue = Array.from(options)
        .filter((option) => option.selected)
        .map((option) => option.value);
    } else if (type === "checkbox") {
      newValue = checked;
    } else if (type === "file") {
      newValue = files.length > 0 ? files[0] : null;
    } else {
      newValue = value;
    }

    setInputState((prevState) => ({
      ...prevState,
      [name]:
        type === "checkbox"
          ? checked
            ? "green"
            : ""
          : newValue
            ? "green"
            : "",
    }));


    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        [name]: newValue,
      };

      return updatedFormData;
    });
    if (value.trim()) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };
  const validateform = () => {
    const newErrors = {};
    const requiredFields = [
      "name",
      "capacity",
    ];

    requiredFields.forEach((field) => {
      console.log(formData[field])
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });

    setErrors(newErrors);
    // setIsSubmitted(true);
    return Object.keys(newErrors).length === 0;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (validateform()) {
      try {
        let data = {
          name: formData.name,
          capacity: formData.capacity
        };

        let res = await axios.post(
          `${BASE_URL}/meetingscheduler/meetingroom/`,
          data,

          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (res.status === 201 || res.status === 200) {
          await getRoomdata();
          onClose();
        } else {

        }


      } catch (error) {
        console.error('Error creating room', error);
      } finally {
        setLoading(false);
      }
    }
  };



  return (
    <>
      <button
        title="Create Room"
        className="upload-svg"
        onClick={handleShow}
      >
        <AddwithWhiteCircle />
        Create Room
      </button>


      <Modal
        show={isOpen}
        onHide={onClose}
        dialogClassName="ourcompany-model"
      >
        <Modal.Header
          closeButton
          className="justify-center task-Tab-heading font-weight500 font-size-heading"
        >
          <Modal.Title>
            Create Room
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="register-form">
            <div className="form-flex-wrap">
              <div title="name" className="flex-column">
                <label htmlFor="name" className="form-labels font-weight500 font-size-subheading">
                  Name <span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter Room Name"
                  onChange={handleInputChange}
                  value={formData.name}

                  className={`form-input ${errors.name
                    ? "error"
                    : inputState.name
                      ? "success"
                      : ""
                    }`}
                />
                {errors.name && (
                  <span className="error-message font-size-text">{errors.name}</span>
                )}
              </div>
              <div title="capacity" className="flex-column">
                <label htmlFor="capacity" className="form-labels font-weight500 font-size-subheading">
                  Capacity <span className="required">*</span>
                </label>
                <input
                  type="number"
                  id="capacity"
                  name="capacity"
                  placeholder="Capacity"
                  onChange={handleInputChange}
                  value={formData.capacity}
                  onWheel={(e) => e.target.blur()}
                  className={`form-input ${errors.capacity
                    ? "error"
                    : inputState.capacity
                      ? "success"
                      : ""
                    }`}
                />
                {errors.capacity && (
                  <span className="error-message font-size-text">{errors.capacity}</span>
                )}
              </div>
              <div className="button-models" style={{ width: '100%' }}>
                <button
                  onClick={handleSubmit}
                  className="model-button   font-weight500    model-button-submit"
                //   disabled={loading}
                >
                  {/* //loading logic */}
                  Add
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

const ViewRoomModal = ({ isOpen,
  onClose,
  handleShow,
  getRoomdata, roomData }) => {

  useEffect(() => {
    if (isOpen) {
      getRoomdata()
    }

  }, [isOpen])




  return (
    <>
      <button
        title="View Room Details"
        className="upload-svg"
        onClick={handleShow}
      >
        View
        Room
      </button>


      <Modal
        show={isOpen}
        onHide={onClose}
        dialogClassName="room-view-modal"
      >
        <Modal.Header
          closeButton
          className="justify-center task-Tab-heading font-weight500 font-size-heading"
        >
          <Modal.Title>
            Room details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="custom-table">
            <thead className="table-heading">
              <tr className="custom-table-head-tr font-weight400 font-size-subheading">
                <th className="table-heading-text">
                  Sr no.
                </th>


                <th className="text-center table-heading-text">
                  Room Name
                </th>

                <th className="text-center table-heading-text">
                  Capacity
                </th>

                <th className="text-center table-heading-text">
                  Actions
                </th>



              </tr>
            </thead>
            <tbody>
              {roomData.length > 0 && roomData?.map((i, index) => (
                <React.Fragment key={index}>

                  <tr className="custom-table-head-td">

                    <td className="table-body">
                      {index + 1}
                    </td>

                    <td className="table-body text-center">
                      {i.name}
                    </td>

                    <td className="table-body text-center">
                      {i.capacity}
                    </td>



                    <td className="text-center table-body width-5vw">
                      <div className="flex-row">

                        <UpdateRoomModal
                          i={i}
                          getRoomdata={getRoomdata}

                        />


                        <DeleteRoomModal
                          i={i}
                          getRoomdata={getRoomdata}

                        />
                      </div>

                    </td>


                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </>
  );
};

export { CreateRoomModal, ViewRoomModal };
