// import React from 'react'
import React, { useEffect, useRef, useState } from "react";
import { BASE_URL, IMAGE_URL } from "../../config/axios";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import { customSortByKey, downloadAsExcel, formatCurrencyIndian, formatMonthYear, handleErrorToast } from "../CustomFunctions";
import { toast, ToastContainer } from "react-toastify";
import { Active, SearchSmall, CircularGrid, DownloadIcon, Employee, Eye, Filter, Office } from "../AllSvg";
import { formatDateTime, formattedDateLong } from "../Date";
import { handleAllError } from "../CustomFunctions";
import { SeprationFormAdminApproval, SeprationFormAdminRejection, SeprationFormHODApproval, SeprationFormHODRejection, SeprationFormHRApproval, SeprationFormHRRejection, SeprationFormRHApproval, SeprationFormRHRejection, AddSeprationFormat, DeleteSeprationFormat, ViewSeprationFormatFlow, ViewSeprationDetails, SeprationFormAccountRejection, SeprationFormAccountApproval, SeprationFormHeadingsApproval, SeprationFormHeadingsRejection, DeleteSettlementFormat, ViewSettlementFormatFlow, AddSettlementFormat, SeprationProcessStatusTD, ViewSeprationAndSettlementDetails } from "./SeprationComponents";
import usePermission from "../../config/permissions";
import { useParams } from "react-router-dom";

const SeprationFormatsTable = () => {
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
  const [toggleCompany, setToggleCompany] = useState(sessionStorage.getItem("company_id"));
  const [buffer, setBuffering] = useState(true); //buffering logic
  const [seprationFormats, setSeprationFormats] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const getSeprationFormats = async () => {
    setBuffering(true); //buffering logic // Start Buffering 
    try {
      // const res = await axios.get(`${BASE_URL}/wfm/salaryHeadingbysubcompany/${toggleCompany}/`);
      const res = await axios.get(`${BASE_URL}/wfm/seprationformatbysubcompany/${toggleCompany}/`);
      setSeprationFormats(res.data);
    } catch (err) {
      handleErrorToast(err)
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  useEffect(() => {
    getSeprationFormats();
  }, [toggleCompany]);

  return (
    <div className="table-css-white-background">
      <div className="table-heading-flex">
        <div className="repo-heading font-weight500    font-size-heading">Sepration Formats</div>
        <div className="field-cont">
          {PERMISSION_AUTHORITY ? <div className="table-searchh dropdown-container field-cont-div">
            <Office />
            <select onChange={(e) => setToggleCompany(e.target.value)} value={toggleCompany} className="dropdown font-size-subheading  ">
              <option value="null">Select SubCompany</option>
              {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};
            </select>
          </div> : null
          }
          <div className="table-searchh">
            <div>
              <SearchSmall />
              <input
                placeholder="Search"
                className="navbar-inputt font-weight400  font-size-text"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <AddSeprationFormat getSeprationFormats={getSeprationFormats} />
        </div>
      </div>
      <table className="table-css">
        <thead>
          <tr className="custom-table-head-tr">
            <th className="align-left">Sr. No.</th>
            <th className="align-center">Name</th>
            <th className="align-right">Company</th>
            <th className="align-right">View Flow</th>
            <th className="align-right width-10vw">Action</th>
          </tr>
        </thead>
        {buffer ? <div className="spinner"></div> : //buffering logic
          <tbody>
            {seprationFormats?.filter((e) =>
              e.name?.toLowerCase()?.includes(searchQuery.toLowerCase())
            )
              .sort((a, b) => a.sorting_var - b.sorting_var)
              .map((i, index) => (
                <React.Fragment key={index}>
                  <tr className="tr-border-bottom">
                    <td colSpan="6"></td>
                  </tr>
                  <tr className="custom-table-head-td">
                    <td className="align-left">{index + 1}</td>
                    <td className="align-center">{i.name}</td>
                    <td className="align-right">{i.sub_company_name}</td>
                    <td className="align-right width-5vw ">
                      <ViewSeprationFormatFlow
                        i={i}
                      />
                    </td>
                    <td className="align-right width-5vw ">
                      {/* <SalaryStructureHeadingUpdate
                        i={i}
                        seprationFormats={seprationFormats}
                        getSeprationFormats={getSeprationFormats}
                      /> */}
                      <DeleteSeprationFormat
                        i={i}
                        getSeprationFormats={getSeprationFormats}
                      />
                    </td>
                  </tr>
                </React.Fragment>
              ))}
          </tbody>
        }
      </table>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>
  );
};

const SettlementFormatsTable = () => {
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
  const [toggleCompany, setToggleCompany] = useState(sessionStorage.getItem("company_id"));
  const [buffer, setBuffering] = useState(true); //buffering logic
  const [settlementFormats, setSettlementFormats] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const getSettlementFormats = async () => {
    setBuffering(true); //buffering logic // Start Buffering 
    try {
      // const res = await axios.get(`${BASE_URL}/wfm/salaryHeadingbysubcompany/${toggleCompany}/`);
      const res = await axios.get(`${BASE_URL}/wfm/settlementformatbysubcompany/${toggleCompany}/`);
      setSettlementFormats(res.data);
    } catch (err) {
      handleErrorToast(err)
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  useEffect(() => {
    getSettlementFormats();
  }, [toggleCompany]);

  return (
    <div className="table-css-white-background">
      <div className="table-heading-flex">
        <div className="repo-heading font-weight500    font-size-heading">Settlement Formats</div>
        <div className="field-cont">
          {PERMISSION_AUTHORITY ? <div className="table-searchh dropdown-container field-cont-div">
            <Office />
            <select onChange={(e) => setToggleCompany(e.target.value)} value={toggleCompany} className="dropdown font-size-subheading  ">
              <option value="null">Select SubCompany</option>
              {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};
            </select>
          </div> : null
          }
          <div className="table-searchh">
            <div>
              <SearchSmall />
              <input
                placeholder="Search"
                className="navbar-inputt font-weight400  font-size-text"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <AddSettlementFormat getSettlementFormats={getSettlementFormats} />
        </div>
      </div>
      <table className="table-css">
        <thead>
          <tr className="custom-table-head-tr">
            <th className="align-left">Sr. No.</th>
            <th className="align-center">Name</th>
            <th className="align-right">Company</th>
            <th className="align-right">View Flow</th>
            <th className="align-right width-10vw">Action</th>
          </tr>
        </thead>
        {buffer ? <div className="spinner"></div> : //buffering logic
          <tbody>
            {settlementFormats?.filter((e) =>
              e.name?.toLowerCase()?.includes(searchQuery.toLowerCase())
            )
              .sort((a, b) => a.sorting_var - b.sorting_var)
              .map((i, index) => (
                <React.Fragment key={index}>
                  <tr className="tr-border-bottom">
                    <td colSpan="6"></td>
                  </tr>
                  <tr className="custom-table-head-td">
                    <td className="align-left">{index + 1}</td>
                    <td className="align-center">{i.name}</td>
                    <td className="align-right">{i.sub_company_name}</td>
                    <td className="align-right width-5vw ">
                      <ViewSettlementFormatFlow
                        i={i}
                      />
                    </td>
                    <td className="align-right width-5vw ">
                      {/* <SalaryStructureHeadingUpdate
                        i={i}
                        settlementFormats={settlementFormats}
                        getSettlementFormats={getSettlementFormats}
                      /> */}
                      <DeleteSettlementFormat
                        i={i}
                        getSettlementFormats={getSettlementFormats}
                      />
                    </td>
                  </tr>
                </React.Fragment>
              ))}
          </tbody>
        }
      </table>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>
  );
};

const SeprationFormsTableRH = () => {

  const [buffer, setBuffering] = useState(true); //buffering logic

  const [toggleStatus, setToggleStatus] = useState("pending");

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // *********** Employee Details Api Start ***********
  const [seprationFormList, setSeprationFormList] = useState([]);

  const getSeprationFormListRH = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/seprationfilterbyrh/${sessionStorage.getItem("emp_code")}/${toggleStatus}/`
        // `${BASE_URL}/wfm/seprationfilterbyrh/null/${toggleStatus}/`
      );
      const sortedData = customSortByKey(res.data, "emp_code");
      setSeprationFormList(sortedData);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  // *********** Employee Details Api End ***********

  useEffect(() => {
    getSeprationFormListRH();
  }, [toggleStatus]);

  return (
    <>
      <div className="attendance-history-cont">

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500    font-size-heading">Sepration Requests</div>
            <div className="btn-cont">
              <div className="field-cont">
                {<div className="table-searchh dropdown-container field-cont-div">
                  <Filter />
                  <select onChange={(e) => setToggleStatus(e.target.value)} value={toggleStatus} className="dropdown font-size-subheading  ">
                    <option value="null">All Reqests</option>
                    <option value="pending">Pending</option>
                    <option value="approved">Approved</option>
                    <option value="rejected">Rejected</option>
                  </select>
                </div>}
                <div className="table-searchh">
                  <div>
                    <SearchSmall />
                  </div>
                  <div>
                    <input
                      placeholder="Search Code/Name"
                      className="dropdown font-size-subheading   navbar-inputt"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <table className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="table-heading">Sr no.</th>
                <th className="table-heading text-center">Employee</th>
                <th className="table-heading">Request Date</th>
                <th className="table-heading text-center">Sepration Process</th>
                <th className="table-heading text-center width-5vw">Status</th>
                <th className="table-heading text-center">Actions</th>
              </tr>
            </thead>
            {buffer ? (
              <div className="spinner"></div>
            ) : (
              <tbody>
                {seprationFormList
                  ?.filter(
                    (e) =>
                      e.emp_name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                      e.emp_code
                        .toString().toLowerCase()
                        .includes(searchQuery.toLowerCase())
                  )
                  .sort((a, b) => new Date(a.application_date) - new Date(b.application_date))
                  .map((i, index) => (
                    <React.Fragment key={index}>
                      <tr className="custom-table-head-td">
                        <td className="table-body">{index + 1}</td>
                        <td className="table-body" style={{ textAlign: "center" }}>
                          <img
                            className="profile-img"
                            src={
                              `${i.profilepic}`
                                ? `${IMAGE_URL}${i.profilepic}`
                                : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            }
                            alt="profilepic"
                          />
                          <p>{i.emp_code}-{i.emp_name}</p>
                        </td>
                        <td className="table-body">
                          {i.application_date ? formattedDateLong(i.application_date) : '-'}
                        </td>

                        <td className="table-body text-center">
                          <ViewSeprationDetails i={i} />
                        </td>

                        <SeprationProcessStatusTD i={i} />

                        <td className="table-body text-center">
                          <SeprationFormRHApproval i={i} getSeprationData={getSeprationFormListRH} />
                          <SeprationFormRHRejection i={i} getSeprationData={getSeprationFormListRH} />
                        </td>

                      </tr >
                    </React.Fragment >
                  ))}
              </tbody >
            )}
          </table >
        </div >
      </div >
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

const SeprationFormsTableHOD = () => {

  const [buffer, setBuffering] = useState(true); //buffering logic

  const [selectedDepartment, setDepartment] = useState(
    `${sessionStorage.getItem("department")}`
  );

  const [selectedSubCompany, setSelectedSubCompany] = useState(
    `${sessionStorage.getItem("company_id")}`
  );


  const [toggleStatus, setToggleStatus] = useState("pending");
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // *********** Employee Details Api Start ***********
  const [employeeList, setEmployeeList] = useState([]);
  const [seprationFormList, setSeprationFormList] = useState([]);

  const getEmpList = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/ourcompanyempdetails/active/${selectedDepartment}/`);
      const sortedData = customSortByKey(res.data, "emp_code");
      setEmployeeList(sortedData);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  const getSeprationFormListHOD = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/seprationfilterbyhod/${selectedDepartment}/${toggleStatus}/ `
      );
      const sortedData = customSortByKey(res.data, "emp_code");
      setSeprationFormList(sortedData);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  // *********** Employee Details Api End ***********

  useEffect(() => {
    getSeprationFormListHOD();
    // getEmpList();
  }, [selectedSubCompany, toggleStatus]);



  return (
    <>
      <div className="attendance-history-cont">

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500    font-size-heading">Sepration Requests</div>
            <div className="btn-cont">
              <div className="field-cont">
                {<div className="table-searchh dropdown-container field-cont-div">
                  <Filter />
                  <select onChange={(e) => setToggleStatus(e.target.value)} value={toggleStatus} className="dropdown font-size-subheading  ">
                    <option value="null">All Reqests</option>
                    <option value="pending">Pending</option>
                    <option value="approved">Approved</option>
                    <option value="rejected">Rejected</option>
                  </select>
                </div>}
                <div className="table-searchh">
                  <div>
                    <SearchSmall />
                  </div>
                  <div>
                    <input
                      placeholder="Search Code/Name"
                      className="dropdown font-size-subheading   navbar-inputt"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <table className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="table-heading">Sr no.</th>
                <th className="table-heading text-center">Employee</th>
                <th className="table-heading">Request Date</th>
                <th className="table-heading text-center">Sepration Process</th>
                <th className="table-heading text-center width-5vw">Status</th>
                <th className="table-heading text-center">Actions</th>
              </tr>
            </thead>
            {buffer ? (
              <div className="spinner"></div>
            ) : (
              <tbody>
                {seprationFormList
                  ?.filter(
                    (e) =>
                      e.emp_name
                        ?.toLowerCase()
                        ?.includes(searchQuery.toLowerCase()) ||
                      e.emp_code
                        ?.toString().toLowerCase()
                        ?.includes(searchQuery.toLowerCase())
                  )
                  .sort((a, b) => new Date(a.application_date) - new Date(b.application_date))
                  .map((i, index) => (
                    <React.Fragment key={index}>
                      <tr className="custom-table-head-td">
                        <td className="table-body">{index + 1}</td>
                        <td className="table-body" style={{ textAlign: "center" }}>
                          <img
                            className="profile-img"
                            src={
                              `${i.profilepic}`
                                ? `${IMAGE_URL}${i.profilepic}`
                                : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            }
                            alt="profilepic"
                          />
                          <p>{i.emp_code}-{i.emp_name}</p>
                        </td>
                        <td className="table-body">
                          {i.application_date ? formattedDateLong(i.application_date) : '-'}
                        </td>

                        <td className="table-body text-center">
                          <ViewSeprationDetails i={i} />
                        </td>

                        <SeprationProcessStatusTD i={i} />
                        <td className="table-body text-center">
                          <SeprationFormHODApproval i={i} getSeprationData={getSeprationFormListHOD} />
                          <SeprationFormHODRejection i={i} getSeprationData={getSeprationFormListHOD} />
                        </td>

                      </tr >
                    </React.Fragment >
                  ))}
              </tbody >
            )}
          </table >
        </div >
      </div >
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

const SeprationFormsTableHR = () => {

  const [buffer, setBuffering] = useState(true);

  const [buffer1, setBuffering1] = useState(true);
  const [employeeList, setEmployeeList] = useState([]);
  const [timeStampView, setTimeStampView] = useState(false);

  const [employeeGet, setEmployeeGet] = useState("");
  const [active, setActive] = useState("active");

  const [selectedDepartment, setDepartment] = useState(``);
  const [seprationFilteredList, setSeprationFilteredList] = useState([]);

  const [toggleStatus, setToggleStatus] = useState("pending");
  const [selectedSubCompany, setSelectedSubCompany] = useState(
    `${sessionStorage.getItem("company_id")}`
  );

  const [SubCompany, setSubCompany] = useState([]);

  const getSubCompany = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/subcompany/`);
      setSubCompany(res.data);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  const [departmentname, setDepartmentname] = useState([]);

  const getDepartment = async () => {
    try {
      setBuffering1(true)
      const dep = await axios.get(`${BASE_URL}/wfm/departmentfilterbysubcompanycount/${selectedSubCompany || 'null'}/active/`);
      setDepartmentname(dep.data)
    } catch (err) {
    } finally {
      setBuffering1(false)
    }
  };

  // *********** Employee Details Api Start ***********
  const [seprationFormList, setSeprationFormList] = useState([]);

  const getEmpList = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/ourcompanyempdetails/${active}/${selectedSubCompany}/`);
      const sortedData = customSortByKey(res.data, "emp_code");
      setEmployeeList(sortedData);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  const getSeprationFormListHR = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/seprationfilterbyhr/${selectedSubCompany}/${toggleStatus}/ `
      );
      const sortedData = customSortByKey(res.data, "emp_code");
      setSeprationFormList(sortedData);
      // setSeprationFormList(res.data);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  // *********** Employee Details Api End ***********

  const applyFilters = () => {
    let filteredData = seprationFormList;

    if (selectedDepartment !== "") {
      filteredData = filteredData.filter(
        (employee) => employee.department === selectedDepartment
      );
    }

    if (employeeGet !== "") {
      filteredData = filteredData.filter(
        (employee) => employee.emp_code === employeeGet
      );
    }

    setSeprationFilteredList(filteredData);
  };

  useEffect(() => {
    getSeprationFormListHR();
  }, [toggleStatus, selectedSubCompany]);

  useEffect(() => {
    applyFilters();
  }, [seprationFormList, selectedDepartment, employeeGet]);

  useEffect(() => {
    getEmpList();
    getDepartment();
    getSubCompany();
  }, [selectedSubCompany]);


  const tableRef = useRef(null);
  const handleDownloadSeprationRequests = () => {
    downloadAsExcel(tableRef, "sepration",
      selectedDepartment === "" ? `SeprationRequests` :
        `SeprationRequests`);
  };
  return (
    <>
      <div className="attendance-history-cont">
        <div className="attendance-subcont">
          <div className="field-cont">

            <div className="field-cont-div">
              <Office />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedSubCompany}
                onChange={(e) => setSelectedSubCompany(e.target.value)}
              >
                {/* <option value="">All Sub Companies</option> */}
                {SubCompany.sort((a, b) => a.title - b.title).map((i, index) => (
                  <option value={i.id}>{i.title}</option>
                ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <CircularGrid />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedDepartment}
                onChange={(e) => setDepartment(e.target.value)}
              >
                <option value="">All Department</option>
                {departmentname
                  .sort((a, b) => a.title - b.title)
                  .map((i, index) => (
                    <option value={i.id}>{i.title}</option>
                  ))};
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Employee />
              <select
                className="attendance-input-field width-15vw   date-field"
                type="text"
                value={employeeGet}
                onChange={(e) => setEmployeeGet(e.target.value)}
              >
                <option value="">Select Employee Code</option>
                {employeeList
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Filter />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={toggleStatus}
                onChange={(e) => setToggleStatus(e.target.value)}
              >
                <option value="null">All Reqests</option>
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
              <hr className="field-cont-hr" />
            </div>


          </div>

          <div className="btn-cont">
            <button className=" model-button-print" title="Download in Excel Format" onClick={handleDownloadSeprationRequests}>
              <DownloadIcon />
            </button>
          </div>
        </div>

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500 font-size-heading">Sepration Requests</div>
            <div className="filter-showing">

            </div>
          </div>
          <table ref={tableRef} className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="table-heading">Sr no.</th>
                <th className="table-heading text-center">Employee</th>
                <th className="table-heading">Request Date</th>
                <th className="table-heading text-center">Sepration Process</th>
                <th className="table-heading text-center width-5vw">Status</th>
                <th className="table-heading text-center">Actions</th>
              </tr>
            </thead>
            {buffer ? (
              <div className="spinner"></div>
            ) : (
              <tbody>
                {seprationFilteredList
                  .sort((a, b) => new Date(a.application_date) - new Date(b.application_date))
                  .map((i, index) => (
                    <React.Fragment key={index}>
                      <tr className="custom-table-head-td">
                        <td className="table-body">{index + 1}</td>
                        <td className="table-body" style={{ textAlign: "center" }}>
                          <img
                            className="profile-img"
                            src={
                              `${i.profilepic}`
                                ? `${IMAGE_URL}${i.profilepic}`
                                : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            }
                            alt="profilepic"
                          />
                          <p>{i.emp_code}-{i.emp_name}</p>
                        </td>
                        <td className="table-body">
                          {i.application_date ? formattedDateLong(i.application_date) : '-'}
                        </td>

                        <td className="table-body text-center">
                          <ViewSeprationAndSettlementDetails i={i} />
                        </td>

                        <SeprationProcessStatusTD i={i} />
                        <td className="table-body text-center">
                          <SeprationFormHRApproval i={i} getSeprationData={getSeprationFormListHR} />
                          <SeprationFormHRRejection i={i} getSeprationData={getSeprationFormListHR} />
                        </td>

                      </tr >
                    </React.Fragment >
                  ))}
              </tbody>
            )}
          </table>
        </div>
      </div >
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

const SeprationFormsTableAdmin = () => {

  const [buffer, setBuffering] = useState(true);

  const [buffer1, setBuffering1] = useState(true);
  const [employeeList, setEmployeeList] = useState([]);
  const [timeStampView, setTimeStampView] = useState(false);

  const [employeeGet, setEmployeeGet] = useState("");
  const [active, setActive] = useState("active");

  const [selectedDepartment, setDepartment] = useState(``);
  const [seprationFilteredList, setSeprationFilteredList] = useState([]);

  const [toggleStatus, setToggleStatus] = useState("pending");
  const [selectedSubCompany, setSelectedSubCompany] = useState(
    `${sessionStorage.getItem("company_id")}`
  );

  const [SubCompany, setSubCompany] = useState([]);

  const getSubCompany = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/subcompany/`);
      setSubCompany(res.data);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  const [departmentname, setDepartmentname] = useState([]);

  const getDepartment = async () => {
    try {
      setBuffering1(true)
      const dep = await axios.get(`${BASE_URL}/wfm/departmentfilterbysubcompanycount/${selectedSubCompany || 'null'}/active/`);
      setDepartmentname(dep.data)
    } catch (err) {
    } finally {
      setBuffering1(false)
    }
  };

  // *********** Employee Details Api Start ***********
  const [seprationFormList, setSeprationFormList] = useState([]);

  const getEmpList = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/ourcompanyempdetails/${active}/${selectedSubCompany}/`);
      const sortedData = customSortByKey(res.data, "emp_code");
      setEmployeeList(sortedData);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  const getSeprationFormListAdmin = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/seprationfilterbyadmin/${selectedSubCompany}/${toggleStatus}/ `
      );
      const sortedData = customSortByKey(res.data, "emp_code");
      setSeprationFormList(sortedData);
      // setSeprationFormList(res.data);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  // *********** Employee Details Api End ***********

  const applyFilters = () => {
    let filteredData = seprationFormList;

    if (selectedDepartment !== "") {
      filteredData = filteredData.filter(
        (employee) => employee.department === selectedDepartment
      );
    }

    if (employeeGet !== "") {
      filteredData = filteredData.filter(
        (employee) => employee.emp_code === employeeGet
      );
    }

    setSeprationFilteredList(filteredData);
  };

  useEffect(() => {
    getSeprationFormListAdmin();
  }, [toggleStatus, selectedSubCompany]);

  useEffect(() => {
    applyFilters();
  }, [seprationFormList, selectedDepartment, employeeGet]);

  useEffect(() => {
    getEmpList();
    getDepartment();
    getSubCompany();
  }, [selectedSubCompany]);


  const tableRef = useRef(null);
  const handleDownloadSeprationRequests = () => {
    downloadAsExcel(tableRef, "sepration",
      selectedDepartment === "" ? `SeprationRequests` :
        `SeprationRequests`);
  };
  return (
    <>
      <div className="attendance-history-cont">
        <div className="attendance-subcont">
          <div className="field-cont">

            <div className="field-cont-div">
              <Office />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedSubCompany}
                onChange={(e) => setSelectedSubCompany(e.target.value)}
              >
                {/* <option value="">All Sub Companies</option> */}
                {SubCompany.sort((a, b) => a.title - b.title).map((i, index) => (
                  <option value={i.id}>{i.title}</option>
                ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <CircularGrid />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedDepartment}
                onChange={(e) => setDepartment(e.target.value)}
              >
                <option value="">All Department</option>
                {departmentname
                  .sort((a, b) => a.title - b.title)
                  .map((i, index) => (
                    <option value={i.id}>{i.title}</option>
                  ))};
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Employee />
              <select
                className="attendance-input-field width-15vw   date-field"
                type="text"
                value={employeeGet}
                onChange={(e) => setEmployeeGet(e.target.value)}
              >
                <option value="">Select Employee Code</option>
                {employeeList
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Filter />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={toggleStatus}
                onChange={(e) => setToggleStatus(e.target.value)}
              >
                <option value="null">All Reqests</option>
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
              <hr className="field-cont-hr" />
            </div>


          </div>

          <div className="btn-cont">
            <button className=" model-button-print" title="Download in Excel Format" onClick={handleDownloadSeprationRequests}>
              <DownloadIcon />
            </button>
          </div>
        </div>

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500 font-size-heading">Sepration Requests</div>
            <div className="filter-showing">

            </div>
          </div>
          <table ref={tableRef} className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="table-heading">Sr no.</th>
                <th className="table-heading text-center">Employee</th>
                <th className="table-heading">Request Date</th>
                <th className="table-heading text-center">Sepration Process</th>
                <th className="table-heading text-center width-5vw">Status</th>
                <th className="table-heading text-center">Actions</th>
              </tr>
            </thead>
            {buffer ? (
              <div className="spinner"></div>
            ) : (
              <tbody>
                {seprationFilteredList
                  .sort((a, b) => new Date(a.application_date) - new Date(b.application_date))
                  .map((i, index) => (
                    <React.Fragment key={index}>
                      <tr className="custom-table-head-td">
                        <td className="table-body">{index + 1}</td>
                        <td className="table-body" style={{ textAlign: "center" }}>
                          <img
                            className="profile-img"
                            src={
                              `${i.profilepic}`
                                ? `${IMAGE_URL}${i.profilepic}`
                                : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            }
                            alt="profilepic"
                          />
                          <p>{i.emp_code}-{i.emp_name}</p>
                        </td>
                        <td className="table-body">
                          {i.application_date ? formattedDateLong(i.application_date) : '-'}
                        </td>

                        <td className="table-body text-center">
                          <ViewSeprationAndSettlementDetails i={i} />
                        </td>

                        <SeprationProcessStatusTD i={i} />
                        <td className="table-body text-center">
                          <SeprationFormAdminApproval i={i} getSeprationData={getSeprationFormListAdmin} />
                          <SeprationFormAdminRejection i={i} getSeprationData={getSeprationFormListAdmin} />
                        </td>

                      </tr >
                    </React.Fragment >
                  ))}
              </tbody>
            )}
          </table>
        </div>
      </div >
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

const SeprationFormsTableAcc = () => {

  const [buffer, setBuffering] = useState(true);

  const [buffer1, setBuffering1] = useState(true);
  const [employeeList, setEmployeeList] = useState([]);

  const [employeeGet, setEmployeeGet] = useState("null");
  const [active, setActive] = useState("active");

  const [selectedDepartment, setDepartment] = useState(``);
  const [seprationFilteredList, setSeprationFilteredList] = useState([]);

  const [toggleStatus, setToggleStatus] = useState("pending");
  const [selectedSubCompany, setSelectedSubCompany] = useState(
    `${sessionStorage.getItem("company_id")}`
  );

  const [SubCompany, setSubCompany] = useState([]);

  const getSubCompany = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/subcompany/`);
      setSubCompany(res.data);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  const [departmentname, setDepartmentname] = useState([]);

  const getDepartment = async () => {
    try {
      setBuffering1(true)
      const dep = await axios.get(`${BASE_URL}/wfm/departmentfilterbysubcompanycount/${selectedSubCompany || 'null'}/active/`);
      setDepartmentname(dep.data)
    } catch (err) {
    } finally {
      setBuffering1(false)
    }
  };

  // *********** Employee Details Api Start ***********
  const [seprationFormList, setSeprationFormList] = useState([]);

  const getEmpList = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/ourcompanyempdetails/${active}/${selectedSubCompany}/`);
      const sortedData = customSortByKey(res.data, "emp_code");
      setEmployeeList(sortedData);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  const getSeprationFormListHR = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/seprationfilterbyaccount/${selectedSubCompany}/${toggleStatus}/ `
      );
      const sortedData = customSortByKey(res.data, "emp_code");
      setSeprationFormList(sortedData);
      // setSeprationFilteredList(sortedData);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  // *********** Employee Details Api End ***********

  const applyFilters = () => {
    let filteredData = seprationFormList;

    if (selectedDepartment !== "") {
      filteredData = filteredData.filter(
        (employee) => employee.department === selectedDepartment
      );
    }

    setSeprationFilteredList(filteredData);
  };

  useEffect(() => {
    getSeprationFormListHR();
  }, [employeeGet, toggleStatus, selectedSubCompany]);

  useEffect(() => {
    applyFilters();
  }, [seprationFormList, selectedDepartment,]);

  useEffect(() => {
    getEmpList();
    getDepartment();
    getSubCompany();
  }, [selectedSubCompany]);

  const tableRef = useRef(null);
  const handleDownloadSeprationRequests = () => {
    downloadAsExcel(tableRef, "sepration",
      selectedDepartment === "" ? `SeprationRequests` :
        `SeprationRequests`);
  };
  return (
    <>
      <div className="attendance-history-cont">
        <div className="attendance-subcont">
          <div className="field-cont">

            <div className="field-cont-div">
              <Office />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedSubCompany}
                onChange={(e) => setSelectedSubCompany(e.target.value)}
              >
                {/* <option value="">All Sub Companies</option> */}
                {SubCompany.sort((a, b) => a.title - b.title).map((i, index) => (
                  <option value={i.id}>{i.title}</option>
                ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <CircularGrid />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedDepartment}
                onChange={(e) => setDepartment(e.target.value)}
              >
                <option value="">All Department</option>
                {departmentname
                  .sort((a, b) => a.title - b.title)
                  .map((i, index) => (
                    <option value={i.id}>{i.title}</option>
                  ))};
              </select>
              <hr className="field-cont-hr" />
            </div>

            {/* <div className="field-cont-div">
              <Employee />
              <select
                className="attendance-input-field width-15vw   date-field"
                type="text"
                value={employeeGet}
                onChange={(e) => setEmployeeGet(e.target.value)}
              >
                <option value="null">Select Employee Code</option>
                {employeeList
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div> */}

            <div className="field-cont-div">
              <Filter />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={toggleStatus}
                onChange={(e) => setToggleStatus(e.target.value)}
              >
                <option value="null">All Reqests</option>
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
              <hr className="field-cont-hr" />
            </div>


          </div>

          <div className="btn-cont">
            <button className=" model-button-print" title="Download in Excel Format" onClick={handleDownloadSeprationRequests}>
              <DownloadIcon />
            </button>
          </div>
        </div>

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500 font-size-heading">Sepration Requests</div>
            <div className="filter-showing">

            </div>
          </div>
          <table ref={tableRef} className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="table-heading">Sr no.</th>
                <th className="table-heading text-center">Employee</th>
                <th className="table-heading">Request Date</th>
                <th className="table-heading text-center">Sepration Process</th>
                <th className="table-heading text-center width-5vw">Status</th>
                <th className="table-heading text-center">Actions</th>
              </tr>
            </thead>
            {buffer ? (
              <div className="spinner"></div>
            ) : (
              <tbody>
                {seprationFilteredList
                  .sort((a, b) => new Date(a.application_date) - new Date(b.application_date))
                  .map((i, index) => (
                    <React.Fragment key={index}>
                      <tr className="custom-table-head-td">
                        <td className="table-body">{index + 1}</td>
                        <td className="table-body" style={{ textAlign: "center" }}>
                          <img
                            className="profile-img"
                            src={
                              `${i.profilepic}`
                                ? `${IMAGE_URL}${i.profilepic}`
                                : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            }
                            alt="profilepic"
                          />
                          <p>{i.emp_code}-{i.emp_name}</p>
                        </td>
                        <td className="table-body">
                          {i.application_date ? formattedDateLong(i.application_date) : '-'}
                        </td>

                        <td className="table-body text-center">
                          <ViewSeprationDetails i={i} />
                        </td>

                        <SeprationProcessStatusTD i={i} />
                        <td className="table-body text-center">
                          <SeprationFormAccountApproval i={i} getSeprationData={getSeprationFormListHR} />
                          <SeprationFormAccountRejection i={i} getSeprationData={getSeprationFormListHR} />
                        </td>

                      </tr >
                    </React.Fragment >
                  ))}
              </tbody>
            )}
          </table>
        </div>
      </div >
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

const SeprationHeadingAuthorityViewPage = () => {
  let { id, emp_code, position } = useParams();

  const [seprationData, setSeprationData] = useState({});
  const [buffer, setBuffering] = useState(false);

  const getEmployeeSeprationData = async (id) => {
    try {
      setBuffering(true)
      const res = await axios.get(
        `${BASE_URL}/wfm/seprationformbyid/${id}/`
      );
      setSeprationData(res.data);
    } catch (err) {
      //toast Logic
      handleAllError(err);
    } finally {
      setBuffering(false)
    }
  };

  useEffect(() => {
    getEmployeeSeprationData(id);
  }, [id]);

  const sortedHeadings = Array.isArray(seprationData?.headings)
    ? [...seprationData.headings].sort((a, b) => (a.sorting_var || 0) - (b.sorting_var || 0))
    : [];
  const currentIndex = sortedHeadings.findIndex(
    (h) => Number(h?.sorting_var) === Number(position)
  );
  const currentHeading = sortedHeadings[currentIndex];

  return (
    <div className="jdview-outer">
      {buffer ? <div className="spinner-z"></div> : null}

      <div className="jdview-section1">
        <div>
          {seprationData.profilepic ?
            <>
              <img
                src={
                  `${seprationData.profilepic}`
                    ? `${IMAGE_URL}${seprationData.profilepic}`
                    : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                }
                alt="Logo Preview"
                className="sepration-profilepic"

              /></> :
            <img
              className="sepration-profilepic"
              src="https://g-eng.s3.ap-south-1.amazonaws.com/template-img/%60logo.png"
              alt="profile-pic"
            />}
        </div>
        <div className="jdview-section1-b">

          <div className="jdview-section1-b-a">
            <div className="jdview-section1-b-heading1  font-weight500  font-size-heading">Employee Code</div>
            <div className="jdview-section1-b-heading2  font-weight500  font-size-heading">
              {seprationData.emp_code}
            </div>
          </div>
          <div className="jdview-section1-b-a">
            <div className="jdview-section1-b-heading1  font-weight500  font-size-heading">Employee Name</div>
            <div className="jdview-section1-b-heading2  font-weight500  font-size-heading">
              {seprationData.emp_name}
            </div>
          </div>
          <div className="jdview-section1-b-a">
            <div className="jdview-section1-b-heading1  font-weight500  font-size-heading">Department</div>
            <div className="jdview-section1-b-heading2  font-weight500  font-size-heading">
              <p>{seprationData.department_name}</p>
            </div>
          </div>

        </div>
        <div>
        </div >
      </div >
      <div className="jdview-section2">
        {/* <PersonalInfoTab />
        <EducationInfoTab />
        <WorkInfoTab />
        <DocsTab /> */}

        <div className="jdfinal-section2">
          {/* <InterviewHeading /> */}

          <div className="sepration-table">

            <form className="register-form">
              <div className="flex-row justify-center">
                <table className="nested-table-lightblue width-40vw">
                  <thead>
                    <tr>
                      <th className="align-center">S. No.</th>
                      <th className="align-center">Name of Department</th>
                      <th className="align-center">Concerned Person</th>
                      <th className="align-center">Signature TimeStamp</th>
                      <th className="align-center">Status</th>
                      <th className="align-center">Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    <React.Fragment>
                      {[
                        { label: "Reporting Head", name: seprationData.rh_log_details?.emp_code ? `${seprationData.rh_log_details?.emp_code} - ${seprationData.rh_log_details?.emp_name}` : null, timestamp: seprationData.rh_log_details?.action_datetime, status: seprationData.rh_approval, remarks: seprationData.rh_log_details?.action_description },
                        { label: "Head Of Department", name: seprationData.hod_log_details?.emp_code ? `${seprationData.hod_log_details?.emp_code} - ${seprationData.hod_log_details?.emp_name}` : null, timestamp: seprationData.hod_log_details?.action_datetime, status: seprationData.hod_approval, remarks: seprationData.hod_log_details?.action_description },
                        ...(seprationData.headings || []).sort((a, b) => (a.sorting_var || 0) - (b.sorting_var || 0)).map((head) => ({
                          label: head.heading_name || head.department_name,
                          name: head.approving_authority ? `${head.approving_authority} - ${head.approving_authority_name}` : null,
                          timestamp: head.approval_log_details?.action_datetime,
                          status: head.approval,
                          remarks: head.approval_log_details?.action_description,
                        })),
                        { label: "Admin", name: seprationData.admin_log_details?.emp_code ? `${seprationData.admin_log_details?.emp_code} - ${seprationData.admin_log_details?.emp_name}` : null, timestamp: seprationData.admin_log_details?.action_datetime, status: seprationData.admin_approval, remarks: seprationData.admin_log_details?.action_description },
                        { label: "Accounts", name: seprationData.acc_log_details?.emp_code ? `${seprationData.acc_log_details?.emp_code} - ${seprationData.acc_log_details?.emp_name}` : null, timestamp: seprationData.acc_log_details?.action_datetime, status: seprationData.account_approval, remarks: seprationData.acc_log_details?.action_description },
                        { label: "Human Resource", name: seprationData.hr_log_details?.emp_code ? `${seprationData.hr_log_details?.emp_code} - ${seprationData.hr_log_details?.emp_name}` : null, timestamp: seprationData.hr_log_details?.action_datetime, status: seprationData.hr_approval, remarks: seprationData.hr_log_details?.action_description },
                      ].map(({ label, name, timestamp, status, remarks }, index) => (
                        <tr key={`${label}-${index}`}>
                          <td className="text-center">{index + 1}</td>
                          <td className="text-center">{label}</td>
                          <td className="text-center">{name}</td>
                          <td className="text-center">{timestamp ? formatDateTime(timestamp).full : ""}</td>
                          <td className="text-center request-status form-text-trasformation-uppercase" request-status={status}>{status}</td>
                          <td className="text-center">{remarks}</td>
                        </tr>
                      ))}
                    </React.Fragment>
                  </tbody>
                </table>

              </div>

              <div className="flex-row justify-center">
                <table className="nested-table-lightblue width-40vw">
                  <thead>
                    <tr>
                      <th className="align-center">S. No.</th>
                      <th className="align-center">Asset Name</th>
                      <th className="align-center">Concerned Heading</th>
                      <th className="align-center">Person Taking Handover</th>
                      <th className="align-center">Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {seprationData.handover_items && seprationData.handover_items.length > 0 ? (
                      seprationData.handover_items
                        ?.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
                        .map((item, index) => (
                          <tr key={`handover-${index}`}>
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center">{item.asset_name}</td>
                            <td className="text-center">{item.concerned_headings}</td>
                            <td className="text-center">{item.person_taking_handover} - {item.person_taking_handover_name}</td>
                            <td className="text-center">{item.remarks}</td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td className="text-center" colSpan="7">No handover items available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div className="button-models">
                {/* <button type="button" className="model-button-cancel model-button font-weight500" >
                  Close
                </button> */}
                {currentHeading?.approval === "pending" ?
                  <div className='printSlip-width flex-row justify-evenly' >
                    <SeprationFormHeadingsRejection i={seprationData} emp_code={emp_code} position={position} getSeprationData={getEmployeeSeprationData} />
                    <SeprationFormHeadingsApproval i={seprationData} emp_code={emp_code} position={position} getSeprationData={getEmployeeSeprationData} />


                    {/* <button className="model-button  font-weight500 model-button-delete" >
                    Reject
                  </button> */}

                    {/* <button className="model-button  font-weight500 model-button-submit">
                    Approve
                  </button> */}

                  </div> : null}


              </div>
            </form>
          </div>



        </div>
      </div>
    </div>
  );
};

export {
  SeprationFormatsTable,
  SettlementFormatsTable,

  SeprationFormsTableRH,
  SeprationFormsTableHOD,

  SeprationHeadingAuthorityViewPage,

  SeprationFormsTableHR,
  SeprationFormsTableAdmin,
  SeprationFormsTableAcc,
};
