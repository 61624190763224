import React, { useEffect, useRef, useState } from "react";
import ReactToPrint from 'react-to-print';
import Logo from './frame-10000027081.png'
import Signature from './Picture 1.png'
import { BASE_URL, FRONTEND_URL } from "../../config/axios";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import numberToWords from 'number-to-words';
import { formatCurrencyIndian } from "../CustomFunctions";
import { formattedDateLong } from "../Date";

const DataSalarySlip = React.forwardRef((props, ref) => {
  let { month, year, id } = useParams();
  const location = useLocation();
  const { i, monthh, yearr } = location.state;
  const [salaryData, setSalaryData] = useState([])

  const getSlipData = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/employeesalaryslipdata/${i.emp_code}/${yearr}/${monthh}/`);
      setSalaryData(res.data)

    } catch (err) {
    }
  };

  useEffect(() => {
    getSlipData();

  }, []);

  const monthNames = [
    "JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY",
    "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"
  ];

  const monthName = monthNames[monthh - 1];

  return (
    // <>
    <div className='' ref={ref}>
      <div className='salaryslip'>
        <div className='salaryslip-header'>
          <div className='salaryslip-header-left'>
            <img src={Logo} alt='logo' className='salaryslip-logo' />
            <div className='salaryslip-header-left-text  font-size-heading'>
              SALARY SLIP FOR THE MONTH OF {monthName} {yearr}
            </div>
          </div>
          <div className='salaryslip-header-right'>
            <div className='salaryslip-header-right-top  font-weight600   font-size-heading'>
              Civilmantra infracon private limited
            </div>
            <div className='salaryslip-header-right-bottom  font-weight400  font-size-subheading'>
              3rd Floor, Tower 3A,<br /> DLF Corporate Greens, Sector 74A,<br /> Gurugram, Haryana 122004 <br /> services@civilmantra.com
            </div>
          </div>
        </div>
        <div className='salaryslip-hr'></div>
        <div className='salaryslip-header-second'>
          <div className='salaryslip-header-second-section1'>
            <div className='salaryslip-heading-black   font-weight600 font-size-subheading'>NAME</div>
            <div className='salaryslip-heading-black   font-weight600 font-size-subheading'>FATHER / HUSBAND</div>
            <div className='salaryslip-heading-black   font-weight600 font-size-subheading'>DATE OF JOINING</div>
            <div className='salaryslip-heading-black   font-weight600 font-size-subheading'>DEPARTMENT</div>


          </div>
          <div className='salaryslip-header-second-section2'>
            <div className='salaryslip-heading-black1  font-weight400  font-size-subheading'>{salaryData.salary_data?.name ?? "-"}</div>
            <div className='salaryslip-heading-black1  font-weight400  font-size-subheading'>{salaryData.salary_data?.user_details?.father_name ?? "-"}</div>
            <div className='salaryslip-heading-black1  font-weight400  font-size-subheading'>{salaryData.salary_data?.user_details?.joining_date ? formattedDateLong(salaryData.salary_data?.user_details?.joining_date) : "-"}</div>
            <div className='salaryslip-heading-black1  font-weight400  font-size-subheading'>{salaryData.salary_data?.user_details?.department_name ?? "-"}</div>
          </div>
          <div className='salaryslip-header-second-section3'>
          </div>
          <div className='salaryslip-header-secondsection4'>
            <div className='salaryslip-heading-black   font-weight600 font-size-subheading'>CODE</div>
            <div className='salaryslip-heading-black   font-weight600 font-size-subheading'>BANK</div>
            <div className='salaryslip-heading-black   font-weight600 font-size-subheading'>ACCOUNT NO.</div>
            <div className='salaryslip-heading-black   font-weight600 font-size-subheading'>IFSC NO.</div>
            {/* <div className='salaryslip-heading-black   font-weight600 font-size-subheading'>UAN NO.</div> */}
          </div>
          <div className='salaryslip-header-second-section5'>
            <div className='salaryslip-heading-black1  font-weight400  font-size-subheading'>{salaryData.salary_data?.emp_code ?? "-"}</div>
            <div className='salaryslip-heading-black1  font-weight400  font-size-subheading'>{salaryData.salary_data?.bank_details?.bank_name ?? "-"}</div>
            <div className='salaryslip-heading-black1  font-weight400  font-size-subheading'>{salaryData.salary_data?.bank_details?.account_number ?? "-"}</div>
            <div className='salaryslip-heading-black1  font-weight400  font-size-subheading'>{salaryData.salary_data?.bank_details?.ifsc_code ?? "-"}</div>
            {/* <div className='salaryslip-heading-black1  font-weight400  font-size-subheading'>{salaryData.salary_data?.bank_details.branch_address}</div> */}
          </div>
        </div>
        <div className='salaryslip-hr'></div>
        <div className='salaryslip-header-third'>
          <div className='salaryslip-header-third-section1'>
            <div className='salaryslip-heading-blue font-weight500 font-size-subheading'>TOTAL DAYS</div>
            <div className='salaryslip-heading-blue font-weight500 font-size-subheading'>TOTAL LEAVES</div>
            <div className='salaryslip-heading-blue font-weight500 font-size-subheading'>TOTAL ABSENT</div>
            <div className='salaryslip-heading-blue font-weight500 font-size-subheading'>WORKING DAYS</div>
          </div>
          <div className='salaryslip-header-third-section2'>
            <div className='salaryslip-heading-blue font-weight500'>{salaryData.salary_data?.total_days}</div>
            <div className='salaryslip-heading-blue font-weight500'>{salaryData.salary_data?.attendance_summary?.leave}</div>
            <div className='salaryslip-heading-blue font-weight500'>{salaryData.salary_data?.attendance_summary?.absent}</div>
            <div className='salaryslip-heading-blue font-weight500'>{salaryData.salary_data?.total_paid_days}</div>

          </div>
          <div className='salaryslip-header-third-section3'>
            <div className='salaryslip-heading-blue font-weight500 font-size-subheading'>WEEKLY OFF</div>
            <div className='salaryslip-heading-blue font-weight500 font-size-subheading'>LEAVE WITHOUT PAY</div>
            <div className='salaryslip-heading-blue font-weight500 font-size-subheading'>HOLIDAY</div>
            <div className='salaryslip-heading-blue font-weight500 font-size-subheading'>NET PAID DAYS</div>

          </div>
          <div className='salaryslip-header-third-section4'>
            <div className='salaryslip-heading-blue font-weight500'>{salaryData.salary_data?.attendance_summary?.weeklyoff}</div>
            <div className='salaryslip-heading-blue font-weight500'>{salaryData.salary_data?.attendance_summary?.unpaidleave}</div>
            <div className='salaryslip-heading-blue font-weight500'>{salaryData.salary_data?.attendance_summary?.holiday}</div>
            <div className='salaryslip-heading-blue font-weight500'>{salaryData.salary_data?.total_paid_days}</div>
          </div>
        </div>
        <div className='salaryslip-hr'></div>
        <div className='salaryslip-body'>
          <table className='nested-table-modern'>
            <thead>
              <tr className='slaryslip-head'>
                {/* <th >S. No.</th> */}
                <th className="align-center" style={{ width: "260px", padding: "0px 5px" }}>Salary Component</th>
                <th className="align-center" >STANDARD</th>
                <th className="align-center" >AMOUNT</th>
              </tr>
            </thead>
            <tbody>
              {
                salaryData.salary_data?.deduction_details
                  ?.filter((j) => !isNaN(j.standard_amount) && !isNaN(j.actual_amount) && j.salaryslip !== false)
                  .sort((a, b) => a.sorting_var - b.sorting_var)
                  .map((j, index) => {
                    const isSpecialRow = j.salary_type === "total" || j.salary_type === "difference";
                    return (
                      <tr key={index} className={isSpecialRow ? "special-row" : ""}>
                        <td>{j.name}</td>
                        <td className="align-right">{formatCurrencyIndian(j.standard_amount)}</td>
                        <td className="align-right">{formatCurrencyIndian(j.actual_amount)}</td>
                      </tr>
                    );
                  })
              }
            </tbody>
          </table>
          <table className='nested-table-modern'>
            <tbody>
              <tr className="salary-total-row">
                <td>SALARY PAY</td>
                <td>
                  {
                    formatCurrencyIndian(
                      salaryData.salary_data?.deduction_details.find((j) => j.final_salary === true)?.actual_amount || 0
                    )
                  }
                </td>
              </tr>
              <tr className="salary-total-row in-words-row">
                <td>IN WORDS</td>
                <td>
                  {
                    numberToWords.toWords(
                      salaryData.salary_data?.deduction_details.find((j) => j.final_salary === true)?.actual_amount || 0
                    ).toUpperCase()
                  }
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <h6 style={{ fontSize: '10px' }}><b>*This is Software generated Salary Slip no signature required*</b></h6>
        </div>
      </div>
      <div className='printSlip-bg'>
      </div>
    </div>
    // </>
  )
});
const PrintDynamicSalarySlipData = () => {
  const ref = useRef();
  const navigate = useNavigate()
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split('/');
  let backUrl = "/" + urlParts[3] + `/payrollmanagement`;
  const location = useLocation();
  const { slip_opener, monthh, yearr } = location.state;

  let togglepayroll = 3;
  const toggleslipmonth = monthh;
  const toggleslipyear = yearr;
  let toggleuser = 7;

  if (slip_opener === "profile") {
    console.log("A")
    backUrl = "/" + urlParts[3] + `/userdetail`;
    toggleuser = 7;
  }
  else if (slip_opener === "monthlyslip") {
    console.log("B")
    backUrl = "/" + urlParts[3] + `/payrollmanagement`;
    togglepayroll = 3;
  }
  else if (slip_opener === "empdetails") {
    console.log("C")
    backUrl = "/" + urlParts[3] + `/payrollmanagement`;
    togglepayroll = 4;
  }


  const backroute = (togglepayroll, toggleuser) => {
    navigate(backUrl, { state: { togglepayroll, toggleslipmonth, toggleslipyear, toggleuser } })
  }

  return (

    <div>
      <div className='flex-column flex-center  slip-container'>
        <div className='printSlip-width flex-row justify-evenly button-margin'>
          <button className="model-button model-button-black font-weight500   " onClick={(e) => { backroute(togglepayroll, toggleuser) }}>Back</button>
          <ReactToPrint
            bodyClass="print-agreement"
            content={() => ref.current}
            trigger={() => (
              <button className="model-button model-button-black">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 18" fill="none">
                  <path d="M17 5H3C1.34 5 0 6.34 0 8V12C0 13.1 0.9 14 2 14H4V16C4 17.1 4.9 18 6 18H14C15.1 18 16 17.1 16 16V14H18C19.1 14 20 13.1 20 12V8C20 6.34 18.66 5 17 5ZM13 16H7C6.45 16 6 15.55 6 15V11H14V15C14 15.55 13.55 16 13 16ZM17 9C16.45 9 16 8.55 16 8C16 7.45 16.45 7 17 7C17.55 7 18 7.45 18 8C18 8.55 17.55 9 17 9ZM15 0H5C4.45 0 4 0.45 4 1V3C4 3.55 4.45 4 5 4H15C15.55 4 16 3.55 16 3V1C16 0.45 15.55 0 15 0Z" fill="black" />
                </svg> Print
              </button>
            )}
          />
        </div>

        <DataSalarySlip ref={ref} />
      </div>
    </div >
  );
};
export default PrintDynamicSalarySlipData
