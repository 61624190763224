import React, { useEffect, useState, useRef } from 'react';
import axios from "axios";
import { BASE_URL } from "../../config/axios";
import { formatDate, formattedDate, formattedDateLong } from "../Date";
import { Modal } from "react-bootstrap";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import Select from "react-select";

import usePermission from "../../config/permissions";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AddItemToStock, AddStationaryItem, DeleteEmployeeStationary, DeleteItemFromStock, DeleteStationaryItem, GiveEmployeeStationary, UpdateEmployeeStationary, UpdateItemInStock, UpdateStationaryItem } from "./StationaryButtons";
import { customSortByKey } from '../CustomFunctions';
import { DownloadIcon, Office, SearchBig, SearchSmall } from '../AllSvg';

const ItemListTable = ({ item }) => {
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
  const [toggleCompany, setToggleCompany] = useState(sessionStorage.getItem("company_id"));
  const [buffer, setBuffering] = useState(true); //buffering logic
  const [show, setShow] = useState(false);
  const [empItemList, setEmpItemList] = useState([]);

  const getItemsList = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        // `${BASE_URL}/wfm/stationary/`
        `${BASE_URL}/wfm/stationary-filter/${toggleCompany}/`
      );

      setEmpItemList(res.data);
    } catch (err) {
      console.log(err)
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    getItemsList();
  }, [toggleCompany]);


  return (
    <>

      <div className="table-css-white-background">
        <div className="table-heading-flex field-cont-between">
          <div className="repo-heading font-weight500    font-size-heading">
            Items List <br />
          </div>
          <div className="field-cont">
            {PERMISSION_AUTHORITY ? <div className="table-searchh dropdown-container field-cont-div">
              <Office />
              <select onChange={(e) => setToggleCompany(e.target.value)} value={toggleCompany} className="dropdown font-size-subheading  ">
                <option value="null">Select Sub Company</option>
                {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};

              </select>
            </div> : null
            }
            <div className="table-searchh">
              <div>
                <SearchSmall />
              </div>

              <div>
                <input
                  placeholder="Search"
                  className="dropdown font-size-subheading   navbar-inputt"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
            <AddStationaryItem getItemsList={getItemsList} />
          </div>
        </div>
        <table className="table-css">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">S.No.</th>
              <th className="align-center">Item Name</th>
              {/* <th className="align-center"> Name </th> */}
              <th className="align-center width-10vw"> Action</th>
              {/* <th className="align-center">Employee View</th> */}
            </tr>
          </thead>
          {buffer ? <div className="spinner"></div> : //buffering logic
            <tbody>
              {empItemList
                ?.filter(
                  (e) =>
                    e.item_name
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase()))
                .sort((a, b) => a.emp_code - b.emp_code)
                .map((i, index) => (
                  <React.Fragment key={index}>
                    {/* <tr className="tr-border-bottom">
                          <td colSpan="6"></td>
                        </tr> */}
                    <tr className="custom-table-head-td">
                      <td className="align-left">{index + 1}</td>
                      <td className="align-center">{i.item_name}</td>
                      {/* <td className="align-center">{i.name}</td> */}
                      {/*<td className="align-center">{i.itemcount}</td>*/}

                      <td className="align-center">
                        <>
                          <UpdateStationaryItem i={i} getItemsList={getItemsList} />
                          {PERMISSION_AUTHORITY ? (
                            <DeleteStationaryItem i={i} getItemsList={getItemsList} />
                          ) : null}
                        </>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
            </tbody>
          }
        </table>
      </div>

      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

    </>
  );
};

const StockListTable = () => {
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
  const [toggleCompany, setToggleCompany] = useState(sessionStorage.getItem("company_id"));
  const [active, setActive] = useState("active");
  const [buffer, setBuffering] = useState(true); //buffering logic
  const [itemList, setItemList] = useState([]);

  const getItemsList = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/stationary-filter/${toggleCompany}/`
      );
      setItemList(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  // *********** Stock Items Details Api Start ***********
  const [stockItemList, setStockItemList] = useState([]);


  const [selectedName, setName] = useState("");
  const [fromdate, setFromDate] = useState("null");
  const [todate, setToDate] = useState("null");

  const getStockList = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/stationary-inventory-details-filter/${toggleCompany}/`
      );
      setStockItemList(res.data);
      console.log(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }

  };

  useEffect(() => {
    getItemsList();
    getStockList();
  }, [fromdate, todate, toggleCompany, active]);

  const [filteredList, setFilteredList] = useState([]);

  const applyFilters = () => {
    let filteredData = stockItemList;


    if (selectedName) {
      const lowercaseSelectedName = selectedName.toLowerCase();
      filteredData = filteredData.filter((data) => {
        const nameMatch = data.item_name.toLowerCase().includes(lowercaseSelectedName);
        const vendorMatch = data.vendor_name.toLowerCase().includes(lowercaseSelectedName);
        return nameMatch || vendorMatch;
      });
    }


    setFilteredList(filteredData);

  };

  useEffect(() => {
    applyFilters();
  }, [stockItemList, selectedName]);

  // *********** Employee Details Api End ***********

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"> </div>
        <div className="attendance-subcont">
          <div className="field-cont">

            {PERMISSION_AUTHORITY ?
              <div title="Company Filter" className="field-cont-div">
                <Office />
                <select
                  onChange={(e) => setToggleCompany(e.target.value)}
                  value={toggleCompany}
                  className="attendance-input-field width-10vw   date-field"
                // className="dropdown font-size-subheading  "
                >
                  <option value="null">Select Sub Company</option>
                  {SUB_COMPANIES.map((company) => (
                    <option value={company.id}>{company.title}</option>
                  ))}

                </select>

                <hr className="field-cont-hr" />
              </div> : null}

            <div title="From Date Filter" className="field-cont-div">
              <div className="  date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={fromdate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>

              <hr className="field-cont-hr" />
            </div>

            <div title="To Date Filter" className="field-cont-div">
              <div className=" date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={todate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />
            </div>

            <div title="Search Filter" className="field-cont-div">
              <SearchBig />
              <input
                className="attendance-input-field width-15vw  "
                placeholder="Item / Vendor Name"
                type="text"
                value={selectedName}
                onChange={(e) => setName(e.target.value)}
              />

              {/* <hr className="field-cont-hr" /> */}
            </div>

          </div>
          <div className="btn-cont"></div>
        </div>

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500    font-size-heading">Stock List</div>
            <AddItemToStock getStockList={getStockList} itemList={itemList} />
          </div>
          <table className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="align-left">S. No.</th>
                <th className="align-center">Item</th>
                <th className="align-center">Purchase Date</th>
                <th className="align-center">Vendor</th>
                <th className="align-center">Total Quantity</th>
                <th className="align-center">Price Rate</th>
                <th className="align-center">Remaining Quantity</th>
                <th className="align-center">Distributed Quantity</th>
                <th className="align-center width-10vw">Action</th>
              </tr>
            </thead>
            {buffer ? <div className="spinner"></div> : //buffering logic
              <tbody>
                {filteredList
                  ?.sort((a, b) => new Date(b.purchase_date) - new Date(a.purchase_date))
                  .map((i, index) => (
                    <React.Fragment key={index}>
                      <tr className="tr-border-bottom">
                        <td colSpan="6"></td>
                      </tr>
                      <tr className="custom-table-head-td">
                        <td className="align-left">{index + 1}</td>

                        <td className="align-center ">{i.item_name}</td>
                        <td className="align-center">{formatDate(i.purchase_date)}</td>
                        <td className="align-center ">{i.vendor_name}</td>
                        <td className="align-center ">{i.quantity}</td>
                        <td className="align-center ">{i.rate_per_item}</td>
                        <td className="align-center ">{i.remaning_quantity === null ? i.quantity : i.remaning_quantity}</td>
                        <td className="align-center ">{i.distributed_quantity === null ? 0 : i.distributed_quantity}</td>

                        <td className="align-center ">
                          <UpdateItemInStock i={i} getStockList={getStockList} itemList={itemList} />
                          {PERMISSION_AUTHORITY ? (
                            <DeleteItemFromStock i={i} getStockList={getStockList} />
                          ) : null}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>}
          </table>
        </div>
      </div>
    </>
  );
};

const StockToEmployeeList = ({ item }) => {
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
  const [toggleCompany, setToggleCompany] = useState(sessionStorage.getItem("company_id"));
  const [buffer, setBuffering] = useState(true); //buffering logic
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    getEmployeeItemsList(item.id);
    setShow(true);
  };

  const [empItemList, setEmpItemList] = useState([]);

  const getEmployeeItemsList = async (itemId) => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/employeedetailsbyitem/${itemId}/`
      );

      const sortedList = customSortByKey(res.data, "emp_code");

      setEmpItemList(sortedList);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const navigate = useNavigate();
  const Viewdetails = (emp_code, user_detail) => {
    navigate(`/hr/userdetailscrud/${emp_code}/${user_detail}/`);
  };



  return (
    <>
      <button
        className=""
        title="View Employees"
        style={{ paddingLeft: "5px", color: "blue", fontSize: "14px" }}
        onClick={handleShow}
      >
        View
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          <>
            {/* <div className="attendance-history-cont">
              <div className="attendance-heading  font-weight500    font-size-heading"></div>
            </div> */}
            <div className="table-css-white-background">
              <div className="table-heading-flex">
                <div className="repo-heading font-weight500    font-size-heading">
                  Employees having : <br />
                  {/* {item.title} */}
                </div>
                <div className="field-cont">
                  <div className="table-searchh">
                    <div>
                      <SearchSmall />
                    </div>
                    <div>
                      <input
                        placeholder="Search"
                        className="navbar-inputt font-weight400  font-size-text"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <table className="table-css">
                <thead>
                  <tr className="custom-table-head-tr">
                    <th className="align-left">ID</th>
                    <th className="align-center">Emp Code</th>
                    <th className="align-center"> Name </th>
                    <th className="align-center"> Item Count</th>
                    {/* <th className="align-center">Employee View</th> */}
                  </tr>
                </thead>
                {buffer ? <div className="spinner"></div> : //buffering logic
                  <tbody>
                    {empItemList
                      ?.filter(
                        (e) =>
                          e.name
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase()) ||
                          e.emp_code
                            .toString()
                            .includes(searchQuery.toLowerCase())
                      )
                      .sort((a, b) => a.emp_code - b.emp_code)
                      .map((i, index) => (
                        <React.Fragment key={index}>
                          {/* <tr className="tr-border-bottom">
                          <td colSpan="6"></td>
                        </tr> */}
                          <tr className="custom-table-head-td">
                            <td className="align-left">{index + 1}</td>
                            <td className="align-center">{i.emp_code}</td>
                            <td className="align-center">{i.name}</td>
                            <td className="align-center">{i.itemcount}</td>

                            {/* <td className="align-center">
                              <button
                                className="employee-edit"
                                onClick={() =>
                                  Viewdetails(i.emp_code, i.user_detail)
                                }
                                title="View Profile"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="40"
                                  height="40"
                                  viewBox="0 0 30 30"
                                  fill="none"
                                >
                                  <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                                  <path
                                    d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                    fill="#2576BC"
                                  />
                                </svg>
                              </button>
                            </td> */}
                          </tr>
                        </React.Fragment>
                      ))}
                  </tbody>
                }
              </table>
            </div>
          </>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const EmployeeItemsList = () => {
  const tableRef = useRef(null);
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
  const [toggleCompany, setToggleCompany] = useState(sessionStorage.getItem("company_id"));
  const [buffer, setBuffering] = useState(true); //buffering logic


  const [stockItemList, setStockItemList] = useState([]);

  const getStockList = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/stationary-inventory-details-filter/${toggleCompany}/`
      );
      setStockItemList(res.data);
      console.log(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }

  };



  const [empItemList, setEmpItemList] = useState([])
  const getEmployeeItemsList = async (itemId) => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/stationary-employee-details-filter/${toggleCompany}/`
      );
      setEmpItemList(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  const [selectedName, setName] = useState("");
  const [fromdate, setFromDate] = useState("null");
  const [todate, setToDate] = useState("null");
  const [filteredList, setFilteredList] = useState([]);
  const [selectedDep, setDep] = useState("");
  const [selectedDesi, setDesi] = useState("");
  const [designationname, setDesignationname] = useState([]);
  const [departmentname, setDepartmentname] = useState([]);
  const [active, setActive] = useState("active");

  useEffect(() => {
    getStockList();
    getEmployeeItemsList();
  }, [fromdate, todate, toggleCompany, active]);

  const getDesignation = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/wfm/designation/`);
      const ress = await axios.get(`${BASE_URL}/wfm/department/`);

      setDesignationname(res.data);
      setDepartmentname(ress.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getDesignation();
  }, []);

  const applyFilters = () => {
    let filteredData = empItemList;


    if (selectedName) {
      const lowercaseSelectedName = selectedName.toLowerCase();
      filteredData = filteredData.filter((data) => {
        const nameMatch = data.employee.toLowerCase().includes(lowercaseSelectedName);
        const itemMatch = data.stat_inventory_details.toLowerCase().includes(lowercaseSelectedName);
        return nameMatch || itemMatch;
      });
    }


    setFilteredList(filteredData);

  };

  useEffect(() => {
    applyFilters();
  }, [empItemList, selectedName]);



  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"> </div>
        <div className="attendance-subcont">
          <div className="field-cont">

            {PERMISSION_AUTHORITY
              ?
              <div title="Company Filter" className="field-cont-div">
                <Office />
                <select
                  onChange={(e) => setToggleCompany(e.target.value)}
                  value={toggleCompany}
                  className="attendance-input-field width-10vw   date-field"
                // className="dropdown font-size-subheading  "
                >
                  <option value="null">Select Sub Company</option>
                  {SUB_COMPANIES.map((company) => (
                    <option value={company.id}>{company.title}</option>
                  ))}
                  ;
                </select>

                <hr className="field-cont-hr" />
              </div> : null}

            <div title="From Date Filter" className="field-cont-div">
              <div className="  date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={fromdate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>

              <hr className="field-cont-hr" />
            </div>

            <div title="To Date Filter" className="field-cont-div">
              <div className="  date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={todate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />
            </div>



            <div title="Search Filter" className="field-cont-div">
              <SearchBig />
              <input
                className="attendance-input-field width-10vw  "
                placeholder="Search"
                type="text"
                value={selectedName}
                onChange={(e) => setName(e.target.value)}
              />

              <hr className="field-cont-hr" />
            </div>

          </div>
          <div className="btn-cont">
            <DownloadTableExcel
              filename="Managed-Stationary-Details"
              sheet="Employee-Issued-Items"
              currentTableRef={tableRef.current}
            >
              <button title='Download as Excel Sheet' className=" model-button-print">
                <DownloadIcon />
                {/* Download as Excel */}
              </button>
            </DownloadTableExcel>
          </div>
        </div>
        <div className="table-css-white-background">
          <div className="table-heading-flex field-cont-between">
            <div className="repo-heading font-weight500    font-size-heading">
              Employees Stationary List <br />
            </div>
            {/* <>Add/give stationary Button</> */}
            <GiveEmployeeStationary getEmployeeItemsList={getEmployeeItemsList} getStockList={getStockList} stockItemList={stockItemList} subCompanyList={SUB_COMPANIES} />
          </div>
          <table ref={tableRef} className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="align-left">Sr. No.</th>
                <th className="align-center">Employee</th>
                <th className="align-center">Issued Date</th>
                <th className="align-center">Item</th>
                <th className="align-center">Quantity</th>
                {PERMISSION_AUTHORITY ? (
                  <th className="align-center width-5vw">Actions</th>
                ) : null}
              </tr>
            </thead>
            {buffer ? <div className="spinner"></div> : //buffering logic
              <tbody>
                {filteredList
                  ?.sort((a, b) => new Date(b.recevied_date) - new Date(a.recevied_date))
                  .map((i, index) => (
                    <React.Fragment key={index}>
                      <tr className="custom-table-head-td">
                        <td className="align-left">{index + 1}</td>
                        <td className="align-center">{i.employee}</td>
                        <td className="align-center">{formattedDateLong(i.recevied_date)}</td>
                        {/* <td className="align-center">{i.stat_inventory_details}</td> */}
                        <td className="align-center">{i.stat_inventory_details.item_name}</td>
                        <td className="align-center">{i.quantity}</td>
                        {PERMISSION_AUTHORITY ? (
                          <td className="align-right flex-row">
                            <UpdateEmployeeStationary i={i} getEmployeeItemsList={getEmployeeItemsList} stockItemList={stockItemList} />
                            <DeleteEmployeeStationary i={i} getEmployeeItemsList={getEmployeeItemsList} />
                          </td>
                        ) : null}

                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            }
          </table>
        </div>
      </div>
    </>
  );
};
export {
  ItemListTable,
  StockListTable,
  StockToEmployeeList,
  EmployeeItemsList
};
