
import React, { useEffect, useState } from 'react';
import { useCalendar } from './CalenderContext';
import CreateEventModal from './CreateEventModal';
import { ViewMeetingModal } from './CreateMeetingModal';

const MonthView = () => {
  const {
    currentMonth,
    handleDayClick,
    events,
    showModal,
    setShowModal,
    selectedTimeSlot,
    selectedDate,
    modaltoogleOption,
    handlechooseToogleoption,
    timeRange,
    handleTimeRangeChange,
    timeRangeOptions,
    formatTime,
    meetingType,
    handleSelectMeetingType,
    showModalmeeting,
    closeMeetingModal,
    getMeetingData,
    meetingData,
    timeSlots,
  } = useCalendar();

  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const today = new Date();
  const todayDate = today.getDate();
  const todayMonth = today.getMonth();
  const todayYear = today.getFullYear();
  const [showViewmeetingModal, setViewMeetingModal] = useState(false)
  const [eventId, setEventid] = useState(false)
  const handleShow = (id) => {
    setViewMeetingModal(true)
    setEventid(id)
  }

  const handleclose = () => {
    setViewMeetingModal(false)
  }
  return (
    <div className="calendar-grid">

      {daysOfWeek.map((dayName, idx) => (
        <div key={idx} className="day-name">
          {dayName}
        </div>
      ))}


      {currentMonth.map((row, i) => (
        <React.Fragment key={i}>
          {row.map((day, idx) => {
            const dayEvents = day
              ? meetingData.length > 0 && meetingData?.filter((event) => {

                const isSameDay = new Date(event.meeting_date).getDate() === day.getDate() &&
                  new Date(event.meeting_date).getMonth() === day.getMonth() &&
                  new Date(event.meeting_date).getFullYear() === day.getFullYear();



                return isSameDay;
              })
              : [];

            // console.log('Filtered Day Events:', dayEvents);

            // console.log(dayEvents, 'dayevents')
            const isToday =
              day &&
              day.getDate() === todayDate &&
              day.getMonth() === todayMonth &&
              day.getFullYear() === todayYear;

            const isSelected =
              day && selectedDate && day.getDate() === selectedDate.getDate() &&
              day.getMonth() === selectedDate.getMonth() &&
              day.getFullYear() === selectedDate.getFullYear();

            const hasEvent = dayEvents.length > 0;

            return (
              <button
                key={idx}
                className="day-button"

                onClick={() => (handleDayClick(day))

                }
              >
                <span className={`day-number   ${isToday ? "today" : ""} 
                  ${isSelected ? "selected" : ""}`}>{day ? day.getDate() : ""}</span>

                {hasEvent && (
                  <span >
                    <ViewMeetingModal
                      showViewmeetingModal={showViewmeetingModal}
                      handleShow={handleShow}
                      handleclose={handleclose}
                      meetingData={dayEvents}
                      id={eventId}

                      view="month"
                    />
                  </span>
                )}

              </button>
            );
          })}
        </React.Fragment>
      ))}

      <CreateEventModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        selectedTime={selectedTimeSlot}
        selectedDate={selectedDate}
        modaltoogleOption={modaltoogleOption}
        handlechooseToogleoption={handlechooseToogleoption}
        timeRange={timeRange}
        timeRangeOptions={timeRangeOptions}
        handleTimeRangeChange={handleTimeRangeChange}
        formatTime={formatTime}
        meetingType={meetingType}
        handleSelectMeetingType={handleSelectMeetingType}
        showModalmeeting={showModalmeeting}
        closeMeetingModal={closeMeetingModal}
        getMeetingData={getMeetingData}
        timeSlots={timeSlots}
      />
    </div>
  );
};

export default MonthView;
