// import React from 'react'
import Logo from '../LetterHead/frame-10000027081.png'
import Signature from '../LetterHead/Picture 1.png'

import GengLogo from '../LetterHead/g-eng.png'
import GengSignature from '../LetterHead/g-eng_signature.png'

import { BASE_URL, FRONTEND_URL } from "../../config/axios";
// import React, { useEffect, useState } from "react";
import usePermission from '../../config/permissions';
import axios from "axios";


// import { BASE_URL } from "../../config/axios";
import React, { useEffect, useState } from "react";
// import axios from "axios";
import { formattedDate, formattedDateLong } from '../Date';
const Lettertermination = ({ lno, empcode }) => {

  const { client } = usePermission();
  let clientCompanyData = {
    company_logo: "https://cipl-aimantra.s3.ap-south-1.amazonaws.com/Logos/Cipl+Logo++(1).png",
    company_name: "Civilmantra infracon private limited",
    address_p1: "3rd Floor, Tower 3A,",
    address_p2: "DLF Corporate Greens, Sector 74A,",
    address_p3: "Gurugram, Haryana 122004",
    email: "services@civilmantra.com",
    signature_name: "CivilMatra Infracon Pvt. Ltd.",
    signature: Signature,
  }

  // switch (client) {
  switch (sessionStorage.getItem('company')) {
    // case "cipl":
    case "Civilmantra":
      clientCompanyData = {
        company_logo: "https://cipl-aimantra.s3.ap-south-1.amazonaws.com/Logos/Cipl+Logo++(1).png",
        company_name: "Civilmantra infracon private limited",
        address_p1: "3rd Floor, Tower 3A,",
        address_p2: "DLF Corporate Greens, Sector 74A,",
        address_p3: "Gurugram, Haryana 122004",
        email: "services@civilmantra.com",
        signature_name: "CivilMatra Infracon Pvt. Ltd.",
        signature: Signature,
      }
      break;
    // case "g-eng":
    case "Saptagon":
      clientCompanyData = {
        company_logo: GengLogo,
        company_name: "G-Eng Advisory Services private limited",
        address_p1: "SCO-102, Sector-55,",
        address_p2: "HUDA Shopping Center,",
        address_p3: "Gurugram, Haryana 122011",
        email: "info@g-eng.in",
        signature_name: "G-Eng Advisory Services Pvt. Ltd.",
        signature: GengSignature,
      }
      break;

    case "Aimantra":
      clientCompanyData = {
        company_logo: "https://g-eng.s3.ap-south-1.amazonaws.com/template-img/%60logo.png",
        company_name: "Aimantra",
        address_p1: "3rd Floor, Tower 3A,",
        address_p2: "DLF Corporate Greens, Sector 74A,",
        address_p3: "Gurugram, Haryana 122004",
        email: "services@aimantra.co",
        signature_name: "AiMantra",
        signature: Signature,
      }
      break;

    default:
      // clientCompanyData = {
      //   company_logo: GengLogo,
      //   company_name: "G-Eng Advisory Services private limited",
      //   address_p1: "SCO-102, Sector-55,",
      //   address_p2: "HUDA Shopping Center,",
      //   address_p3: "Gurugram, Haryana 122011",
      //   email: "info@g-eng.in",
      //   signature_name: "G-Eng Advisory Services Pvt. Ltd.",
      //   signature: GengSignature,
      // }
      clientCompanyData = {
        company_logo: "",
        company_name: "",
        address_p1: "",
        address_p2: "",
        address_p3: "",
        email: "",
        signature_name: "",
        signature: "",
      }
      break;
  }

  const [letterData, setLetterData] = useState({})
  const [personalData, setPersonalData] = useState({})

  const date = formattedDate

  const getLetterData = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/incrementbyemployee/${empcode}`);
      const ress = await axios.get(`${BASE_URL}/wfm/ourcompanydetailsbyemp/${empcode}/`);

      setLetterData(res.data[lno])
      setPersonalData(ress.data)

    } catch (err) {
    }
  };

  // console.log(letterData)


  useEffect(() => {
    getLetterData();

  }, []);

  const dojdate = personalData?.joining_date

  const doj_date = dojdate?.split("-")
  return (
    <>
      <div className='letter-parent'>
        <div className='letterslip'>
          <div className='salaryslip-header'>
            <div className='salaryslip-header-left'>
              <img src={`${clientCompanyData.company_logo}`} alt='logo' className='salaryslip-logo' />
              {/* <div className='salaryslip-header-left-text  font-size-heading'>
              SALARY SLIP FOR THE MONTH OF JUNE 2023
            </div> */}
            </div>
            <div className='salaryslip-header-right'>
              {/* <div className={`${client === "cipl" ? 'salaryslip-header-right-top-red' : 'salaryslip-header-right-top-green'} font-weight600 font-size-heading`}> */}
              <div className={`${sessionStorage.getItem('company') === "Civilmantra" ? 'salaryslip-header-right-top-red' : 'salaryslip-header-right-top-red'} font-weight600 font-size-heading`}>
                {clientCompanyData.company_name}
              </div>
              <div className='salaryslip-header-right-bottom  font-weight400  font-size-subheading'>
                {clientCompanyData.address_p1}<br /> {clientCompanyData.address_p2}<br /> {clientCompanyData.address_p3} <br /> {clientCompanyData.email}
              </div>
            </div>
          </div>
          <div className='salaryslip-hr'></div>

          <b>To</b>,  <span style={{ float: 'right' }}>Date – {formattedDateLong(date)}</span>
          <br />
          <b>{personalData.name}</b>
          <br />
          <b>Emp Code - </b>{personalData.emp_code}
          <br />
          <b> Department - </b>{personalData.department_name}
          <br />
          <b>Designation - </b>{letterData.old_designation}
          <br />
          <b>Grade – </b>{letterData.previous_grade}
          <br />
          <b>D.O.J. – </b>{formattedDateLong(personalData.joining_date)}
          <br />
          <br />
          <b>Subject: </b>Termination of Employment
          <br />


          <br />
          <b>Dear {personalData.name},</b>

          <br />
          <br />
          It is with regret that we inform you that your employment with <b>[Company Name]</b> will be terminated, effective from <b>[Termination Date]</b>.

          <br />
          <br />
          This decision has been made due to <b>[Reason for Termination]</b>, despite previous discussions and attempts to resolve the matter.

          {/* <br />
        <br />
        We are pleased to inform you that you have been promoted from the Designation {personalData.designation_name} to<b> {(letterData.updated_designation) ? letterData.updated_designation : "-"} </b>in accordance to which your grade is promoted from {letterData.previous_grade} to<b> {letterData.update_grade}</b> at CivilMantra Infracon Pvt. Ltd. We congratulate you for your achievement. */}

          <br />
          <br />
          We understand that this news may be difficult to process, and we want to assure you that this decision has been made after careful consideration. We value the contributions you have made to the company, and this action is in no way a reflection of your overall capabilities or efforts. However, the circumstances have led us to this unfortunate decision.


          <br />
          <br />
          As part of your termination process, we will provide you with a detailed breakdown of your final settlement, which will include any pending dues, benefits, and the procedures for the return of company property, if applicable.

          <br />
          <br />
          Should you require any assistance during this transition period, please feel free to reach out to the HR department. We are here to support you in every possible way.

          <br />
          <br />
          We wish you all the best in your future endeavors, and we hope that you find success and fulfillment in your next role.

          <br />

          <div className='salaryslip-hr'></div>
          <br />
          <th>For {clientCompanyData.signature_name}</th>
          <div className='salaryslip-bottom'>
            <img src={clientCompanyData.signature} alt='signature' className='slaryslip-bg-signature' />
            <th>AUTHORISED SIGNATORY</th>
          </div>
        </div>
        {/* <div className={`${client === "cipl" ? 'slaryslip-bg' : 'slaryslip-bg-geng'}`}> */}
        <div className={`${sessionStorage.getItem('company') === "Civilmantra"
          ? 'slaryslip-bg'
          : sessionStorage.getItem('company') === "Aimantra"
            ? 'aimantra-bg-letter'
            : 'slaryslip-bg-geng'}`}>
        </div>
      </div>
    </>
  )
}

export default Lettertermination
