import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Dashboardnavbarcopy from "../../layout/Dashboardnavbarcopy";
import { AdvanceFormsTableAcc } from "../AdvanceManagement/AdvanceTables";
import { SeprationFormsTableAcc } from "../SeprationManagement/SeprationTables";

const AccountsRequestMangement = () => {
    const location = useLocation();
    const accfirsttoggle = location.state !== null ? location.state?.accfirsttoggle : 1;
    const [toggleState, setToggleState] = useState(accfirsttoggle);
    const getSelectedTitle = () => {
        switch (toggleState) {
            case 1:
                return "Advance Requests";
            case 2:
                return "Sepration Requests";
            default:
                return "Accounts Requests Management";
        }
    };

    return (
        <>
            <div>
                <Dashboardnavbarcopy name={getSelectedTitle()} url="Accounts Request Management" />
                <div className="bloc-tabs-with-white-bg content-tabs">
                    <button
                        className={toggleState === 1 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                        onClick={() => setToggleState(1)}
                    >
                        Advance Requests
                    </button>
                    <button
                        className={toggleState === 2 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                        onClick={() => setToggleState(2)}
                    >
                        Sepration Requests
                    </button>
                </div>
            </div>
            <div className="content-tabs">
                {toggleState === 1 ? <AdvanceFormsTableAcc /> : null}
                {toggleState === 2 ? <SeprationFormsTableAcc /> : null}
            </div>
        </>
    )
}

export {
    AccountsRequestMangement,
};
