import React, { useState } from "react";
import Applymodels from "../components/settings/model/Applymodels";
import { ToastContainer, toast } from 'react-toastify';

import { BASE_URL, FRONTEND_URL, IMAGE_URL } from "../config/axios";
import { NotificationToast, NotificationModal } from "../components/Alerts/NotificationComponents";
import { useNavigate } from "react-router-dom";
import { ProfileProgressModal } from "../components/UserDetails/ProfileProgressComponent";

const Dashboardnavbarcopy = ({ name, url }) => {

  return (
    // <div className="navbar-section-main scroll-fix-table-heading">
    <div className="navbar-section-main">
      <div className="navbar-margin-left">
        <div className="navbar-subheading font-weight500 font-size-text">Pages / {url}</div>
        <div className="navbar-heading font-weight700 ">{name}</div>
      </div>
      <div className="navbar-subsection navbar-margin-right">
        <div className="applymodel">
          <Applymodels />
        </div>
        <div className="navbar-subsection-b">
          <div className="navbar-search">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="12"
                viewBox="0 0 11 12"
                fill="none"
              >
                <circle
                  cx="5"
                  cy="5"
                  r="4.3"
                  stroke="#2B3674"
                  strokeWidth="1.4"
                />
                <line
                  x1="10.0101"
                  y1="11"
                  x2="8"
                  y2="8.98995"
                  stroke="#2B3674"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                />
              </svg>
            </div>
            <div>
              <input placeholder="Search" className="navbar-input font-weight400 font-size-text " />
            </div>
          </div>
          <NotificationModal />
          <div className="navbar-logo-mobile">
            {/* <div title="Notification" className="navbar-svg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clipPath="url(#clip0_1297_50061)">
                  <path
                    d="M19.2905 17.29L18.0005 16V11C18.0005 7.93 16.3605 5.36 13.5005 4.68V4C13.5005 3.17 12.8305 2.5 12.0005 2.5C11.1705 2.5 10.5005 3.17 10.5005 4V4.68C7.63054 5.36 6.00054 7.92 6.00054 11V16L4.71054 17.29C4.08054 17.92 4.52054 19 5.41054 19H18.5805C19.4805 19 19.9205 17.92 19.2905 17.29ZM16.0005 17H8.00054V11C8.00054 8.52 9.51054 6.5 12.0005 6.5C14.4905 6.5 16.0005 8.52 16.0005 11V17ZM12.0005 22C13.1005 22 14.0005 21.1 14.0005 20H10.0005C10.0005 21.1 10.8905 22 12.0005 22Z"
                    fill="#A3AED0"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1297_50061">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div> */}


            {/* <div className="navbar-svg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                className="bi bi-box-arrow-right"
                fill="none"
              >
                <g clipPath="url(#clip0_1297_50059)">
                  <path
                    d="M9.95703 18C12.733 18 15.2684 16.737 16.9481 14.6675C17.1966 14.3613 16.9256 13.9141 16.5416 13.9872C12.1751 14.8188 8.16522 11.4709 8.16522 7.06303C8.16522 4.52398 9.52444 2.18914 11.7335 0.931992C12.074 0.738211 11.9884 0.221941 11.6015 0.150469C11.059 0.0504468 10.5086 8.21369e-05 9.95703 0C4.98914 0 0.957031 4.02578 0.957031 9C0.957031 13.9679 4.98281 18 9.95703 18Z"
                    fill="#A3AED0"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1297_50059">
                    <rect width="18" height="18" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div> */}

            <button
              title="logout"
              className="navbar-svg"
              onClick={() => {
                toast.info('Loging Out...');
                sessionStorage.clear();
                window.location = FRONTEND_URL;
              }}>
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-box-arrow-right"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
                  fill="#A3AED0"
                />
                <path
                  fillRule="evenodd"
                  d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
                  fill="#A3AED0"
                />
              </svg>
            </button>
            <>
              <ProfileProgressModal />
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboardnavbarcopy;