import React, { useEffect, useState } from "react";
import Select from "react-select";
import { BASE_URL, IMAGE_URL } from "../../config/axios";
import { formatDate, formatDateTime, formattedDateLong } from "../Date";
import { customSortByKey, formatCurrencyIndian, formatRoundoff2D, handleAllError } from "../CustomFunctions";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { AddwithBlueCircle } from "../AllSvg";
import { ToastContainer, toast } from "react-toastify";

const AddProjectFromImprest = ({ i, departmentLists, getFilterProjects, setFormData, setInputState, setNewProject }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => {
        setShow(true)
    };

    const [loading, setLoading] = useState(false); //loading logic
    const handleSubmit = async (e) => {
        e.preventDefault();
        const updatedformvalue = {
            // ...formData,
            project_code: i.ref_project_code,
            project_name: i.ref_project_name,
            department: i.department,
            sub_company: sessionStorage.getItem('company_id'),
            created_by: sessionStorage.getItem('emp_code'),
            created_at: new Date().toISOString(),
        };
        // console.log("updatedformvalue")
        // console.log(updatedformvalue)
        setLoading(true); //loading logic
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
        try {
            let res = await axios.post(`${BASE_URL}/wfm/projectcode/`, updatedformvalue);

            if (res.status === 200) {
                // Fetch the newly created project details
                const newProjectResponse = await axios.get(`${BASE_URL}/wfm/projectcode/${i.department}/${sessionStorage.getItem("company_id")}/`);
                const newProjectList = newProjectResponse.data || [];

                // Find the newly created project in the updated project list
                const newlyCreatedProject = newProjectList.find(
                    project => project.project_code === i.ref_project_code
                );

                if (newlyCreatedProject) {
                    // Set all four values in formData and inputState
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        project_id: newlyCreatedProject.id,
                        project_name: newlyCreatedProject.project_name,
                        ref_project_code: newlyCreatedProject.project_code,
                        ref_project_name: newlyCreatedProject.project_name,
                    }));

                    setInputState(prevInputState => ({
                        ...prevInputState,
                        project_id: "green",
                        project_name: "green",
                        ref_project_code: "green",
                        ref_project_name: "green",
                    }));
                }

                // Refresh the project list in the outer component
                await getFilterProjects(i.department);
                setNewProject(false)
                // Close the modal
                handleClose();
            } else {
                alert(res);
            }
        } catch (err) {
            handleAllError(err)
        } finally {
            setLoading(false); //loading logic
            toast.dismiss(loadingToastId);
        }
    };
    return (
        <>
            <button type='button' title="Add New Project" className="jd-heading-main font-weight600" onClick={handleShow} >
                <AddwithBlueCircle /> Add The New Project : {i.ref_project_code}-{i.ref_project_name}<span className="required">*</span>
            </button>
            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Project Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <h3 className="task-Tab-heading   font-weight500    font-size-heading">
                                Are You sure you want to add project:
                            </h3>

                            <p>Project Code : {i.ref_project_code}</p>
                            <p>Project Name : {i.ref_project_name}</p>
                            <p>For Department : {departmentLists.find(t => t.id === i.department)?.title || ""}</p>


                            <div className="button-models">
                                <button
                                    className="model-button model-button-cancel font-weight500   "
                                    onClick={handleClose}
                                    disabled={loading}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >

        </>

    );
}

const EmployeeImprestExpenseDashboard = () => {

    const location = useLocation();
    let filters_employee = location.state?.filter_employee !== null ? location.state?.filter_employee : 'null';
    let filters_department = location.state?.filter_department !== null ? location.state?.filter_department : `${sessionStorage.getItem("department")}`;
    let filters_subCompany = location.state?.filter_subCompany !== null ? location.state?.filter_subCompany : `${sessionStorage.getItem("company_id")}`;
    let filters_empstatus = location.state?.filter_empstatus !== null ? location.state?.filter_empstatus : 'active';


    const [selectedEmployee, setSelectedEmployee] = useState(sessionStorage.getItem('emp_code'));
    const [selectedProject, setSelectedProject] = useState(null);
    const [employeeGet, setEmployeeGet] = useState(filters_employee || 'null');
    const [selectedDepartment, setSelectedDepartment] = useState(filters_department || `${sessionStorage.getItem("department")}`);
    const [selectedSubCompany, setSubCompany] = useState(filters_subCompany || `${sessionStorage.getItem("company_id")}`);
    const [active, setActive] = useState(filters_empstatus || 'active');



    const [dates, setDates] = useState(0)
    // setDates(res.data.map(item => item.date).sort((a, b) => new Date(a) - new Date(b)));
    const [buffer, setBuffering] = useState(true); //buffering logic
    const [buffer1, setBuffering1] = useState(true); //buffering logic
    const [imprestExpenseData, setImprestExpenseData] = useState([]);

    const getImprestExpenseData = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            // const res = await axios.get(`${BASE_URL}/wfm/expense/${selectedStatus}/${fromdate}/${todate}/${employeeGet}/`);
            const res = await axios.get(
                `${BASE_URL}/wfm/imprestexpenseadmindashboard/${selectedEmployee}/${selectedDepartment}/${selectedSubCompany}/${active}/`
            );
            setImprestExpenseData(res.data);
        } catch (err) {
            handleAllError(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    useEffect(() => {
        getImprestExpenseData();
    }, [employeeGet, selectedDepartment, selectedSubCompany, active]);


    const [allemployee, setAllemployee] = useState([]);
    const getEmpcode = async () => {
        setBuffering1(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/wfm/ourcompanyempdetails/${active}/${selectedSubCompany}/`
            );
            const sortedList = customSortByKey(res.data, "emp_code");
            setAllemployee(sortedList);
        } catch (err) {
            handleAllError(err);
        } finally {
            setBuffering1(false); //buffering logic // End Buffering
        }
    };

    useEffect(() => {
        getEmpcode();
    }, [active, selectedSubCompany]);

    const options = allemployee
        .sort((a, b) => a.emp_code - b.emp_code)
        .map((i) => ({
            value: i.emp_code,
            label: `${i.emp_code} - ${i.name}`,
        }));

    const nullOption = { value: 'null', label: 'Select Employee Code' };

    const handleChange = (selectedOption) => {
        if (selectedOption) {
            // Set selected employee code(s)
            setEmployeeGet(selectedOption.value);
        } else {
            setEmployeeGet(null); // Clear selection
        }
    };


    return (
        <div className="attendance-history-cont">
            {/* <div className="attendance-subcont">
                <div className="field-cont">

                    <div className="field-cont-div">
                        <Select
                            className="attendance-input-field width-20vw"
                            // options={[{ value: "null", label: "All" }, ...projects]}
                            options={[{ value: "null", label: "All" },]}
                            placeholder="Select Project Code"
                            value={selectedProject}
                            onChange={handleProjectChange}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    color: '#707070',
                                    outline: 'none',
                                    border: 'none',
                                    padding: '0.6rem 0.5rem',
                                    textTransform: 'capitalize',
                                    boxShadow: 'none',
                                    '&:hover': {
                                        border: 'none',
                                    },
                                }),
                                singleValue: (provided) => ({
                                    ...provided,
                                    color: '#707070',
                                }),
                                placeholder: (provided) => ({
                                    ...provided,
                                    color: '#707070',
                                }),
                                dropdownIndicator: (provided) => ({
                                    ...provided,
                                    filter: 'invert(50%)',
                                    cursor: 'pointer',
                                    left: '9.5rem',
                                    width: '20px',
                                    height: '20px',
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999, // Ensure dropdown menu appears above other elements
                                }),
                                option: (provided, state) => ({
                                    ...provided,
                                    color: state.isSelected ? '#2576bc' : '#707070',
                                    backgroundColor: state.isSelected ? 'rgba(37,118,188,0.1)' : 'white',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: '#2576bc',
                                        color: 'white',
                                    },
                                }),
                            }}
                        />
                        <hr className="field-cont-hr" />
                    </div>
                </div>
                <div className="btn-cont"></div>
            </div> */}

            {/* Data Table */}
            <div className="table-css-white-background">
                <table className="table-css">
                    <thead>
                        <tr className="custom-table-head-tr">
                            <th className="table-heading">S. No.</th>
                            <th className="table-heading">Employee Details</th>
                            <th className="table-heading">Project Reference</th>
                            <th className="table-heading text-center">Imprest Details</th>
                            <th className="table-heading">Imprest Amount</th>
                            <th className="table-heading">Imprest Received Date</th>
                            <th className="table-heading">Imprest Approval Status</th>
                            <th className="table-heading text-center">Expense Details</th>
                            <th className="table-heading text-center">Expense Amount</th>
                            <th className="table-heading">Expense Bills Date Range</th>
                            <th className="table-heading">Expense Approval Status</th>
                        </tr>
                    </thead>
                    {buffer ? <div className="spinner"></div> : //buffering logic
                        <tbody>
                            {imprestExpenseData?.map((item, index) => {
                                const expenseData = item?.expense_data?.length > 0 ? item.expense_data[0] : null;

                                // Check if expense details exist before mapping
                                const DatesList = expenseData?.expense_details?.length > 0
                                    ? expenseData.expense_details.map(exp => exp.date).sort((a, b) => new Date(a) - new Date(b))
                                    : [];

                                const FirstBillDate = DatesList.length === 0 ? '"Details Pending"' : formatDate(DatesList[0]);
                                const LastBillDate = DatesList.length === 0 ? '"Details Pending"' : formatDate(DatesList[DatesList.length - 1]);

                                console.log("DatesList")
                                console.log(DatesList)
                                return (

                                    <tr className="custom-table-head-td" key={index}>
                                        <td className="table-body">{index + 1}</td>
                                        <td className="table-body" style={{ textAlign: "center" }}>
                                            <img
                                                className="profile-img"
                                                src={
                                                    `${item.profilepic}`
                                                        ? `${IMAGE_URL}${item.profilepic}`
                                                        : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                                }
                                                alt="profilepic"
                                            />
                                            <p>{item.empcode}-{item.emp_name}</p>
                                        </td>
                                        <td className="table-body">
                                            {item.project_id ?
                                                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                                    <tbody>
                                                        <tr>
                                                            <td className="table-body" style={{ paddingBottom: "5px" }}>Project Code</td>
                                                            <td style={{ padding: "0px 4px" }}>:</td>
                                                            <td className="align-right">
                                                                {item.ref_project_code}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="table-body" style={{ paddingBottom: "5px" }}>Project Name</td>
                                                            <td style={{ padding: "0px 4px" }}>:</td>
                                                            <td className="align-right">
                                                                {item.ref_project_name}
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table> :
                                                <div className="justify-center">
                                                    - No Project Referenced -
                                                </div>
                                            }
                                        </td>
                                        <td className="table-body">
                                            <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                                <tbody>
                                                    <tr>
                                                        <td className="table-body" style={{ paddingBottom: "5px" }}>Imprest Name</td>
                                                        <td style={{ padding: "0px 4px" }}>:</td>
                                                        <td className="align-right">
                                                            {item.project_name}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="table-body" style={{ paddingBottom: "5px" }}>Imprest Date</td>
                                                        <td style={{ padding: "0px 4px" }}>:</td>
                                                        <td className="align-right">
                                                            {formatDate(item.date)}
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </td>
                                        {item.rh_leave_status === "approved" ?
                                            <td className="table-body">
                                                <td className="align-right">
                                                    {/* <b> */}
                                                    {formatCurrencyIndian(
                                                        Number(item.approved_transportation) +
                                                        Number(item.approved_accomodation) +
                                                        Number(item.approved_fooding) +
                                                        (item.other_details?.length > 0
                                                            ? item.other_details.reduce(
                                                                (total, other) =>
                                                                    total + Number(other.approved_other || 0),
                                                                0
                                                            )
                                                            : 0)
                                                    )}
                                                    {" "}
                                                    Rs.
                                                    {/* </b> */}
                                                </td>
                                            </td>
                                            :
                                            <td className="table-body">
                                                <td className="align-right">
                                                    {/* <b> */}
                                                    {formatCurrencyIndian(
                                                        Number(item.transportation) +
                                                        Number(item.accomodation) +
                                                        Number(item.fooding) +
                                                        (item.other_details?.length > 0
                                                            ? item.other_details.reduce((total, other) => total + Number(other.amount || 0), 0)
                                                            : 0)
                                                    )}
                                                    {" "}
                                                    Rs.
                                                    {/* </b>  */}
                                                </td>
                                            </td>}
                                        <td className="table-body">
                                            {item.account_update_datetime ? <>At: {formatDateTime(item.account_update_datetime).date}</> : null}</td>
                                        <td title="Imprest Approval Status" className="table-body width-10vw">
                                            <span
                                                request-status={item.rh_leave_status}
                                                className="request-status "
                                            >
                                                {item.rh_leave_status === "approved" ?
                                                    <>
                                                        Rh Approved :
                                                        {item.rh_assigned}- {item.rh_assigned_name}
                                                        {item.rh_update_datetime ? <><br />At: {formatDateTime(item.rh_update_datetime).date}</> : ''}
                                                    </>
                                                    : item.rh_leave_status === "rejected"
                                                        ? <>Rh ✖,<br />Rh Reject Reason:<br />"{item.rejection_reason}"</>
                                                        : "Pending For Rh Approval ⌛"}
                                            </span>
                                            {/* <br /> */}
                                            <br />
                                            <span
                                                request-status={item.admin_leave_status}
                                                className="request-status "
                                            >
                                                {item.admin_leave_status === "approved" ?
                                                    <>
                                                        Admin {item.admin_update_by ? `(${item.admin_update_by})` : ''} Approved
                                                        {item.admin_update_datetime ? <><br />At: {formatDateTime(item.admin_update_datetime).date}</> : ''}
                                                    </>
                                                    : item.admin_leave_status === "rejected"
                                                        ? <>Admin ✖,<br />Admin Reject Reason: "{item.admin_rejection_reason}"</>
                                                        : "Pending For Admin Approval ⌛"}
                                            </span>
                                            {/* <br /> */}
                                            <br />
                                            <span
                                                request-status={item.account_leave_status}
                                                className="request-status "
                                            >
                                                {item.account_leave_status === "approved" ?
                                                    <>
                                                        Accounts{item.account_update_by ? `(${item.account_update_by})` : ''} Approved
                                                        {item.account_update_datetime ? <><br />At: {formatDateTime(item.account_update_datetime).date}</> : ''}
                                                    </>
                                                    : item.account_leave_status === "rejected"
                                                        ? <>Accounts ✖,<br />Accounts Reject Reason:<br />"{item.account_rejection_reason}"</>
                                                        : "Pending For Account Approval ⌛"}
                                            </span>
                                        </td>
                                        {
                                            item.expense_data.length > 0 ?
                                                <>
                                                    <td className="table-body">
                                                        <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="table-body" style={{ paddingBottom: "5px" }}>Expense Name</td>
                                                                    <td style={{ padding: "0px 4px" }}>:</td>
                                                                    <td className="align-right">
                                                                        {item.expense_data[0]?.name}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="table-body" style={{ paddingBottom: "5px" }}>Expense Date</td>
                                                                    <td style={{ padding: "0px 4px" }}>:</td>
                                                                    <td className="align-right">
                                                                        {formatDate(item.expense_data[0]?.date)}
                                                                    </td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    <td className="table-body align-center">{item.expense_data[0]?.utilized_amount ? formatRoundoff2D(item.expense_data[0]?.utilized_amount) : '-'} Rs</td>
                                                    {/* {
                                                        item.submit_for_approval === false ?
                                                            <td className="table-body align-center">
                                                                <span
                                                                    request-status={"pending"}
                                                                    className="request-status"
                                                                >
                                                                    Expense Not Submitted
                                                                </span>
                                                            </td>
                                                            :
                                                            <td className="table-body">
                                                                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className="table-body" style={{ paddingBottom: "5px" }}>Utilized Bills</td>
                                                                            <td style={{ padding: "0px 4px" }}>:</td>
                                                                            <td className="align-right">
                                                                                {item.utilized_amount ? formatRoundoff2D(item.expense_data[0]?.utilized_amount) : '-'} Rs
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="table-body" style={{ paddingBottom: "5px" }}>Remaining Balance</td>
                                                                            <td style={{ padding: "0px 4px" }}>:</td>
                                                                            <td className="align-right">
                                                                                {item.remaining_amount ? formatRoundoff2D(item.expense_data[0]?.remaining_amount) : '-'} Rs
                                                                            </td>
                                                                        </tr>

                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                    } */}
                                                    <td className="table-body">{FirstBillDate} to {LastBillDate}</td>
                                                    <td title="Expense Approval Status" className="table-body">
                                                        <span
                                                            request-status={item.expense_data[0]?.rh_status}
                                                            className="request-status "
                                                        >
                                                            {item.expense_data[0]?.rh_status === "pending"
                                                                ? "Pending For Rh Approval ⌛"
                                                                : item.expense_data[0]?.rh_status === "rejected"
                                                                    ? `Rh Reject Reason : ${item.expense_data[0]?.rh_rejection_reason}`
                                                                    : `Rh${item.expense_data[0]?.rh_assigned ? `(${item.expense_data[0]?.rh_assigned}) ` : ''}: Verified Amounts for Bills ${item.expense_data[0]?.rh_update_datetime ? `At: ${formatDateTime(item.expense_data[0]?.rh_update_datetime).date}` : ''}`}
                                                        </span>
                                                        <br />
                                                        <span
                                                            request-status={item.expense_data[0]?.account_status_a}
                                                            className="request-status "
                                                        >
                                                            {item.expense_data[0]?.account_status_a === "pending"
                                                                ? "Pending For Account Approval ⌛"
                                                                : item.expense_data[0]?.account_status_a === "rejected"
                                                                    ? `Accounts Reject Reason : ${item.expense_data[0]?.account1_rejection_reason}`
                                                                    : `Accounts${item.expense_data[0]?.account_update_by ? `(${item.expense_data[0]?.account_update_by}) ` : ''}: Bills Verified ${item.expense_data[0]?.account_update_datetime ? `At: ${formatDateTime(item.expense_data[0]?.account_update_datetime).date}` : ''}`}
                                                        </span>
                                                        <br />
                                                        <span
                                                            request-status={item.expense_data[0]?.admin_status}
                                                            className="request-status "
                                                            title={item.expense_data[0]?.admin_status === "rejected" ? `Admin Reject Reason : ${item.expense_data[0]?.admin_rejection_reason}` : ""}
                                                        >
                                                            {item.expense_data[0]?.admin_status === "pending"
                                                                ? "Pending For Admin Approval ⌛"
                                                                : item.expense_data[0]?.admin_status === "rejected"
                                                                    ? `Admin Rejection Reason : ${item.expense_data[0]?.admin_rejection_reason}`
                                                                    : `Admin${item.expense_data[0]?.admin_update_by ? `(${item.expense_data[0]?.admin_update_by}) ` : ''}: Approved After Final Verification  ${item.expense_data[0]?.admin_update_datetime ? `At: ${formatDateTime(item.expense_data[0]?.admin_update_datetime).date}` : ''}`}
                                                        </span>
                                                        <br />
                                                        <span
                                                            request-status={item.expense_data[0]?.account_status_b}
                                                            className="request-status "
                                                        >
                                                            {item.expense_data[0]?.account_status_b === "pending"
                                                                ? "Final Stage Pending⌛"
                                                                : item.expense_data[0]?.account_status_b === "rejected"
                                                                    ? `Final Rejection Reason : ${item.expense_data[0]?.account2_rejection_reason}`
                                                                    : `Final${item.expense_data[0]?.final_update_by ? `(${item.expense_data[0]?.final_update_by}) ` : ''}: Approved And Settled ${item.expense_data[0]?.final_update_datetime ? `At: ${formatDateTime(item.expense_data[0]?.final_update_datetime).date}` : ''}`}
                                                        </span>
                                                    </td>
                                                </> :
                                                <td colSpan={4} className="table-body align-center">- No Expense Details Updated Yet -</td>

                                        }

                                    </tr>
                                )
                            }
                            )}
                        </tbody>}
                </table>
            </div>
        </div>
    );
};


const ImprestExpenseDashboard = () => {

    const location = useLocation();
    let filters_employee = location.state?.filter_employee !== null ? location.state?.filter_employee : 'null';
    let filters_department = location.state?.filter_department !== null ? location.state?.filter_department : `${sessionStorage.getItem("department")}`;
    let filters_subCompany = location.state?.filter_subCompany !== null ? location.state?.filter_subCompany : `${sessionStorage.getItem("company_id")}`;
    let filters_empstatus = location.state?.filter_empstatus !== null ? location.state?.filter_empstatus : 'active';


    const [selectedEmployee, setSelectedEmployee] = useState(sessionStorage.getItem('emp_code'));
    const [selectedProject, setSelectedProject] = useState(null);
    const [employeeGet, setEmployeeGet] = useState(filters_employee || 'null');
    const [selectedDepartment, setSelectedDepartment] = useState(filters_department || `${sessionStorage.getItem("department")}`);
    const [selectedSubCompany, setSubCompany] = useState(filters_subCompany || `${sessionStorage.getItem("company_id")}`);
    const [active, setActive] = useState(filters_empstatus || 'active');



    const [dates, setDates] = useState(0)
    // setDates(res.data.map(item => item.date).sort((a, b) => new Date(a) - new Date(b)));
    const [buffer, setBuffering] = useState(true); //buffering logic
    const [buffer1, setBuffering1] = useState(true); //buffering logic
    const [imprestExpenseData, setImprestExpenseData] = useState([]);

    const getImprestExpenseData = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            // const res = await axios.get(`${BASE_URL}/wfm/expense/${selectedStatus}/${fromdate}/${todate}/${employeeGet}/`);
            const res = await axios.get(
                `${BASE_URL}/wfm/imprestexpenseadmindashboard/${employeeGet}/${selectedDepartment}/${selectedSubCompany}/${active}/`
            );
            setImprestExpenseData(res.data);
        } catch (err) {
            handleAllError(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    useEffect(() => {
        getImprestExpenseData();
    }, [employeeGet, selectedDepartment, selectedSubCompany, active]);


    const [allemployee, setAllemployee] = useState([]);
    const getEmpcode = async () => {
        setBuffering1(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/wfm/ourcompanyempdetails/${active}/${selectedSubCompany}/`
            );
            const sortedList = customSortByKey(res.data, "emp_code");
            setAllemployee(sortedList);
        } catch (err) {
            handleAllError(err);
        } finally {
            setBuffering1(false); //buffering logic // End Buffering
        }
    };

    useEffect(() => {
        getEmpcode();
    }, [active, selectedSubCompany]);

    const options = allemployee
        .sort((a, b) => a.emp_code - b.emp_code)
        .map((i) => ({
            value: i.emp_code,
            label: `${i.emp_code} - ${i.name}`,
        }));

    const nullOption = { value: 'null', label: 'Select Employee Code' };

    const handleChange = (selectedOption) => {
        if (selectedOption) {
            // Set selected employee code(s)
            setEmployeeGet(selectedOption.value);
        } else {
            setEmployeeGet(null); // Clear selection
        }
    };


    return (
        <div className="attendance-history-cont">
            <div className="attendance-subcont">
                <div className="field-cont">
                    <div className="field-cont-div">
                        {/* <Employee /> */}
                        {buffer1 ?
                            <div className="align-center form-loader">
                                <div className="bar-loader"></div>
                            </div>
                            :
                            <>
                                Employee:
                                <Select
                                    className="attendance-input-field width-15vw"
                                    width="200px"
                                    options={[nullOption, ...options]} // Include "null" option at the beginning
                                    value={options.find(option => option.value === employeeGet) || nullOption}  // Ensure the correct option is selected
                                    placeholder="Select Employee Code"
                                    onChange={handleChange}
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            color: '#707070',
                                            outline: 'none',
                                            border: 'none',
                                            padding: '0.6rem 0.5rem',
                                            textTransform: 'capitalize',
                                            boxShadow: 'none',
                                            '&:hover': {
                                                border: 'none',
                                            },
                                        }),
                                        singleValue: (provided) => ({
                                            ...provided,
                                            color: '#707070',
                                        }),
                                        placeholder: (provided) => ({
                                            ...provided,
                                            color: '#707070',
                                        }),
                                        dropdownIndicator: (provided) => ({
                                            ...provided,
                                            filter: 'invert(50%)',
                                            cursor: 'pointer',
                                            left: '9.5rem',
                                            width: '20px',
                                            height: '20px',
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            zIndex: 9999, // Ensure dropdown menu appears above other elements
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            color: state.isSelected ? '#2576bc' : '#707070',
                                            backgroundColor: state.isSelected ? 'rgba(37,118,188,0.1)' : 'white',
                                            cursor: 'pointer',
                                            '&:hover': {
                                                backgroundColor: '#2576bc',
                                                color: 'white',
                                            },
                                        }),
                                    }}
                                />
                            </>}
                        <hr className="field-cont-hr" />
                    </div>


                    {/* Project Code Filter */}
                    {/* <div className="field-cont-div">
                        <Select
                            className="attendance-input-field width-20vw"
                            // options={[{ value: "null", label: "All" }, ...projects]}
                            options={[{ value: "null", label: "All" },]}
                            placeholder="Select Project Code"
                            value={selectedProject}
                            onChange={handleProjectChange}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    color: '#707070',
                                    outline: 'none',
                                    border: 'none',
                                    padding: '0.6rem 0.5rem',
                                    textTransform: 'capitalize',
                                    boxShadow: 'none',
                                    '&:hover': {
                                        border: 'none',
                                    },
                                }),
                                singleValue: (provided) => ({
                                    ...provided,
                                    color: '#707070',
                                }),
                                placeholder: (provided) => ({
                                    ...provided,
                                    color: '#707070',
                                }),
                                dropdownIndicator: (provided) => ({
                                    ...provided,
                                    filter: 'invert(50%)',
                                    cursor: 'pointer',
                                    left: '9.5rem',
                                    width: '20px',
                                    height: '20px',
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999, // Ensure dropdown menu appears above other elements
                                }),
                                option: (provided, state) => ({
                                    ...provided,
                                    color: state.isSelected ? '#2576bc' : '#707070',
                                    backgroundColor: state.isSelected ? 'rgba(37,118,188,0.1)' : 'white',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: '#2576bc',
                                        color: 'white',
                                    },
                                }),
                            }}
                        />
                        <hr className="field-cont-hr" />
                    </div> */}
                </div>
                <div className="btn-cont"></div>
            </div>

            {/* Data Table */}
            <div className="table-css-white-background">
                <table className="table-css">
                    <thead>
                        <tr className="custom-table-head-tr">
                            <th className="table-heading">S. No.</th>
                            <th className="table-heading">Employee Details</th>
                            <th className="table-heading">Project Reference</th>
                            <th className="table-heading text-center">Imprest Details</th>
                            <th className="table-heading">Imprest Amount</th>
                            <th className="table-heading">Imprest Received Date</th>
                            <th className="table-heading">Imprest Approval Status</th>
                            <th className="table-heading">Expense Details</th>
                            <th className="table-heading">Expense Amount</th>
                            <th className="table-heading">Expense Bills Date Range</th>
                            <th className="table-heading">Expense Approval Status</th>
                        </tr>
                    </thead>
                    {buffer ? <div className="spinner"></div> : //buffering logic
                        <tbody>
                            {imprestExpenseData?.map((item, index) => {
                                const expenseData = item?.expense_data?.length > 0 ? item.expense_data[0] : null;

                                // Check if expense details exist before mapping
                                const DatesList = expenseData?.expense_details?.length > 0
                                    ? expenseData.expense_details.map(exp => exp.date).sort((a, b) => new Date(a) - new Date(b))
                                    : [];

                                const FirstBillDate = DatesList.length === 0 ? '"Details Pending"' : formatDate(DatesList[0]);
                                const LastBillDate = DatesList.length === 0 ? '"Details Pending"' : formatDate(DatesList[DatesList.length - 1]);

                                console.log("DatesList")
                                console.log(DatesList)
                                return (

                                    <tr className="custom-table-head-td" key={index}>
                                        <td className="table-body">{index + 1}</td>
                                        <td className="table-body" style={{ textAlign: "center" }}>
                                            <img
                                                className="profile-img"
                                                src={
                                                    `${item.profilepic}`
                                                        ? `${IMAGE_URL}${item.profilepic}`
                                                        : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                                }
                                                alt="profilepic"
                                            />
                                            <p>{item.empcode}-{item.emp_name}</p>
                                        </td>
                                        <td className="table-body">
                                            {item.projectid ?
                                                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                                    <tbody>
                                                        <tr>
                                                            <td className="table-body" style={{ paddingBottom: "5px" }}>Project Code</td>
                                                            <td style={{ padding: "0px 4px" }}>:</td>
                                                            <td className="align-right">
                                                                {item.ref_project_code}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="table-body" style={{ paddingBottom: "5px" }}>Project Name</td>
                                                            <td style={{ padding: "0px 4px" }}>:</td>
                                                            <td className="align-right">
                                                                {item.ref_project_name}
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table> :
                                                <div className="justify-center">
                                                    - No Project Referenced -
                                                </div>
                                            }
                                        </td>
                                        <td className="table-body">
                                            <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                                <tbody>
                                                    <tr>
                                                        <td className="table-body" style={{ paddingBottom: "5px" }}>Imprest Name</td>
                                                        <td style={{ padding: "0px 4px" }}>:</td>
                                                        <td className="align-right">
                                                            {item.project_name}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="table-body" style={{ paddingBottom: "5px" }}>Imprest Date</td>
                                                        <td style={{ padding: "0px 4px" }}>:</td>
                                                        <td className="align-right">
                                                            {formatDate(item.date)}
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </td>
                                        {item.rh_leave_status === "approved" ?
                                            <td className="table-body">
                                                <td className="align-right">
                                                    {/* <b> */}
                                                    {formatCurrencyIndian(
                                                        Number(item.approved_transportation) +
                                                        Number(item.approved_accomodation) +
                                                        Number(item.approved_fooding) +
                                                        (item.other_details?.length > 0
                                                            ? item.other_details.reduce(
                                                                (total, other) =>
                                                                    total + Number(other.approved_other || 0),
                                                                0
                                                            )
                                                            : 0)
                                                    )}
                                                    {" "}
                                                    Rs.
                                                    {/* </b> */}
                                                </td>
                                            </td>
                                            :
                                            <td className="table-body">
                                                <td className="align-right">
                                                    {/* <b> */}
                                                    {formatCurrencyIndian(
                                                        Number(item.transportation) +
                                                        Number(item.accomodation) +
                                                        Number(item.fooding) +
                                                        (item.other_details?.length > 0
                                                            ? item.other_details.reduce((total, other) => total + Number(other.amount || 0), 0)
                                                            : 0)
                                                    )}
                                                    {" "}
                                                    Rs.
                                                    {/* </b>  */}
                                                </td>
                                            </td>}
                                        <td className="table-body">
                                            {item.account_update_datetime ? <>At: {formatDateTime(item.account_update_datetime).date}</> : null}</td>
                                        <td title="Imprest Approval Status" className="table-body width-10vw">
                                            <span
                                                request-status={item.rh_leave_status}
                                                className="request-status "
                                            >
                                                {item.rh_leave_status === "approved" ?
                                                    <>
                                                        Rh Approved :
                                                        {item.rh_assigned}- {item.rh_assigned_name}
                                                        {item.rh_update_datetime ? <><br />At: {formatDateTime(item.rh_update_datetime).date}</> : ''}
                                                    </>
                                                    : item.rh_leave_status === "rejected"
                                                        ? <>Rh ✖,<br />Rh Reject Reason:<br />"{item.rejection_reason}"</>
                                                        : "Pending For Rh Approval ⌛"}
                                            </span>
                                            {/* <br /> */}
                                            <br />
                                            <span
                                                request-status={item.admin_leave_status}
                                                className="request-status "
                                            >
                                                {item.admin_leave_status === "approved" ?
                                                    <>
                                                        Admin {item.admin_update_by ? `(${item.admin_update_by})` : ''} Approved
                                                        {item.admin_update_datetime ? <><br />At: {formatDateTime(item.admin_update_datetime).date}</> : ''}
                                                    </>
                                                    : item.admin_leave_status === "rejected"
                                                        ? <>Admin ✖,<br />Admin Reject Reason: "{item.admin_rejection_reason}"</>
                                                        : "Pending For Admin Approval ⌛"}
                                            </span>
                                            {/* <br /> */}
                                            <br />
                                            <span
                                                request-status={item.account_leave_status}
                                                className="request-status "
                                            >
                                                {item.account_leave_status === "approved" ?
                                                    <>
                                                        Accounts{item.account_update_by ? `(${item.account_update_by})` : ''} Approved
                                                        {item.account_update_datetime ? <><br />At: {formatDateTime(item.account_update_datetime).date}</> : ''}
                                                    </>
                                                    : item.account_leave_status === "rejected"
                                                        ? <>Accounts ✖,<br />Accounts Reject Reason:<br />"{item.account_rejection_reason}"</>
                                                        : "Pending For Account Approval ⌛"}
                                            </span>
                                        </td>
                                        {
                                            item.expense_data.length > 0 ?
                                                <>
                                                    <td className="table-body">
                                                        <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="table-body" style={{ paddingBottom: "5px" }}>Expense Name</td>
                                                                    <td style={{ padding: "0px 4px" }}>:</td>
                                                                    <td className="align-right">
                                                                        {item.expense_data[0]?.name}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="table-body" style={{ paddingBottom: "5px" }}>Expense Date</td>
                                                                    <td style={{ padding: "0px 4px" }}>:</td>
                                                                    <td className="align-right">
                                                                        {formatDate(item.expense_data[0]?.date)}
                                                                    </td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    <td className="table-body align-center">{item.expense_data[0]?.utilized_amount ? formatRoundoff2D(item.expense_data[0]?.utilized_amount) : '-'} Rs</td>
                                                    <td className="table-body">{FirstBillDate} to {LastBillDate}</td>
                                                    <td title="Expense Approval Status" className="table-body">
                                                        <span
                                                            request-status={item.expense_data[0]?.rh_status}
                                                            className="request-status "
                                                        >
                                                            {item.expense_data[0]?.rh_status === "pending"
                                                                ? "Pending For Rh Approval ⌛"
                                                                : item.expense_data[0]?.rh_status === "rejected"
                                                                    ? `Rh Reject Reason : ${item.expense_data[0]?.rh_rejection_reason}`
                                                                    : `Rh${item.expense_data[0]?.rh_assigned ? `(${item.expense_data[0]?.rh_assigned}) ` : ''}: Verified Amounts for Bills ${item.expense_data[0]?.rh_update_datetime ? `At: ${formatDateTime(item.expense_data[0]?.rh_update_datetime).date}` : ''}`}
                                                        </span>
                                                        <br />
                                                        <span
                                                            request-status={item.expense_data[0]?.account_status_a}
                                                            className="request-status "
                                                        >
                                                            {item.expense_data[0]?.account_status_a === "pending"
                                                                ? "Pending For Account Approval ⌛"
                                                                : item.expense_data[0]?.account_status_a === "rejected"
                                                                    ? `Accounts Reject Reason : ${item.expense_data[0]?.account1_rejection_reason}`
                                                                    : `Accounts${item.expense_data[0]?.account_update_by ? `(${item.expense_data[0]?.account_update_by}) ` : ''}: Bills Verified ${item.expense_data[0]?.account_update_datetime ? `At: ${formatDateTime(item.expense_data[0]?.account_update_datetime).date}` : ''}`}
                                                        </span>
                                                        <br />
                                                        <span
                                                            request-status={item.expense_data[0]?.admin_status}
                                                            className="request-status "
                                                            title={item.expense_data[0]?.admin_status === "rejected" ? `Admin Reject Reason : ${item.expense_data[0]?.admin_rejection_reason}` : ""}
                                                        >
                                                            {item.expense_data[0]?.admin_status === "pending"
                                                                ? "Pending For Admin Approval ⌛"
                                                                : item.expense_data[0]?.admin_status === "rejected"
                                                                    ? `Admin Rejection Reason : ${item.expense_data[0]?.admin_rejection_reason}`
                                                                    : `Admin${item.expense_data[0]?.admin_update_by ? `(${item.expense_data[0]?.admin_update_by}) ` : ''}: Approved After Final Verification  ${item.expense_data[0]?.admin_update_datetime ? `At: ${formatDateTime(item.expense_data[0]?.admin_update_datetime).date}` : ''}`}
                                                        </span>
                                                        <br />
                                                        <span
                                                            request-status={item.expense_data[0]?.account_status_b}
                                                            className="request-status "
                                                        >
                                                            {item.expense_data[0]?.account_status_b === "pending"
                                                                ? "Final Stage Pending⌛"
                                                                : item.expense_data[0]?.account_status_b === "rejected"
                                                                    ? `Final Rejection Reason : ${item.expense_data[0]?.account2_rejection_reason}`
                                                                    : `Final${item.expense_data[0]?.final_update_by ? `(${item.expense_data[0]?.final_update_by}) ` : ''}: Approved And Settled ${item.expense_data[0]?.final_update_datetime ? `At: ${formatDateTime(item.expense_data[0]?.final_update_datetime).date}` : ''}`}
                                                        </span>
                                                    </td>
                                                </> :
                                                <td colSpan={4} className="table-body align-center">- No Expense Details Updated Yet -</td>

                                        }

                                    </tr>
                                )
                            }
                            )}
                        </tbody>}
                </table>
            </div>
        </div>
    );
};

export {
    AddProjectFromImprest,

    EmployeeImprestExpenseDashboard,
    ImprestExpenseDashboard
};
